<template>
  <div>
    <!-- header -->
    <header class="header header-absolute" id="pageTop">
      <!-- Top Color Bar -->
      <div class="color-bars">
        <div class="container-fluid">
          <div class="row">
            <div class="col color-bar bg-warning d-none d-md-block"></div>
            <div class="col color-bar bg-success d-none d-md-block"></div>
            <div class="col color-bar bg-danger d-none d-md-block"></div>
            <div class="col color-bar bg-info d-none d-md-block"></div>
            <div class="col color-bar bg-purple d-none d-md-block"></div>
            <div class="col color-bar bg-pink d-none d-md-block"></div>
            <div class="col color-bar bg-warning"></div>
            <div class="col color-bar bg-success"></div>
            <div class="col color-bar bg-danger"></div>
            <div class="col color-bar bg-info"></div>
            <div class="col color-bar bg-purple"></div>
            <div class="col color-bar bg-pink"></div>
          </div>
        </div>
      </div>

      <!-- Top Bar-->
      <!-- d-none d-md-block -->
      <div class="bg-stone top-bar d-none d-md-block">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 d-none d-lg-block">
              <ul
                class="list-inline d-flex justify-content-xl-start align-items-center h-100 mb-0"
              >
                <li>
                  <time
                    class="mr-lg-4 mr-xl-6 text-white opacity-80"
                    :datetime="`${year}-${month}-${day}`"
                    >{{ month }} {{ day }}, {{ year }}</time
                  >
                </li>
              </ul>
            </div>

            <div class="col-lg-7">
              <ul
                class="list-inline d-flex mb-0 justify-content-xl-end justify-content-center align-items-center mr-xl-2"
              >
                <li class="text-white mr-md-3 mr-lg-2 mr-xl-5">
                  <img src="assets/img/coco_search.png" width="32">
                </li>
                <li class="mr-3 mr-md-3 mr-lg-2 mr-xl-5">
                  <form action="" class="d-flex align-items-center">
                    <div
                      class="input-group shadow-light rounded-sm input-addon"
                    >
                      <input
                        type="text"
                        class="form-control py-4"
                        placeholder="search..."
                        aria-describedby="basic-addon21"
                        v-model="searchText"
                      />
                      <div class="input-group-append" @click="search">
                        <div class="input-group-text bg-danger">
                          <i
                            class="fa fa-search text-white"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </li>
                <li class="text-white mr-md-3 mr-lg-2 mr-xl-5">
                  <a class="text-white font-weight-medium opacity-80" href="javascript:void(0)" @click="handleMemberLink">
                    <span class="rounded-sm bg-purple icon-small icon-badge ">
                      <i class="fa fa-user text-white" aria-hidden="true" style="margin-top: 6px;"></i>
                    </span>
                  </a>
                </li>

                <li class="cart-dropdown d-none d-md-block">
                  <div
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-display="static"
                  >
                    <a href="javascript:void(0)">
                      <span
                        class="rounded-sm bg-info icon-small icon-badge d-none close-icon"
                      >
                        <i
                          class="fa fa-close text-white"
                          aria-hidden="true"
                        ></i>
                      </span>
                      <span
                        class="rounded-sm bg-info icon-small icon-badge shopping-icon"
                      >
                        <i
                          class="fa fa-binoculars text-white"
                          aria-hidden="true"
                          style="margin-top: 6px;"
                        ></i>
                      </span>
                    </a>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="bg-white list-unstyled">
                      <li class="d-flex align-items-center">
                        <i
                          class="fa fa-binoculars font-size-20 mr-3"
                          aria-hidden="true"
                        ></i>
                        <h3 class="text-capitalize font-weight-bold mb-0">
                          依財商年齡瀏覽
                        </h3>
                      </li>
                      <hr />
                      <li>
                        <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')">
                          <div class="media">
                            <div
                              class="media-icon-large bg-success rounded shadow-sm mr-4"
                            >
                              <i class="fa fa-leaf" aria-hidden="true"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-success text-truncate">
                                理財幼幼級
                              </h3>
                              <p>適合初學者的你</p>
                            </div>
                          </div>
                        </a>
                        <hr />
                      </li>
                      <li>
                        <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 2, '/fqgame/elementary')">
                          <div class="media">
                            <div
                              class="media-icon-large bg-info rounded shadow-sm mr-4"
                            >
                              <i class="fa fa-futbol-o" aria-hidden="true"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-info text-truncate">
                                理財國小級
                              </h3>
                              <p>適合有點基礎的你</p>
                            </div>
                          </div>
                        </a>
                        <hr />
                      </li>
                      <li>
                        <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 3, '/fqgame/junior')">
                          <div class="media">
                            <div
                              class="media-icon-large bg-danger rounded shadow-sm mr-4"
                            >
                              <i class="fa fa-gamepad" aria-hidden="true"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-danger text-truncate">
                                理財國中級
                              </h3>
                              <p>適合進階的你</p>
                            </div>
                          </div>
                        </a>
                        <hr />
                      </li>
                      <li>
                        <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 4, '/fqgame/senior')">
                          <div class="media">
                            <div
                              class="media-icon-large bg-warning rounded shadow-sm mr-4"
                            >
                              <i
                                class="fa fa-graduation-cap"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-warning text-truncate">
                                理財高中級
                              </h3>
                              <p>適合想挑戰的你</p>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, -1, '/fqgame/108course')">
                          <div class="media">
                            <div
                              class="media-icon-large bg-warning rounded shadow-sm mr-4"
                            >
                              <i
                                class="fa fa-graduation-cap"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div class="media-body">
                              <h3 class="text-warning text-truncate">
                                108課綱
                              </h3>
                              <p>適合想挑戰的你</p>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Navbar -->
      <nav
        class="navbar navbar-expand-lg navbar-scrollUp navbar-sticky navbar-light-dark"
      >
        <div class="container flex-nowrap">
          <a href="/" class="navbar-brand mr-0 mr-sm-auto">
            <img
              class="header__logo d-inline-block"
              src="assets/img/logo-school.png"
              alt="logo"
            />
          </a>

          <div class=" mr-1 mr-sm-5 d-md-none">
            <img src="assets/img/coco_search.png" class="coco__mobile">
          </div>

          <!-- cart-dropdown -->
          <div class="dropdown cart-dropdown ml-auto mr-1 mr-sm-5 d-md-none">
            <div
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <a href="javascript:void(0)">
                <span
                  class="rounded-sm bg-info icon-small icon-badge d-none close-icon"
                >
                  <i class="fa fa-close text-white" aria-hidden="true"></i>
                </span>
                <span
                  class="rounded-sm bg-info icon-small icon-badge shopping-icon"
                >
                  <i class="fa fa-binoculars text-white" aria-hidden="true" style="margin-top: 6px;"></i>
                </span>
              </a>
            </div>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="bg-white list-unstyled">
                <li class="d-flex align-items-center">
                  <i
                    class="fa fa-binoculars font-size-20 mr-3"
                    aria-hidden="true"
                  ></i>
                  <h3 class="text-capitalize font-weight-bold mb-0">
                    依財商年齡瀏覽
                  </h3>
                </li>
                <hr />
                <li>
                  <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')">
                    <div class="media">
                      <div
                        class="media-icon-large bg-success rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-leaf" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-success text-truncate">理財幼幼級</h3>
                        <p>適合初學者的你</p>
                      </div>
                    </div>
                  </a>
                  <hr />
                </li>
                <li>
                  <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 2, '/fqgame/elementary')">
                    <div class="media">
                      <div
                        class="media-icon-large bg-info rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-futbol-o" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-info text-truncate">理財國小級</h3>
                        <p>適合有點基礎的你</p>
                      </div>
                    </div>
                  </a>
                  <hr />
                </li>
                <li>
                  <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 3, '/fqgame/junior')">
                    <div class="media">
                      <div
                        class="media-icon-large bg-danger rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-gamepad" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-danger text-truncate">理財國中級</h3>
                        <p>適合進階的你</p>
                      </div>
                    </div>
                  </a>
                  <hr />
                </li>
                <li>
                  <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, 4, '/fqgame/senior')">
                    <div class="media">
                      <div
                        class="media-icon-large bg-warning rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-warning text-truncate">理財高中級</h3>
                        <p>適合想挑戰的你</p>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" style="display: block;" @click="checkGradeIsAvaliable($event, -1, '/fqgame/108course')">
                    <div class="media">
                      <div
                        class="media-icon-large bg-warning rounded shadow-sm mr-4"
                      >
                        <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                      </div>
                      <div class="media-body">
                        <h3 class="text-warning text-truncate">108課綱</h3>
                        <p>適合想挑戰的你</p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class=" mr-1 mr-sm-5 d-md-none">
            <a class="text-white font-weight-medium opacity-80" href="javascript:void(0)" @click="handleMemberLink">
              <span class="rounded-sm bg-purple icon-small icon-badge ">
                <i class="fa fa-user text-white" aria-hidden="true" style="margin-top: 6px;"></i>
              </span>
            </a>
          </div>

          <button
            class="navbar-toggler py-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fa fa-bars"></i>
          </button>

          <div class="collapse navbar-collapse" id="navbarContent">
            <ul class="navbar-nav ml-lg-auto">
              <li class="nav-item dropdown bg-warning">
                <router-link to="/fqgame"
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i class="fa fa-graduation-cap nav-icon" aria-hidden="true"></i>
                  <span>FQ大冒險</span>
                </router-link>
                <a @click.once="openDropDown('#dropdown-menu-1')" href="javascript:void(0)" class="click-preventer" data-toggle="dropdown"></a>
                <ul id="dropdown-menu-1" class="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')">理財幼幼級</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 2, '/fqgame/elementary')">理財國小級</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 3, '/fqgame/junior')">理財國中級</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" @click="checkGradeIsAvaliable($event, 4, '/fqgame/senior')">理財高中級</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="javascript:void(0)" @click="checkGradeIsAvaliable($event, -1, '/fqgame/108course')">108課綱</a>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-danger">
                <router-link to="/topic"
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown">
                  <i class="fa fa-list-ul nav-icon" aria-hidden="true"></i>
                  <span>主題區</span>
                </router-link>
                <a @click.once="openDropDown('#dropdown-menu-2')" href="javascript:void(0)" class="click-preventer" data-toggle="dropdown"></a>
                <ul id="dropdown-menu-2" class="dropdown-menu">
                  <li>
                    <router-link to="/topic/management" class="dropdown-item">我為何要學理財</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/track-expenses" class="dropdown-item">記帳教我的事</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/pocket-money" class="dropdown-item">零用錢三堂課</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/currency" class="dropdown-item">認識貨幣跟外匯</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/investment" class="dropdown-item">認識投資商品</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/balance-sheet" class="dropdown-item">人生資產負債表</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/board-game" class="dropdown-item">桌遊學理財</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/column" class="dropdown-item">專家觀點</router-link>
                  </li>
                  <li>
                    <router-link to="/topic/majashow" class="dropdown-item">小馬哈影音秀</router-link>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-success">
                <router-link to="/learning-sheet"
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i class="fa fa-file-text-o nav-icon" aria-hidden="true"></i>
                  <span>爸媽請進</span>
                </router-link>
                <a @click.once="openDropDown('#dropdown-menu-3')" href="javascript:void(0)" class="click-preventer" data-toggle="dropdown"></a>
                <ul id="dropdown-menu-3" class="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="callLearningSheetAnchor($route.query, '/learning-sheet?anchor=levelA')">理財幼幼級練習單</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="callLearningSheetAnchor($route.query, '/learning-sheet?anchor=levelB')">理財國小級練習單</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="callLearningSheetAnchor($route.query, '/learning-sheet?anchor=levelC')">理財國中級練習單</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="callLearningSheetAnchor($route.query, '/learning-sheet?anchor=levelD')">理財高中級練習單</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="callLearningSheetAnchor($route.query, '/learning-sheet?anchor=levelE')">108 課綱</a>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-info">
                <router-link to="/course"
                  class="nav-link dropdown-toggle"
                  id="stores"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i class="fa fa-pencil-square-o nav-icon" aria-hidden="true"></i>
                  <span>理財課程</span>
                </router-link>
                <a @click.once="openDropDown('#dropdown-menu-4')" href="javascript:void(0)" class="click-preventer" data-toggle="dropdown"></a>
                <ul id="dropdown-menu-4" class="dropdown-menu" aria-labelledby="stores">
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="callCourseAnchor($route.query, '/course?anchor=online', false)">線上課程</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" @click.prevent="callCourseAnchor($route.query, '/course?anchor=offline', true)">線下課程</a>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-purple">
                <router-link to="/rank"
                  class="nav-link dropdown-toggle"
                  id="stores"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i class="fa fa-trophy nav-icon" aria-hidden="true"></i>
                  <span>排行榜</span>
                </router-link>
                <a @click.once="openDropDown('#dropdown-menu-5')" href="javascript:void(0)" class="click-preventer" data-toggle="dropdown"></a>
                <ul
                  class="dropdown-menu dropdown-menu-right" id="dropdown-menu-5"
                  aria-labelledby="stores"
                >
                  <li>
                    <router-link to="/rank" class="dropdown-item">幼幼王排行榜</router-link>
                  </li>
                  <li>
                    <router-link to="/rank" class="dropdown-item">國小王排行榜</router-link>
                  </li>
                  <li>
                    <router-link to="/rank" class="dropdown-item">國中王排行榜</router-link>
                  </li>
                  <li>
                    <router-link to="/rank" class="dropdown-item">高中王排行榜</router-link>
                  </li>
                  <li>
                    <router-link to="/rank" class="dropdown-item">點數王排行榜</router-link>
                  </li>
                </ul>
              </li>

              <li class="nav-item dropdown bg-pink">
                <router-link to="/activity"
                  class="nav-link dropdown-toggle"
                  id="stores"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i class="fa fa-picture-o nav-icon" aria-hidden="true"></i>
                  <span>活動實錄</span>
                </router-link>
                <a @click.once="openDropDown('#dropdown-menu-6')" href="javascript:void(0)" class="click-preventer" data-toggle="dropdown"></a>
                <ul
                  id="dropdown-menu-6"
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="stores"
                >
                  <li>
                    <router-link to="/activity/camp" class="dropdown-item">營隊</router-link>
                  </li>
                  <li>
                    <router-link to="/activity/course" class="dropdown-item">課程</router-link>
                  </li>
                  <li>
                    <router-link to="/activity/lecture" class="dropdown-item">企業講座</router-link>
                  </li>
                  <li>
                    <router-link to="/activity/welfare" class="dropdown-item">公益團體</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <div class="main-wrapper home-daycare pt-0 pt-md-8">
      <div class="d-none d-lg-block" dir="ltr" style="background: #a1d2fb">
        <svg
          id="sky_day"
          data-name="sky day"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1295.87 650"
        >
          <g>
            <rect
              id="day"
              class="cls-1"
              x="-8.79"
              y="-27.96"
              width="1340"
              height="700"
            />
            <g id="clouds-front">
              <g id="cloud-1" class="cloud-all">
                <path
                  class="cls-6"
                  d="M899.69,71.14a26.77,26.77,0,0,0-3,0c-8.74,0-15.84,4.89-16.61,11.11a28.73,28.73,0,0,0-10.76-2c-12.24,0-22.17,7.22-22.17,16.13,0,0,0,.07,0,0.1-9.18,2.27-15.78,8.58-15.78,16,0,9.36,10.42,16.94,23.28,16.94H899.5Z"
                />
                <path
                  class="cls-7"
                  d="M899.49,71.14a23.27,23.27,0,0,1,2.81,0c8.74,0,15.84,4.89,16.61,11.11a28.73,28.73,0,0,1,10.76-2c12.24,0,22.17,7.22,22.17,16.13,0,0,0,.07,0,0.1,9.18,2.27,15.78,8.58,15.78,16,0,9.36-10.42,16.94-23.28,16.94H899.49V71.14Z"
                />
              </g>
              <g id="cloud-4" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M1151.17,173.76a12.85,12.85,0,0,0-1.91-.14c-5.81,0-10.53,4-11,9.17a15.89,15.89,0,0,0-7.15-1.68c-8.14,0-14.73,6-14.73,13.32,0,0,0,.06,0,0.09-6.1,1.88-10.49,7.09-10.49,13.23,0,7.73,6.93,14,15.48,14h30Z"
                />
                <path
                  class="cls-7"
                  d="M1151.08,173.75a12.8,12.8,0,0,1,1.91-.13c5.81,0,10.53,4,11,9.17a15.89,15.89,0,0,1,7.15-1.68c8.14,0,14.73,6,14.73,13.32,0,0,0,.06,0,0.09,6.1,1.88,10.49,7.09,10.49,13.23,0,7.73-6.93,14-15.48,14h-29.81Z"
                />
              </g>
              <g id="cloud-6" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M361,176.6a34.45,34.45,0,0,0-3.83,0c-9.72,0-17.61,6.12-18.46,13.91a28.82,28.82,0,0,0-12-2.55c-13.61,0-24.64,9-24.64,20.21,0,0,0,.09,0,0.13-10.2,2.85-17.55,10.75-17.55,20.08,0,11.72,11.59,21.22,25.88,21.22H361v-73Z"
                />
                <path
                  class="cls-7"
                  d="M360.29,176.54a23.12,23.12,0,0,1,3.13.05c9.72,0,17.61,6.12,18.46,13.91a28.82,28.82,0,0,1,12-2.55c13.61,0,24.64,9,24.64,20.21,0,0,0,.09,0,0.13C428.68,211.14,436,219,436,228.37c0,11.72-11.59,21.22-25.88,21.22H360.29V176.54Z"
                />
              </g>
            </g>
            <g id="clouds-back">
              <g id="cloud-2" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M1075.9,143.73a25.08,25.08,0,0,0-3.06-.18c-9.21,0-16.69,5.29-17.5,12a29.61,29.61,0,0,0-11.34-2.2c-12.9,0-23.36,7.81-23.36,17.44,0,0,0,.07,0,0.11-9.67,2.46-16.63,9.28-16.63,17.33,0,10.12,11,18.31,24.53,18.31h47.67Z"
                />
                <path
                  class="cls-7"
                  d="M1075.8,143.72a23.49,23.49,0,0,1,3-.17c9.21,0,16.69,5.29,17.5,12a29.61,29.61,0,0,1,11.34-2.2c12.9,0,23.36,7.81,23.36,17.44,0,0,0,.07,0,0.11,9.67,2.46,16.63,9.28,16.63,17.33,0,10.12-11,18.31-24.53,18.31H1075.8V143.72Z"
                />
              </g>
              <g id="cloud-3" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M430.87,168.15a21.33,21.33,0,0,0-2.39-.07c-6.16,0-11.15,3.82-11.7,8.68a18.49,18.49,0,0,0-7.58-1.59c-8.62,0-15.61,5.64-15.61,12.61,0,0,0,.05,0,0.08-6.46,1.78-11.12,6.71-11.12,12.52,0,7.31,7.34,13.24,16.39,13.24,4.44,0,18.37-.07,32-0.07v-45.4Z"
                />
                <path
                  class="cls-7"
                  d="M430.46,168.11a14.69,14.69,0,0,1,2,0c6.16,0,11.15,3.82,11.7,8.68a18.49,18.49,0,0,1,7.58-1.59c8.62,0,15.61,5.64,15.61,12.61,0,0,0,.05,0,0.08,6.46,1.78,11.12,6.71,11.12,12.52,0,7.31-7.34,13.24-16.39,13.24-4.44,0-18-.07-31.58-0.07V168.11Z"
                />
              </g>
              <g id="cloud-5" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M539.43,64.77a31.69,31.69,0,0,0-3.17-.08c-8,0-14.45,4.52-15.15,10.27a25.88,25.88,0,0,0-9.82-1.88c-11.17,0-20.22,6.68-20.22,14.92,0,0,0,.06,0,0.1-8.37,2.1-14.4,7.94-14.4,14.83,0,8.65,9.51,15.67,21.23,15.67,5.75,0,23.87-.08,41.51-0.08V64.77Z"
                />
                <path
                  class="cls-7"
                  d="M538.82,64.72a20.83,20.83,0,0,1,2.57,0c8,0,14.45,4.52,15.15,10.27a25.88,25.88,0,0,1,9.82-1.88c11.17,0,20.22,6.68,20.22,14.92,0,0,0,.06,0,0.1C594.93,90.2,601,96,601,102.92c0,8.65-9.51,15.67-21.23,15.67-5.75,0-23.26-.08-40.9-0.08V64.72Z"
                />
              </g>
            </g>
          </g>

          <g id="rollercoaster" class="cls-8">
            <g id="down">
              <g id="goriz">
                <path
                  class="cls-9"
                  d="M109.29,327.06c-1.34,2.64-5,8-6.3,10.69-1.91-3.77-3.86-9.12-5.77-12.88-1.92,3.77-3.8,9.12-5.72,12.88l-5.75-11.3L80,337.75l-5.75-11.3-5.75,11.3-2.3-4.52"
                />
                <path
                  class="cls-9"
                  d="M119.81,330.26c-0.19.38-5.13,7.12-5.33,7.49-1-1.88-4.23-8.8-5.19-10.69"
                />
                <path
                  class="cls-9"
                  d="M131.7,352.81c-0.3-.59-3.72-8.6-5.6-12.31"
                />
                <path
                  class="cls-9"
                  d="M404.1,332.18l-2.82,5.57-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-4.59-9"
                />
                <path
                  class="cls-9"
                  d="M141.84,355.65c-1,1.88-3.44,6.57-4.4,8.45l-5.74-11.3L126,364.11l-5.74-11.3-5.74,11.3-5.75-11.3L103,364.11l-5.74-11.3-5.75,11.3-5.75-11.3L80,364.11l-5.75-11.3-5.75,11.3-5.75-11.3L57,364.11"
                />
                <path class="cls-9" d="M348.55,355.07l-4.58,9-1.72-3.39" />
                <path
                  class="cls-9"
                  d="M414.59,360.46l-1.85,3.64L407,352.81l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.15-10.17"
                />
                <path
                  class="cls-9"
                  d="M161.54,386.32l-1.15,2.26-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,388.58l-5.74-11.3-5.74,11.3-5.75-11.3L103,388.58l-5.74-11.3-5.75,11.3-5.75-11.3L80,388.58l-5.75-11.3-5.75,11.3-5.75-11.3L57,388.58"
                />
                <path
                  class="cls-9"
                  d="M428.1,380.93l-3.89,7.65-5.74-11.3-5.74,11.3L407,377.29l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,388.58l-5.73-11.3-5.73,11.3-5.73-11.3L321,388.58l-2.87-5.65"
                />
                <path
                  class="cls-9"
                  d="M188.52,404.77l-5.17,10.17-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,414.94l-5.74-11.3-5.74,11.3-5.75-11.3L103,414.94l-5.74-11.3-5.75,11.3-5.75-11.3L80,414.94l-5.75-11.3-5.75,11.3-5.75-11.3L57,414.94"
                />
                <path class="cls-9" d="M194.83,414.94l-5.16-10.17" />
                <path
                  class="cls-9"
                  d="M449.64,410l-2.49,4.9-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3L407,403.64l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,414.94l-5.73-11.3-5.73,11.3-5.73-11.3L321,414.94l-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-2.87-5.65"
                />
                <path class="cls-9" d="M538.9,414.94l-3-5.94" />
                <path
                  class="cls-9"
                  d="M511.14,431.78L516,441.3,521.7,430l5.73,11.3,5.73-11.3c2.11,4.14,4.35,8.21,6.46,12.35"
                />
                <path
                  class="cls-9"
                  d="M57,441.3L62.77,430l5.75,11.3L74.27,430,80,441.3,85.76,430l5.75,11.3L97.25,430,103,441.3l5.74-11.3,5.75,11.3,5.74-11.3L126,441.3,131.7,430l5.74,11.3,5.74-11.3,5.73,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3L189.1,430l5.74,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3L235,430l5.74,11.3,5.74-11.3,5.74,11.3L258,430l5.73,11.3,5.73-11.3,5.74,11.3L280.9,430l5.74,11.3,5.73-11.3,5.73,11.3,5.73-11.3,5.73,11.3L315.3,430,321,441.3l5.74-11.3,5.73,11.3,5.73-11.3L344,441.3,349.7,430l5.73,11.3,5.73-11.3,5.73,11.3,5.73-11.3,5.74,11.3,5.74-11.3,5.73,11.3,5.73-11.3,5.73,11.3L407,430l5.74,11.3,5.74-11.3,5.74,11.3,5.73-11.3,5.73,11.3,5.73-11.3,5.73,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.73,11.3,5.73-11.3,5.73,11.3,5.73-11.3L493,441.3l5.74-11.3,5.74,11.3,4.83-9.51"
                />
                <path
                  class="cls-9"
                  d="M539.31,468.91c-1.91-3.77-4.23-8.78-6.14-12.55l-5.73,11.3-5.73-11.3L516,467.66l-5.73-11.3-5.74,11.3-5.74-11.3L493,467.66l-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3L407,456.36l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,467.66l-5.73-11.3-5.73,11.3-5.73-11.3L321,467.66l-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.73,11.3L258,456.36l-5.74,11.3-5.74-11.3-5.74,11.3L235,456.36l-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,467.66l-5.74-11.3-5.74,11.3-5.75-11.3L103,467.66l-5.74-11.3-5.75,11.3-5.75-11.3L80,467.66l-5.75-11.3-5.75,11.3-5.75-11.3L57,467.66"
                />
                <path
                  class="cls-9"
                  d="M538.9,494l-5.73-11.3L527.44,494l-5.73-11.3L516,494l-5.73-11.3L504.5,494l-5.74-11.3L493,494l-5.73-11.3L481.56,494l-5.73-11.3L470.09,494l-5.73-11.3L458.62,494l-5.74-11.3L447.15,494l-5.73-11.3L435.68,494l-5.73-11.3L424.21,494l-5.74-11.3L412.74,494,407,482.72,401.28,494l-5.73-11.3L389.82,494l-5.73-11.3L378.35,494l-5.74-11.3L366.88,494l-5.73-11.3L355.43,494l-5.73-11.3L344,494l-5.73-11.3L332.51,494l-5.73-11.3L321,494l-5.74-11.3L309.57,494l-5.73-11.3L298.1,494l-5.73-11.3L286.64,494l-5.74-11.3L275.16,494l-5.74-11.3L263.69,494,258,482.72,252.22,494l-5.74-11.3L240.74,494,235,482.72,229.27,494l-5.74-11.3L217.79,494l-5.74-11.3L206.32,494l-5.74-11.3L194.83,494l-5.74-11.3L183.36,494l-5.74-11.3L171.87,494l-5.74-11.3L160.39,494l-5.74-11.3L148.91,494l-5.73-11.3L137.44,494l-5.74-11.3L126,494l-5.74-11.3L114.48,494l-5.75-11.3L103,494l-5.74-11.3L91.5,494l-5.75-11.3L80,494l-5.75-11.3L68.52,494l-5.75-11.3L57,494"
                />
                <path
                  class="cls-9"
                  d="M538.9,518.49l-5.73-11.3-5.73,11.3-5.73-11.3L516,518.49l-5.73-11.3-5.74,11.3-5.74-11.3L493,518.49l-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3L407,507.19l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,518.49l-5.73-11.3-5.73,11.3-5.73-11.3L321,518.49l-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.73,11.3L258,507.19l-5.74,11.3-5.74-11.3-5.74,11.3L235,507.19l-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,518.49l-5.74-11.3-5.74,11.3-5.75-11.3L103,518.49l-5.74-11.3-5.75,11.3-5.75-11.3L80,518.49l-5.75-11.3-5.75,11.3-5.75-11.3L57,518.49"
                />
                <path
                  class="cls-9"
                  d="M539.07,542.62c-1.79-.59-4.63-8.46-5.9-11L527.44,543l-5.73-11.3L516,543l-5.73-11.3L504.5,543l-5.74-11.3L493,543l-5.73-11.3L481.56,543l-5.73-11.3L470.09,543l-5.73-11.3L458.62,543l-5.74-11.3L447.15,543l-5.73-11.3L435.68,543l-5.73-11.3L424.21,543l-5.74-11.3L412.74,543,407,531.67,401.28,543l-5.73-11.3L389.82,543l-5.73-11.3L378.35,543l-5.74-11.3L366.88,543l-5.73-11.3L355.43,543l-5.73-11.3L344,543l-5.73-11.3L332.51,543l-5.73-11.3L321,543l-5.74-11.3L309.57,543l-5.73-11.3L298.1,543l-5.73-11.3L286.64,543l-5.74-11.3L275.16,543l-5.74-11.3L263.69,543,258,531.67,252.22,543l-5.74-11.3L240.74,543,235,531.67,229.27,543l-5.74-11.3L217.79,543l-5.74-11.3L206.32,543l-5.74-11.3L194.83,543l-5.74-11.3L183.36,543l-5.74-11.3L171.87,543l-5.74-11.3L160.39,543l-5.74-11.3L148.91,543l-5.73-11.3L137.44,543l-5.74-11.3L126,543l-5.74-11.3L114.48,543l-5.75-11.3L103,543l-5.74-11.3L91.5,543l-5.75-11.3L80,543l-5.75-11.3L68.52,543l-5.75-11.3L57,543"
                />
                <path
                  class="cls-9"
                  d="M126.1,338.84c-1.34,2.64-4.44,9.45-5.78,12.09l-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-4-7.91"
                />
                <path
                  class="cls-9"
                  d="M408.94,347.29l-1.85,3.64-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,339.63l-5.73,11.3-2.87-5.65"
                />
                <path
                  class="cls-9"
                  d="M148.45,366.23c-1.34,2.64-3.84,6.54-5.18,9.18l-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-5.75-11.3"
                />
                <path class="cls-9" d="M331.09,367.08l-4.22,8.32-1.15-2.26" />
                <path
                  class="cls-9"
                  d="M421,370.63l-2.42,4.77-5.74-11.3-5.74,11.3-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,364.11l-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-4.82-9.51"
                />
                <path
                  class="cls-9"
                  d="M170.24,393.85l-4,7.91-5.74-11.3-5.74,11.3L149,390.46l-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-5.75-11.3"
                />
                <path
                  class="cls-9"
                  d="M178.86,399.5l-1.15,2.26c-1-1.88-.42-2.28-1.38-4.16"
                />
                <path
                  class="cls-9"
                  d="M294.17,398.37l-1.72,3.39c-0.38-.75.72,0,0.34-0.77"
                />
                <path
                  class="cls-9"
                  d="M434.86,392.25L430,401.76l-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,390.46l-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3L300.48,395"
                />
                <path
                  class="cls-9"
                  d="M239.32,419.8l-4.23,8.32-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3L172,416.82l-5.74,11.3-5.74-11.3-5.74,11.3L149,416.82l-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-5.75-11.3"
                />
                <path
                  class="cls-9"
                  d="M467.76,421.58l-3.32,6.54-5.73-11.3L453,428.12l-5.74-11.3-5.74,11.3-5.73-11.3L430,428.12l-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,416.82l-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L281,428.12l-5.74-11.3-5.74,11.3-5.74-11.3L258,428.12l-5.73-11.3-5.74,11.3-3.93-7.73"
                />
                <path class="cls-9" d="M477.47,425.05l-1.56,3.06-2.72-5.35" />
                <path class="cls-9" d="M539,416.82l-5.73,11.3-2.72-5.35" />
                <polyline
                  class="cls-9"
                  points="539.63 442.35 533.25 454.48 527.52 443.18 521.79 454.48 516.05 443.18 510.32 454.48 504.59 443.18 498.85 454.48 493.12 443.18 487.38 454.48 481.64 443.18 475.91 454.48 470.18 443.18 464.44 454.48 458.71 443.18 452.98 454.48 447.24 443.18 441.5 454.48 435.77 443.18 430.03 454.48 424.3 443.18 418.56 454.48 412.83 443.18 407.09 454.48 401.36 443.18 395.63 454.48 389.9 443.18 384.18 454.48 378.44 443.18 372.71 454.48 366.97 443.18 361.24 454.48 355.51 443.18 349.78 454.48 344.05 443.18 338.32 454.48 332.59 443.18 326.86 454.48 321.13 443.18 315.39 454.48 309.65 443.18 303.92 454.48 298.19 443.18 292.46 454.48 286.72 443.18 280.99 454.48 275.25 443.18 269.51 454.48 263.78 443.18 258.04 454.48 252.31 443.18 246.57 454.48 240.83 443.18 235.09 454.48 229.36 443.18 223.62 454.48 217.88 443.18 212.14 454.48 206.4 443.18 200.67 454.48 194.93 443.18 189.19 454.48 183.45 443.18 177.71 454.48 171.97 443.18 166.23 454.48 160.49 443.18 154.74 454.48 149 443.18 143.26 454.48 137.1 444.27 132.04 453.77 126.05 443.18 120.32 454.48 114.57 443.18 108.83 454.48 103.09 443.18 97.35 454.48 91.6 443.18 85.86 454.48 80.11 443.18 74.37 454.48 68.62 443.18 62.87 454.48 57.12 443.18"
                />
                <polyline
                  class="cls-9"
                  points="539.31 468.9 533.25 480.83 527.52 469.54 521.79 480.83 516.05 469.54 510.32 480.83 504.59 469.54 498.85 480.83 493.12 469.54 487.38 480.83 481.64 469.54 475.91 480.83 470.18 469.54 464.44 480.83 458.71 469.54 452.98 480.83 447.24 469.54 441.5 480.83 435.77 469.54 430.03 480.83 424.3 469.54 418.56 480.83 412.83 469.54 407.09 480.83 401.36 469.54 395.63 480.83 389.9 469.54 384.18 480.83 378.44 469.54 372.71 480.83 366.97 469.54 361.24 480.83 355.51 469.54 349.78 480.83 344.05 469.54 338.32 480.83 332.59 469.54 326.86 480.83 321.13 469.54 315.39 480.83 309.65 469.54 303.92 480.83 298.19 469.54 292.46 480.83 286.72 469.54 280.99 480.83 275.25 469.54 269.51 480.83 263.78 469.54 258.04 480.83 252.31 469.54 246.57 480.83 240.83 469.54 235.09 480.83 229.36 469.54 223.62 480.83 217.88 469.54 212.14 480.83 206.4 469.54 200.67 480.83 194.93 469.54 189.19 480.83 183.45 469.54 177.71 480.83 171.97 469.54 166.23 480.83 160.49 469.54 154.74 480.83 149 469.54 144.75 482.32 139.02 471.03 131.8 480.83 126.05 469.54 120.32 480.83 114.57 469.54 108.83 480.83 103.09 469.54 97.35 480.83 91.6 469.54 85.86 480.83 80.11 469.54 74.37 480.83 68.62 469.54 62.87 480.83 57.12 469.54"
                />
                <polyline
                  class="cls-9"
                  points="538.99 494.01 533.25 505.31 527.52 494.01 521.79 505.31 516.05 494.01 510.32 505.31 504.59 494.01 498.85 505.31 493.12 494.01 487.38 505.31 481.64 494.01 475.91 505.31 470.18 494.01 464.44 505.31 458.71 494.01 452.98 505.31 447.24 494.01 441.5 505.31 435.77 494.01 430.03 505.31 424.3 494.01 418.56 505.31 412.83 494.01 407.09 505.31 401.36 494.01 395.63 505.31 389.9 494.01 384.18 505.31 378.44 494.01 372.71 505.31 366.97 494.01 361.24 505.31 355.51 494.01 349.78 505.31 344.05 494.01 338.32 505.31 332.59 494.01 326.86 505.31 321.13 494.01 315.39 505.31 309.65 494.01 303.92 505.31 298.19 494.01 292.46 505.31 286.72 494.01 280.99 505.31 275.25 494.01 269.51 505.31 263.78 494.01 258.04 505.31 252.31 494.01 246.57 505.31 240.83 494.01 235.09 505.31 229.36 494.01 223.62 505.31 217.88 494.01 212.14 505.31 206.4 494.01 200.67 505.31 194.93 494.01 189.19 505.31 183.45 494.01 177.71 505.31 171.97 494.01 166.23 505.31 160.49 494.01 154.74 505.31 149 494.01 143.26 505.31 137.53 494.01 131.8 505.31 126.05 494.01 120.32 505.31 114.57 494.01 108.83 505.31 103.09 494.01 97.35 505.31 91.6 494.01 85.86 505.31 80.11 494.01 74.37 505.31 68.62 494.01 62.87 505.31 57.12 494.01"
                />
                <polyline
                  class="cls-9"
                  points="538.99 518.49 533.25 529.78 527.52 518.49 521.79 529.78 516.05 518.49 510.32 529.78 504.59 518.49 498.85 529.78 493.12 518.49 487.38 529.78 481.64 518.49 475.91 529.78 470.18 518.49 464.44 529.78 458.71 518.49 452.98 529.78 447.24 518.49 441.5 529.78 435.77 518.49 430.03 529.78 424.3 518.49 418.56 529.78 412.83 518.49 407.09 529.78 401.36 518.49 395.63 529.78 389.9 518.49 384.18 529.78 378.44 518.49 372.71 529.78 366.97 518.49 361.24 529.78 355.51 518.49 349.78 529.78 344.05 518.49 338.32 529.78 332.59 518.49 326.86 529.78 321.13 518.49 315.39 529.78 309.65 518.49 303.92 529.78 298.19 518.49 292.46 529.78 286.72 518.49 280.99 529.78 275.25 518.49 269.51 529.78 263.78 518.49 258.04 529.78 252.31 518.49 246.57 529.78 240.83 518.49 235.09 529.78 229.36 518.49 223.62 529.78 217.88 518.49 212.14 529.78 206.4 518.49 200.67 529.78 194.93 518.49 189.19 529.78 183.45 518.49 177.71 529.78 171.97 518.49 166.23 529.78 160.49 518.49 154.74 529.78 149 518.49 143.26 529.78 137.53 518.49 131.8 529.78 126.05 518.49 120.32 529.78 114.57 518.49 108.83 529.78 103.09 518.49 97.35 529.78 91.6 518.49 85.86 529.78 80.11 518.49 74.37 529.78 68.62 518.49 62.87 529.78 57.12 518.49"
                />
                <path
                  class="cls-9"
                  d="M135.54,347.16c-0.19.38-3.65,5.27-3.84,5.65"
                />
              </g>
              <g id="vert">
                <path
                  class="cls-10"
                  d="M539,542.38c0.86-44.56.86-91.63,0-136.11"
                />
                <path
                  class="cls-10"
                  d="M527.69,541.13c0.86-41.43.86-137.81,0-178.4"
                />
                <path
                  class="cls-10"
                  d="M516.39,542.38c0.86-40.8.86-152.64,0-194"
                />
                <path
                  class="cls-10"
                  d="M504.6,542.81c-0.53-39.34-.53-166,0-206"
                />
                <path
                  class="cls-10"
                  d="M492.89,542.38c-0.12-40.8-.12-171.12,0-211.95"
                />
                <path
                  class="cls-10"
                  d="M481.65,542.23c-0.17-40.64-.17-176.22,0-217.33"
                />
                <path
                  class="cls-10"
                  d="M470.23,542.38c-0.05-42.68-.74-174.42-0.39-218"
                />
                <path
                  class="cls-10"
                  d="M458.5,542.38c0.39-44.56.39-167.66,0-213.68"
                />
                <path
                  class="cls-10"
                  d="M447.2,542.38c0.39-46.45.39-156.52,0-203"
                />
                <path
                  class="cls-10"
                  d="M435.9,542.38c0.39-50.21.39-134.52,0-186"
                />
                <path
                  class="cls-10"
                  d="M424.14,542.41c0.86-55.89.86-110.49,0-167.28"
                />
                <path
                  class="cls-10"
                  d="M412.84,542.38c0.86-61.51.86-123.64,0-186"
                />
                <line
                  class="cls-10"
                  x1="401.55"
                  y1="542.38"
                  x2="401.55"
                  y2="328.7"
                />
                <line
                  class="cls-10"
                  x1="378.96"
                  y1="541.96"
                  x2="378.96"
                  y2="324.9"
                />
                <path
                  class="cls-10"
                  d="M366.92,542.38c-0.29-69-.29-136.82,0-205.92"
                />
                <path
                  class="cls-10"
                  d="M355.54,543.15c-0.2-66-.2-130.06,0-195.36"
                />
                <line
                  class="cls-10"
                  x1="344.05"
                  y1="542.38"
                  x2="344.05"
                  y2="358.46"
                />
                <line
                  class="cls-10"
                  x1="332.67"
                  y1="542.29"
                  x2="332.67"
                  y2="368.11"
                />
                <path
                  class="cls-10"
                  d="M321.19,542.2c0.24-54.34.27-135.2,0.09-189.54"
                />
                <path
                  class="cls-10"
                  d="M309.8,542.38c0.27-50.92.41-158.24,0.27-209.15"
                />
                <path
                  class="cls-10"
                  d="M298,542.38c0.86-48.33.86-180.2,0-228.87"
                />
                <path
                  class="cls-10"
                  d="M286.7,542.38c0.86-46.45.86-209.45,0-257.12"
                />
                <path
                  class="cls-10"
                  d="M275.41,542.38c0.86-46.45.86-238.36,0-284.52"
                />
                <path
                  class="cls-10"
                  d="M264.11,542.38c0.86-44.56,1.5-251.51.64-297.26"
                />
                <path
                  class="cls-10"
                  d="M252.21,542.38c-0.42-44.56-.42-256.92,0-302.44"
                />
                <path
                  class="cls-10"
                  d="M240.72,542.38c-0.23-42.68-.23-256.63,0-300.56"
                />
                <path
                  class="cls-10"
                  d="M229.34,542.57c-0.14-42.87-.14-250.52,0-294.63"
                />
                <path
                  class="cls-10"
                  d="M217.34,542.57c-0.14-42.87-.14-240.59,0-284.7"
                />
                <path
                  class="cls-10"
                  d="M206.47,542.38c0.14-44.56.14-230.32,0-275.65"
                />
                <path
                  class="cls-10"
                  d="M194.71,542.38c0.6-44.56.6-215.73,0-261"
                />
                <path
                  class="cls-10"
                  d="M183.41,542.38c0.6-46.45.6-190.82,0-238.39"
                />
                <path
                  class="cls-10"
                  d="M172.12,542.38c0.6-48.33.6-166.64,0-215.32"
                />
                <path
                  class="cls-10"
                  d="M390.25,542.38c-0.25-73.59-.25-148.59,0-223.07"
                />
                <path class="cls-10" d="M160.82,542.38q0.9-78.14,0-158.24" />
                <path
                  class="cls-10"
                  d="M149.06,542.38c-0.82-57.74-.82-118.38,0-177.27"
                />
                <path
                  class="cls-10"
                  d="M137.58,542.38c-0.63-61.51-.63-127.57,0-190.22"
                />
                <path
                  class="cls-10"
                  d="M126.1,542.29c-0.45-65.18-.45-138.33,0-203.45"
                />
                <path
                  class="cls-10"
                  d="M114.52,542.38c-0.17-69-.17-141.59,0-212.13"
                />
                <path
                  class="cls-10"
                  d="M103.13,542.38c-0.08-72.8-.08-146.23,0-218.78"
                />
                <line
                  class="cls-10"
                  x1="91.16"
                  y1="542.38"
                  x2="91.16"
                  y2="322.65"
                />
                <line
                  class="cls-10"
                  x1="79.86"
                  y1="542.38"
                  x2="79.86"
                  y2="325.73"
                />
                <line
                  class="cls-10"
                  x1="68.57"
                  y1="542.38"
                  x2="68.57"
                  y2="332.43"
                />
                <line
                  class="cls-10"
                  x1="57.27"
                  y1="542.38"
                  x2="57.27"
                  y2="347.1"
                />
              </g>
            </g>
            <g id="up">
              <path
                class="cls-11"
                d="M538,418.2c5.09-11.15,3.08-24.91-.56-36.21C533,368.14,523.72,355.6,514,345c-9.92-10.79-22.49-22-37.57-24-11.88-1.58-20.6,2.8-28.18,11.76C437,346.05,430.51,362.22,423.69,378c-1.26,2.92,3,5.47,4.32,2.52,6.71-15.55,13-31.81,24.2-44.79,4.21-4.88,8.95-9.14,15.58-10,8.45-1.07,16.77,1.89,23.81,6.36,15,9.51,27.21,24.58,36,39.84,6.86,11.89,12.13,30.53,6.11,43.7-1.33,2.91,3,5.45,4.32,2.52h0Z"
              />
              <path
                class="cls-11"
                d="M332.58,368.09c-16.09-24.84-30.91-51-42.14-78.46-3.48-8.5-6.53-17.17-10-25.68-2.28-5.6-4.73-11.35-8.77-15.93-10-11.4-26.61-8.89-38.86-2.82-21.65,10.72-38.9,31.44-50.53,52.16A138.64,138.64,0,0,0,167,341.1c-2.2,12.26-2.54,26.4-10.09,36.83-1.89,2.61,2.45,5.11,4.32,2.52,6-8.27,7.46-18.59,9-28.43,2.16-13.6,4.77-27,10.25-39.76C190,290.07,206,268.8,226,255c11.2-7.69,29.85-16.15,41.34-4.35,3.9,4,6.19,9.49,8.27,14.59,3.23,7.9,6.06,16,9.26,23.86,11.53,28.5,26.69,55.69,43.38,81.47,1.74,2.69,6.07.19,4.32-2.52h0Z"
              />
              <path
                class="cls-12"
                d="M60.05,349.52c1.11-10.23,10.09-17.49,18.82-21.63,10.1-4.78,21.76-5.31,31.84-.19,9.62,4.89,17.54,13.23,24.57,21.25a158.09,158.09,0,0,1,18.93,26.93c9.24,16.34,22.38,27.38,39.42,35.17,16.64,7.6,37,14.72,55.39,11.23,21.32-4,38.82-19.23,56.45-30.86,18.67-12.31,35.19-27.77,51.56-42.93,8.42-7.79,16.53-16.14,25.28-23.56,12.27-10.4,19.31,6.34,23,15.68,7.49,18.8,13.78,38.18,25.87,54.68,12.75,17.4,30.74,29.36,51.39,35.44,19.19,5.65,45.88,7.43,56.48-13.43,1.45-2.86-2.86-5.4-4.32-2.52-11,21.74-41.59,15.29-59.84,8-19.94-8-35.37-21.83-46.11-40.34-5.19-8.94-9.13-18.45-12.81-28.09-3.57-9.35-6.57-19.62-11.8-28.22-4.2-6.9-11.39-11.74-19.64-8.54-3.8,1.47-6.69,4.63-9.58,7.36-4.33,4.1-8.73,8.12-13.09,12.18-17.46,16.26-34.85,33.33-54.51,47-9.12,6.32-18.46,12.33-27.6,18.63-9.47,6.53-19.86,12.23-31.23,14.55s-22.84-.41-33.85-3.69a134,134,0,0,1-26.6-10.92c-8.17-4.52-15.87-10.14-21.86-17.37-6.75-8.15-10.6-18.3-16.78-26.86-12-16.63-29.59-37.83-51.61-39.38C80,317.83,57.13,330.27,55.05,349.52c-0.35,3.19,4.66,3.17,5,0h0Z"
              />
            </g>
          </g>

          <g id="ground">
            <path
              id="ground2"
              class="cls-13"
              d="M1248.58,572.43V555.74a12,12,0,0,0-12-12H59.63a12,12,0,0,0-12,12v16.69h1201Z"
            />
            <path
              id="ground1"
              class="cls-14"
              d="M1248.58,571.74v16.69a12,12,0,0,1-12,12H59.63a12,12,0,0,1-12-12V571.74h1201Z"
            />
          </g>

          <g id="bush-7" data-name="bush">
            <path
              class="cls-13"
              d="M1004.25,491.46a10.82,10.82,0,0,0-12.65,9.78,14.43,14.43,0,0,0-21.45,12.62s0,0.06,0,.09A15.16,15.16,0,0,0,975,543.46h29.22v-52Z"
            />
            <path
              class="cls-14"
              d="M1004.28,491.46a10.82,10.82,0,0,1,12.65,9.78,14.43,14.43,0,0,1,21.45,12.62s0,0.06,0,.09a15.16,15.16,0,0,1-4.88,29.51h-29.22v-52Z"
            />
          </g>

          <g id="bush-8" data-name="bush">
            <path
              class="cls-13"
              d="M1194.25,482.05a11.26,11.26,0,0,0-2-.2c-6.36,0-11.52,5.29-12.08,12a15.1,15.1,0,0,0-7.83-2.2c-8.9,0-16.12,7.81-16.12,17.45,0,0,0,.07,0,0.11-6.67,2.46-11.48,9.29-11.48,17.34,0,10.12,7.58,18.33,16.93,18.33h32.62V482.05Z"
            />
            <path
              class="cls-14"
              d="M1194.07,482.05a11.26,11.26,0,0,1,2-.2c6.36,0,11.52,5.29,12.08,12a15.1,15.1,0,0,1,7.83-2.2c8.9,0,16.12,7.81,16.12,17.45,0,0,0,.07,0,0.11,6.67,2.46,11.48,9.29,11.48,17.34,0,10.12-7.58,18.33-16.93,18.33h-32.62V482.05Z"
            />
          </g>

          <g id="tree2">
            <path
              class="cls-18"
              d="M1223.27,343.52c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.52Z"
            />
            <path
              class="cls-18"
              d="M1223.27,343.52c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.52Z"
            />
            <path
              class="cls-19"
              d="M1223.27,343.52c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.52Z"
            />
            <path
              class="cls-19"
              d="M1223.27,343.52c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.52Z"
            />
            <polygon
              class="cls-20"
              points="1210.74 544.47 1219.48 498.94 1210.74 472.75 1219.48 485.67 1219.48 452.67 1201.32 408.25 1219.48 438.28 1219.48 392.23 1224.22 438.28 1244.71 423.27 1228.57 444.54 1229.58 472.75 1244.71 452.67 1234.62 476.45 1233.61 507.58 1240.67 544.47 1210.74 544.47"
            />
            <polygon
              class="cls-20"
              points="1210.74 544.47 1219.48 498.94 1210.74 472.75 1219.48 485.67 1219.48 452.67 1201.32 408.25 1219.48 438.28 1219.48 392.23 1224.22 438.28 1244.71 423.27 1228.57 444.54 1229.58 472.75 1244.71 452.67 1234.62 476.45 1233.61 507.58 1240.67 544.47 1210.74 544.47"
            />
          </g>

          <g id="tree2-2" data-name="tree2">
            <path
              class="cls-18"
              d="M91.48,343.29c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.29Z"
            />
            <path
              class="cls-18"
              d="M91.48,343.29c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.29Z"
            />
            <path
              class="cls-19"
              d="M91.16,342.83c33.86,0,61.3,34.79,61.3,77.71S125,498.24,91.16,498.24V342.83Z"
            />
            <path
              class="cls-19"
              d="M91.16,342.83c33.86,0,61.3,34.79,61.3,77.71S125,498.24,91.16,498.24V342.83Z"
            />
            <polygon
              class="cls-20"
              points="78.88 543.79 87.63 498.26 78.88 472.07 87.63 484.98 87.63 451.98 69.47 407.56 87.63 437.6 87.63 391.55 92.36 437.6 112.86 422.58 96.71 443.86 97.72 472.07 112.86 451.98 102.77 475.77 101.76 506.89 108.82 543.79 78.88 543.79"
            />
            <polygon
              class="cls-20"
              points="78.88 543.79 87.63 498.26 78.88 472.07 87.63 484.98 87.63 451.98 69.47 407.56 87.63 437.6 87.63 391.55 92.36 437.6 112.86 422.58 96.71 443.86 97.72 472.07 112.86 451.98 102.77 475.77 101.76 506.89 108.82 543.79 78.88 543.79"
            />
          </g>

          <g id="tree2-3" data-name="tree2">
            <path
              class="cls-18"
              d="M1010.88,343.09c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.09Z"
            />
            <path
              class="cls-18"
              d="M1010.88,343.09c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.09Z"
            />
            <path
              class="cls-19"
              d="M1010.88,343.09c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.09Z"
            />
            <path
              class="cls-19"
              d="M1010.88,343.09c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.09Z"
            />
            <polygon
              class="cls-20"
              points="998.35 544.04 1007.1 498.51 998.35 472.32 1007.1 485.24 1007.1 452.24 988.94 407.82 1007.1 437.86 1007.1 391.8 1011.83 437.86 1032.33 422.84 1016.18 444.12 1017.19 472.32 1032.33 452.24 1022.24 476.02 1021.23 507.15 1028.29 544.04 998.35 544.04"
            />
            <polygon
              class="cls-20"
              points="998.35 544.04 1007.1 498.51 998.35 472.32 1007.1 485.24 1007.1 452.24 988.94 407.82 1007.1 437.86 1007.1 391.8 1011.83 437.86 1032.33 422.84 1016.18 444.12 1017.19 472.32 1032.33 452.24 1022.24 476.02 1021.23 507.15 1028.29 544.04 998.35 544.04"
            />
          </g>

          <g id="bush-9" data-name="bush">
            <path
              class="cls-13"
              d="M808.91,501.82a8.64,8.64,0,0,0-1.47-.13,8.8,8.8,0,0,0-8.71,8.11,11.43,11.43,0,0,0-5.64-1.49,11.7,11.7,0,0,0-11.62,11.78s0,0,0,.08a12.39,12.39,0,0,0,3.93,24.08h23.51V501.82Z"
            />
            <path
              class="cls-14"
              d="M808.91,501.82a8.64,8.64,0,0,1,1.47-.13,8.8,8.8,0,0,1,8.71,8.11,11.43,11.43,0,0,1,5.64-1.49,11.7,11.7,0,0,1,11.62,11.78s0,0,0,.08a12.39,12.39,0,0,1-3.93,24.08H808.91V501.82Z"
            />
          </g>

          <g id="tree1">
            <path
              class="cls-21"
              d="M839.47,359.72V501.07A70.67,70.67,0,1,0,839.47,359.72Z"
            />
            <path
              class="cls-21"
              d="M839.47,359.72V501.07A70.67,70.67,0,1,0,839.47,359.72Z"
            />
            <path
              class="cls-13"
              d="M839.68,359.72V501.07A70.67,70.67,0,0,1,839.68,359.72Z"
            />
            <path
              class="cls-13"
              d="M839.68,359.72V501.07A70.67,70.67,0,0,1,839.68,359.72Z"
            />
            <polygon
              class="cls-20"
              points="827.4 544.36 836.15 498.83 827.4 472.64 836.15 485.56 836.15 452.56 817.99 408.14 836.15 438.17 836.15 392.12 840.88 438.17 861.38 423.16 845.23 444.44 846.24 472.64 861.38 452.56 851.29 476.34 850.28 507.47 857.34 544.36 827.4 544.36"
            />
            <polygon
              class="cls-20"
              points="827.4 544.36 836.15 498.83 827.4 472.64 836.15 485.56 836.15 452.56 817.99 408.14 836.15 438.17 836.15 392.12 840.88 438.17 861.38 423.16 845.23 444.44 846.24 472.64 861.38 452.56 851.29 476.34 850.28 507.47 857.34 544.36 827.4 544.36"
            />
          </g>

          <g id="tree1-2" data-name="tree1">
            <path
              class="cls-21"
              d="M236,360.65V502A70.67,70.67,0,1,0,236,360.65Z"
            />
            <path
              class="cls-21"
              d="M236,360.65V502A70.67,70.67,0,1,0,236,360.65Z"
            />
            <path
              class="cls-13"
              d="M236.18,360.65V502A70.67,70.67,0,0,1,236.18,360.65Z"
            />
            <path
              class="cls-13"
              d="M236.18,360.65V502A70.67,70.67,0,0,1,236.18,360.65Z"
            />
            <polygon
              class="cls-20"
              points="223.9 544.29 232.65 498.76 223.9 472.57 232.65 485.49 232.65 452.49 214.48 408.07 232.65 438.1 232.65 392.05 237.38 438.1 257.87 423.09 241.73 444.37 242.74 472.57 257.87 452.49 247.78 476.27 246.78 507.4 253.84 544.29 223.9 544.29"
            />
            <polygon
              class="cls-20"
              points="223.9 544.29 232.65 498.76 223.9 472.57 232.65 485.49 232.65 452.49 214.48 408.07 232.65 438.1 232.65 392.05 237.38 438.1 257.87 423.09 241.73 444.37 242.74 472.57 257.87 452.49 247.78 476.27 246.78 507.4 253.84 544.29 223.9 544.29"
            />
          </g>

          <g id="tree1-3" data-name="tree1">
            <path
              class="cls-21"
              d="M480.65,358.65V500A70.67,70.67,0,1,0,480.65,358.65Z"
            />
            <path
              class="cls-21"
              d="M480.65,358.65V500A70.67,70.67,0,1,0,480.65,358.65Z"
            />
            <path
              class="cls-13"
              d="M480.86,358.65V500A70.67,70.67,0,0,1,480.86,358.65Z"
            />
            <path
              class="cls-13"
              d="M480.86,358.65V500A70.67,70.67,0,0,1,480.86,358.65Z"
            />
            <polygon
              class="cls-20"
              points="468.58 542.29 477.33 496.76 468.58 470.57 477.33 483.49 477.33 450.49 459.17 406.07 477.33 436.1 477.33 390.05 482.06 436.1 502.56 421.09 486.41 442.37 487.42 470.57 502.56 450.49 492.47 474.27 491.46 505.4 498.52 542.29 468.58 542.29"
            />
            <polygon
              class="cls-20"
              points="468.58 542.29 477.33 496.76 468.58 470.57 477.33 483.49 477.33 450.49 459.17 406.07 477.33 436.1 477.33 390.05 482.06 436.1 502.56 421.09 486.41 442.37 487.42 470.57 502.56 450.49 492.47 474.27 491.46 505.4 498.52 542.29 468.58 542.29"
            />
          </g>

          <g id="bush-10" data-name="bush">
            <path
              class="cls-13"
              d="M447.92,500.42a7.39,7.39,0,0,0-1.39-.14c-4.31,0-7.8,3.7-8.18,8.41a10,10,0,0,0-5.3-1.54c-6,0-10.92,5.47-10.92,12.22,0,0,0,.05,0,0.08-4.52,1.72-7.78,6.5-7.78,12.14,0,7.09,5.14,12.83,11.47,12.83h22.1v-44Z"
            />
            <path
              class="cls-14"
              d="M447.92,500.46a7.39,7.39,0,0,1,1.39-.14c4.31,0,7.8,3.7,8.18,8.41a10,10,0,0,1,5.3-1.54c6,0,10.92,5.47,10.92,12.22,0,0,0,.05,0,0.08,4.52,1.72,7.78,6.5,7.78,12.14,0,7.09-5.14,12.83-11.47,12.83h-22.1v-44Z"
            />
          </g>

          <g>
            <path
              class="cls-22"
              d="M77.93,469c-0.08-.25-0.15-0.5-0.22-0.75l0-.18c0,0.32.11,0.63,0.18,0.93h0Z"
            />
            <path
              class="cls-22"
              d="M77.72,468.29c0.06,0.25.13,0.51,0.22,0.75-0.07-.31-0.13-0.61-0.18-0.93C77.74,468.17,77.73,468.23,77.72,468.29Z"
            />
            <path
              class="cls-22"
              d="M94.72,469c-0.08-.25-0.15-0.5-0.22-0.75l0-.18c0,0.32.11,0.63,0.18,0.93h0Z"
            />
            <path
              class="cls-22"
              d="M94.5,468.29c0.06,0.26.14,0.51,0.22,0.75-0.07-.31-0.13-0.62-0.18-0.93C94.53,468.17,94.51,468.23,94.5,468.29Z"
            />
            <path
              class="cls-22"
              d="M111.5,469c-0.08-.25-0.15-0.5-0.22-0.75l0-.18c0,0.32.11,0.63,0.18,0.93h0Z"
            />
            <path
              class="cls-22"
              d="M111.29,468.29c0.06,0.26.14,0.51,0.22,0.75-0.07-.31-0.13-0.62-0.18-0.93C111.31,468.17,111.3,468.23,111.29,468.29Z"
            />
            <path
              class="cls-23"
              d="M57.65,543.88a6.46,6.46,0,0,1-6.55-8.19l9.73-69.36v0.27c0.17,5.54,3.91,9.82,8.52,9.82,4.06,0,7.57-3.42,8.36-8.13,0.06,0.25.13,0.51,0.22,0.75,1,4.34,4.4,7.38,8.21,7.38,4.06,0,7.57-3.42,8.36-8.13,0.06,0.25.13,0.51,0.22,0.75,1,4.34,4.4,7.38,8.21,7.38,4.06,0,7.57-3.42,8.36-8.13,0.06,0.25.13,0.51,0.22,0.75,1,4.34,4.4,7.38,8.21,7.38,4.14,0,7.67-3.49,8.39-8.31,0-.05,0-0.29,0-0.54v-0.05c0,0.14,0,.23,0,0.31l0.05,13.07c-6.1,0-10.87,15.15-13.17,24.18-3.24,12.72-5.5,28.67-5.5,38.79H57.65Z"
            />
            <path
              class="cls-23"
              d="M128.21,480.9l-0.05-13.07c0-.09,0-0.19,0-0.35v-0.07c0,0.31,0,.63,0,0.7-0.71,4.72-4.2,8.31-8.39,8.31-3.91,0-7.21-3.12-8.21-7.38-0.08-.25-0.15-0.5-0.22-0.75-0.77,4.63-4.22,8.13-8.36,8.13-3.91,0-7.21-3.12-8.21-7.38-0.08-.25-0.15-0.5-0.22-0.75-0.77,4.63-4.22,8.13-8.36,8.13-3.91,0-7.21-3.12-8.21-7.38-0.08-.25-0.15-0.5-0.22-0.75-0.77,4.63-4.22,8.13-8.36,8.13-4.61,0-8.35-4.32-8.51-9.73,0,0,0-.06,0-0.09s0-.18,0-0.27L51.1,535.69a6.46,6.46,0,0,0,6.55,8.19h51.89C109.54,524.4,118,480.9,128.21,480.9Z"
            />
            <path
              class="cls-24"
              d="M146.88,543.88c0-20.74-8.64-63-18.67-63l-0.05-13.07a1.53,1.53,0,0,0,.06.33c0.72,4.79,4.27,8.26,8.43,8.26s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31,4.74,0,8.57-4.14,8.73-9.42v-0.57l9.32,69.27a6.46,6.46,0,0,1-6.55,8.19H146.88Z"
            />
            <path
              class="cls-24"
              d="M205.06,535.69l-9.32-69.36c0,0.09,0,.24,0,0.33s0,0.31,0,.34c-0.16,5.4-4.13,9.42-8.73,9.42-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.72-3.54-8.43-8.26a1.52,1.52,0,0,1-.06-0.33l0.05,13.07c10.22,0,18.67,43.5,18.67,63h51.62A6.46,6.46,0,0,0,205.06,535.69Z"
            />
            <polygon
              class="cls-23"
              points="60.83 466.33 128.16 415.25 128.15 466.33 60.83 466.33"
            />
            <polygon
              class="cls-23"
              points="61.2 466.33 77.62 466.33 77.89 466.33 94.4 466.33 94.67 466.33 111.19 466.33 111.46 466.33 127.97 466.33 128.15 466.33 128.15 466.33 128.21 415.21 128.16 415.25 60.83 466.33 60.96 466.33 61.2 466.33"
            />
            <path
              class="cls-24"
              d="M128.15,466.33l0.06-51.12-0.06-.08c0-1.54,0-1.82.08-6.67,0.51,0.17,1.07.39,1.65,0.62a16.8,16.8,0,0,0,5.33,1.46,18.58,18.58,0,0,0,5.54-1.28c0.91-.31,1.77-0.59,2.58-0.8l-3.75,4.13,4.69,1.68a8.07,8.07,0,0,1-5.72,2,31.39,31.39,0,0,1-6.2-.88c-1.38-.29-2.69-0.56-4-0.71l0,0.66,67.37,51H128.15Z"
            />
            <path
              class="cls-24"
              d="M128.15,466.33h67.58l-67.37-51,0-.66c5.31,0.63,11.66,3.46,15.88-.4l-4.69-1.68,3.75-4.13c-2.51.64-5.56,2.08-8.12,2.08-2.1,0-4.95-1.38-7-2.08-0.13,5-.12,5.13-0.07,6.8l0.05,0Z"
            />
            <path
              class="cls-25"
              d="M119.71,476.42c-3.81,0-7.19-3-8.21-7.38-0.07-.3-0.13-0.62-0.18-0.93l0,0.18c-0.79,4.71-4.3,8.13-8.36,8.13-3.81,0-7.19-3-8.21-7.38-0.07-.3-0.13-0.62-0.18-0.93l0,0.18c-0.79,4.71-4.3,8.13-8.36,8.13-3.81,0-7.19-3-8.21-7.38-0.07-.31-0.13-0.62-0.18-0.93l0,0.18c-0.79,4.71-4.3,8.13-8.36,8.13-4.61,0-8.35-4.27-8.51-9.73l0-.36h67.32v1.24c0,0.25,0,.49,0,0.54C127.38,472.92,123.85,476.42,119.71,476.42Z"
            />
            <path
              class="cls-25"
              d="M60.85,466.69c0.16,5.4,3.91,9.73,8.51,9.73,4.14,0,7.59-3.5,8.36-8.13,0-.06,0-0.12,0-0.18,0,0.32.11,0.63,0.18,0.93,1,4.25,4.3,7.38,8.21,7.38,4.14,0,7.59-3.5,8.36-8.13,0-.06,0-0.12,0-0.18,0,0.32.11,0.63,0.18,0.93,1,4.25,4.3,7.38,8.21,7.38,4.14,0,7.59-3.5,8.36-8.13,0-.06,0-0.12,0-0.18,0,0.32.11,0.63,0.18,0.93,1,4.25,4.3,7.38,8.21,7.38,4.2,0,7.68-3.59,8.39-8.31,0-.06,0-0.39,0-0.7v-1.08H60.83c0,0.09,0,.18,0,0.27S60.85,466.66,60.85,466.69Z"
            />
            <path
              class="cls-26"
              d="M187,476.42c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.71-3.47-8.43-8.26a1.53,1.53,0,0,1-.06-0.33c0-.08,0-0.17,0-0.31v-1.19h67.58V467C195.58,472.28,191.74,476.42,187,476.42Z"
            />
            <path
              class="cls-26"
              d="M178.75,466.33H128.15v1.15c0,0.16,0,.26,0,0.35a1.52,1.52,0,0,0,.06.33c0.71,4.72,4.24,8.26,8.43,8.26s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31,4.61,0,8.57-4,8.73-9.42,0,0,0-.31,0-0.34s0-.24,0-0.33h-17Z"
            />
            <path
              class="cls-25"
              d="M109.54,543.88c0-20.74,8.64-63,18.67-63v63H109.54Z"
            />
            <path
              class="cls-25"
              d="M128.21,480.9c-10.22,0-18.67,43.5-18.67,63h18.67v-63Z"
            />
            <path
              class="cls-26"
              d="M128.21,543.88v-63c6.1,0,10.87,15.15,13.17,24.18,3.24,12.72,5.5,28.67,5.5,38.79H128.21Z"
            />
            <path
              class="cls-26"
              d="M128.21,480.9v63h18.67C146.88,524.4,138.44,480.9,128.21,480.9Z"
            />
          </g>

          <g>
            <polygon
              class="cls-22"
              points="321.59 476.19 321.59 476.19 321.58 476.19 321.59 476.19"
            />
            <polygon
              class="cls-22"
              points="321.59 476.19 321.59 476.19 321.58 476.19 321.59 476.19"
            />
            <path
              class="cls-22"
              d="M371.95,476.19c-4.14,0-7.67-3.49-8.39-8.31,0.73,4.81,4.25,8.31,8.39,8.31h0Z"
            />
            <path
              class="cls-22"
              d="M363.56,467.88h0c0.71,4.72,4.2,8.31,8.39,8.31h0C367.75,476.19,364.27,472.6,363.56,467.88Z"
            />
            <path
              class="cls-22"
              d="M355.16,476.19a7.53,7.53,0,0,1-4.22-1.32,10.17,10.17,0,0,1-4.17-7,10.18,10.18,0,0,0,4.17,7h0a7.53,7.53,0,0,0,4.22,1.32h0Z"
            />
            <path
              class="cls-22"
              d="M346.77,467.88h0c0.71,4.72,4.2,8.31,8.39,8.31h0C351,476.19,347.48,472.6,346.77,467.88Z"
            />
            <path
              class="cls-23"
              d="M242.8,544.58a6.46,6.46,0,0,1-6.55-8.19l9.47-70.29v0.27c0.17,5.54,3.91,9.82,8.52,9.82,4.14,0,7.67-3.49,8.39-8.31,0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31a1.55,1.55,0,0,0,0-.18l0.06,13.89c-6.1,0-10.87,15.15-13.17,24.18-3.24,12.72-5.5,28.67-5.5,38.79H242.8Z"
            />
            <path
              class="cls-23"
              d="M313.1,481.6L313,467.71a1.61,1.61,0,0,1,0,.18c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31-4.61,0-8.35-4.32-8.51-9.73,0,0,0-.06,0-0.09s0-.18,0-0.27l-9.47,70.29a6.46,6.46,0,0,0,6.55,8.19h51.62C294.43,525.1,302.87,481.6,313.1,481.6Z"
            />
            <path
              class="cls-24"
              d="M331.77,544.58c0-20.74-8.64-63-18.67-63L313,467.71l0-.15,0,0.12c0,0.06,0,.14.06,0.26,0.72,4.78,4.26,8.25,8.42,8.26s7.68-3.49,8.4-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31,4.61,0,8.35-4.27,8.51-9.73l9.48,69.93a6.46,6.46,0,0,1-6.55,8.19H331.77Z"
            />
            <path
              class="cls-24"
              d="M389.94,536.39l-9.42-69.93h-0.06c-0.16,5.4-3.91,9.73-8.51,9.73h0c-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31h0c-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31h0c-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31h0c-4.19,0-7.71-3.54-8.42-8.26,0-.19-0.07-0.29-0.08-0.38l0,0.15,0.06,13.89c10.22,0,18.67,43.5,18.67,63h51.62A6.46,6.46,0,0,0,389.94,536.39Z"
            />
            <polygon
              class="cls-23"
              points="245.72 466.1 313.05 415.03 313.1 466.1 245.72 466.1"
            />
            <polygon
              class="cls-23"
              points="262.5 466.1 262.67 466.1 262.77 466.1 262.94 466.1 279.29 466.1 279.46 466.1 279.56 466.1 279.73 466.1 296.07 466.1 296.24 466.1 296.34 466.1 296.51 466.1 312.86 466.1 313.03 466.1 313.1 466.1 313.1 414.99 313.05 415.03 245.72 466.1 246.09 466.1 262.5 466.1"
            />
            <path
              class="cls-24"
              d="M313.17,466.1L313.1,415a64.37,64.37,0,0,1,0-6.76c0.51,0.17,1.06.39,1.64,0.62a16.82,16.82,0,0,0,5.33,1.46,18.58,18.58,0,0,0,5.54-1.28c0.91-.31,1.77-0.59,2.58-0.8l-3.75,4.13,4.69,1.68a8.07,8.07,0,0,1-5.72,2,31.37,31.37,0,0,1-6.2-.88c-1.32-.28-2.69-0.56-4-0.71l0,0.66,66.85,51H313.17Z"
            />
            <path
              class="cls-24"
              d="M313.1,466.1h67l-66.85-51,0-.66c5.31,0.63,11.66,3.46,15.88-.4l-4.69-1.68,3.75-4.13c-2.51.64-5.56,2.08-8.12,2.08-2.1,0-4.95-1.38-7-2.08-0.13,5-.12,5.13-0.07,6.8l0.05,0V466.1Z"
            />
            <path
              class="cls-25"
              d="M304.6,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31-4.61,0-8.35-4.27-8.51-9.73l0-.36H313l0.07,1.16a0.66,0.66,0,0,0,0,.3l0,0.15a1.55,1.55,0,0,1,0,.18C312.27,472.7,308.74,476.19,304.6,476.19Z"
            />
            <path
              class="cls-25"
              d="M245.73,466.46c0.16,5.4,3.91,9.73,8.51,9.73,4.2,0,7.68-3.59,8.39-8.31,0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31a1.61,1.61,0,0,0,0-.18l0-.15a0.66,0.66,0,0,1,0-.3V466.1H245.72c0,0.09,0,.18,0,0.27S245.73,466.43,245.73,466.46Z"
            />
            <path
              class="cls-26"
              d="M371.95,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.73,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31s-7.71-3.48-8.43-8.26c0-.12,0-0.2-0.06-0.26l0-.12a0.66,0.66,0,0,1,0-.3V466.1h67.38v0.27C380.3,471.92,376.56,476.19,371.95,476.19Z"
            />
            <path
              class="cls-26"
              d="M363.69,466.1H313.1v1.15a0.66,0.66,0,0,0,0,.3c0,0.09.05,0.19,0.08,0.38,0.71,4.72,4.23,8.25,8.42,8.26h0c4.2,0,7.68-3.59,8.39-8.31,0.71,4.72,4.2,8.31,8.39,8.31h0c4.2,0,7.68-3.59,8.39-8.31h0c0.71,4.72,4.2,8.31,8.39,8.31h0c4.2,0,7.68-3.59,8.39-8.31h0c0.71,4.72,4.2,8.31,8.39,8.31h0c4.61,0,8.35-4.32,8.51-9.73,0,0,0-.06,0-0.09s0-.18,0-0.27H363.69Z"
            />
            <path
              class="cls-25"
              d="M294.43,544.58c0-20.74,8.64-63,18.67-63v63H294.43Z"
            />
            <path
              class="cls-25"
              d="M313.1,481.6c-10.22,0-18.67,43.5-18.67,63H313.1v-63Z"
            />
            <path
              class="cls-26"
              d="M313.1,544.58v-63c6.1,0,10.87,15.15,13.17,24.18,3.24,12.72,5.5,28.67,5.5,38.79H313.1Z"
            />
            <path
              class="cls-26"
              d="M313.1,481.6v63h18.67C331.77,525.1,323.32,481.6,313.1,481.6Z"
            />
          </g>

          <g>
            <path
              class="cls-23"
              d="M853,544.08a5.58,5.58,0,0,1-4.46-2,6.25,6.25,0,0,1-1.06-5.38l9.39-69.13,0.07-.16,0.13-1.2v0.21c0.17,5.54,3.91,9.82,8.52,9.82,4.14,0,7.67-3.49,8.39-8.31,0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.26,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0,0,0-.12,0-0.24v2.58l0.07,10.53c-6.1,0-10.87,15.16-13.16,24.19-3.24,12.72-5.5,28.66-5.5,38.78h18.52l0,0.1-70.29.16a7.27,7.27,0,0,1-1,.08h0Z"
            />
            <path
              class="cls-23"
              d="M905.8,543.74c0-19.48,8.44-63,18.66-63l-0.07-10.53c0-1.19,0-2,0-2.58,0,0.12,0,.21,0,0.24-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31-4.61,0-8.35-4.32-8.51-9.73,0,0,0-.06,0-0.09s0-.14,0-0.2l-0.13,1.2-0.07.16-9.39,69.13c-1,4.45,2.1,8,6.55,7.35l70.29-.16v-0.1H905.8Z"
            />
            <path
              class="cls-24"
              d="M943.15,543.74c0-20.74-8.64-63-18.67-63l-0.08-10.53v-2.58l0-.46v0.15a1.77,1.77,0,0,0,.06.6c0.72,4.79,4.27,8.26,8.43,8.26s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.26,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31,4.61,0,8.35-4.27,8.51-9.73v-0.1l9.39,69.19a6.46,6.46,0,0,1-6.55,8.19H943.15Z"
            />
            <path
              class="cls-24"
              d="M1001.17,535.56l-9.38-69.23v0s0,0.06,0,.09c-0.16,5.4-3.91,9.73-8.51,9.73-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.72-3.54-8.43-8.26a2,2,0,0,1-.06-0.68v-0.07c0,0.16,0,.33,0,0.46,0,0.57,0,1.39,0,2.58l0.07,10.53h0c10.22,0,18.67,43.5,18.67,63h51.48A6.46,6.46,0,0,0,1001.17,535.56Z"
            />
            <polygon
              class="cls-23"
              points="857.11 466.1 857.12 466 924.39 415.14 924.48 466.1 857.11 466.1"
            />
            <polygon
              class="cls-23"
              points="873.88 466.1 874.15 466.1 890.67 466.1 890.94 466.1 907.45 466.1 907.72 466.1 924.24 466.1 924.42 466.1 924.47 466.1 924.39 415.14 924.39 415.14 857.12 466 857.11 466.1 857.46 466.1 873.88 466.1"
            />
            <path
              class="cls-24"
              d="M924.55,466.1l-0.16-51,0-.24c0-1.54,0-1.82.08-6.67,0.51,0.17,1.06.39,1.64,0.62a16.81,16.81,0,0,0,5.33,1.46A18.59,18.59,0,0,0,937,409c0.91-.31,1.77-0.59,2.58-0.8l-3.75,4.13,4.69,1.68a8.07,8.07,0,0,1-5.72,2,31.36,31.36,0,0,1-6.2-.88c-1.32-.28-2.69-0.56-4-0.71l0,0.82L991.8,466.1H924.55Z"
            />
            <path
              class="cls-24"
              d="M924.55,466.1H991.8l-67.17-50.84,0-.82c5.31,0.63,11.66,3.46,15.88-.4l-4.69-1.68,3.75-4.13c-2.51.64-5.56,2.08-8.12,2.08-2.1,0-4.95-1.38-7-2.08-0.13,5.06-.12,5.13-0.07,6.88l0,0v0.05l0.08,51h0.08Z"
            />
            <path
              class="cls-25"
              d="M916,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.26,8.31-8.39,8.31-4.61,0-8.35-4.27-8.51-9.73v-0.32l0.37,0h67v1.08l0,0.46c0,0.12,0,.21,0,0.24C923.65,472.7,920.12,476.19,916,476.19Z"
            />
            <path
              class="cls-25"
              d="M857.11,466.46c0.16,5.4,3.91,9.73,8.51,9.73,4.2,0,7.68-3.59,8.39-8.31,0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0,0,0-.12,0-0.24s0-.3,0-0.46V466.1H857.1s0,0,0,.07,0,0.14,0,.2S857.11,466.43,857.11,466.46Z"
            />
            <path
              class="cls-26"
              d="M983.27,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.26,8.31-8.39,8.31s-7.71-3.47-8.43-8.26a1.77,1.77,0,0,1-.06-0.6V466.1H991.8l0,0.36C991.62,471.92,987.88,476.19,983.27,476.19Z"
            />
            <path
              class="cls-26"
              d="M975,466.1H924.42v1.15a2,2,0,0,0,.06.68c0.71,4.72,4.24,8.26,8.43,8.26s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31,4.61,0,8.35-4.32,8.51-9.73,0,0,0-.06,0-0.09v0c0-.08,0-0.17,0-0.25H975Z"
            />
            <path
              class="cls-25"
              d="M905.8,543.74c0-20.72,8.64-63,18.66-63v63H905.8Z"
            />
            <path
              class="cls-25"
              d="M924.48,480.76h0c-10.22,0-18.66,43.5-18.66,63h18.67v-63Z"
            />
            <path
              class="cls-26"
              d="M924.47,543.74v-63c6.1,0,10.87,15.15,13.17,24.18,3.24,12.72,5.5,28.67,5.5,38.79H924.47Z"
            />
            <path
              class="cls-26"
              d="M924.48,480.76v63h18.67C943.15,524.26,934.7,480.76,924.48,480.76Z"
            />
          </g>

          <g id="carousel-2" data-name="carousel">
            <g id="caro">
              <rect
                class="cls-27"
                x="1082.34"
                y="441.86"
                width="15.63"
                height="85.72"
              />
              <polygon
                class="cls-28"
                points="1082.49 512.44 1098.12 503.41 1098.12 499.24 1082.49 508.26 1082.49 512.44"
              />
              <polygon
                class="cls-29"
                points="1082.49 503.67 1098.12 494.64 1098.12 490.47 1082.49 499.49 1082.49 503.67"
              />
              <polygon
                class="cls-28"
                points="1082.49 494.89 1098.12 485.87 1098.12 481.7 1082.49 490.72 1082.49 494.89"
              />
              <polygon
                class="cls-28"
                points="1082.49 529.98 1098.12 520.96 1098.12 516.78 1082.49 525.81 1082.49 529.98"
              />
              <polygon
                class="cls-28"
                points="1082.49 521.21 1098.12 512.18 1098.12 508.01 1082.49 517.04 1082.49 521.21"
              />
              <polygon
                class="cls-28"
                points="1098.12 449.69 1098.12 447.63 1082.49 447.63 1082.49 458.71 1098.12 449.69"
              />
              <polygon
                class="cls-28"
                points="1082.49 486.12 1098.12 477.1 1098.12 472.93 1082.49 481.95 1082.49 486.12"
              />
              <polygon
                class="cls-28"
                points="1082.49 477.35 1098.12 468.33 1098.12 464.16 1082.49 473.18 1082.49 477.35"
              />
              <polygon
                class="cls-28"
                points="1082.49 468.58 1098.12 459.56 1098.12 454.29 1082.49 463.31 1082.49 468.58"
              />
              <polygon
                class="cls-30"
                points="1090.35 447.6 1110.16 447.6 1110.24 447.6 1130.05 447.6 1130.13 447.6 1149.88 447.6 1090.16 404.14 1090.16 447.6 1090.27 447.6 1090.35 447.6"
              />
              <path
                class="cls-30"
                d="M1156,539.78l-5.13-7.71h-0.68v0.34h-60v11.76h63.18A2.89,2.89,0,0,0,1156,539.78Z"
              />
              <polygon
                class="cls-27"
                points="1151.24 532.07 1151.24 526.49 1090.16 526.49 1090.16 532.41 1151.24 532.41 1151.24 532.07"
              />
              <path
                class="cls-27"
                d="M1100.3,457.88a10.1,10.1,0,0,0,9.95-9.43,10,10,0,0,0,19.89,0,10,10,0,0,0,19.93-.85H1090.2a6.43,6.43,0,0,0,.15.85A10.1,10.1,0,0,0,1100.3,457.88Z"
              />
              <polygon
                class="cls-12"
                points="1090.01 447.6 1070.2 447.6 1070.12 447.6 1050.31 447.6 1050.22 447.6 1030.48 447.6 1090.2 404.14 1090.2 447.6 1090.09 447.6 1090.01 447.6"
              />
              <path
                class="cls-12"
                d="M1023.61,539.78l5.22-7.71h0.69v0.34h61.09v11.76h-64.27C1023.84,544.16,1022.31,541.7,1023.61,539.78Z"
              />
              <polygon
                class="cls-31"
                points="1029.11 532.07 1029.11 526.49 1090.2 526.49 1090.2 532.41 1029.11 532.41 1029.11 532.07"
              />
              <path
                class="cls-31"
                d="M1080.1,457.88a10.1,10.1,0,0,1-9.95-9.43,10,10,0,0,1-19.89,0,10,10,0,0,1-19.93-.85h59.87s-0.08.5-.15,0.85A10.1,10.1,0,0,1,1080.1,457.88Z"
              />
            </g>
            <g id="hors-line">
              <line
                class="cls-32"
                x1="1140.16"
                y1="526.49"
                x2="1140.16"
                y2="457.87"
              />
              <line
                class="cls-32"
                x1="1090.16"
                y1="526.49"
                x2="1090.35"
                y2="447.6"
              />
              <line
                class="cls-32"
                x1="1039.81"
                y1="526.49"
                x2="1039.81"
                y2="457.88"
              />
            </g>
            <g id="hors">
              <path
                class="cls-33"
                d="M1125.55,501.49c0,0.87,1.12,2.82.5,3.44s-3.11-.54-3.92,0a2,2,0,0,0-.61,1.48c0.12,0.7,1.49,1.52,1.92,2.08a10.13,10.13,0,0,1,1.48,2.36,6.48,6.48,0,0,1,0,3.48c-0.23.46-1.46,0.74-1.66,1.22-0.31.76-.24,2.92,0.52,3.22s1.75-1.25,2.27-1.74a9.33,9.33,0,0,0,2-2.4,3.53,3.53,0,0,0,0-1.69,12,12,0,0,0-1.31-1.83,8.12,8.12,0,0,1-1.22-2.18,2.29,2.29,0,0,1,.35-1.57c0.66-.56,3.37.16,4.06-0.31a8,8,0,0,0,.15-0.95Z"
              />
              <path
                class="cls-34"
                d="M1146.24,505.22c0,0.77,2.95,2.67,3.44,3.26,0.34,0.41,1.3,1.22,1.22,1.74s-1.19,1.13-1.7,1.31a4.23,4.23,0,0,1-2.66,0c-0.49-.31-0.47-1.78-1-2.1-0.85-.55-3.59-0.9-4.06,0-0.3.56,1,1.61,1.44,2.1a10.29,10.29,0,0,0,2.62,2.52,4.09,4.09,0,0,0,1.74,0,26.08,26.08,0,0,0,3.05-1.31,10.31,10.31,0,0,0,2.44-.87,3.19,3.19,0,0,0,.87-1.7c0-1.39-1.8-4.52-1.8-5.91,0.21,0-.67-1.25-2-1.73a8.64,8.64,0,0,0-3.38-.33Z"
              />
              <path
                class="cls-33"
                d="M1151.45,506c0.79-.83,3.73-2,4.43-1.53s0.13,2.73-.09,3.61a13.4,13.4,0,0,1-2.08,4.15c-0.41.48-1.83,0.86-2.08,1.44s-0.26,2.76.45,3.07c0.86,0.37,2.6-1.35,3.07-2.16,0.24-.41,0-1.46.18-1.89,0.3-.66,1.71-1.45,2-2.12s0-2.07.09-2.75,0.71-2.08.81-2.8a4.59,4.59,0,0,0,0-2.07,2.84,2.84,0,0,0-1.45-1c-0.76-.16-2.15-1-2.54-0.28l-0.55.2-2.94,1.63Z"
              />
              <path
                class="cls-34"
                d="M1162.83,490.52l-0.33-3.06v-0.14l-0.07-2.48c0-.25,0,0-0.13-0.21l-0.9-1.48a1.34,1.34,0,0,0-.31-0.35l-1.34-1.07a1.34,1.34,0,0,0-.48-0.25l-2.37-.64a1.9,1.9,0,0,0-.5-0.07h-2.51a1.34,1.34,0,0,0-.56.12l-2.62,1.2a1.34,1.34,0,0,0-.3.19l-1.67,1.39a1.34,1.34,0,0,0-.41.6l-1.34,3.94,0,0.1-0.36,1.19v0c-0.06.25-.18,0.67-0.21,0.77v0c-0.34.83-1.17,2.55-1.06,3.28,0.09,0.56.88,1.47,1,2a4.88,4.88,0,0,1,0,2.72,3.67,3.67,0,0,1-2.33,1.16,2.66,2.66,0,0,1-1.48-.78A4.67,4.67,0,0,1,1142,497a1.73,1.73,0,0,0,0-.84c-0.42-.34-1.56.44-2.1,0.45a4.94,4.94,0,0,1-2.26-.45c-0.76-.57-1.38-2.5-1.79-3.36a5.27,5.27,0,0,0-.79-1.45,15.74,15.74,0,0,1-1.63,0h0l-3.17.17-0.24,0-1.34.32a1.34,1.34,0,0,0-.64.36l-1.07,1.07-0.11.1-1.38,1.1a1.34,1.34,0,0,0-.43.61l-0.5,1.45a1.33,1.33,0,0,0-.07.43v2.35a1.34,1.34,0,0,0,.19.68l0.87,1.46,0.19,0.26c0.41,0.45,1.16,1.29,1.55,1.71s1.22,1.28,1.65,1.73l0.17,0.22,0.31,0.48a1.34,1.34,0,0,1-.15,1.64l-1.73,1.84a0.93,0.93,0,0,0,.68,1.58h0.68l2,0.15a1.34,1.34,0,0,1,.4.1l1.33,0.54a1.34,1.34,0,0,1,.63.53l1,1.6a1.34,1.34,0,0,1,.2.84l-0.16,1.73a0.93,0.93,0,0,0,.47.9c0.57,0.32,1.69,1.58,2.26,1.27,0.31-.17.07-1,0.09-1.39s0.06-1.25.08-1.7l0-.31-0.2-1a1.34,1.34,0,0,1,0-.37l0.09-1a0.93,0.93,0,0,0-.58-0.95l-0.84-.35-2.05-.82-0.24-.13-1.14-.76a1.28,1.28,0,0,1-.57-1.07h0a1.28,1.28,0,0,1,.38-0.91l1-1,0.14-.12,1.43-1.08a1.34,1.34,0,0,1,1.43-.12l1,0.5,0.24,0.1,2,0.6a1.34,1.34,0,0,0,.38.06h4.85a1.34,1.34,0,0,0,.53-0.11l0.81-.35a1.52,1.52,0,0,0,.75-2,1.13,1.13,0,0,1,.47-1.37l0.21-.13a1.29,1.29,0,0,1,.82-0.18l1.19,0.14a1.34,1.34,0,0,1,.89.5l0.18,0.22a1.34,1.34,0,0,0,1.83.26l0.9-.64a1.34,1.34,0,0,0,.27-0.26l1.11-1.39a1,1,0,0,0,.21-0.47c0.17-1.11.83-5.43,0.83-5.66s0.08-2.8.09-3.18a0.9,0.9,0,0,0,0-.09l-0.06-.8a1,1,0,0,1,1.83-.67,10.4,10.4,0,0,1,.58.86c0.35,0.65,1.2,1.86,1.54,2.5l0.11,0.17,0.72,1a1.34,1.34,0,0,0,1.36.51l0.11,0a1.34,1.34,0,0,0,1.06-1.31v-3.63Z"
              />
              <path
                class="cls-31"
                d="M1127.17,493.23c0-.72-1.65-1.15-2.34-1.36a5.2,5.2,0,0,0-2.17,0,7.81,7.81,0,0,0-2.62,1.61,6.52,6.52,0,0,0-1.4,2.57,7.74,7.74,0,0,0,.52,2.44c0.21,0.67.86,1.93,1,2.61s1,2.29.44,2.7-1.57-.6-2-0.94-1.56-1.24-2-.92c-0.25.18,0.06,0.93,0,1.23-0.1.53-.67,1.71-0.33,2.12,0.57,0.68,2.94.07,3.57,0a3.92,3.92,0,0,0,1.92-.44c0.62-.68.44-2.73,0.5-3.66s-0.27-2.7,0-3.55a6,6,0,0,1,1.42-2,11.29,11.29,0,0,1,1.66-.78,6.85,6.85,0,0,0,1.44-.91l0.11-.11A0.84,0.84,0,0,0,1127.17,493.23Z"
              />
              <path
                class="cls-31"
                d="M1147.11,488.09l0.73-2a16.33,16.33,0,0,1,1.3-2.68l0.94-.79A9.94,9.94,0,0,1,1151,482l0.74-.4a20.06,20.06,0,0,1,2.45-.84h1.06c0.16,0,1.47,0,1.63.07l1.85,0.5a4.66,4.66,0,0,1,1.1.46l1.62,1.46a7.43,7.43,0,0,0,1.2-2,3.43,3.43,0,0,0-1.78-2.3c-0.58-.21-1.81.36-2.41,0.49a3.91,3.91,0,0,1-1.54.35c-0.48-.18-0.76-1.38-1.19-1.67a3.69,3.69,0,0,0-2-.49,3.9,3.9,0,0,0-2,1.46c-0.17.34,0.27,1.26,0,1.53-0.61.61-2.67-.39-3.44,0a3.92,3.92,0,0,0-1.47,2.07c-0.06.34,0.5,1,.37,1.35s-1.37.61-1.69,1a3.48,3.48,0,0,0-.77,1.74,4.51,4.51,0,0,0,1,1.88l0.08,0.08a0.84,0.84,0,0,0,1.28-.47l0-.08Z"
              />
              <path
                class="cls-35"
                d="M1134.45,490.25h0a1.18,1.18,0,0,1,1.31.42l0.66,0.89a1.18,1.18,0,0,0,.66.44l2.94,0.74a1.18,1.18,0,0,0,.8-0.08l1.65-.79a1.18,1.18,0,0,0,.58-0.6l0.66-1.53a1.18,1.18,0,0,1,1.08-.71h0.58a1.18,1.18,0,0,1,1.17,1.36l-0.38,2.44a1.18,1.18,0,0,0,.1.68l0.9,1.93a1.18,1.18,0,0,1,.1.34l0.34,2.51a1.18,1.18,0,0,1-.47,1.1l-1.73,1.27a1.18,1.18,0,0,1-1.21.11l-1.17-.56a1.18,1.18,0,0,1-.66-0.93l-0.23-2v-0.72l-2,.68a1.18,1.18,0,0,1-.44.06l-1.7-.09a1.18,1.18,0,0,1-.29-0.05l-1-.3a1.18,1.18,0,0,1-.61-0.45l-0.85-1.22a1.18,1.18,0,0,1-.12-0.21l-0.89-2.06-0.47-1A1.18,1.18,0,0,1,1134.45,490.25Z"
              />
            </g>
            <g id="hors-2" data-name="hors">
              <path
                class="cls-34"
                d="M1075.55,501.49c0,0.87,1.12,2.82.5,3.44s-3.11-.54-3.92,0a2,2,0,0,0-.61,1.48c0.12,0.7,1.49,1.52,1.92,2.08a10.13,10.13,0,0,1,1.48,2.36,6.48,6.48,0,0,1,0,3.48c-0.23.46-1.46,0.74-1.66,1.22-0.31.76-.24,2.92,0.52,3.22s1.75-1.25,2.27-1.74a9.33,9.33,0,0,0,2-2.4,3.53,3.53,0,0,0,0-1.69,12,12,0,0,0-1.31-1.83,8.12,8.12,0,0,1-1.22-2.18,2.29,2.29,0,0,1,.35-1.57c0.66-.56,3.37.16,4.06-0.31a8,8,0,0,0,.15-0.95Z"
              />
              <path
                class="cls-36"
                d="M1096.24,505.22c0,0.77,2.95,2.67,3.44,3.26,0.34,0.41,1.3,1.22,1.22,1.74s-1.19,1.13-1.7,1.31a4.23,4.23,0,0,1-2.66,0c-0.49-.31-0.47-1.78-1-2.1-0.85-.55-3.59-0.9-4.06,0-0.3.56,1,1.61,1.44,2.1a10.29,10.29,0,0,0,2.62,2.52,4.09,4.09,0,0,0,1.74,0,26.08,26.08,0,0,0,3.05-1.31,10.31,10.31,0,0,0,2.44-.87,3.19,3.19,0,0,0,.87-1.7c0-1.39-1.8-4.52-1.8-5.91,0.21,0-.67-1.25-2-1.73a8.64,8.64,0,0,0-3.38-.33Z"
              />
              <path
                class="cls-34"
                d="M1101.45,506c0.79-.83,3.73-2,4.43-1.53s0.13,2.73-.09,3.61a13.4,13.4,0,0,1-2.08,4.15c-0.41.48-1.83,0.86-2.08,1.44s-0.26,2.76.45,3.07c0.86,0.37,2.6-1.35,3.07-2.16,0.24-.41,0-1.46.18-1.89,0.3-.66,1.71-1.45,2-2.12s0-2.07.09-2.75,0.71-2.08.81-2.8a4.59,4.59,0,0,0,0-2.07,2.84,2.84,0,0,0-1.45-1c-0.76-.16-2.15-1-2.54-0.28l-0.55.2-2.94,1.63Z"
              />
              <path
                class="cls-36"
                d="M1112.83,490.52l-0.33-3.06v-0.14l-0.07-2.48c0-.25,0,0-0.13-0.21l-0.9-1.48a1.34,1.34,0,0,0-.31-0.35l-1.34-1.07a1.34,1.34,0,0,0-.48-0.25l-2.37-.64a1.9,1.9,0,0,0-.5-0.07h-2.51a1.34,1.34,0,0,0-.56.12l-2.62,1.2a1.34,1.34,0,0,0-.3.19l-1.67,1.39a1.34,1.34,0,0,0-.41.6l-1.34,3.94,0,0.1-0.36,1.19v0c-0.06.25-.18,0.67-0.21,0.77v0c-0.34.83-1.17,2.55-1.06,3.28,0.09,0.56.88,1.47,1,2a4.88,4.88,0,0,1,0,2.72,3.67,3.67,0,0,1-2.33,1.16,2.66,2.66,0,0,1-1.48-.78A4.67,4.67,0,0,1,1092,497a1.73,1.73,0,0,0,0-.84c-0.42-.34-1.56.44-2.1,0.45a4.94,4.94,0,0,1-2.26-.45c-0.76-.57-1.38-2.5-1.79-3.36a5.27,5.27,0,0,0-.79-1.45,15.74,15.74,0,0,1-1.63,0h0l-3.17.17-0.24,0-1.34.32a1.34,1.34,0,0,0-.64.36l-1.07,1.07-0.11.1-1.38,1.1a1.34,1.34,0,0,0-.43.61l-0.5,1.45a1.33,1.33,0,0,0-.07.43v2.35a1.34,1.34,0,0,0,.19.68l0.87,1.46,0.19,0.26c0.41,0.45,1.16,1.29,1.55,1.71s1.22,1.28,1.65,1.73l0.17,0.22,0.31,0.48a1.34,1.34,0,0,1-.15,1.64l-1.73,1.84a0.93,0.93,0,0,0,.68,1.58h0.68l2,0.15a1.34,1.34,0,0,1,.4.1l1.33,0.54a1.34,1.34,0,0,1,.63.53l1,1.6a1.34,1.34,0,0,1,.2.84l-0.16,1.73a0.93,0.93,0,0,0,.47.9c0.57,0.32,1.69,1.58,2.26,1.27,0.31-.17.07-1,0.09-1.39s0.06-1.25.08-1.7l0-.31-0.2-1a1.34,1.34,0,0,1,0-.37l0.09-1a0.93,0.93,0,0,0-.58-0.95l-0.84-.35-2.05-.82-0.24-.13-1.14-.76a1.28,1.28,0,0,1-.57-1.07h0a1.28,1.28,0,0,1,.38-0.91l1-1,0.14-.12,1.43-1.08a1.34,1.34,0,0,1,1.43-.12l1,0.5,0.24,0.1,2,0.6a1.34,1.34,0,0,0,.38.06h4.85a1.34,1.34,0,0,0,.53-0.11l0.81-.35a1.52,1.52,0,0,0,.75-2,1.13,1.13,0,0,1,.47-1.37l0.21-.13a1.29,1.29,0,0,1,.82-0.18l1.19,0.14a1.34,1.34,0,0,1,.89.5l0.18,0.22a1.34,1.34,0,0,0,1.83.26l0.9-.64a1.34,1.34,0,0,0,.27-0.26l1.11-1.39a1,1,0,0,0,.21-0.47c0.17-1.11.83-5.43,0.83-5.66s0.08-2.8.09-3.18a0.9,0.9,0,0,0,0-.09l-0.06-.8a1,1,0,0,1,1.83-.67,10.4,10.4,0,0,1,.58.86c0.35,0.65,1.2,1.86,1.54,2.5l0.11,0.17,0.72,1a1.34,1.34,0,0,0,1.36.51l0.11,0a1.34,1.34,0,0,0,1.06-1.31v-3.63Z"
              />
              <path
                class="cls-31"
                d="M1077.17,493.23c0-.72-1.65-1.15-2.34-1.36a5.2,5.2,0,0,0-2.17,0,7.81,7.81,0,0,0-2.62,1.61,6.52,6.52,0,0,0-1.4,2.57,7.74,7.74,0,0,0,.52,2.44c0.21,0.67.86,1.93,1,2.61s1,2.29.44,2.7-1.57-.6-2-0.94-1.56-1.24-2-.92c-0.25.18,0.06,0.93,0,1.23-0.1.53-.67,1.71-0.33,2.12,0.57,0.68,2.94.07,3.57,0a3.92,3.92,0,0,0,1.92-.44c0.62-.68.44-2.73,0.5-3.66s-0.27-2.7,0-3.55a6,6,0,0,1,1.42-2,11.29,11.29,0,0,1,1.66-.78,6.85,6.85,0,0,0,1.44-.91l0.11-.11A0.84,0.84,0,0,0,1077.17,493.23Z"
              />
              <path
                class="cls-31"
                d="M1097.11,488.09l0.73-2a16.33,16.33,0,0,1,1.3-2.68l0.94-.79A9.94,9.94,0,0,1,1101,482l0.74-.4a20.06,20.06,0,0,1,2.45-.84h1.06c0.16,0,1.47,0,1.63.07l1.85,0.5a4.66,4.66,0,0,1,1.1.46l1.62,1.46a7.43,7.43,0,0,0,1.2-2,3.43,3.43,0,0,0-1.78-2.3c-0.58-.21-1.81.36-2.41,0.49a3.91,3.91,0,0,1-1.54.35c-0.48-.18-0.76-1.38-1.19-1.67a3.69,3.69,0,0,0-2-.49,3.9,3.9,0,0,0-2,1.46c-0.17.34,0.27,1.26,0,1.53-0.61.61-2.67-.39-3.44,0a3.92,3.92,0,0,0-1.47,2.07c-0.06.34,0.5,1,.37,1.35s-1.37.61-1.69,1a3.48,3.48,0,0,0-.77,1.74,4.51,4.51,0,0,0,1,1.88l0.08,0.08a0.84,0.84,0,0,0,1.28-.47l0-.08Z"
              />
              <path
                class="cls-35"
                d="M1084.45,490.25h0a1.18,1.18,0,0,1,1.31.42l0.66,0.89a1.18,1.18,0,0,0,.66.44l2.94,0.74a1.18,1.18,0,0,0,.8-0.08l1.65-.79a1.18,1.18,0,0,0,.58-0.6l0.66-1.53a1.18,1.18,0,0,1,1.08-.71h0.58a1.18,1.18,0,0,1,1.17,1.36l-0.38,2.44a1.18,1.18,0,0,0,.1.68l0.9,1.93a1.18,1.18,0,0,1,.1.34l0.34,2.51a1.18,1.18,0,0,1-.47,1.1l-1.73,1.27a1.18,1.18,0,0,1-1.21.11l-1.17-.56a1.18,1.18,0,0,1-.66-0.93l-0.23-2v-0.72l-2,.68a1.18,1.18,0,0,1-.44.06l-1.7-.09a1.18,1.18,0,0,1-.29-0.05l-1-.3a1.18,1.18,0,0,1-.61-0.45l-0.85-1.22a1.18,1.18,0,0,1-.12-0.21l-0.89-2.06-0.47-1A1.18,1.18,0,0,1,1084.45,490.25Z"
              />
            </g>
            <g id="hors-3" data-name="hors">
              <path
                class="cls-34"
                d="M1025.2,501.49c0,0.87,1.12,2.82.5,3.44s-3.11-.54-3.92,0a2,2,0,0,0-.61,1.48c0.12,0.7,1.49,1.52,1.92,2.08a10.13,10.13,0,0,1,1.48,2.36,6.48,6.48,0,0,1,0,3.48c-0.23.46-1.46,0.74-1.66,1.22-0.31.76-.24,2.92,0.52,3.22s1.75-1.25,2.27-1.74a9.33,9.33,0,0,0,2-2.4,3.53,3.53,0,0,0,0-1.69,12,12,0,0,0-1.31-1.83,8.12,8.12,0,0,1-1.22-2.18,2.29,2.29,0,0,1,.35-1.57c0.66-.56,3.37.16,4.06-0.31a8,8,0,0,0,.15-0.95Z"
              />
              <path
                class="cls-4"
                d="M1045.89,505.22c0,0.77,2.95,2.67,3.44,3.26,0.34,0.41,1.3,1.22,1.22,1.74s-1.19,1.13-1.7,1.31a4.23,4.23,0,0,1-2.66,0c-0.49-.31-0.47-1.78-1-2.1-0.85-.55-3.59-0.9-4.06,0-0.3.56,1,1.61,1.44,2.1a10.29,10.29,0,0,0,2.62,2.52,4.09,4.09,0,0,0,1.74,0,26.08,26.08,0,0,0,3.05-1.31,10.31,10.31,0,0,0,2.44-.87,3.19,3.19,0,0,0,.87-1.7c0-1.39-1.8-4.52-1.8-5.91,0.21,0-.67-1.25-2-1.73a8.64,8.64,0,0,0-3.38-.33Z"
              />
              <path
                class="cls-34"
                d="M1051.1,506c0.79-.83,3.73-2,4.43-1.53s0.13,2.73-.09,3.61a13.4,13.4,0,0,1-2.08,4.15c-0.41.48-1.83,0.86-2.08,1.44s-0.26,2.76.45,3.07c0.86,0.37,2.6-1.35,3.07-2.16,0.24-.41,0-1.46.18-1.89,0.3-.66,1.71-1.45,2-2.12s0-2.07.09-2.75,0.71-2.08.81-2.8a4.59,4.59,0,0,0,0-2.07,2.84,2.84,0,0,0-1.45-1c-0.76-.16-2.15-1-2.54-0.28l-0.55.2-2.94,1.63Z"
              />
              <path
                class="cls-4"
                d="M1062.49,490.52l-0.33-3.06v-0.14l-0.07-2.48c0-.25,0,0-0.13-0.21l-0.9-1.48a1.34,1.34,0,0,0-.31-0.35l-1.34-1.07a1.34,1.34,0,0,0-.48-0.25l-2.37-.64a1.9,1.9,0,0,0-.5-0.07h-2.51a1.34,1.34,0,0,0-.56.12l-2.62,1.2a1.34,1.34,0,0,0-.3.19l-1.67,1.39a1.34,1.34,0,0,0-.41.6l-1.34,3.94,0,0.1-0.36,1.19v0c-0.06.25-.18,0.67-0.21,0.77v0c-0.34.83-1.17,2.55-1.06,3.28,0.09,0.56.88,1.47,1,2a4.88,4.88,0,0,1,0,2.72,3.67,3.67,0,0,1-2.33,1.16,2.66,2.66,0,0,1-1.48-.78,4.67,4.67,0,0,1-.47-1.79,1.73,1.73,0,0,0,0-.84c-0.42-.34-1.56.44-2.1,0.45a4.94,4.94,0,0,1-2.26-.45c-0.76-.57-1.38-2.5-1.79-3.36a5.27,5.27,0,0,0-.79-1.45,15.74,15.74,0,0,1-1.63,0h0l-3.17.17-0.24,0-1.34.32a1.34,1.34,0,0,0-.64.36l-1.07,1.07-0.11.1-1.38,1.1a1.34,1.34,0,0,0-.43.61l-0.5,1.45a1.33,1.33,0,0,0-.07.43v2.35a1.34,1.34,0,0,0,.19.68l0.87,1.46,0.19,0.26c0.41,0.45,1.16,1.29,1.55,1.71s1.22,1.28,1.65,1.73l0.17,0.22,0.31,0.48a1.34,1.34,0,0,1-.15,1.64l-1.73,1.84a0.93,0.93,0,0,0,.68,1.58h0.68l2,0.15a1.34,1.34,0,0,1,.4.1l1.33,0.54a1.34,1.34,0,0,1,.63.53l1,1.6a1.34,1.34,0,0,1,.2.84l-0.16,1.73a0.93,0.93,0,0,0,.47.9c0.57,0.32,1.69,1.58,2.26,1.27,0.31-.17.07-1,0.09-1.39s0.06-1.25.08-1.7l0-.31-0.2-1a1.34,1.34,0,0,1,0-.37l0.09-1a0.93,0.93,0,0,0-.58-0.95l-0.84-.35-2.05-.82-0.24-.13-1.14-.76a1.28,1.28,0,0,1-.57-1.07h0a1.28,1.28,0,0,1,.38-0.91l1-1,0.14-.12,1.43-1.08a1.34,1.34,0,0,1,1.43-.12l1,0.5,0.24,0.1,2,0.6a1.34,1.34,0,0,0,.38.06h4.85a1.34,1.34,0,0,0,.53-0.11l0.81-.35a1.52,1.52,0,0,0,.75-2,1.13,1.13,0,0,1,.47-1.37l0.21-.13a1.29,1.29,0,0,1,.82-0.18l1.19,0.14a1.34,1.34,0,0,1,.89.5l0.18,0.22a1.34,1.34,0,0,0,1.83.26l0.9-.64a1.34,1.34,0,0,0,.27-0.26l1.11-1.39a1,1,0,0,0,.21-0.47c0.17-1.11.83-5.43,0.83-5.66s0.08-2.8.09-3.18a0.9,0.9,0,0,0,0-.09l-0.06-.8a1,1,0,0,1,1.83-.67,10.4,10.4,0,0,1,.58.86c0.35,0.65,1.2,1.86,1.54,2.5l0.11,0.17,0.72,1a1.34,1.34,0,0,0,1.36.51l0.11,0a1.34,1.34,0,0,0,1.06-1.31v-3.63Z"
              />
              <path
                class="cls-31"
                d="M1026.82,493.23c0-.72-1.65-1.15-2.34-1.36a5.2,5.2,0,0,0-2.17,0,7.81,7.81,0,0,0-2.62,1.61,6.52,6.52,0,0,0-1.4,2.57,7.74,7.74,0,0,0,.52,2.44c0.21,0.67.86,1.93,1,2.61s1,2.29.44,2.7-1.57-.6-2-0.94-3.56-1.24-4-.92c-0.25.18,0.06,0.93,0,1.23-0.1.53-.67,1.71-0.33,2.12,0.57,0.68,4.94.07,5.57,0a3.92,3.92,0,0,0,1.92-.44c0.62-.68.44-2.73,0.5-3.66s-0.27-2.7,0-3.55a6,6,0,0,1,1.42-2,11.29,11.29,0,0,1,1.66-.78,6.85,6.85,0,0,0,1.44-.91l0.11-.11A0.84,0.84,0,0,0,1026.82,493.23Z"
              />
              <path
                class="cls-31"
                d="M1046.76,488.09l0.73-2a16.33,16.33,0,0,1,1.3-2.68l0.94-.79a9.94,9.94,0,0,1,.94-0.65l0.74-.4a20.06,20.06,0,0,1,2.45-.84h1.06c0.16,0,1.47,0,1.63.07l1.85,0.5a4.66,4.66,0,0,1,1.1.46l1.62,1.46a7.43,7.43,0,0,0,1.2-2,3.43,3.43,0,0,0-1.78-2.3c-0.58-.21-1.81.36-2.41,0.49a3.91,3.91,0,0,1-1.54.35c-0.48-.18-0.76-1.38-1.19-1.67a3.69,3.69,0,0,0-2-.49,3.9,3.9,0,0,0-2,1.46c-0.17.34,0.27,1.26,0,1.53-0.61.61-2.67-.39-3.44,0a3.92,3.92,0,0,0-1.47,2.07c-0.06.34,0.5,1,.37,1.35s-1.37.61-1.69,1a3.48,3.48,0,0,0-.77,1.74,4.51,4.51,0,0,0,1,1.88l0.08,0.08a0.84,0.84,0,0,0,1.28-.47l0-.08Z"
              />
              <path
                class="cls-35"
                d="M1034.1,490.25h0a1.18,1.18,0,0,1,1.31.42l0.66,0.89a1.18,1.18,0,0,0,.66.44l2.94,0.74a1.18,1.18,0,0,0,.8-0.08l1.65-.79a1.18,1.18,0,0,0,.58-0.6l0.66-1.53a1.18,1.18,0,0,1,1.08-.71H1045a1.18,1.18,0,0,1,1.17,1.36l-0.38,2.44a1.18,1.18,0,0,0,.1.68l0.9,1.93a1.18,1.18,0,0,1,.1.34l0.34,2.51a1.18,1.18,0,0,1-.47,1.1l-1.73,1.27a1.18,1.18,0,0,1-1.21.11l-1.17-.56a1.18,1.18,0,0,1-.66-0.93l-0.23-2v-0.72l-2,.68a1.18,1.18,0,0,1-.44.06l-1.7-.09a1.18,1.18,0,0,1-.29-0.05l-1-.3a1.18,1.18,0,0,1-.61-0.45l-0.85-1.22a1.18,1.18,0,0,1-.12-0.21l-0.89-2.06-0.47-1A1.18,1.18,0,0,1,1034.1,490.25Z"
              />
            </g>
          </g>

          <g>
            <g id="leg">
              <path
                class="cls-2"
                d="M581.09,516.09s67.05-211.66,67.05-211.66l-95.8,211.65C554.53,516.17,581,516.11,581.09,516.09Z"
              />
              <path
                class="cls-37"
                d="M743.93,516.25L648.18,304.33v0l67.14,211.9C714.49,516.3,737.35,516.37,743.93,516.25Z"
              />
              <rect
                class="cls-23"
                x="524.4"
                y="516.13"
                width="247.24"
                height="12.44"
                rx="6.22"
                ry="6.22"
              />
              <rect
                class="cls-35"
                x="494.08"
                y="528.27"
                width="306.64"
                height="15.8"
                rx="7.9"
                ry="7.9"
              />
            </g>
            <g id="needles" class="cls-8">
              <polygon
                class="cls-38"
                points="648.42 181.87 657.13 260.57 697.1 192.22 673.04 267.66 737.36 221.47 684.7 280.6 762.25 264.56 690.08 297.17 767.45 314.06 688.26 314.49 752.07 361.38 679.55 329.57 718.77 398.37 665.46 339.81 673.31 418.61 648.42 343.43 623.54 418.61 631.39 339.81 578.08 398.37 617.29 329.57 544.78 361.38 608.59 314.49 529.4 314.06 606.77 297.17 534.6 264.56 612.15 280.6 559.49 221.47 623.8 267.66 599.75 192.22 639.72 260.57 648.42 181.87"
              />
              <polygon
                class="cls-38"
                points="673.69 184.6 665.4 263.35 719.04 205.09 679.43 273.67 752.13 242.27 688.05 288.8 767.24 289.68 689.78 306.13 761.75 339.14 684.3 322.67 736.63 382.1 672.57 335.54 696.2 411.12 657.22 342.54 648.06 421.19 639.2 342.44 598.85 410.57 623.33 335.26 558.75 381.09 611.75 322.25 534.12 337.85 606.46 305.66 529.2 288.33 608.38 288.35 544.84 241.09 617.18 273.31 578.35 204.3 631.33 263.15 623.93 184.31 648.38 259.63 673.69 184.6"
              />
            </g>
            <g id="wheels">
              <circle class="cls-38" cx="648.22" cy="301.55" r="158.88" />
              <path
                class="cls-35"
                d="M648.15,423.09h0.21a121.54,121.54,0,1,0,0-243.08h-0.21V159.66h0.21a141.89,141.89,0,0,1,0,283.79h-0.21V423.09Z"
              />
              <path
                class="cls-15"
                d="M648.42,423.09h-0.21a121.54,121.54,0,1,1,0-243.08h0.21V159.66h-0.21a141.89,141.89,0,0,0,0,283.79h0.21V423.09Z"
              />
              <path
                class="cls-37"
                d="M648.14,257.79a43.76,43.76,0,0,1,0,87.52V257.79Z"
              />
              <path
                class="cls-2"
                d="M648.15,257.79a43.76,43.76,0,0,0,0,87.52V257.79Z"
              />
            </g>
            <g id="arms">
              <line
                class="cls-39"
                x1="733.52"
                y1="435.61"
                x2="563.03"
                y2="167.41"
              />
              <line
                class="cls-39"
                x1="797.24"
                y1="356.77"
                x2="499.28"
                y2="246.11"
              />
              <line
                class="cls-39"
                x1="797.16"
                y1="246.11"
                x2="499.2"
                y2="356.77"
              />
              <line
                class="cls-39"
                x1="733.41"
                y1="167.41"
                x2="562.92"
                y2="435.61"
              />
              <line
                class="cls-39"
                x1="648.22"
                y1="142.66"
                x2="648.22"
                y2="460.43"
              />
            </g>
            <g id="cabins">
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M801.41,357.53a24.71,24.71,0,0,0-4.14-.35V361a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V377.94C814.64,370.9,809.6,359.08,801.41,357.53Z"
                />
                <path
                  class="cls-41"
                  d="M793.13,357.53a24.71,24.71,0,0,1,4.14-.35V361a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V377.94C779.89,370.9,784.93,359.08,793.13,357.53Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M503.08,357.53a24.71,24.71,0,0,0-4.14-.35V361a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V377.94C516.32,370.9,511.27,359.08,503.08,357.53Z"
                />
                <path
                  class="cls-41"
                  d="M494.8,357.53a24.71,24.71,0,0,1,4.14-.35V361a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V377.94C481.56,370.9,486.61,359.08,494.8,357.53Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M503.34,247.09a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1H503c7.51-.25,13.64-6.44,13.56-13.56V267.5C516.58,260.46,511.53,248.64,503.34,247.09Z"
                />
                <path
                  class="cls-41"
                  d="M495.06,247.09a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V267.5C481.82,260.46,486.87,248.64,495.06,247.09Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M567.39,168.41a24.71,24.71,0,0,0-4.14-.35v3.79A26.34,26.34,0,0,1,566,172c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V188.82C580.63,181.78,575.58,170,567.39,168.41Z"
                />
                <path
                  class="cls-41"
                  d="M559.11,168.41a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V188.82C545.87,181.78,550.92,170,559.11,168.41Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M566.81,436.21a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V456.62C580,449.57,575,437.75,566.81,436.21Z"
                />
                <path
                  class="cls-41"
                  d="M558.53,436.21a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V456.62C545.29,449.57,550.34,437.75,558.53,436.21Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M652.17,143a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V163.42C665.4,156.38,660.36,144.55,652.17,143Z"
                />
                <path
                  class="cls-41"
                  d="M643.89,143a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1H644.2c-7.51-.25-13.64-6.44-13.56-13.56V163.42C630.65,156.38,635.69,144.55,643.89,143Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M737.69,167.81a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V188.22C750.93,181.18,745.89,169.36,737.69,167.81Z"
                />
                <path
                  class="cls-41"
                  d="M729.42,167.81a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V188.22C716.18,181.18,721.22,169.36,729.42,167.81Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M801.3,247a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1H801c7.51-.25,13.64-6.44,13.56-13.56V267.36C814.53,260.32,809.49,248.49,801.3,247Z"
                />
                <path
                  class="cls-41"
                  d="M793,247a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V267.36C779.78,260.32,784.82,248.49,793,247Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M737.58,436a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V456.39C750.82,449.34,745.78,437.52,737.58,436Z"
                />
                <path
                  class="cls-41"
                  d="M729.3,436a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V456.39C716.07,449.34,721.11,437.52,729.3,436Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M652.28,461a27.42,27.42,0,0,0-4.36-.35v3.79c0.93,0,2.08,0,3,.15,7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74-0.89,0-2-.06-2.89-0.06v20.1H652c7.51-.25,13.64-6.44,13.56-13.56V481.42C665.52,474.38,660.47,462.55,652.28,461Z"
                />
                <path
                  class="cls-41"
                  d="M643.78,461a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V481.42C630.54,474.38,635.58,462.55,643.78,461Z"
                />
              </g>
            </g>
            <g id="mask">
              <path
                class="cls-35"
                d="M648.27,278.27a23.28,23.28,0,0,1,0,46.56V278.27Z"
              />
              <path
                class="cls-23"
                d="M648.14,278.27a23.28,23.28,0,0,0,0,46.56V278.27Z"
              />
            </g>
          </g>

          <g id="icecream">
            <g id="icekr">
              <g id="stall">
                <g>
                  <path
                    class="cls-22"
                    d="M988.64,469H968.93l-8.33,15.07h7.72v28.78a6.18,6.18,0,0,0-.27,1.76v14.12c0,3.89,3.65,7.08,8.11,7.08h12.48V469Z"
                  />
                  <path
                    class="cls-22"
                    d="M975.81,507.55a2.8,2.8,0,0,1-3.08-2.58V484.09h6.5c0,2.24,2.08,4.05,4.64,4.05a4.49,4.49,0,0,0,4.58-3.41,3.62,3.62,0,0,0,.19.67v22.15H975.81Z"
                  />
                  <path
                    class="cls-41"
                    d="M968.05,528.75V514.63a6.18,6.18,0,0,1,.27-1.76V484.09h4.41V505a2.8,2.8,0,0,0,3.08,2.58h12.84v28.29H976.16C971.7,535.83,968.05,532.65,968.05,528.75Z"
                  />
                  <polygon
                    class="cls-42"
                    points="988.39 484.09 979.23 484.09 972.73 484.09 968.32 484.09 960.6 484.09 968.93 469.02 988.64 469.02 988.64 484.09 988.51 484.09 988.39 484.09"
                  />
                  <path
                    class="cls-31"
                    d="M983.87,488.14c-2.56,0-4.64-1.81-4.64-4.05h9.41v1.31a3.62,3.62,0,0,1-.19-0.67A4.49,4.49,0,0,1,983.87,488.14Z"
                  />
                </g>
                <g>
                  <path
                    class="cls-22"
                    d="M988.33,469H1008l8.33,15.07h-7.72v28.78a6.18,6.18,0,0,1,.27,1.76v14.12c0,3.89-3.65,7.08-8.11,7.08H988.33V469Z"
                  />
                  <path
                    class="cls-22"
                    d="M1001.17,507.55a2.8,2.8,0,0,0,3.08-2.58V484.09h-6.5c0,2.24-2.08,4.05-4.64,4.05a4.49,4.49,0,0,1-4.58-3.41,3.62,3.62,0,0,1-.19.67v22.15h12.84Z"
                  />
                  <path
                    class="cls-40"
                    d="M1008.93,528.75V514.63a6.18,6.18,0,0,0-.27-1.76V484.09h-4.41V505a2.8,2.8,0,0,1-3.08,2.58H988.33v28.29h12.48C1005.27,535.83,1008.93,532.65,1008.93,528.75Z"
                  />
                  <polygon
                    class="cls-43"
                    points="988.59 484.09 997.74 484.09 1004.25 484.09 1008.66 484.09 1016.38 484.09 1008.05 469.02 988.33 469.02 988.33 484.09 988.46 484.09 988.59 484.09"
                  />
                  <path
                    class="cls-31"
                    d="M993.1,488.14c2.56,0,4.64-1.81,4.64-4.05h-9.41v1.31a3.62,3.62,0,0,0,.19-0.67A4.49,4.49,0,0,0,993.1,488.14Z"
                  />
                </g>
                <g id="телега3">
                  <path
                    class="cls-31"
                    d="M1002.39,488.14c2.56,0,4.64-1.81,4.64-4.05h-9.28C997.74,486.33,999.82,488.14,1002.39,488.14Z"
                  />
                  <path
                    class="cls-31"
                    d="M1011.67,488.14c2.56,0,4.64-1.81,4.64-4.05H1007C1007,486.33,1009.1,488.14,1011.67,488.14Z"
                  />
                </g>
                <g id="телега3-2" data-name="телега3">
                  <path
                    class="cls-31"
                    d="M974.59,488.14c-2.56,0-4.64-1.81-4.64-4.05h9.28C979.23,486.33,977.16,488.14,974.59,488.14Z"
                  />
                  <path
                    class="cls-31"
                    d="M965.31,488.14c-2.56,0-4.64-1.81-4.64-4.05H970C970,486.33,967.87,488.14,965.31,488.14Z"
                  />
                </g>
              </g>
              <g id="ice">
                <path
                  class="cls-31"
                  d="M995.16,477.73c-4.88-.1-8.52-3.82-8.14-8.32s4.65-8.07,9.53-8,8.52,3.82,8.14,8.32S1000,477.82,995.16,477.73Z"
                />
                <polygon
                  class="cls-36"
                  points="987.67 470.37 997.06 477.76 978.96 493.12 987.67 470.37"
                />
                <path
                  class="cls-44"
                  d="M993.19,472.16a6.27,6.27,0,0,1-7.79,2.52,4.83,4.83,0,0,1-2.39-6.95,6.27,6.27,0,0,1,7.79-2.52A4.83,4.83,0,0,1,993.19,472.16Z"
                />
                <path
                  class="cls-23"
                  d="M995.94,481a5.49,5.49,0,0,1-5.67-5.8,6.43,6.43,0,0,1,6.65-5.56,5.49,5.49,0,0,1,5.67,5.8A6.43,6.43,0,0,1,995.94,481Z"
                />
                <path
                  class="cls-45"
                  d="M992.82,464.59l0.93,0.16a0.66,0.66,0,0,0,.59-0.2l1.24-1.35a0.51,0.51,0,0,0,.09-0.6,0.52,0.52,0,0,0-.14-0.16,0.6,0.6,0,0,0-.43-0.11l-1.58.17a0.64,0.64,0,0,0-.49.31l-0.59,1a0.49,0.49,0,0,0,0,.48A0.54,0.54,0,0,0,992.82,464.59Z"
                />
                <path
                  class="cls-46"
                  d="M987.21,466.32a0.35,0.35,0,0,0-.09-0.11,0.4,0.4,0,0,0-.28-0.07l-1,.11a0.42,0.42,0,0,0-.32.2l-0.39.68a0.33,0.33,0,0,0,0,.31,0.36,0.36,0,0,0,.26.19l0.61,0.1a0.43,0.43,0,0,0,.39-0.13l0.82-.89A0.33,0.33,0,0,0,987.21,466.32Z"
                />
                <path
                  class="cls-47"
                  d="M996.17,471.13a0.35,0.35,0,0,0-.09-0.11,0.4,0.4,0,0,0-.28-0.07l-1,.11a0.42,0.42,0,0,0-.32.2l-0.39.68a0.33,0.33,0,0,0,0,.31,0.36,0.36,0,0,0,.26.19l0.61,0.1a0.43,0.43,0,0,0,.39-0.13l0.82-.89A0.33,0.33,0,0,0,996.17,471.13Z"
                />
              </g>
            </g>
            <g id="wheel">
              <path
                class="cls-31"
                d="M1008.93,522.6a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="1008.93"
                y1="543.24"
                x2="1008.93"
                y2="521.6"
              />
              <line
                class="cls-48"
                x1="1014.72"
                y1="541.56"
                x2="1003.13"
                y2="523.29"
              />
              <line
                class="cls-48"
                x1="999"
                y1="528.12"
                x2="1018.85"
                y2="536.72"
              />
              <line
                class="cls-48"
                x1="998.17"
                y1="533.55"
                x2="1019.68"
                y2="531.29"
              />
              <line
                class="cls-48"
                x1="1003.76"
                y1="541.92"
                x2="1014.09"
                y2="522.92"
              />
              <line
                class="cls-48"
                x1="1000.16"
                y1="538.76"
                x2="1017.69"
                y2="526.08"
              />
              <circle class="cls-49" cx="1008.93" cy="532.42" r="3.4" />
            </g>
            <g id="whel">
              <path
                class="cls-31"
                d="M968.21,522.6a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1A10.82,10.82,0,1,0,979,532.42a10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="968.21"
                y1="543.24"
                x2="968.21"
                y2="521.6"
              />
              <line
                class="cls-48"
                x1="974"
                y1="541.56"
                x2="962.42"
                y2="523.29"
              />
              <line
                class="cls-48"
                x1="958.29"
                y1="528.12"
                x2="978.13"
                y2="536.72"
              />
              <line
                class="cls-48"
                x1="957.45"
                y1="533.55"
                x2="978.97"
                y2="531.29"
              />
              <line
                class="cls-48"
                x1="963.04"
                y1="541.92"
                x2="973.38"
                y2="522.92"
              />
              <line
                class="cls-48"
                x1="959.45"
                y1="538.76"
                x2="976.97"
                y2="526.08"
              />
              <circle class="cls-36" cx="968.21" cy="532.42" r="3.4" />
            </g>
          </g>

          <g id="cotton_candy" data-name="cotton candy">
            <g id="stall-2" data-name="stall">
              <g id="stall-3" data-name="stall">
                <g id="тачка">
                  <g>
                    <path
                      class="cls-22"
                      d="M1194.25,469.22h-19.71l-8.33,15.07h7.72v28.78a6.18,6.18,0,0,0-.27,1.76v14.12c0,3.89,3.65,7.08,8.11,7.08h12.48V469.22Z"
                    />
                    <path
                      class="cls-22"
                      d="M1181.41,507.75a2.8,2.8,0,0,1-3.08-2.58V484.29h6.5c0,2.24,2.08,4.05,4.64,4.05a4.49,4.49,0,0,0,4.58-3.41,3.62,3.62,0,0,0,.19.67v22.15h-12.84Z"
                    />
                    <path
                      class="cls-41"
                      d="M1173.65,528.95V514.82a6.18,6.18,0,0,1,.27-1.76V484.29h4.41v20.88a2.8,2.8,0,0,0,3.08,2.58h12.84V536h-12.48C1177.31,536,1173.65,532.84,1173.65,528.95Z"
                    />
                    <polygon
                      class="cls-42"
                      points="1193.99 484.29 1184.84 484.29 1178.33 484.29 1173.92 484.29 1166.2 484.29 1174.53 469.22 1194.25 469.22 1194.25 484.29 1194.12 484.29 1193.99 484.29"
                    />
                    <path
                      class="cls-27"
                      d="M1189.48,488.34c-2.56,0-4.64-1.81-4.64-4.05h9.41v1.31a3.62,3.62,0,0,1-.19-0.67A4.49,4.49,0,0,1,1189.48,488.34Z"
                    />
                  </g>
                  <g>
                    <path
                      class="cls-22"
                      d="M1193.94,469.22h19.71l8.33,15.07h-7.72v28.78a6.18,6.18,0,0,1,.27,1.76v14.12c0,3.89-3.65,7.08-8.11,7.08h-12.48V469.22Z"
                    />
                    <path
                      class="cls-22"
                      d="M1206.77,507.75a2.8,2.8,0,0,0,3.08-2.58V484.29h-6.5c0,2.24-2.08,4.05-4.64,4.05a4.49,4.49,0,0,1-4.58-3.41,3.62,3.62,0,0,1-.19.67v22.15h12.84Z"
                    />
                    <path
                      class="cls-40"
                      d="M1214.53,528.95V514.82a6.18,6.18,0,0,0-.27-1.76V484.29h-4.41v20.88a2.8,2.8,0,0,1-3.08,2.58h-12.84V536h12.48C1210.88,536,1214.53,532.84,1214.53,528.95Z"
                    />
                    <polygon
                      class="cls-43"
                      points="1194.19 484.29 1203.35 484.29 1209.85 484.29 1214.26 484.29 1221.98 484.29 1213.65 469.22 1193.94 469.22 1193.94 484.29 1194.07 484.29 1194.19 484.29"
                    />
                    <path
                      class="cls-27"
                      d="M1198.71,488.34c2.56,0,4.64-1.81,4.64-4.05h-9.41v1.31a3.62,3.62,0,0,0,.19-0.67A4.49,4.49,0,0,0,1198.71,488.34Z"
                    />
                  </g>
                  <g id="телега3-3" data-name="телега3">
                    <path
                      class="cls-27"
                      d="M1208,488.34c2.56,0,4.64-1.81,4.64-4.05h-9.28C1203.35,486.52,1205.42,488.34,1208,488.34Z"
                    />
                    <path
                      class="cls-27"
                      d="M1217.27,488.34c2.56,0,4.64-1.81,4.64-4.05h-9.28C1212.63,486.52,1214.71,488.34,1217.27,488.34Z"
                    />
                  </g>
                  <g id="телега3-4" data-name="телега3">
                    <path
                      class="cls-27"
                      d="M1180.19,488.34c-2.56,0-4.64-1.81-4.64-4.05h9.28C1184.84,486.52,1182.76,488.34,1180.19,488.34Z"
                    />
                    <path
                      class="cls-27"
                      d="M1170.91,488.34c-2.56,0-4.64-1.81-4.64-4.05h9.28C1175.55,486.52,1173.48,488.34,1170.91,488.34Z"
                    />
                  </g>
                </g>
              </g>
              <g id="wheel-2" data-name="wheel">
                <path
                  class="cls-27"
                  d="M1214.53,522.8a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
                />
                <line
                  class="cls-50"
                  x1="1214.53"
                  y1="543.43"
                  x2="1214.53"
                  y2="521.8"
                />
                <line
                  class="cls-50"
                  x1="1220.32"
                  y1="541.75"
                  x2="1208.74"
                  y2="523.48"
                />
                <line
                  class="cls-50"
                  x1="1204.6"
                  y1="528.31"
                  x2="1224.45"
                  y2="536.92"
                />
                <line
                  class="cls-50"
                  x1="1203.77"
                  y1="533.75"
                  x2="1225.28"
                  y2="531.49"
                />
                <line
                  class="cls-50"
                  x1="1209.36"
                  y1="542.12"
                  x2="1219.7"
                  y2="523.11"
                />
                <line
                  class="cls-50"
                  x1="1205.76"
                  y1="538.96"
                  x2="1223.29"
                  y2="526.28"
                />
                <circle class="cls-49" cx="1214.53" cy="532.62" r="3.4" />
              </g>
              <g id="wheel-3" data-name="wheel">
                <path
                  class="cls-27"
                  d="M1173.81,522.8a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
                />
                <line
                  class="cls-50"
                  x1="1173.81"
                  y1="543.43"
                  x2="1173.81"
                  y2="521.8"
                />
                <line
                  class="cls-50"
                  x1="1179.6"
                  y1="541.75"
                  x2="1168.02"
                  y2="523.48"
                />
                <line
                  class="cls-50"
                  x1="1163.89"
                  y1="528.31"
                  x2="1183.74"
                  y2="536.92"
                />
                <line
                  class="cls-50"
                  x1="1163.05"
                  y1="533.75"
                  x2="1184.57"
                  y2="531.49"
                />
                <line
                  class="cls-50"
                  x1="1168.64"
                  y1="542.12"
                  x2="1178.98"
                  y2="523.11"
                />
                <line
                  class="cls-50"
                  x1="1165.05"
                  y1="538.96"
                  x2="1182.57"
                  y2="526.28"
                />
                <circle class="cls-36" cx="1173.81" cy="532.62" r="3.4" />
              </g>
            </g>
            <g id="candy">
              <g>
                <rect
                  class="cls-51"
                  x="1199.18"
                  y="478.71"
                  width="3.22"
                  height="14.47"
                  rx="0.81"
                  ry="0.81"
                  transform="matrix(0.8, -0.6, 0.6, 0.8, -51.79, 816.2)"
                />
                <path
                  class="cls-23"
                  d="M1202.33,466.35a3,3,0,0,1-1.73-1.36q-0.14-.26-0.3-0.51a11.8,11.8,0,1,0-19.91,12.65q0.17,0.27.36,0.53a3.16,3.16,0,0,1,.6,2.2,10.17,10.17,0,0,0,1.93,7.11,10.42,10.42,0,0,0,14,2.4l7.09-4.5A10.46,10.46,0,0,0,1208,471,10.15,10.15,0,0,0,1202.33,466.35Z"
                />
              </g>
              <path
                class="cls-52"
                d="M1200.06,473a7.59,7.59,0,0,0,2.1-4.15,8.59,8.59,0,0,0-1-2.47c0.07-.44-1.41-2.76-1.51-2.45s0.29,3.84.32,4.06c0.18,1.44-1.36,3.41-1.77,4s-4.23,4.43-4.23,4.43C1194,476.71,1199.82,473.23,1200.06,473Z"
              />
            </g>
          </g>

          <g id="tickets">
            <g id="part2">
              <path
                class="cls-41"
                d="M478.29,482.09h0a8.75,8.75,0,0,1-17.48,0h0a8.72,8.72,0,0,1-1.66,4.72v52.72a5.14,5.14,0,0,0,5.12,5.12H487V522.19H469.19a5.14,5.14,0,0,1-5.12-5.12V501.38a5.14,5.14,0,0,1,5.12-5.12H487v-5.83A8.75,8.75,0,0,1,478.29,482.09Z"
              />
              <path
                class="cls-53"
                d="M464.07,501.38v15.68a5.14,5.14,0,0,0,5.12,5.12H487l-9.87,0L481,513.5c1.46-2.83,3.52-4.08,6-4.16V496.26H469.19A5.14,5.14,0,0,0,464.07,501.38Z"
              />
              <polygon
                class="cls-42"
                points="443.78 481.66 460.76 481.66 460.79 481.66 478.27 481.66 478.29 481.66 487.03 481.66 487.03 456.7 486.92 456.64 443.78 481.66"
              />
              <path
                class="cls-31"
                d="M478.27,481.66h-35c0,0.1,0,.2,0,0.29s0,0.09,0,.13a8.75,8.75,0,0,0,17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,0,17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,0,8.74,8.34v-8.77h-8.77Z"
              />
              <path
                class="cls-25"
                d="M480.85,513.89l-3.68,8.28,9.87,0V509.33A7.12,7.12,0,0,0,480.85,513.89Z"
              />
            </g>
            <g id="part1">
              <path
                class="cls-40"
                d="M495.33,482.09h0a8.75,8.75,0,0,0,17.48,0h0a8.72,8.72,0,0,0,1.66,4.72v52.72a5.14,5.14,0,0,1-5.12,5.12H486.59V522.19h17.84a5.14,5.14,0,0,0,5.12-5.12V501.38a5.14,5.14,0,0,0-5.12-5.12H486.59v-5.83A8.75,8.75,0,0,0,495.33,482.09Z"
              />
              <path
                class="cls-54"
                d="M509.56,501.38v15.68a5.14,5.14,0,0,1-5.12,5.12H486.59v-0.42l9.87,0-3.81-8.13a7,7,0,0,0-6.06-4.28V496.26h17.84A5.14,5.14,0,0,1,509.56,501.38Z"
              />
              <polygon
                class="cls-43"
                points="530.1 481.66 513.11 481.66 513.09 481.66 495.61 481.66 495.58 481.66 486.84 481.66 486.84 456.7 486.96 456.64 530.1 481.66"
              />
              <path
                class="cls-31"
                d="M495.36,481.66h35c0,0.1,0,.2,0,0.29s0,0.09,0,.13a8.75,8.75,0,0,1-17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,1-17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,1-8.74,8.34v-8.77h8.77Z"
              />
              <path
                class="cls-26"
                d="M492.78,513.89l3.68,8.28-9.87,0V509.33A7.12,7.12,0,0,1,492.78,513.89Z"
              />
            </g>
            <g id="tickets-2" data-name="tickets">
              <path
                class="cls-24"
                d="M508.61,467.25a2.78,2.78,0,1,0-1.77-5.27,0.15,0.15,0,1,1-.09-0.27,2.78,2.78,0,0,0-1.77-5.27L461,471.15a2.78,2.78,0,1,0,1.77,5.27c0.07,0,.16.25,0.09,0.27a2.78,2.78,0,0,0,1.77,5.27c0.07,0,.16.25,0.09,0.27a2.78,2.78,0,1,0,1.77,5.27,0.52,0.52,0,0,1,.15.45,2.78,2.78,0,0,0,1.77,5.27l43.95-14.73a2.78,2.78,0,1,0-1.77-5.27c-0.21.07-.36-0.38-0.15-0.45a2.78,2.78,0,1,0-1.77-5.27A0.15,0.15,0,1,1,508.61,467.25Z"
              />
              <rect
                class="cls-55"
                x="465.29"
                y="465.17"
                width="42.45"
                height="19.59"
                rx="3.1"
                ry="3.1"
                transform="translate(-125.72 179.24) rotate(-18.53)"
              />
              <polygon
                class="cls-23"
                points="499.93 468.86 500.7 470.36 502.37 470.57 501.18 471.77 501.49 473.43 499.99 472.66 498.51 473.47 498.77 471.81 497.54 470.65 499.21 470.38 499.93 468.86"
              />
              <polygon
                class="cls-23"
                points="492.23 466.89 493.4 468.79 495.62 468.9 494.18 470.59 494.75 472.74 492.7 471.89 490.83 473.11 491.01 470.89 489.28 469.49 491.44 468.97 492.23 466.89"
              />
              <polygon
                class="cls-23"
                points="471.31 478.45 471.6 480.11 470.4 481.29 472.07 481.53 472.82 483.04 473.56 481.52 475.23 481.27 474.02 480.1 474.3 478.44 472.81 479.23 471.31 478.45"
              />
              <polygon
                class="cls-23"
                points="476.27 472.24 476.48 474.45 474.78 475.88 476.95 476.37 477.78 478.43 478.91 476.51 481.13 476.36 479.66 474.69 480.19 472.53 478.16 473.42 476.27 472.24"
              />
              <polygon
                class="cls-23"
                points="483.54 467.45 485.27 469.19 487.69 468.76 486.57 470.94 487.73 473.11 485.31 472.72 483.61 474.49 483.23 472.06 481.02 471 483.21 469.89 483.54 467.45"
              />
              <path
                class="cls-38"
                d="M473.76,485.66s9-7.42,12.72-8.53c9.69-2.93,15.42-2,15.42-2"
              />
            </g>
          </g>

          <g id="stall_of_balloons" data-name="stall of balloons">
            <path
              class="cls-41"
              d="M378.15,510.07H357.8v21.19a7.1,7.1,0,0,0,7.08,7.08h13.28V510.07Z"
            />
            <path
              class="cls-40"
              d="M377.86,510.07h20.36v21.19a7.1,7.1,0,0,1-7.08,7.08H377.86V510.07Z"
            />
            <g>
              <path
                class="cls-31"
                d="M398.51,523.57a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="398.51"
                y1="544.2"
                x2="398.51"
                y2="522.57"
              />
              <line
                class="cls-48"
                x1="404.3"
                y1="542.52"
                x2="392.72"
                y2="524.25"
              />
              <line
                class="cls-48"
                x1="388.59"
                y1="529.09"
                x2="408.44"
                y2="537.69"
              />
              <line
                class="cls-48"
                x1="387.76"
                y1="534.52"
                x2="409.27"
                y2="532.26"
              />
              <line
                class="cls-48"
                x1="393.34"
                y1="542.89"
                x2="403.68"
                y2="523.89"
              />
              <line
                class="cls-48"
                x1="389.75"
                y1="539.73"
                x2="407.28"
                y2="527.05"
              />
              <circle class="cls-49" cx="398.51" cy="533.39" r="3.4" />
            </g>
            <g>
              <path
                class="cls-31"
                d="M357.8,523.57a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="357.8"
                y1="544.2"
                x2="357.8"
                y2="522.57"
              />
              <line
                class="cls-48"
                x1="363.59"
                y1="542.52"
                x2="352"
                y2="524.25"
              />
              <line
                class="cls-48"
                x1="347.87"
                y1="529.09"
                x2="367.72"
                y2="537.69"
              />
              <line
                class="cls-48"
                x1="347.04"
                y1="534.52"
                x2="368.55"
                y2="532.26"
              />
              <line
                class="cls-48"
                x1="352.63"
                y1="542.89"
                x2="362.97"
                y2="523.89"
              />
              <line
                class="cls-48"
                x1="349.03"
                y1="539.73"
                x2="366.56"
                y2="527.05"
              />
              <circle class="cls-36" cx="357.8" cy="533.39" r="3.4" />
            </g>
          </g>

          <g id="bunch_of_balloons1" data-name="bunch of balloons1">
            <ellipse
              class="cls-13"
              cx="362.51"
              cy="380.68"
              rx="10.87"
              ry="8.66"
              transform="translate(-79.86 664.66) rotate(-79.1)"
            />
            <ellipse
              class="cls-31"
              cx="347.58"
              cy="384.21"
              rx="7.83"
              ry="11.84"
              transform="translate(-78.99 90.16) rotate(-13.29)"
            />
            <ellipse
              class="cls-12"
              cx="372.19"
              cy="395.32"
              rx="10.96"
              ry="8.66"
              transform="translate(-158.23 511.31) rotate(-59.06)"
            />
            <circle
              class="cls-36"
              cx="358.4"
              cy="393.42"
              r="9.36"
              transform="translate(-166.76 479.7) rotate(-57)"
            />
            <polygon
              class="cls-56"
              points="352.63 401.64 353.16 404.35 350.63 402.71 352.63 401.64"
            />
            <circle
              class="cls-2"
              cx="368.06"
              cy="425.24"
              r="9.36"
              transform="translate(-185.9 522.31) rotate(-59.06)"
            />
            <ellipse
              class="cls-42"
              cx="337.1"
              cy="392.74"
              rx="7.83"
              ry="11.84"
              transform="translate(-152.19 223.98) rotate(-30.33)"
            />
            <path
              class="cls-57"
              d="M360.64,416.9c0.54-.36,2,0.65,2.29,1.2s0.74,2.94,0,3-1.69-.95-2.29-1.23C359.87,419.56,359.94,417.36,360.64,416.9Z"
            />
            <path
              class="cls-2"
              d="M346.52,407.27l3.75-1.78a1.52,1.52,0,0,1,1.41.06l3.59,2.08a1.52,1.52,0,0,0,2.27-1.51L357,402a1.52,1.52,0,0,1,.49-1.33l3.08-2.77a1.52,1.52,0,0,0-.74-2.62l-4.08-.76a1.52,1.52,0,0,1-1.11-.88L353,389.84a1.52,1.52,0,0,0-2.72-.11l-2,3.64a1.52,1.52,0,0,1-1.18.78l-4.13.43a1.52,1.52,0,0,0-.95,2.56l2.85,3a1.52,1.52,0,0,1,.38,1.36l-0.87,4.06A1.52,1.52,0,0,0,346.52,407.27Z"
            />
            <circle
              class="cls-23"
              cx="373.79"
              cy="410.92"
              r="9.36"
              transform="translate(-100.41 700.25) rotate(-79.1)"
            />
            <polygon
              class="cls-56"
              points="343.63 401.75 345.62 403.68 342.59 403.77 343.63 401.75"
            />
            <ellipse
              class="cls-13"
              cx="361.17"
              cy="410.5"
              rx="10.96"
              ry="8.66"
              transform="translate(-157.66 583.04) rotate(-67.05)"
            />
            <circle
              class="cls-23"
              cx="337.65"
              cy="406.69"
              r="9.36"
              transform="translate(-23.51 20.72) rotate(-3.4)"
            />
            <ellipse
              class="cls-31"
              cx="349.77"
              cy="415.21"
              rx="11.84"
              ry="7.83"
              transform="translate(-85.61 739.52) rotate(-86.57)"
            />
            <ellipse
              class="cls-36"
              cx="358.06"
              cy="422.83"
              rx="10.96"
              ry="8.66"
              transform="translate(-105.96 725.6) rotate(-82.87)"
            />
            <path
              class="cls-12"
              d="M340.92,432.64l3.18-2.67a1.52,1.52,0,0,1,1.38-.3l4,1.1a1.52,1.52,0,0,0,1.81-2l-1.56-3.85a1.52,1.52,0,0,1,.14-1.41l2.29-3.46a1.52,1.52,0,0,0-1.37-2.35l-4.14.29a1.52,1.52,0,0,1-1.3-.57l-2.59-3.24a1.52,1.52,0,0,0-2.66.58l-1,4a1.52,1.52,0,0,1-.94,1.06l-3.88,1.46A1.52,1.52,0,0,0,334,424l3.52,2.2a1.52,1.52,0,0,1,.71,1.22l0.19,4.14A1.52,1.52,0,0,0,340.92,432.64Z"
            />
            <path
              class="cls-58"
              d="M358.1,374.17c-0.39-.52.52-2,1.05-2.36s2.89-.93,3-0.18-0.84,1.74-1.08,2.36C360.81,374.76,358.6,374.83,358.1,374.17Z"
            />
            <path
              class="cls-58"
              d="M358,404.09c-0.35-.54.67-1.94,1.23-2.27s3-.71,3,0-1,1.68-1.25,2.27C360.66,404.89,358.45,404.79,358,404.09Z"
            />
            <path
              class="cls-59"
              d="M352.7,388.55c-0.46-.46.24-2,0.72-2.48s2.73-1.32,3-.6-0.59,1.84-.74,2.49C355.46,388.76,353.28,389.14,352.7,388.55Z"
            />
            <path
              class="cls-59"
              d="M353.87,416c-0.37-.53.6-2,1.15-2.31s2.93-.81,3-0.06-0.91,1.71-1.17,2.31C356.55,416.71,354.34,416.69,353.87,416Z"
            />
            <path
              class="cls-60"
              d="M332.34,402c-0.49-.36.06-1.89,0.49-2.35s2.59-1.56,2.89-.94-0.42,1.76-.52,2.36C335.09,401.79,333,402.41,332.34,402Z"
            />
            <path
              class="cls-61"
              d="M342.58,378.29c-0.6-.24-0.59-2-0.33-2.56s2-2.3,2.5-1.73,0.19,1.93.31,2.58C345.2,377.39,343.36,378.59,342.58,378.29Z"
            />
            <path
              class="cls-61"
              d="M346,408.11c-0.34-.55.7-1.93,1.27-2.25s3-.66,3,0.1-1,1.66-1.29,2.25C348.69,408.95,346.48,408.82,346,408.11Z"
            />
            <path
              class="cls-60"
              d="M370.09,404.88c-0.34-.55.69-1.94,1.25-2.26s3-.69,3,0.07-1,1.67-1.27,2.26C372.74,405.69,370.54,405.58,370.09,404.88Z"
            />
            <path
              class="cls-62"
              d="M370,388.58c-0.26-.59,1-1.82,1.56-2.06s3-.25,3,0.51-1.21,1.51-1.59,2.05S370.29,389.34,370,388.58Z"
            />
            <path
              class="cls-62"
              d="M336.35,421.76c-0.26-.44,3-1.29,3.36-1.57s1.8-3.24,1.88-2.62a15.46,15.46,0,0,1-.71,3.66C340.64,421.85,336.68,422.32,336.35,421.76Z"
            />
            <path
              class="cls-63"
              d="M344.79,395.59c-0.22-.46,3.08-1,3.49-1.27s2.07-3.07,2.1-2.45a15.46,15.46,0,0,1-1,3.59C349.06,396,345.08,396.18,344.79,395.59Z"
            />
            <path
              class="cls-64"
              d="M342.19,416.87c0.3-.41,2.36,2.23,2.77,2.47"
            />
            <path
              class="cls-65"
              d="M329.83,387.09c-0.54-.36-0.17-2.05.21-2.57s2.42-1.83,2.8-1.18-0.21,1.92-.23,2.58C332.58,386.75,330.52,387.55,329.83,387.09Z"
            />
          </g>

          <g id="bunch_of_balloons" data-name="bunch of balloons">
            <path
              class="cls-36"
              d="M433.05,403.31l-3.47,2.27a1.52,1.52,0,0,0-.69,1.24l-0.1,4.15a1.52,1.52,0,0,1-2.47,1.15l-3.23-2.6a1.52,1.52,0,0,0-1.39-.27l-4,1.19a1.52,1.52,0,0,1-1.86-2l1.47-3.88a1.52,1.52,0,0,0-.17-1.4l-2.36-3.41a1.52,1.52,0,0,1,1.33-2.38l4.14,0.2a1.52,1.52,0,0,0,1.28-.6l2.52-3.3a1.52,1.52,0,0,1,2.67.52l1.09,4a1.52,1.52,0,0,0,1,1l3.91,1.37A1.52,1.52,0,0,1,433.05,403.31Z"
            />
            <path
              class="cls-59"
              d="M421.07,393.93c-0.22-.46.42,5.32,0,5.48s-6.84-3.9-6.38-3.49a15.46,15.46,0,0,0,3.43,1.46C418.74,397.54,421.35,394.53,421.07,393.93Z"
            />
            <ellipse
              class="cls-13"
              cx="400.11"
              cy="434.3"
              rx="8.66"
              ry="10.87"
            />
            <ellipse
              class="cls-31"
              cx="414.1"
              cy="440.26"
              rx="11.84"
              ry="7.83"
              transform="translate(-157.18 637.63) rotate(-65.81)"
            />
            <ellipse
              class="cls-12"
              cx="387.84"
              cy="446.52"
              rx="8.66"
              ry="10.96"
              transform="translate(-129.52 159.91) rotate(-20.04)"
            />
            <circle
              class="cls-36"
              cx="401.73"
              cy="447.27"
              r="9.36"
              transform="translate(-138.77 184.02) rotate(-22.1)"
            />
            <circle
              class="cls-2"
              cx="386.23"
              cy="476.68"
              r="9.36"
              transform="translate(-139.95 161.19) rotate(-20.04)"
            />
            <ellipse
              class="cls-42"
              cx="422.78"
              cy="450.62"
              rx="11.84"
              ry="7.83"
              transform="translate(-194.77 471.57) rotate(-48.77)"
            />
            <path
              class="cls-63"
              d="M383.63,469.67c-0.46-.45-2,0.27-2.47.75s-1.29,2.75-.56,3,1.84-.61,2.48-0.77C383.88,472.43,384.23,470.25,383.63,469.67Z"
            />
            <path
              class="cls-2"
              d="M410.78,463.11l-3.34-2.46a1.52,1.52,0,0,0-1.4-.21l-3.92,1.36a1.52,1.52,0,0,1-1.94-1.91l1.3-3.94a1.52,1.52,0,0,0-.23-1.4l-2.5-3.31a1.52,1.52,0,0,1,1.22-2.44l4.15,0a1.52,1.52,0,0,0,1.26-.65l2.37-3.4a1.52,1.52,0,0,1,2.7.41l1.26,4a1.52,1.52,0,0,0,1,1l4,1.2a1.52,1.52,0,0,1,.45,2.69l-3.37,2.42a1.52,1.52,0,0,0-.63,1.26l0.08,4.15A1.52,1.52,0,0,1,410.78,463.11Z"
            />
            <circle class="cls-23" cx="383.31" cy="461.54" r="9.36" />
            <ellipse
              class="cls-13"
              cx="395.78"
              cy="463.51"
              rx="8.66"
              ry="10.96"
              transform="translate(-88.02 92.81) rotate(-12.05)"
            />
            <circle
              class="cls-23"
              cx="419.6"
              cy="464.22"
              r="9.36"
              transform="translate(-133.87 756.17) rotate(-75.7)"
            />
            <ellipse
              class="cls-31"
              cx="406.09"
              cy="470.29"
              rx="11.84"
              ry="7.83"
              transform="translate(-113.02 811.77) rotate(-82.53)"
            />
            <ellipse
              class="cls-36"
              cx="396.51"
              cy="476.2"
              rx="10.96"
              ry="8.66"
              transform="translate(-104.71 840.57) rotate(-86.23)"
            />
            <path
              class="cls-12"
              d="M411.49,489.08l-2.62-3.22a1.52,1.52,0,0,0-1.3-.56l-4.14.33a1.52,1.52,0,0,1-1.4-2.34l2.25-3.48a1.52,1.52,0,0,0,.13-1.41l-1.59-3.83a1.52,1.52,0,0,1,1.8-2.05l4,1.07a1.52,1.52,0,0,0,1.38-.31l3.15-2.7a1.52,1.52,0,0,1,2.51,1.07l0.22,4.14a1.52,1.52,0,0,0,.72,1.21l3.54,2.17a1.52,1.52,0,0,1-.25,2.71L416,483.38a1.52,1.52,0,0,0-.93,1.06l-1,4A1.52,1.52,0,0,1,411.49,489.08Z"
            />
            <path
              class="cls-58"
              d="M398.7,427.08c-0.41-.5-2.06,0-2.54.46s-1.59,2.59-.9,2.9,1.89-.4,2.55-0.49C398.63,429.85,399.23,427.72,398.7,427.08Z"
            />
            <path
              class="cls-58"
              d="M392.24,456c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C392.75,458.75,392.88,456.55,392.24,456Z"
            />
            <path
              class="cls-59"
              d="M399.19,440.24c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C399.7,443,399.84,440.76,399.19,440.24Z"
            />
            <path
              class="cls-59"
              d="M393.94,468.59c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C394.45,471.32,394.59,469.11,393.94,468.59Z"
            />
            <path
              class="cls-60"
              d="M418.77,456.47c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C419.29,459.19,419.42,457,418.77,456.47Z"
            />
            <path
              class="cls-61"
              d="M415.4,431.41c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C415.92,434.13,416.05,431.93,415.4,431.41Z"
            />
            <path
              class="cls-61"
              d="M406.09,460.92c-0.36-.54-2.05-0.17-2.57.2s-1.84,2.41-1.19,2.8,1.92-.21,2.58-0.23C405.74,463.67,406.54,461.61,406.09,460.92Z"
            />
            <path
              class="cls-60"
              d="M380,454.67c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C380.47,457.4,380.6,455.19,380,454.67Z"
            />
            <path
              class="cls-62"
              d="M382.75,439.94c-0.6-.23-1.77,1.05-2,1.66s-0.1,3,.66,3,1.45-1.29,2-1.69S383.52,440.24,382.75,439.94Z"
            />
            <path
              class="cls-62"
              d="M413.22,473.36c-0.22-.46-2.74,1.74-3.19,1.91s-3.69-.33-3.23.09a15.46,15.46,0,0,0,3.43,1.46C410.88,477,413.49,473.95,413.22,473.36Z"
            />
            <path
              class="cls-63"
              d="M408.12,448.29c-0.22-.46-2.74,1.74-3.19,1.91s-3.69-.33-3.23.09a15.46,15.46,0,0,0,3.43,1.46C405.79,451.89,408.4,448.88,408.12,448.29Z"
            />
            <path
              class="cls-64"
              d="M413.22,473.36c-0.22-.46-2.74,1.74-3.19,1.91"
            />
            <path
              class="cls-65"
              d="M427.37,442.88c-0.17-.62-1.89-0.8-2.51-0.61s-2.5,1.72-2,2.29,1.89,0.4,2.53.59C426.19,445.39,427.59,443.68,427.37,442.88Z"
            />
          </g>

          <g id="bench1">
            <rect
              class="cls-36"
              x="207.87"
              y="530.96"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
              transform="translate(2.06 -0.79) rotate(0.22)"
            />
            <rect
              class="cls-49"
              x="250.28"
              y="509.59"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
              transform="translate(500.92 1054.68) rotate(-179.78)"
            />
            <rect
              class="cls-49"
              x="213.28"
              y="509.45"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
              transform="translate(426.92 1054.25) rotate(-179.78)"
            />
            <rect
              class="cls-49"
              x="256.01"
              y="531.14"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
              transform="translate(512.33 1079.64) rotate(-179.78)"
            />
            <path
              class="cls-36"
              d="M233.08,526.68l-22.41-.09c-1.27,0-2.31-1.32-2.3-2.93h0c0-1.61,1.05-2.92,2.33-2.91l22.41,0.09"
            />
            <path
              class="cls-36"
              d="M233.06,533l-25-.1a1.79,1.79,0,0,1,0-3.49l25,0.1V533Z"
            />
            <path
              class="cls-36"
              d="M233.11,518.65l-22.41-.09c-1.27,0-2.31-1.32-2.3-2.93s1.05-2.92,2.33-2.91l22.41,0.09"
            />
            <path
              class="cls-49"
              d="M233.08,526.68l22.41,0.09c1.27,0,2.32-1.31,2.33-2.91h0c0-1.61-1-2.93-2.3-2.93l-22.41-.09"
            />
            <path
              class="cls-49"
              d="M233.06,533l25,0.1a1.79,1.79,0,0,0,0-3.49l-25-.1"
            />
            <path
              class="cls-49"
              d="M233.11,518.65l22.41,0.09c1.27,0,2.32-1.31,2.33-2.91s-1-2.93-2.3-2.93l-22.41-.09"
            />
          </g>

          <g id="bench2">
            <rect
              class="cls-49"
              x="814.65"
              y="509.77"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
            />
            <rect
              class="cls-36"
              x="808.88"
              y="531.3"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
            />
            <rect
              class="cls-49"
              x="851.23"
              y="509.77"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
              transform="translate(1704.84 1054.08) rotate(-180)"
            />
            <rect
              class="cls-49"
              x="857.01"
              y="531.3"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
              transform="translate(1716.39 1078.98) rotate(180)"
            />
            <path
              class="cls-36"
              d="M834,526.93H811.63c-1.27,0-2.31-1.32-2.31-2.92h0c0-1.61,1-2.92,2.31-2.92H834"
            />
            <path
              class="cls-36"
              d="M834,533.22H809a1.79,1.79,0,0,1,0-3.49h25v3.49Z"
            />
            <path
              class="cls-36"
              d="M834,518.89H811.63c-1.27,0-2.31-1.32-2.31-2.92s1-2.92,2.31-2.92H834"
            />
            <path
              class="cls-49"
              d="M834,526.93h22.41c1.27,0,2.31-1.32,2.31-2.92h0c0-1.61-1-2.92-2.31-2.92H834"
            />
            <path
              class="cls-49"
              d="M834,533.22h25a1.79,1.79,0,0,0,0-3.49H834"
            />
            <path
              class="cls-49"
              d="M834,518.89h22.41c1.27,0,2.31-1.32,2.31-2.92s-1-2.92-2.31-2.92H834"
            />
          </g>

          <g id="light4">
            <g>
              <path
                class="cls-36"
                d="M58.58,512.54l0.08,0h1.73V443.31H58.58v69.23Z"
              />
              <path
                class="cls-42"
                d="M58.58,512.54a1.8,1.8,0,0,0-1.73,1.79v8.35a1.87,1.87,0,0,1,.34-0.07h3.2V512.52H58.65Z"
              />
              <path
                class="cls-30"
                d="M57.19,522.61a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V522.61h-3.2Z"
              />
              <path
                class="cls-25"
                d="M56.59,541.48H54.66a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86H56.59Z"
              />
              <path
                class="cls-25"
                d="M57.31,439H55.74a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64V439H57.31Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M61.71,512.54l-0.08,0H59.9V443.31h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M61.71,512.54a1.8,1.8,0,0,1,1.73,1.79v8.35a1.87,1.87,0,0,0-.34-0.07H59.9V512.52h1.73Z"
              />
              <path
                class="cls-30"
                d="M63.1,522.61a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9H59.9V522.61h3.2Z"
              />
              <path
                class="cls-25"
                d="M63.7,541.48h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91H59.9v-4.86H63.7Z"
              />
              <path
                class="cls-25"
                d="M63,439h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69H59.9V439H63Z"
              />
              <circle class="cls-5" cx="60.39" cy="428.26" r="11.66" />
            </g>
            <path
              class="cls-66"
              d="M55.86,419.58c-0.39-.51-2.06,0-2.55.38s-1.67,2.53-1,2.87,1.91-.34,2.56-0.4C55.7,422.35,56.36,420.24,55.86,419.58Z"
            />
          </g>

          <g id="light3">
            <g>
              <path
                class="cls-36"
                d="M450.48,511.39l0.08,0h1.73V442.16h-1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M450.48,511.39a1.8,1.8,0,0,0-1.73,1.79v8.35a1.87,1.87,0,0,1,.34-0.07h3.2V511.38h-1.73Z"
              />
              <path
                class="cls-30"
                d="M449.1,521.46a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V521.46h-3.2Z"
              />
              <path
                class="cls-25"
                d="M448.5,540.34h-1.93a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86H448.5Z"
              />
              <path
                class="cls-25"
                d="M449.22,437.84h-1.57a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64v-4.32h-3.08Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M453.62,511.39l-0.08,0h-1.73V442.16h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M453.62,511.39a1.8,1.8,0,0,1,1.73,1.79v8.35a1.87,1.87,0,0,0-.34-0.07h-3.2V511.38h1.73Z"
              />
              <path
                class="cls-30"
                d="M455,521.46a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9h-5.18V521.46H455Z"
              />
              <path
                class="cls-25"
                d="M455.6,540.34h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91h-5.73v-4.86h3.79Z"
              />
              <path
                class="cls-25"
                d="M454.89,437.84h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69h-4.64v-4.32h3.08Z"
              />
              <circle class="cls-5" cx="452.29" cy="427.12" r="11.66" />
            </g>
            <path
              class="cls-66"
              d="M447.86,419.58c-0.39-.51-2.06,0-2.55.38s-1.67,2.53-1,2.87,1.91-.34,2.56-0.4C447.7,422.35,448.36,420.24,447.86,419.58Z"
            />
          </g>

          <g id="light2">
            <g>
              <path
                class="cls-36"
                d="M865.57,510.59l0.08,0h1.73V441.36h-1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M865.57,510.59a1.8,1.8,0,0,0-1.73,1.79v8.35a1.87,1.87,0,0,1,.34-0.07h3.2V510.57h-1.73Z"
              />
              <path
                class="cls-30"
                d="M864.18,520.66a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V520.66h-3.2Z"
              />
              <path
                class="cls-25"
                d="M863.59,539.53h-1.93a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86h-3.79Z"
              />
              <path
                class="cls-25"
                d="M864.3,437h-1.57a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64V437H864.3Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M868.71,510.59l-0.08,0H866.9V441.36h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M868.71,510.59a1.8,1.8,0,0,1,1.73,1.79v8.35a1.87,1.87,0,0,0-.34-0.07h-3.2V510.57h1.73Z"
              />
              <path
                class="cls-30"
                d="M870.09,520.66a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9H866.9V520.66h3.2Z"
              />
              <path
                class="cls-25"
                d="M870.69,539.53h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91H866.9v-4.86h3.79Z"
              />
              <path
                class="cls-25"
                d="M870,437h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69H866.9V437H870Z"
              />
              <circle class="cls-5" cx="867.38" cy="426.31" r="11.66" />
            </g>
          </g>

          <g id="light1">
            <g>
              <path
                class="cls-36"
                d="M1232.63,510.82l0.08,0h1.73V441.59h-1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M1232.63,510.82a1.8,1.8,0,0,0-1.73,1.79V521a1.87,1.87,0,0,1,.34-0.07h3.2V510.81h-1.73Z"
              />
              <path
                class="cls-30"
                d="M1231.25,520.89a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V520.89h-3.2Z"
              />
              <path
                class="cls-25"
                d="M1230.65,539.77h-1.93a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86h-3.79Z"
              />
              <path
                class="cls-25"
                d="M1231.37,437.27h-1.57a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64v-4.32h-3.08Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M1235.77,510.82l-0.08,0H1234V441.59h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M1235.77,510.82a1.8,1.8,0,0,1,1.73,1.79V521a1.87,1.87,0,0,0-.34-0.07H1234V510.81h1.73Z"
              />
              <path
                class="cls-30"
                d="M1237.15,520.89a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9H1234V520.89h3.2Z"
              />
              <path
                class="cls-25"
                d="M1237.75,539.77h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91H1234v-4.86h3.79Z"
              />
              <path
                class="cls-25"
                d="M1237,437.27h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69H1234v-4.32H1237Z"
              />
              <circle class="cls-5" cx="1234.44" cy="426.55" r="11.66" />
            </g>
            <path
              class="cls-66"
              d="M1229.86,419.58c-0.39-.51-2.06,0-2.55.38s-1.67,2.53-1,2.87,1.91-.34,2.56-0.4C1229.7,422.35,1230.36,420.24,1229.86,419.58Z"
            />
          </g>

          <!--game熱氣球-->
          <svg viewBox="100 -500 1792 1792">
            <a href="/fqgame">
              <g id="balloon">
                <g>
                  <path
                    class="st00"
                    d="M291.7,437.5c-0.4,0-0.7,0-1-0.1c-2.1-0.6-3.4-2.8-2.8-4.9l16.3-60.4c0.5-2.1,2.7-3.4,4.9-2.9
                s3.4,2.7,2.9,4.9v0.1l-16.3,60.4C295.1,436.3,293.6,437.5,291.7,437.5L291.7,437.5z"
                  />
                  <path
                    class="st00"
                    d="M214.6,437.5c-1.9,0-3.6-1.4-3.9-3.3l-10.6-60.4c-0.4-2.2,1.1-4.2,3.3-4.6s4.2,1.1,4.6,3.2l10.6,60.4
                c0.4,2.2-1.1,4.2-3.3,4.6C215,437.5,214.8,437.5,214.6,437.5L214.6,437.5z"
                  />
                  <path
                    class="st00"
                    d="M269.4,440c-0.2,0-0.3,0-0.5,0c-2.2-0.3-3.7-2.3-3.5-4.5l7.7-62.7c0.3-2.2,2.2-3.8,4.4-3.5
                c2.2,0.3,3.8,2.2,3.5,4.4c0,0,0,0,0,0.1l-7.7,62.7C273.1,438.5,271.4,440,269.4,440z"
                  />
                  <path
                    class="st00"
                    d="M239.6,439.8c-2,0-3.6-1.4-3.9-3.4l-9.6-62.7c-0.4-2.2,1.1-4.2,3.3-4.6s4.2,1.1,4.6,3.3c0,0.1,0,0.1,0,0.2
                l9.6,62.7c0.3,2.2-1.2,4.2-3.3,4.6l0,0C240,439.8,239.8,439.8,239.6,439.8L239.6,439.8z"
                  />
                </g>
                <path
                  class="st01"
                  d="M411.1,134.3c-10-62-42.7-94.5-72.9-111.6C315.7,10.2,290.4,3.8,264.7,4h-17.4c-25.7-0.2-51,6.2-73.4,18.6
              c-30.3,17.1-62.9,49.7-72.9,111.6C86,226.9,166.7,285,201.1,376H311C345.3,285,426,227,411.1,134.3L411.1,134.3z"
                />
                <path
                  class="st02"
                  d="M385.5,134.3c-8.3-62-35.7-94.5-61-111.6C306.5,10.4,285.1,3.9,263.2,4h-14.5c-21.9-0.1-43.3,6.4-61.4,18.6
              c-25.3,17.1-52.6,49.7-61,111.6C113.9,226.9,181.4,285,210,376h91.7C330.6,285,398,227,385.5,134.3L385.5,134.3z"
                />
                <path
                  class="st03"
                  d="M357.1,134.2c-6.5-62-27.9-94.5-47.6-111.6c-13.2-11.9-30.3-18.5-48-18.6h-11.3c-17.7,0.1-34.8,6.8-48,18.6
              c-19.7,17.1-41.1,49.7-47.6,111.6c-9.7,92.7,42.9,150.8,65.3,241.8h71.6C314.3,284.9,366.9,226.9,357.1,134.2L357.1,134.2z"
                />
                <path
                  class="st02"
                  d="M296.9,134.3c-2.6-62-11.3-94.5-19.2-111.6C271.8,10,265.1,4,258.3,4h-4.6c-6.8,0-13.5,6-19.4,18.6
              c-8,17.1-16.6,49.7-19.2,111.6c-3.9,92.7,17.3,150.8,26.4,241.8h29C279.6,285,300.9,227,296.9,134.3L296.9,134.3z"
                />
                <path
                  class="st04"
                  d="M206,433v65.6c0,5.2,4.2,9.4,9.4,9.4h81.2c5.2,0,9.4-4.2,9.4-9.4V433H206z"
                />
                <path
                  class="st05"
                  d="M306,433.1v65.5c0,5.2-4.2,9.4-9.4,9.4H294v-52h-88v-23L306,433.1z"
                />
                <path
                  class="st04"
                  d="M310,429H202c-4.6,0.1-8.2,4-8.1,8.7c0.1,4.5,3.6,8.2,8.1,8.3h108c4.6-0.1,8.2-4,8.1-8.7
              C318,432.7,314.4,429.1,310,429z"
                />
                <g>
                  <path
                    class="st06"
                    d="M148,184.4c0-9.9,2.1-17.5,6.3-23c4-5.5,9.7-8.2,17.1-8.4c4.2,0,8.2,1.3,11.8,3.8c3.2,1.9,4.7,4,4.9,6.3
                c0,2.1-0.6,3.8-1.9,4.9c-1.3,1.1-3.6,0.8-6.6-0.9c-3-2.3-6.1-3.4-8.7-3.6c-4.6,0.2-8.2,1.9-10.4,5.1c-2.3,3.2-3.4,8.5-3.2,15.9
                c0.4,4,1.9,6.8,4.4,8.7c2.5,1.7,5.3,2.5,8.7,2.1c3.4-0.6,6.1-1.5,8.2-2.8c0.9-0.6,1.7-0.9,2.5-1.1c0-1.7-0.6-2.8-1.3-3.4
                c-0.8-0.6-2.1-1.1-3.8-1.1c-0.8,0-1.5,0.2-2.5,0.4c-0.8,0.2-1.7,0.6-2.5,0.8c-1.5,0.4-2.8-0.2-4-1.7c-0.9-1.5-1.1-3.2-0.6-5.1
                c0.8-1.9,2.1-3,4-3.6c1.1-0.2,2.3-0.4,3.6-0.6c1.3,0,2.3-0.2,3-0.2c4,0.6,7,1.7,9.1,3.8c2.1,2.5,3.2,5.1,3.8,8.4
                c0,4.2-0.8,8-2.1,11.6c-1.3,4.2-2.7,6.5-4.4,6.8c-0.4,0-0.6,0-0.9,0c-1.3,0-2.3-0.6-3.2-1.9c-0.4-0.6-0.8-1.3-0.9-2.1
                c-2.7,0.9-5.7,1.3-9.3,1.7c-4.9,0.2-9.5-1.5-13.7-5.3C151,196.7,148.6,191.2,148,184.4z"
                  />
                  <path
                    class="st06"
                    d="M192.4,197.9c1.1-8,2.3-14.6,3.2-19.5c1.1-5.1,3-10.6,5.9-16.5c1.1-2.5,2.5-4.4,4-5.9
                c2.3-2.3,5.1-3.2,8.5-3.2c5.7,0,9.9,2.7,12.5,8.2c2.8,5.7,4.7,11.8,5.5,18.2c0,0.6,0.2,1.1,0.2,1.9c0.8,5.7,1.3,11.4,1.7,17.1
                c0,3.8-0.8,6.1-2.1,7c-0.8,0.4-1.5,0.8-2.5,0.9c-0.8,0-1.5-0.4-2.5-0.8c-1.7-0.9-2.7-2.7-2.8-5.1c0-3-0.2-5.7-0.2-8h-0.2
                c-4-0.6-7.8-0.9-11.8-0.9c-4,0-7,0.2-9.1,0.8c-0.4,0-0.6,0-0.9,0.2c-0.2,2.5-0.4,5.3-0.6,8.5c0,2.7-0.9,4.4-2.7,5.1
                c-0.6,0-0.9,0.2-1.5,0.2c-1.1,0-2.1-0.4-2.8-1.1c-1.1-1.3-1.7-3.2-1.9-6.1V197.9z M222.6,182.3c-0.4-3.6-1.3-7.6-2.7-11.8
                c-1.5-4.7-3.8-7-6.8-7c-3.2,0.2-5.7,2.7-7.6,7.6c-1.5,3.8-2.7,7.4-3.2,10.8C207.2,181.4,214.1,181.6,222.6,182.3z"
                  />
                  <path
                    class="st06"
                    d="M238,199.8c-1.3-5.5-1.9-12.3-2.1-20.5c0-8.5,0.8-14.8,2.5-18.8c1.9-4.2,4.2-6.5,6.8-6.6
                c0.8-0.2,1.5-0.4,2.3-0.4c1.9,0,3.6,0.8,5.1,2.1c1.9,1.7,4.4,6.3,7,13.5c1.9-5.5,3.8-9.3,5.7-11.8c1.9-2.5,4-3.8,6.8-3.8
                c0.6-0.2,1.1-0.2,1.7-0.2c2.7,0,4.9,1.5,6.6,4.6c2.1,3.6,3.4,9.3,3.8,17.1l0.2,5.5c0,6.1-0.6,12.3-1.5,18.6
                c-0.8,4-2.3,6.3-4.7,6.8c-0.6,0-0.9,0.2-1.5,0.2c-1.5,0-2.7-0.6-3.4-1.9c-0.9-1.7-1.1-3.6-0.8-5.7c2.1-8.2,3-15,3-20.3
                s-0.4-8.7-1.1-10.2c-0.8-1.5-1.5-2.3-2.5-2.3c-0.8,0.2-1.7,1.1-2.5,2.7c-0.8,1.5-1.9,4.6-3.2,8.9c-1.7,4.7-4,7-6.6,7.2
                c-2.7,0-4.9-2.3-6.8-7.2c-1.5-4.2-2.7-7.2-3.6-8.7c-0.8-1.5-1.7-2.3-2.5-2.3c-0.9,0.2-1.7,1.5-2.3,4.2s-0.8,6.6-0.2,11.8
                c0.6,5.1,1.5,10.4,2.8,15.9c0.6,2.8,0.4,4.9-0.8,6.5c-0.8,1.1-1.9,1.7-3,1.7c-0.4,0-0.9,0-1.5-0.2
                C240.2,204.9,238.7,203,238,199.8z"
                  />
                  <path
                    class="st06"
                    d="M288.9,180.1c0.2-4.6,0.4-10.9,1.1-19.3c-0.4-2.1,0-3.9,0.9-5.1c0.7-0.9,1.8-1.4,3.2-1.4l11.2-0.2
                c5.4,0,10.2,0.4,14.2,0.9c1.4,0,2.5,0.7,3,1.8c0.5,0.9,0.9,2.1,0.9,3.5c0,1.4-0.5,2.5-1.2,3.3c-0.7,0.9-1.8,1.1-3.2,1.1
                c-4.2-0.5-9-0.9-14.2-0.9c-3,0-4.4,0-6.4,0c-0.4,4.1-0.4,7.7-0.6,10.8c1.9-0.2,5.4-0.2,10.4-0.2c5,0,9.5,0,13.3,0.4
                c1.4,0,2.5,0.6,3.3,1.7c0.6,1,1,2.3,1,3.7s-0.6,2.7-1.4,3.7c-0.8,1-1.9,1.4-3.5,1.2c-3.9-0.4-8.3-0.6-13.1-0.6c-5,0-8.3,0-9.9,0.2
                c-0.2,0-0.4,0-0.4,0c0,1.4,0.2,2.3,0.2,3.3c0,2.9,0.6,5.2,1.9,6.8s3.5,2.5,6.4,2.5c4.1,0,9.5-1.2,15.9-3.5c1.4-1,2.9-0.6,4.4,0.6
                c1.2,1,1.7,2.1,1.7,3.7c0,0.4,0,1-0.2,1.4c-0.4,2.1-1.5,3.7-3.3,4.3c-6,2.1-11.6,3.3-17,3.5c-6.2-0.2-11-1.7-13.9-5
                c-2.9-3.3-4.4-7.7-4.4-13.7v-4.2C288.7,183.2,288.9,181.8,288.9,180.1z"
                  />
                  <path
                    class="st06"
                    d="M342.2,154.2c3.6-0.9,7-1.3,10.2-1.3c4.2,0,8.4,0.8,12.1,2.3c3.8,1.5,5.9,3.4,6.3,5.7c0,0.4,0,0.8,0,1.1
                c0,1.7-0.6,2.8-1.5,3.6c-1.3,0.9-3.4,0.8-6.3-0.4c-5.5-2.7-10.8-3.6-15.8-2.7c-4.9,1.1-7.4,4.4-7.6,9.9c0,1.1,0.6,1.7,1.7,2.1
                c4.7,0.6,8.7,0.9,12,1.1c5.7,0.8,9.3,2.5,11.2,5.1c1.9,2.5,2.7,5.7,2.7,9.1c0,5.7-2.5,9.9-7,12.5c-4,2.3-8.7,3.4-14.2,3.6
                c-0.8,0-1.5-0.2-2.5-0.2c-2.3-0.2-4-1.3-4.7-3c-0.8-1.9-0.6-3.6,0.6-5.1c1.1-1.7,3-2.5,5.3-2.1c3.4,0.2,6.5-0.2,9.1-1.3
                c2.7-0.9,3.8-2.7,3.8-4.7c0-1.9-1.5-3-4.4-3.4c-3.8-0.2-8.2-0.6-12.9-1.3c-6.6-1.1-10.1-5.3-10.2-12.3
                C330.2,162.6,334.2,156.5,342.2,154.2z"
                  />
                </g>
                <g>
                  <path
                    d="M148,184.4c0-9.9,2.1-17.5,6.3-23c4-5.5,9.7-8.2,17.1-8.4c4.2,0,8.2,1.3,11.8,3.8c3.2,1.9,4.7,4,4.9,6.3
                c0,2.1-0.6,3.8-1.9,4.9c-1.3,1.1-3.6,0.8-6.6-0.9c-3-2.3-6.1-3.4-8.7-3.6c-4.6,0.2-8.2,1.9-10.4,5.1c-2.3,3.2-3.4,8.5-3.2,15.9
                c0.4,4,1.9,6.8,4.4,8.7c2.5,1.7,5.3,2.5,8.7,2.1c3.4-0.6,6.1-1.5,8.2-2.8c0.9-0.6,1.7-0.9,2.5-1.1c0-1.7-0.6-2.8-1.3-3.4
                c-0.8-0.6-2.1-1.1-3.8-1.1c-0.8,0-1.5,0.2-2.5,0.4c-0.8,0.2-1.7,0.6-2.5,0.8c-1.5,0.4-2.8-0.2-4-1.7c-0.9-1.5-1.1-3.2-0.6-5.1
                c0.8-1.9,2.1-3,4-3.6c1.1-0.2,2.3-0.4,3.6-0.6c1.3,0,2.3-0.2,3-0.2c4,0.6,7,1.7,9.1,3.8c2.1,2.5,3.2,5.1,3.8,8.4
                c0,4.2-0.8,8-2.1,11.6c-1.3,4.2-2.7,6.5-4.4,6.8c-0.4,0-0.6,0-0.9,0c-1.3,0-2.3-0.6-3.2-1.9c-0.4-0.6-0.8-1.3-0.9-2.1
                c-2.7,0.9-5.7,1.3-9.3,1.7c-4.9,0.2-9.5-1.5-13.7-5.3C151,196.7,148.6,191.2,148,184.4z"
                  />
                  <path
                    d="M192.4,197.9c1.1-8,2.3-14.6,3.2-19.5c1.1-5.1,3-10.6,5.9-16.5c1.1-2.5,2.5-4.4,4-5.9c2.3-2.3,5.1-3.2,8.5-3.2
                c5.7,0,9.9,2.7,12.5,8.2c2.8,5.7,4.7,11.8,5.5,18.2c0,0.6,0.2,1.1,0.2,1.9c0.8,5.7,1.3,11.4,1.7,17.1c0,3.8-0.8,6.1-2.1,7
                c-0.8,0.4-1.5,0.8-2.5,0.9c-0.8,0-1.5-0.4-2.5-0.8c-1.7-0.9-2.7-2.7-2.8-5.1c0-3-0.2-5.7-0.2-8h-0.2c-4-0.6-7.8-0.9-11.8-0.9
                c-4,0-7,0.2-9.1,0.8c-0.4,0-0.6,0-0.9,0.2c-0.2,2.5-0.4,5.3-0.6,8.5c0,2.7-0.9,4.4-2.7,5.1c-0.6,0-0.9,0.2-1.5,0.2
                c-1.1,0-2.1-0.4-2.8-1.1c-1.1-1.3-1.7-3.2-1.9-6.1V197.9z M222.6,182.3c-0.4-3.6-1.3-7.6-2.7-11.8c-1.5-4.7-3.8-7-6.8-7
                c-3.2,0.2-5.7,2.7-7.6,7.6c-1.5,3.8-2.7,7.4-3.2,10.8C207.2,181.4,214.1,181.6,222.6,182.3z"
                  />
                  <path
                    d="M238,199.8c-1.3-5.5-1.9-12.3-2.1-20.5c0-8.5,0.8-14.8,2.5-18.8c1.9-4.2,4.2-6.5,6.8-6.6c0.8-0.2,1.5-0.4,2.3-0.4
                c1.9,0,3.6,0.8,5.1,2.1c1.9,1.7,4.4,6.3,7,13.5c1.9-5.5,3.8-9.3,5.7-11.8c1.9-2.5,4-3.8,6.8-3.8c0.6-0.2,1.1-0.2,1.7-0.2
                c2.7,0,4.9,1.5,6.6,4.6c2.1,3.6,3.4,9.3,3.8,17.1l0.2,5.5c0,6.1-0.6,12.3-1.5,18.6c-0.8,4-2.3,6.3-4.7,6.8c-0.6,0-0.9,0.2-1.5,0.2
                c-1.5,0-2.7-0.6-3.4-1.9c-0.9-1.7-1.1-3.6-0.8-5.7c2.1-8.2,3-15,3-20.3s-0.4-8.7-1.1-10.2c-0.8-1.5-1.5-2.3-2.5-2.3
                c-0.8,0.2-1.7,1.1-2.5,2.7c-0.8,1.5-1.9,4.6-3.2,8.9c-1.7,4.7-4,7-6.6,7.2c-2.7,0-4.9-2.3-6.8-7.2c-1.5-4.2-2.7-7.2-3.6-8.7
                c-0.8-1.5-1.7-2.3-2.5-2.3c-0.9,0.2-1.7,1.5-2.3,4.2s-0.8,6.6-0.2,11.8c0.6,5.1,1.5,10.4,2.8,15.9c0.6,2.8,0.4,4.9-0.8,6.5
                c-0.8,1.1-1.9,1.7-3,1.7c-0.4,0-0.9,0-1.5-0.2C240.2,204.9,238.7,203,238,199.8z"
                  />
                  <path
                    d="M288.9,180.1c0.2-4.6,0.4-10.9,1.1-19.3c-0.4-2.1,0-3.9,0.9-5.1c0.7-0.9,1.8-1.4,3.2-1.4l11.2-0.2
                c5.4,0,10.2,0.4,14.2,0.9c1.4,0,2.5,0.7,3,1.8c0.5,0.9,0.9,2.1,0.9,3.5c0,1.4-0.5,2.5-1.2,3.3c-0.7,0.9-1.8,1.1-3.2,1.1
                c-4.2-0.5-9-0.9-14.2-0.9c-3,0-4.4,0-6.4,0c-0.4,4.1-0.4,7.7-0.6,10.8c1.9-0.2,5.4-0.2,10.4-0.2c5,0,9.5,0,13.3,0.4
                c1.4,0,2.5,0.6,3.3,1.7c0.6,1,1,2.3,1,3.7s-0.6,2.7-1.4,3.7c-0.8,1-1.9,1.4-3.5,1.2c-3.9-0.4-8.3-0.6-13.1-0.6c-5,0-8.3,0-9.9,0.2
                c-0.2,0-0.4,0-0.4,0c0,1.4,0.2,2.3,0.2,3.3c0,2.9,0.6,5.2,1.9,6.8s3.5,2.5,6.4,2.5c4.1,0,9.5-1.2,15.9-3.5c1.4-1,2.9-0.6,4.4,0.6
                c1.2,1,1.7,2.1,1.7,3.7c0,0.4,0,1-0.2,1.4c-0.4,2.1-1.5,3.7-3.3,4.3c-6,2.1-11.6,3.3-17,3.5c-6.2-0.2-11-1.7-13.9-5
                c-2.9-3.3-4.4-7.7-4.4-13.7v-4.2C288.7,183.2,288.9,181.8,288.9,180.1z"
                  />
                  <path
                    d="M342.2,154.2c3.6-0.9,7-1.3,10.2-1.3c4.2,0,8.4,0.8,12.1,2.3c3.8,1.5,5.9,3.4,6.3,5.7c0,0.4,0,0.8,0,1.1
                c0,1.7-0.6,2.8-1.5,3.6c-1.3,0.9-3.4,0.8-6.3-0.4c-5.5-2.7-10.8-3.6-15.8-2.7c-4.9,1.1-7.4,4.4-7.6,9.9c0,1.1,0.6,1.7,1.7,2.1
                c4.7,0.6,8.7,0.9,12,1.1c5.7,0.8,9.3,2.5,11.2,5.1c1.9,2.5,2.7,5.7,2.7,9.1c0,5.7-2.5,9.9-7,12.5c-4,2.3-8.7,3.4-14.2,3.6
                c-0.8,0-1.5-0.2-2.5-0.2c-2.3-0.2-4-1.3-4.7-3c-0.8-1.9-0.6-3.6,0.6-5.1c1.1-1.7,3-2.5,5.3-2.1c3.4,0.2,6.5-0.2,9.1-1.3
                c2.7-0.9,3.8-2.7,3.8-4.7c0-1.9-1.5-3-4.4-3.4c-3.8-0.2-8.2-0.6-12.9-1.3c-6.6-1.1-10.1-5.3-10.2-12.3
                C330.2,162.6,334.2,156.5,342.2,154.2z"
                  />
                </g>
              </g>
            </a>
          </svg>

          <!--Video飛船-->
          <svg viewBox="-1500 -600 1792 1792">
            <router-link to="/topic/majashow">
              <g id="airship">
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="462.1299"
                  y1="215.2745"
                  x2="420.7648"
                  y2="311.5265"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="st0"
                  d="M404.2,163.1l44.5-44.5c10.7-10.7,29-3.1,29,12v81.6L404.2,163.1z"
                />
                <linearGradient
                  id="SVGID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="462.1425"
                  y1="2212.7285"
                  x2="420.7775"
                  y2="2308.9817"
                  gradientTransform="matrix(1.0039 0 0 -1.0039 0.6396 2563.304)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="st1"
                  d="M404.2,311.5l44.5,44.5c10.7,10.7,29,3.1,29-12v-81.6L404.2,311.5z"
                />
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="222.6596"
                  y1="439.4502"
                  x2="231.5746"
                  y2="488.8902"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="st2"
                  d="M266.2,398l-91.4,0.4c-11,0-20.7-7.1-24-17.6l-13.2-42.5l166-0.7l-13.7,43C286.6,391,277,398,266.2,398
              L266.2,398z"
                />
                <linearGradient
                  id="SVGID_4_"
                  gradientUnits="userSpaceOnUse"
                  x1="236.9532"
                  y1="458.0924"
                  x2="191.5652"
                  y2="362.9944"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop
                    offset="0"
                    style="stop-color: #a34a9e; stop-opacity: 0"
                  />
                  <stop offset="1" style="stop-color: #343168" />
                </linearGradient>
                <path
                  class="st3"
                  d="M137.7,338.3l1.8,5.9l54.1,54.1l72.6-0.3c10.8,0,20.4-7.1,23.7-17.4l13.7-43L137.7,338.3z"
                />
                <linearGradient
                  id="SVGID_5_"
                  gradientUnits="userSpaceOnUse"
                  x1="236.9967"
                  y1="220.7074"
                  x2="279.3747"
                  y2="465.4785"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #3fa9f5" />
                  <stop offset="1" style="stop-color: #666ad6" />
                </linearGradient>
                <path
                  class="st4"
                  d="M176.5,128C77,128,0,176.9,0,237.3s77,109.3,176.5,109.3c175.2,0,335.5-48.9,335.5-109.3S351.7,128,176.5,128
              L176.5,128z"
                />
                <linearGradient
                  id="SVGID_6_"
                  gradientUnits="userSpaceOnUse"
                  x1="436.83"
                  y1="309.5501"
                  x2="441.505"
                  y2="336.5481"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <ellipse class="st5" cx="440.9" cy="237.3" rx="50.2" ry="9.7" />
                <linearGradient
                  id="SVGID_7_"
                  gradientUnits="userSpaceOnUse"
                  x1="242.324"
                  y1="250.5253"
                  x2="271.694"
                  y2="420.1673"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #d4f7ff" />
                  <stop offset="1" style="stop-color: #a2e3e9" />
                </linearGradient>
                <path
                  class="st6"
                  d="M176.5,168.9C77,168.9,0,199.5,0,237.3c0,37.7,77,68.3,176.5,68.3c175.2,0,335.5-30.6,335.5-68.3
              C512,199.5,351.7,168.9,176.5,168.9L176.5,168.9z"
                />
                <linearGradient
                  id="SVGID_8_"
                  gradientUnits="userSpaceOnUse"
                  x1="247.0365"
                  y1="277.2343"
                  x2="265.8475"
                  y2="385.8854"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #3fa9f5" />
                  <stop offset="1" style="stop-color: #666ad6" />
                </linearGradient>
                <path
                  class="st7"
                  d="M176.5,210C77,210,0,222.2,0,237.3s77,27.3,176.5,27.3c175.2,0,335.5-12.2,335.5-27.3S351.7,210,176.5,210
              L176.5,210z"
                />
                <linearGradient
                  id="SVGID_9_"
                  gradientUnits="userSpaceOnUse"
                  x1="454.7332"
                  y1="356.6664"
                  x2="436.3612"
                  y2="287.5044"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop
                    offset="0"
                    style="stop-color: #a34a9e; stop-opacity: 0"
                  />
                  <stop offset="1" style="stop-color: #343168" />
                </linearGradient>
                <path
                  class="st8"
                  d="M508.1,253.5l-17.3-17.3c-2.7-4.9-24-8.7-49.9-8.7c-27.7,0-50.2,4.4-50.2,9.7c0,0.9,0.7,1.8,1.9,2.6l58.8,58.8
              C480,285.5,500.1,270.1,508.1,253.5L508.1,253.5z"
                />
                <linearGradient
                  id="SVGID_10_"
                  gradientUnits="userSpaceOnUse"
                  x1="436.83"
                  y1="309.5501"
                  x2="441.505"
                  y2="336.5481"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <ellipse class="st9" cx="440.9" cy="237.3" rx="50.2" ry="9.7" />
                <linearGradient
                  id="SVGID_11_"
                  gradientUnits="userSpaceOnUse"
                  x1="271.3299"
                  y1="449.6757"
                  x2="233.0849"
                  y2="449.6757"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="st10"
                  d="M265.8,385.9h-9.4c-3.2,0-5.8-2.6-5.8-5.8v-24.2c0-3.2,2.6-5.8,5.8-5.8h9.4c3.2,0,5.8,2.6,5.8,5.8v24.2
              C271.6,383.3,269,385.9,265.8,385.9z"
                />
                <linearGradient
                  id="SVGID_12_"
                  gradientUnits="userSpaceOnUse"
                  x1="198.4851"
                  y1="437.8734"
                  x2="200.1871"
                  y2="453.8663"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #4f4a72" />
                  <stop offset="1" style="stop-color: #3b395f" />
                </linearGradient>
                <path
                  class="st11"
                  d="M203.6,374.2H198c-4,0-7.3-3.3-7.3-7.3v-5.5c0-4,3.3-7.3,7.3-7.3h5.5c4,0,7.3,3.3,7.3,7.3v5.5
              C210.8,371,207.6,374.2,203.6,374.2z"
                />
                <linearGradient
                  id="SVGID_13_"
                  gradientUnits="userSpaceOnUse"
                  x1="227.9692"
                  y1="437.8695"
                  x2="229.6722"
                  y2="453.8625"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #4f4a72" />
                  <stop offset="1" style="stop-color: #3b395f" />
                </linearGradient>
                <path
                  class="st12"
                  d="M233.1,374.2h-5.5c-4,0-7.3-3.3-7.3-7.3v-5.5c0-4,3.3-7.3,7.3-7.3h5.5c4,0,7.3,3.3,7.3,7.3v5.5
              C240.4,371,237.1,374.2,233.1,374.2z"
                />
                <linearGradient
                  id="SVGID_14_"
                  gradientUnits="userSpaceOnUse"
                  x1="166.4089"
                  y1="437.8655"
                  x2="168.1118"
                  y2="453.8586"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #4f4a72" />
                  <stop offset="1" style="stop-color: #3b395f" />
                </linearGradient>
                <path
                  class="st13"
                  d="M171.3,374.2h-5.5c-4,0-7.3-3.3-7.3-7.3v-5.5c0-4,3.3-7.3,7.3-7.3h5.5c4,0,7.3,3.3,7.3,7.3v5.5
              C178.6,371,175.3,374.2,171.3,374.2z"
                />
                <g>
                  <g>
                    <path
                      class="st14"
                      d="M99.6,191.1c0-0.9,0-1.8,0.1-2.7c0.1-2.9,0.9-4.8,2.3-5.7c0.8-0.5,1.7-0.8,2.7-0.8c0.7,0,1.3,0.1,2.1,0.4
                  c1.7,0.7,2.5,2,2.4,4.1c-0.2,8.7,0.3,15.5,1.3,20.4c1.2,4.8,2.8,8.4,4.9,10.7c2.1,2.2,4.3,3.3,6.7,3.4c2.5-0.1,4.8-2,6.7-5.6
                  c2-3.6,3.5-7.7,4.4-12.2c0.9-4.5,1.7-9.4,2.2-14.7c0.3-2.8,1.2-4.6,2.8-5.4c0.8-0.4,1.6-0.6,2.3-0.6c0.8,0,1.5,0.2,2.2,0.6
                  c1.3,0.8,2,2,2.1,3.8c0,14.1-3.6,26.4-10.7,36.9c-3,4.4-6.9,6.7-11.7,6.8c-5.2-0.1-9.5-1.8-12.8-5c-3.2-3.2-5.7-7.7-7.5-13.6
                  C100.4,206.3,99.6,199.5,99.6,191.1z"
                    />
                    <path
                      class="st14"
                      d="M164.6,191.2c-0.3,4.9-0.5,10.4-0.5,16.7c0.1,5.8,0.2,9.9,0.4,12.1c2-0.5,3.6-0.1,4.9,1.2
                  c1.3,1.2,1.7,2.8,1.2,4.7c-0.5,1.8-2,2.9-4.5,3.3c-1.4,0.2-3.3,0.4-5.6,0.5c-2.1-0.1-4.5-0.2-7.3-0.4c-2-0.1-3.4-1-4.2-2.7
                  c-0.3-0.7-0.5-1.4-0.5-2c0-0.9,0.4-1.9,1.1-2.8c1.2-1.5,2.6-2.1,4.4-1.9c0.5,0,0.9,0.1,1.3,0.1c-0.2-2.7-0.3-6.9-0.4-12.7
                  c-0.1-6,0-11.3,0.2-16c-1,0-1.7,0.1-2.1,0.2c-2,0.2-3.4-0.5-4.4-2.1c-0.4-0.9-0.7-1.8-0.7-2.5c0-0.8,0.2-1.5,0.6-2.1
                  c0.9-1.5,2.3-2.3,4.2-2.4c1.1-0.1,3.2-0.3,6.1-0.4l5.1-0.2c0.9,0,1.8,0,2.7,0.1c2.2,0.2,3.7,1.2,4.5,3c0.3,0.7,0.4,1.4,0.4,2.1
                  c0,0.9-0.3,1.7-0.9,2.5c-1,1.1-2.5,1.7-4.5,1.6C165.6,191.2,165.1,191.2,164.6,191.2z"
                    />
                    <path
                      class="st14"
                      d="M220.6,202.7c0,7.3-1.3,13-3.8,17.2c-2.4,4.2-5.4,6.9-9,8.3c-2.9,1-5.9,1.5-9,1.5c-4.3,0-8.6-1-13.1-2.9
                  c-0.5,1.8-1.9,2.9-3.9,3.1c-1.3,0-2.4-0.4-3.3-1.2c-0.9-0.8-1.4-2-1.5-3.5c-0.2-3-0.4-8.5-0.8-16.2c-0.1-3.5-0.2-6.8-0.3-9.9
                  c0.1-3.9,0.3-7.4,0.4-10.7c0.2-3,1.8-4.6,4.7-4.6c0.8,0,1.5,0.2,2.1,0.5c3.5-1.1,8.2-1.7,14.1-1.9l1.2-0.1
                  c5.6,0,10.7,1.6,15.1,4.9C218.2,190.5,220.6,195.7,220.6,202.7z M207.9,214.8c1.5-3,2.3-7.1,2.3-12.1c0-3.8-1.3-6.8-4-9
                  c-2.7-2.1-6.4-3-11.2-2.6c-4,0.3-7.2,0.8-9.7,1.5c-0.2,5-0.2,10.6-0.1,16.8l0.2,5.9c0.7,0.2,1.4,0.6,2.1,1.3
                  c2.7,2,5.8,3.3,9.3,3.8c2,0.3,4,0.2,5.9-0.3C204.6,219.5,206.4,217.7,207.9,214.8z"
                    />
                    <path
                      class="st14"
                      d="M224.9,207.2c0.1-4.4,0.5-10.7,1.2-19c-0.4-2.1-0.1-3.8,1.1-5c0.9-0.9,2.1-1.3,3.7-1.3l13.1-0.1
                  c6.3,0.1,11.8,0.4,16.6,0.9c1.6,0.1,2.8,0.6,3.5,1.6c0.7,0.9,1,2.1,1,3.5c-0.1,1.3-0.6,2.4-1.5,3.3c-0.9,0.8-2.1,1.1-3.8,1
                  c-4.8-0.6-10.4-0.9-16.7-0.9c-3.5,0-6.3,0-8.4-0.1c-0.3,3.6-0.5,6.8-0.6,9.7c2.1-0.1,5.8-0.2,11.1-0.2c5.3-0.1,10,0.1,14.1,0.4
                  c1.5,0.1,2.7,0.6,3.4,1.5c0.7,0.9,1.1,2,1.1,3.3c-0.1,1.2-0.5,2.3-1.4,3.2c-0.8,0.8-2,1.1-3.6,1.1c-4.1-0.4-8.8-0.5-14.1-0.5
                  s-8.8,0.1-10.4,0.2c-0.2,0-0.3,0-0.5-0.1c0.1,1.1,0.1,2.1,0.2,3c0,2.6,0.7,4.6,2.1,6.1s3.7,2.2,6.8,2.2c4.3,0.1,10-1,16.9-3.1
                  c1.5-0.8,3.1-0.6,4.6,0.6c1.2,0.9,1.8,2,1.8,3.3c0,0.4-0.1,0.8-0.2,1.3c-0.4,1.9-1.6,3.2-3.5,3.9c-6.3,2-12.3,3-18.2,3.1
                  c-6.6-0.1-11.6-1.6-14.9-4.5c-3.1-2.9-4.7-6.9-4.8-12.1l0.1-1.9C224.8,210.4,224.8,208.9,224.9,207.2z"
                    />
                    <path
                      class="st14"
                      d="M269.4,209.5c0-4.8,1.1-9.4,3.2-14c2.1-4.6,5.1-8.1,9-10.7c3.8-2.6,7.8-3.9,12.2-4c4.4,0,8.3,1.1,11.8,3.1
                  s6.1,4.7,7.9,8.3c1.9,3.6,2.9,7.3,2.9,11.4c-0.1,3.6-0.8,6.8-2.2,9.5c-1.3,2.7-2.9,4.7-4.7,6.3c-1.8,1.5-5.1,3.4-9.8,5.9
                  c-0.2,0.1-0.4,0.2-0.6,0.2c0.4,1.1,0.2,2.2-0.6,3.5c-1,1.4-2.1,2.1-3.3,2.2c-0.3-0.1-0.6-0.1-0.9-0.1c-5-0.5-9.3-1.5-12.8-3
                  c-3.5-1.6-6.4-4-8.7-7.1C270.7,217.7,269.5,213.9,269.4,209.5z M280.9,199.6c-1.5,3.3-2.2,6.8-2.3,10.4c0,1.9,0.5,3.7,1.5,5.2
                  c0.9,1.5,2.5,2.9,4.8,4.1c2,1,4.6,1.7,7.9,2.1c0.2-2.1,1.2-3.7,3-4.7c4.7-2.2,7.7-4.3,9-6.2c1.4-1.9,2.1-4.1,2.1-6.6
                  c0-2.8-0.5-5.2-1.6-7.3c-1-2.2-2.4-3.8-4.2-4.9c-1.8-1.2-4-1.8-6.7-1.8c-2.6,0-5.1,0.8-7.5,2.4
                  C284.5,193.8,282.5,196.3,280.9,199.6z"
                    />
                    <path
                      class="st14"
                      d="M333,183c3.8-0.8,7.5-1.2,11.1-1.3c4.6,0,8.9,0.7,13.1,2.1c4.1,1.3,6.3,3,6.7,5.1c0,0.3,0,0.7,0.1,1.1
                  c0,1.5-0.5,2.6-1.6,3.3c-1.4,0.9-3.7,0.7-6.9-0.4c-5.9-2.5-11.6-3.2-17-2.3c-5.4,1-8.1,4-8.1,9c0,1,0.6,1.6,1.9,1.9
                  c5.2,0.4,9.5,0.8,13,1.1c6.1,0.7,10.1,2.2,12,4.6c2,2.3,2.9,5.1,2.9,8.3c-0.1,5.1-2.6,8.9-7.6,11.3c-4.3,2-9.5,3.1-15.5,3.2
                  c-0.8-0.1-1.6-0.1-2.6-0.1c-2.5-0.2-4.3-1.2-5.2-2.8c-0.8-1.6-0.6-3.2,0.5-4.7c1.2-1.5,3.2-2.2,5.8-1.9c3.8,0.2,7-0.2,9.8-1.2
                  c2.8-0.9,4.1-2.4,4.1-4.3c0-1.8-1.6-2.8-4.8-3.2c-4.2-0.2-8.8-0.6-13.9-1.2c-7.2-1.1-10.9-4.8-11.1-11.2
                  C319.9,190.5,324.3,185.1,333,183z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.6,191.1c0-0.9,0-1.8,0.1-2.7c0.1-2.9,0.9-4.8,2.3-5.7c0.8-0.5,1.7-0.8,2.7-0.8c0.7,0,1.3,0.1,2.1,0.4
                  c1.7,0.7,2.5,2,2.4,4.1c-0.2,8.7,0.3,15.5,1.3,20.4c1.2,4.8,2.8,8.4,4.9,10.7c2.1,2.2,4.3,3.3,6.7,3.4c2.5-0.1,4.8-2,6.7-5.6
                  c2-3.6,3.5-7.7,4.4-12.2c0.9-4.5,1.7-9.4,2.2-14.7c0.3-2.8,1.2-4.6,2.8-5.4c0.8-0.4,1.6-0.6,2.3-0.6c0.8,0,1.5,0.2,2.2,0.6
                  c1.3,0.8,2,2,2.1,3.8c0,14.1-3.6,26.4-10.7,36.9c-3,4.4-6.9,6.7-11.7,6.8c-5.2-0.1-9.5-1.8-12.8-5c-3.2-3.2-5.7-7.7-7.5-13.6
                  C100.4,206.3,99.6,199.5,99.6,191.1z"
                    />
                    <path
                      d="M164.6,191.2c-0.3,4.9-0.5,10.4-0.5,16.7c0.1,5.8,0.2,9.9,0.4,12.1c2-0.5,3.6-0.1,4.9,1.2c1.3,1.2,1.7,2.8,1.2,4.7
                  c-0.5,1.8-2,2.9-4.5,3.3c-1.4,0.2-3.3,0.4-5.6,0.5c-2.1-0.1-4.5-0.2-7.3-0.4c-2-0.1-3.4-1-4.2-2.7c-0.3-0.7-0.5-1.4-0.5-2
                  c0-0.9,0.4-1.9,1.1-2.8c1.2-1.5,2.6-2.1,4.4-1.9c0.5,0,0.9,0.1,1.3,0.1c-0.2-2.7-0.3-6.9-0.4-12.7c-0.1-6,0-11.3,0.2-16
                  c-1,0-1.7,0.1-2.1,0.2c-2,0.2-3.4-0.5-4.4-2.1c-0.4-0.9-0.7-1.8-0.7-2.5c0-0.8,0.2-1.5,0.6-2.1c0.9-1.5,2.3-2.3,4.2-2.4
                  c1.1-0.1,3.2-0.3,6.1-0.4l5.1-0.2c0.9,0,1.8,0,2.7,0.1c2.2,0.2,3.7,1.2,4.5,3c0.3,0.7,0.4,1.4,0.4,2.1c0,0.9-0.3,1.7-0.9,2.5
                  c-1,1.1-2.5,1.7-4.5,1.6C165.6,191.2,165.1,191.2,164.6,191.2z"
                    />
                    <path
                      d="M220.6,202.7c0,7.3-1.3,13-3.8,17.2c-2.4,4.2-5.4,6.9-9,8.3c-2.9,1-5.9,1.5-9,1.5c-4.3,0-8.6-1-13.1-2.9
                  c-0.5,1.8-1.9,2.9-3.9,3.1c-1.3,0-2.4-0.4-3.3-1.2c-0.9-0.8-1.4-2-1.5-3.5c-0.2-3-0.4-8.5-0.8-16.2c-0.1-3.5-0.2-6.8-0.3-9.9
                  c0.1-3.9,0.3-7.4,0.4-10.7c0.2-3,1.8-4.6,4.7-4.6c0.8,0,1.5,0.2,2.1,0.5c3.5-1.1,8.2-1.7,14.1-1.9l1.2-0.1
                  c5.6,0,10.7,1.6,15.1,4.9C218.2,190.5,220.6,195.7,220.6,202.7z M207.9,214.8c1.5-3,2.3-7.1,2.3-12.1c0-3.8-1.3-6.8-4-9
                  c-2.7-2.1-6.4-3-11.2-2.6c-4,0.3-7.2,0.8-9.7,1.5c-0.2,5-0.2,10.6-0.1,16.8l0.2,5.9c0.7,0.2,1.4,0.6,2.1,1.3
                  c2.7,2,5.8,3.3,9.3,3.8c2,0.3,4,0.2,5.9-0.3C204.6,219.5,206.4,217.7,207.9,214.8z"
                    />
                    <path
                      d="M224.9,207.2c0.1-4.4,0.5-10.7,1.2-19c-0.4-2.1-0.1-3.8,1.1-5c0.9-0.9,2.1-1.3,3.7-1.3l13.1-0.1
                  c6.3,0.1,11.8,0.4,16.6,0.9c1.6,0.1,2.8,0.6,3.5,1.6c0.7,0.9,1,2.1,1,3.5c-0.1,1.3-0.6,2.4-1.5,3.3c-0.9,0.8-2.1,1.1-3.8,1
                  c-4.8-0.6-10.4-0.9-16.7-0.9c-3.5,0-6.3,0-8.4-0.1c-0.3,3.6-0.5,6.8-0.6,9.7c2.1-0.1,5.8-0.2,11.1-0.2c5.3-0.1,10,0.1,14.1,0.4
                  c1.5,0.1,2.7,0.6,3.4,1.5c0.7,0.9,1.1,2,1.1,3.3c-0.1,1.2-0.5,2.3-1.4,3.2c-0.8,0.8-2,1.1-3.6,1.1c-4.1-0.4-8.8-0.5-14.1-0.5
                  s-8.8,0.1-10.4,0.2c-0.2,0-0.3,0-0.5-0.1c0.1,1.1,0.1,2.1,0.2,3c0,2.6,0.7,4.6,2.1,6.1s3.7,2.2,6.8,2.2c4.3,0.1,10-1,16.9-3.1
                  c1.5-0.8,3.1-0.6,4.6,0.6c1.2,0.9,1.8,2,1.8,3.3c0,0.4-0.1,0.8-0.2,1.3c-0.4,1.9-1.6,3.2-3.5,3.9c-6.3,2-12.3,3-18.2,3.1
                  c-6.6-0.1-11.6-1.6-14.9-4.5c-3.1-2.9-4.7-6.9-4.8-12.1l0.1-1.9C224.8,210.4,224.8,208.9,224.9,207.2z"
                    />
                    <path
                      d="M269.4,209.5c0-4.8,1.1-9.4,3.2-14c2.1-4.6,5.1-8.1,9-10.7c3.8-2.6,7.8-3.9,12.2-4c4.4,0,8.3,1.1,11.8,3.1
                  s6.1,4.7,7.9,8.3c1.9,3.6,2.9,7.3,2.9,11.4c-0.1,3.6-0.8,6.8-2.2,9.5c-1.3,2.7-2.9,4.7-4.7,6.3c-1.8,1.5-5.1,3.4-9.8,5.9
                  c-0.2,0.1-0.4,0.2-0.6,0.2c0.4,1.1,0.2,2.2-0.6,3.5c-1,1.4-2.1,2.1-3.3,2.2c-0.3-0.1-0.6-0.1-0.9-0.1c-5-0.5-9.3-1.5-12.8-3
                  c-3.5-1.6-6.4-4-8.7-7.1C270.7,217.7,269.5,213.9,269.4,209.5z M280.9,199.6c-1.5,3.3-2.2,6.8-2.3,10.4c0,1.9,0.5,3.7,1.5,5.2
                  c0.9,1.5,2.5,2.9,4.8,4.1c2,1,4.6,1.7,7.9,2.1c0.2-2.1,1.2-3.7,3-4.7c4.7-2.2,7.7-4.3,9-6.2c1.4-1.9,2.1-4.1,2.1-6.6
                  c0-2.8-0.5-5.2-1.6-7.3c-1-2.2-2.4-3.8-4.2-4.9c-1.8-1.2-4-1.8-6.7-1.8c-2.6,0-5.1,0.8-7.5,2.4
                  C284.5,193.8,282.5,196.3,280.9,199.6z"
                    />
                    <path
                      d="M333,183c3.8-0.8,7.5-1.2,11.1-1.3c4.6,0,8.9,0.7,13.1,2.1c4.1,1.3,6.3,3,6.7,5.1c0,0.3,0,0.7,0.1,1.1
                  c0,1.5-0.5,2.6-1.6,3.3c-1.4,0.9-3.7,0.7-6.9-0.4c-5.9-2.5-11.6-3.2-17-2.3c-5.4,1-8.1,4-8.1,9c0,1,0.6,1.6,1.9,1.9
                  c5.2,0.4,9.5,0.8,13,1.1c6.1,0.7,10.1,2.2,12,4.6c2,2.3,2.9,5.1,2.9,8.3c-0.1,5.1-2.6,8.9-7.6,11.3c-4.3,2-9.5,3.1-15.5,3.2
                  c-0.8-0.1-1.6-0.1-2.6-0.1c-2.5-0.2-4.3-1.2-5.2-2.8c-0.8-1.6-0.6-3.2,0.5-4.7c1.2-1.5,3.2-2.2,5.8-1.9c3.8,0.2,7-0.2,9.8-1.2
                  c2.8-0.9,4.1-2.4,4.1-4.3c0-1.8-1.6-2.8-4.8-3.2c-4.2-0.2-8.8-0.6-13.9-1.2c-7.2-1.1-10.9-4.8-11.1-11.2
                  C319.9,190.5,324.3,185.1,333,183z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <ellipse
                      transform="matrix(0.1644 -0.9864 0.9864 0.1644 -92.7368 467.0645)"
                      class="st15"
                      cx="229.3"
                      cy="288.3"
                      rx="41"
                      ry="41"
                    />
                    <path
                      class="st16"
                      d="M188.3,288.3c0,22.6,18.3,41,41,41v-81.9C206.7,247.3,188.3,265.6,188.3,288.3z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        class="st17"
                        d="M270.3,288.3c0,22.6-18.3,41-41,41c-4.2,0-8.2-0.6-12-1.8l-13.6-13.6c-6.5-6.5-10.6-15.6-10.6-25.6
                    c0-20,16.2-36.2,36.2-36.2c10,0,19,4.1,25.6,10.6l13.6,13.6C269.7,280.1,270.3,284.1,270.3,288.3z"
                      />
                      <path
                        class="st18"
                        d="M229.3,252.1c-20,0-36.2,16.2-36.2,36.2c0,10,4.1,19,10.6,25.6l13.6,13.6c3.8,1.2,7.8,1.8,12,1.8
                    C229.3,329.2,229.3,252.1,229.3,252.1z"
                      />
                    </g>
                    <g>
                      <circle class="st19" cx="229.3" cy="288.3" r="36.2" />
                      <path
                        class="st20"
                        d="M193.1,288.3c0,20,16.2,36.2,36.2,36.2v-72.4C209.3,252.1,193.1,268.3,193.1,288.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        class="st21"
                        d="M264.7,296c-3.5,16.2-17.9,28.3-35.1,28.4l-14-14c-0.6-0.6-0.9-1.4-0.9-2.3v-39.7c0-2.5,2.7-4.1,4.9-2.8
                    l34.4,19.9c0.3,0.2,0.5,0.3,0.7,0.5L264.7,296z"
                      />
                      <path
                        class="st22"
                        d="M219.5,265.6c-2.2-1.3-4.9,0.3-4.9,2.8v39.7c0,0.9,0.4,1.7,0.9,2.3l13.8,13.8v-53L219.5,265.6z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          class="st23"
                          d="M253.9,291.1L219.5,311c-2.2,1.3-4.9-0.3-4.9-2.8v-39.7c0-2.5,2.7-4.1,4.9-2.8l34.4,19.9
                      C256.1,286.7,256.1,289.8,253.9,291.1z"
                        />
                      </g>
                      <g>
                        <path
                          class="st24"
                          d="M219.5,265.6c-2.2-1.3-4.9,0.3-4.9,2.8v39.7c0,2.5,2.7,4.1,4.9,2.8l9.8-5.7v-34L219.5,265.6z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </router-link>
          </svg>
        </svg>
      </div>

      <div class="d-lg-none" dir="ltr" style="background: #a1d2fb">
        <!--game熱氣球-->
        <svg viewBox="50 -250 1024 780" preserveAspectRatio="xMidYMax slice">
          <router-link to="/fqgame">
            <g id="balloon">
              <g>
                <path
                  class="st00"
                  d="M291.7,437.5c-0.4,0-0.7,0-1-0.1c-2.1-0.6-3.4-2.8-2.8-4.9l16.3-60.4c0.5-2.1,2.7-3.4,4.9-2.9
                s3.4,2.7,2.9,4.9v0.1l-16.3,60.4C295.1,436.3,293.6,437.5,291.7,437.5L291.7,437.5z"
                />
                <path
                  class="st00"
                  d="M214.6,437.5c-1.9,0-3.6-1.4-3.9-3.3l-10.6-60.4c-0.4-2.2,1.1-4.2,3.3-4.6s4.2,1.1,4.6,3.2l10.6,60.4
                c0.4,2.2-1.1,4.2-3.3,4.6C215,437.5,214.8,437.5,214.6,437.5L214.6,437.5z"
                />
                <path
                  class="st00"
                  d="M269.4,440c-0.2,0-0.3,0-0.5,0c-2.2-0.3-3.7-2.3-3.5-4.5l7.7-62.7c0.3-2.2,2.2-3.8,4.4-3.5
                c2.2,0.3,3.8,2.2,3.5,4.4c0,0,0,0,0,0.1l-7.7,62.7C273.1,438.5,271.4,440,269.4,440z"
                />
                <path
                  class="st00"
                  d="M239.6,439.8c-2,0-3.6-1.4-3.9-3.4l-9.6-62.7c-0.4-2.2,1.1-4.2,3.3-4.6s4.2,1.1,4.6,3.3c0,0.1,0,0.1,0,0.2
                l9.6,62.7c0.3,2.2-1.2,4.2-3.3,4.6l0,0C240,439.8,239.8,439.8,239.6,439.8L239.6,439.8z"
                />
              </g>
              <path
                class="st01"
                d="M411.1,134.3c-10-62-42.7-94.5-72.9-111.6C315.7,10.2,290.4,3.8,264.7,4h-17.4c-25.7-0.2-51,6.2-73.4,18.6
              c-30.3,17.1-62.9,49.7-72.9,111.6C86,226.9,166.7,285,201.1,376H311C345.3,285,426,227,411.1,134.3L411.1,134.3z"
              />
              <path
                class="st02"
                d="M385.5,134.3c-8.3-62-35.7-94.5-61-111.6C306.5,10.4,285.1,3.9,263.2,4h-14.5c-21.9-0.1-43.3,6.4-61.4,18.6
              c-25.3,17.1-52.6,49.7-61,111.6C113.9,226.9,181.4,285,210,376h91.7C330.6,285,398,227,385.5,134.3L385.5,134.3z"
              />
              <path
                class="st03"
                d="M357.1,134.2c-6.5-62-27.9-94.5-47.6-111.6c-13.2-11.9-30.3-18.5-48-18.6h-11.3c-17.7,0.1-34.8,6.8-48,18.6
              c-19.7,17.1-41.1,49.7-47.6,111.6c-9.7,92.7,42.9,150.8,65.3,241.8h71.6C314.3,284.9,366.9,226.9,357.1,134.2L357.1,134.2z"
              />
              <path
                class="st02"
                d="M296.9,134.3c-2.6-62-11.3-94.5-19.2-111.6C271.8,10,265.1,4,258.3,4h-4.6c-6.8,0-13.5,6-19.4,18.6
              c-8,17.1-16.6,49.7-19.2,111.6c-3.9,92.7,17.3,150.8,26.4,241.8h29C279.6,285,300.9,227,296.9,134.3L296.9,134.3z"
              />
              <path
                class="st04"
                d="M206,433v65.6c0,5.2,4.2,9.4,9.4,9.4h81.2c5.2,0,9.4-4.2,9.4-9.4V433H206z"
              />
              <path
                class="st05"
                d="M306,433.1v65.5c0,5.2-4.2,9.4-9.4,9.4H294v-52h-88v-23L306,433.1z"
              />
              <path
                class="st04"
                d="M310,429H202c-4.6,0.1-8.2,4-8.1,8.7c0.1,4.5,3.6,8.2,8.1,8.3h108c4.6-0.1,8.2-4,8.1-8.7
              C318,432.7,314.4,429.1,310,429z"
              />
              <g>
                <path
                  class="st06"
                  d="M148,184.4c0-9.9,2.1-17.5,6.3-23c4-5.5,9.7-8.2,17.1-8.4c4.2,0,8.2,1.3,11.8,3.8c3.2,1.9,4.7,4,4.9,6.3
                c0,2.1-0.6,3.8-1.9,4.9c-1.3,1.1-3.6,0.8-6.6-0.9c-3-2.3-6.1-3.4-8.7-3.6c-4.6,0.2-8.2,1.9-10.4,5.1c-2.3,3.2-3.4,8.5-3.2,15.9
                c0.4,4,1.9,6.8,4.4,8.7c2.5,1.7,5.3,2.5,8.7,2.1c3.4-0.6,6.1-1.5,8.2-2.8c0.9-0.6,1.7-0.9,2.5-1.1c0-1.7-0.6-2.8-1.3-3.4
                c-0.8-0.6-2.1-1.1-3.8-1.1c-0.8,0-1.5,0.2-2.5,0.4c-0.8,0.2-1.7,0.6-2.5,0.8c-1.5,0.4-2.8-0.2-4-1.7c-0.9-1.5-1.1-3.2-0.6-5.1
                c0.8-1.9,2.1-3,4-3.6c1.1-0.2,2.3-0.4,3.6-0.6c1.3,0,2.3-0.2,3-0.2c4,0.6,7,1.7,9.1,3.8c2.1,2.5,3.2,5.1,3.8,8.4
                c0,4.2-0.8,8-2.1,11.6c-1.3,4.2-2.7,6.5-4.4,6.8c-0.4,0-0.6,0-0.9,0c-1.3,0-2.3-0.6-3.2-1.9c-0.4-0.6-0.8-1.3-0.9-2.1
                c-2.7,0.9-5.7,1.3-9.3,1.7c-4.9,0.2-9.5-1.5-13.7-5.3C151,196.7,148.6,191.2,148,184.4z"
                />
                <path
                  class="st06"
                  d="M192.4,197.9c1.1-8,2.3-14.6,3.2-19.5c1.1-5.1,3-10.6,5.9-16.5c1.1-2.5,2.5-4.4,4-5.9
                c2.3-2.3,5.1-3.2,8.5-3.2c5.7,0,9.9,2.7,12.5,8.2c2.8,5.7,4.7,11.8,5.5,18.2c0,0.6,0.2,1.1,0.2,1.9c0.8,5.7,1.3,11.4,1.7,17.1
                c0,3.8-0.8,6.1-2.1,7c-0.8,0.4-1.5,0.8-2.5,0.9c-0.8,0-1.5-0.4-2.5-0.8c-1.7-0.9-2.7-2.7-2.8-5.1c0-3-0.2-5.7-0.2-8h-0.2
                c-4-0.6-7.8-0.9-11.8-0.9c-4,0-7,0.2-9.1,0.8c-0.4,0-0.6,0-0.9,0.2c-0.2,2.5-0.4,5.3-0.6,8.5c0,2.7-0.9,4.4-2.7,5.1
                c-0.6,0-0.9,0.2-1.5,0.2c-1.1,0-2.1-0.4-2.8-1.1c-1.1-1.3-1.7-3.2-1.9-6.1V197.9z M222.6,182.3c-0.4-3.6-1.3-7.6-2.7-11.8
                c-1.5-4.7-3.8-7-6.8-7c-3.2,0.2-5.7,2.7-7.6,7.6c-1.5,3.8-2.7,7.4-3.2,10.8C207.2,181.4,214.1,181.6,222.6,182.3z"
                />
                <path
                  class="st06"
                  d="M238,199.8c-1.3-5.5-1.9-12.3-2.1-20.5c0-8.5,0.8-14.8,2.5-18.8c1.9-4.2,4.2-6.5,6.8-6.6
                c0.8-0.2,1.5-0.4,2.3-0.4c1.9,0,3.6,0.8,5.1,2.1c1.9,1.7,4.4,6.3,7,13.5c1.9-5.5,3.8-9.3,5.7-11.8c1.9-2.5,4-3.8,6.8-3.8
                c0.6-0.2,1.1-0.2,1.7-0.2c2.7,0,4.9,1.5,6.6,4.6c2.1,3.6,3.4,9.3,3.8,17.1l0.2,5.5c0,6.1-0.6,12.3-1.5,18.6
                c-0.8,4-2.3,6.3-4.7,6.8c-0.6,0-0.9,0.2-1.5,0.2c-1.5,0-2.7-0.6-3.4-1.9c-0.9-1.7-1.1-3.6-0.8-5.7c2.1-8.2,3-15,3-20.3
                s-0.4-8.7-1.1-10.2c-0.8-1.5-1.5-2.3-2.5-2.3c-0.8,0.2-1.7,1.1-2.5,2.7c-0.8,1.5-1.9,4.6-3.2,8.9c-1.7,4.7-4,7-6.6,7.2
                c-2.7,0-4.9-2.3-6.8-7.2c-1.5-4.2-2.7-7.2-3.6-8.7c-0.8-1.5-1.7-2.3-2.5-2.3c-0.9,0.2-1.7,1.5-2.3,4.2s-0.8,6.6-0.2,11.8
                c0.6,5.1,1.5,10.4,2.8,15.9c0.6,2.8,0.4,4.9-0.8,6.5c-0.8,1.1-1.9,1.7-3,1.7c-0.4,0-0.9,0-1.5-0.2
                C240.2,204.9,238.7,203,238,199.8z"
                />
                <path
                  class="st06"
                  d="M288.9,180.1c0.2-4.6,0.4-10.9,1.1-19.3c-0.4-2.1,0-3.9,0.9-5.1c0.7-0.9,1.8-1.4,3.2-1.4l11.2-0.2
                c5.4,0,10.2,0.4,14.2,0.9c1.4,0,2.5,0.7,3,1.8c0.5,0.9,0.9,2.1,0.9,3.5c0,1.4-0.5,2.5-1.2,3.3c-0.7,0.9-1.8,1.1-3.2,1.1
                c-4.2-0.5-9-0.9-14.2-0.9c-3,0-4.4,0-6.4,0c-0.4,4.1-0.4,7.7-0.6,10.8c1.9-0.2,5.4-0.2,10.4-0.2c5,0,9.5,0,13.3,0.4
                c1.4,0,2.5,0.6,3.3,1.7c0.6,1,1,2.3,1,3.7s-0.6,2.7-1.4,3.7c-0.8,1-1.9,1.4-3.5,1.2c-3.9-0.4-8.3-0.6-13.1-0.6c-5,0-8.3,0-9.9,0.2
                c-0.2,0-0.4,0-0.4,0c0,1.4,0.2,2.3,0.2,3.3c0,2.9,0.6,5.2,1.9,6.8s3.5,2.5,6.4,2.5c4.1,0,9.5-1.2,15.9-3.5c1.4-1,2.9-0.6,4.4,0.6
                c1.2,1,1.7,2.1,1.7,3.7c0,0.4,0,1-0.2,1.4c-0.4,2.1-1.5,3.7-3.3,4.3c-6,2.1-11.6,3.3-17,3.5c-6.2-0.2-11-1.7-13.9-5
                c-2.9-3.3-4.4-7.7-4.4-13.7v-4.2C288.7,183.2,288.9,181.8,288.9,180.1z"
                />
                <path
                  class="st06"
                  d="M342.2,154.2c3.6-0.9,7-1.3,10.2-1.3c4.2,0,8.4,0.8,12.1,2.3c3.8,1.5,5.9,3.4,6.3,5.7c0,0.4,0,0.8,0,1.1
                c0,1.7-0.6,2.8-1.5,3.6c-1.3,0.9-3.4,0.8-6.3-0.4c-5.5-2.7-10.8-3.6-15.8-2.7c-4.9,1.1-7.4,4.4-7.6,9.9c0,1.1,0.6,1.7,1.7,2.1
                c4.7,0.6,8.7,0.9,12,1.1c5.7,0.8,9.3,2.5,11.2,5.1c1.9,2.5,2.7,5.7,2.7,9.1c0,5.7-2.5,9.9-7,12.5c-4,2.3-8.7,3.4-14.2,3.6
                c-0.8,0-1.5-0.2-2.5-0.2c-2.3-0.2-4-1.3-4.7-3c-0.8-1.9-0.6-3.6,0.6-5.1c1.1-1.7,3-2.5,5.3-2.1c3.4,0.2,6.5-0.2,9.1-1.3
                c2.7-0.9,3.8-2.7,3.8-4.7c0-1.9-1.5-3-4.4-3.4c-3.8-0.2-8.2-0.6-12.9-1.3c-6.6-1.1-10.1-5.3-10.2-12.3
                C330.2,162.6,334.2,156.5,342.2,154.2z"
                />
              </g>
              <g>
                <path
                  d="M148,184.4c0-9.9,2.1-17.5,6.3-23c4-5.5,9.7-8.2,17.1-8.4c4.2,0,8.2,1.3,11.8,3.8c3.2,1.9,4.7,4,4.9,6.3
                c0,2.1-0.6,3.8-1.9,4.9c-1.3,1.1-3.6,0.8-6.6-0.9c-3-2.3-6.1-3.4-8.7-3.6c-4.6,0.2-8.2,1.9-10.4,5.1c-2.3,3.2-3.4,8.5-3.2,15.9
                c0.4,4,1.9,6.8,4.4,8.7c2.5,1.7,5.3,2.5,8.7,2.1c3.4-0.6,6.1-1.5,8.2-2.8c0.9-0.6,1.7-0.9,2.5-1.1c0-1.7-0.6-2.8-1.3-3.4
                c-0.8-0.6-2.1-1.1-3.8-1.1c-0.8,0-1.5,0.2-2.5,0.4c-0.8,0.2-1.7,0.6-2.5,0.8c-1.5,0.4-2.8-0.2-4-1.7c-0.9-1.5-1.1-3.2-0.6-5.1
                c0.8-1.9,2.1-3,4-3.6c1.1-0.2,2.3-0.4,3.6-0.6c1.3,0,2.3-0.2,3-0.2c4,0.6,7,1.7,9.1,3.8c2.1,2.5,3.2,5.1,3.8,8.4
                c0,4.2-0.8,8-2.1,11.6c-1.3,4.2-2.7,6.5-4.4,6.8c-0.4,0-0.6,0-0.9,0c-1.3,0-2.3-0.6-3.2-1.9c-0.4-0.6-0.8-1.3-0.9-2.1
                c-2.7,0.9-5.7,1.3-9.3,1.7c-4.9,0.2-9.5-1.5-13.7-5.3C151,196.7,148.6,191.2,148,184.4z"
                />
                <path
                  d="M192.4,197.9c1.1-8,2.3-14.6,3.2-19.5c1.1-5.1,3-10.6,5.9-16.5c1.1-2.5,2.5-4.4,4-5.9c2.3-2.3,5.1-3.2,8.5-3.2
                c5.7,0,9.9,2.7,12.5,8.2c2.8,5.7,4.7,11.8,5.5,18.2c0,0.6,0.2,1.1,0.2,1.9c0.8,5.7,1.3,11.4,1.7,17.1c0,3.8-0.8,6.1-2.1,7
                c-0.8,0.4-1.5,0.8-2.5,0.9c-0.8,0-1.5-0.4-2.5-0.8c-1.7-0.9-2.7-2.7-2.8-5.1c0-3-0.2-5.7-0.2-8h-0.2c-4-0.6-7.8-0.9-11.8-0.9
                c-4,0-7,0.2-9.1,0.8c-0.4,0-0.6,0-0.9,0.2c-0.2,2.5-0.4,5.3-0.6,8.5c0,2.7-0.9,4.4-2.7,5.1c-0.6,0-0.9,0.2-1.5,0.2
                c-1.1,0-2.1-0.4-2.8-1.1c-1.1-1.3-1.7-3.2-1.9-6.1V197.9z M222.6,182.3c-0.4-3.6-1.3-7.6-2.7-11.8c-1.5-4.7-3.8-7-6.8-7
                c-3.2,0.2-5.7,2.7-7.6,7.6c-1.5,3.8-2.7,7.4-3.2,10.8C207.2,181.4,214.1,181.6,222.6,182.3z"
                />
                <path
                  d="M238,199.8c-1.3-5.5-1.9-12.3-2.1-20.5c0-8.5,0.8-14.8,2.5-18.8c1.9-4.2,4.2-6.5,6.8-6.6c0.8-0.2,1.5-0.4,2.3-0.4
                c1.9,0,3.6,0.8,5.1,2.1c1.9,1.7,4.4,6.3,7,13.5c1.9-5.5,3.8-9.3,5.7-11.8c1.9-2.5,4-3.8,6.8-3.8c0.6-0.2,1.1-0.2,1.7-0.2
                c2.7,0,4.9,1.5,6.6,4.6c2.1,3.6,3.4,9.3,3.8,17.1l0.2,5.5c0,6.1-0.6,12.3-1.5,18.6c-0.8,4-2.3,6.3-4.7,6.8c-0.6,0-0.9,0.2-1.5,0.2
                c-1.5,0-2.7-0.6-3.4-1.9c-0.9-1.7-1.1-3.6-0.8-5.7c2.1-8.2,3-15,3-20.3s-0.4-8.7-1.1-10.2c-0.8-1.5-1.5-2.3-2.5-2.3
                c-0.8,0.2-1.7,1.1-2.5,2.7c-0.8,1.5-1.9,4.6-3.2,8.9c-1.7,4.7-4,7-6.6,7.2c-2.7,0-4.9-2.3-6.8-7.2c-1.5-4.2-2.7-7.2-3.6-8.7
                c-0.8-1.5-1.7-2.3-2.5-2.3c-0.9,0.2-1.7,1.5-2.3,4.2s-0.8,6.6-0.2,11.8c0.6,5.1,1.5,10.4,2.8,15.9c0.6,2.8,0.4,4.9-0.8,6.5
                c-0.8,1.1-1.9,1.7-3,1.7c-0.4,0-0.9,0-1.5-0.2C240.2,204.9,238.7,203,238,199.8z"
                />
                <path
                  d="M288.9,180.1c0.2-4.6,0.4-10.9,1.1-19.3c-0.4-2.1,0-3.9,0.9-5.1c0.7-0.9,1.8-1.4,3.2-1.4l11.2-0.2
                c5.4,0,10.2,0.4,14.2,0.9c1.4,0,2.5,0.7,3,1.8c0.5,0.9,0.9,2.1,0.9,3.5c0,1.4-0.5,2.5-1.2,3.3c-0.7,0.9-1.8,1.1-3.2,1.1
                c-4.2-0.5-9-0.9-14.2-0.9c-3,0-4.4,0-6.4,0c-0.4,4.1-0.4,7.7-0.6,10.8c1.9-0.2,5.4-0.2,10.4-0.2c5,0,9.5,0,13.3,0.4
                c1.4,0,2.5,0.6,3.3,1.7c0.6,1,1,2.3,1,3.7s-0.6,2.7-1.4,3.7c-0.8,1-1.9,1.4-3.5,1.2c-3.9-0.4-8.3-0.6-13.1-0.6c-5,0-8.3,0-9.9,0.2
                c-0.2,0-0.4,0-0.4,0c0,1.4,0.2,2.3,0.2,3.3c0,2.9,0.6,5.2,1.9,6.8s3.5,2.5,6.4,2.5c4.1,0,9.5-1.2,15.9-3.5c1.4-1,2.9-0.6,4.4,0.6
                c1.2,1,1.7,2.1,1.7,3.7c0,0.4,0,1-0.2,1.4c-0.4,2.1-1.5,3.7-3.3,4.3c-6,2.1-11.6,3.3-17,3.5c-6.2-0.2-11-1.7-13.9-5
                c-2.9-3.3-4.4-7.7-4.4-13.7v-4.2C288.7,183.2,288.9,181.8,288.9,180.1z"
                />
                <path
                  d="M342.2,154.2c3.6-0.9,7-1.3,10.2-1.3c4.2,0,8.4,0.8,12.1,2.3c3.8,1.5,5.9,3.4,6.3,5.7c0,0.4,0,0.8,0,1.1
                c0,1.7-0.6,2.8-1.5,3.6c-1.3,0.9-3.4,0.8-6.3-0.4c-5.5-2.7-10.8-3.6-15.8-2.7c-4.9,1.1-7.4,4.4-7.6,9.9c0,1.1,0.6,1.7,1.7,2.1
                c4.7,0.6,8.7,0.9,12,1.1c5.7,0.8,9.3,2.5,11.2,5.1c1.9,2.5,2.7,5.7,2.7,9.1c0,5.7-2.5,9.9-7,12.5c-4,2.3-8.7,3.4-14.2,3.6
                c-0.8,0-1.5-0.2-2.5-0.2c-2.3-0.2-4-1.3-4.7-3c-0.8-1.9-0.6-3.6,0.6-5.1c1.1-1.7,3-2.5,5.3-2.1c3.4,0.2,6.5-0.2,9.1-1.3
                c2.7-0.9,3.8-2.7,3.8-4.7c0-1.9-1.5-3-4.4-3.4c-3.8-0.2-8.2-0.6-12.9-1.3c-6.6-1.1-10.1-5.3-10.2-12.3
                C330.2,162.6,334.2,156.5,342.2,154.2z"
                />
              </g>
            </g>
          </router-link>

          <!--Video飛船-->
          <svg viewBox="-380 -100 780 780">
            <router-link to="/topic/majashow">
              <g id="airship">
                <linearGradient
                  id="SVGID_1aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="462.1299"
                  y1="215.2745"
                  x2="420.7648"
                  y2="311.5265"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="staa0"
                  d="M404.2,163.1l44.5-44.5c10.7-10.7,29-3.1,29,12v81.6L404.2,163.1z"
                />
                <linearGradient
                  id="SVGID_2aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="462.1425"
                  y1="2212.7285"
                  x2="420.7775"
                  y2="2308.9817"
                  gradientTransform="matrix(1.0039 0 0 -1.0039 0.6396 2563.304)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="staa1"
                  d="M404.2,311.5l44.5,44.5c10.7,10.7,29,3.1,29-12v-81.6L404.2,311.5z"
                />
                <linearGradient
                  id="SVGID_3aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="222.6596"
                  y1="439.4502"
                  x2="231.5746"
                  y2="488.8902"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="staa2"
                  d="M266.2,398l-91.4,0.4c-11,0-20.7-7.1-24-17.6l-13.2-42.5l166-0.7l-13.7,43C286.6,391,277,398,266.2,398
              L266.2,398z"
                />
                <linearGradient
                  id="SVGID_4aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="236.9532"
                  y1="458.0924"
                  x2="191.5652"
                  y2="362.9944"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop
                    offset="0"
                    style="stop-color: #a34a9e; stop-opacity: 0"
                  />
                  <stop offset="1" style="stop-color: #343168" />
                </linearGradient>
                <path
                  class="staa3"
                  d="M137.7,338.3l1.8,5.9l54.1,54.1l72.6-0.3c10.8,0,20.4-7.1,23.7-17.4l13.7-43L137.7,338.3z"
                />
                <linearGradient
                  id="SVGID_5aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="236.9967"
                  y1="220.7074"
                  x2="279.3747"
                  y2="465.4785"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #3fa9f5" />
                  <stop offset="1" style="stop-color: #666ad6" />
                </linearGradient>
                <path
                  class="staa4"
                  d="M176.5,128C77,128,0,176.9,0,237.3s77,109.3,176.5,109.3c175.2,0,335.5-48.9,335.5-109.3S351.7,128,176.5,128
              L176.5,128z"
                />
                <linearGradient
                  id="SVGID_6aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="436.83"
                  y1="309.5501"
                  x2="441.505"
                  y2="336.5481"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <ellipse
                  class="staa5"
                  cx="440.9"
                  cy="237.3"
                  rx="50.2"
                  ry="9.7"
                />
                <linearGradient
                  id="SVGID_7aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="242.324"
                  y1="250.5253"
                  x2="271.694"
                  y2="420.1673"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #d4f7ff" />
                  <stop offset="1" style="stop-color: #a2e3e9" />
                </linearGradient>
                <path
                  class="staa6"
                  d="M176.5,168.9C77,168.9,0,199.5,0,237.3c0,37.7,77,68.3,176.5,68.3c175.2,0,335.5-30.6,335.5-68.3
              C512,199.5,351.7,168.9,176.5,168.9L176.5,168.9z"
                />
                <linearGradient
                  id="SVGID_8aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="247.0365"
                  y1="277.2343"
                  x2="265.8475"
                  y2="385.8854"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #3fa9f5" />
                  <stop offset="1" style="stop-color: #666ad6" />
                </linearGradient>
                <path
                  class="staa7"
                  d="M176.5,210C77,210,0,222.2,0,237.3s77,27.3,176.5,27.3c175.2,0,335.5-12.2,335.5-27.3S351.7,210,176.5,210
              L176.5,210z"
                />
                <linearGradient
                  id="SVGID_9aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="454.7332"
                  y1="356.6664"
                  x2="436.3612"
                  y2="287.5044"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop
                    offset="0"
                    style="stop-color: #a34a9e; stop-opacity: 0"
                  />
                  <stop offset="1" style="stop-color: #343168" />
                </linearGradient>
                <path
                  class="staa8"
                  d="M508.1,253.5l-17.3-17.3c-2.7-4.9-24-8.7-49.9-8.7c-27.7,0-50.2,4.4-50.2,9.7c0,0.9,0.7,1.8,1.9,2.6l58.8,58.8
              C480,285.5,500.1,270.1,508.1,253.5L508.1,253.5z"
                />
                <linearGradient
                  id="SVGID_10aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="436.83"
                  y1="309.5501"
                  x2="441.505"
                  y2="336.5481"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <ellipse
                  class="staa9"
                  cx="440.9"
                  cy="237.3"
                  rx="50.2"
                  ry="9.7"
                />
                <linearGradient
                  id="SVGID_11aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="271.3299"
                  y1="449.6757"
                  x2="233.0849"
                  y2="449.6757"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #ff4974" />
                  <stop offset="0.2231" style="stop-color: #fb3f6c" />
                  <stop offset="0.6075" style="stop-color: #f12357" />
                  <stop offset="1" style="stop-color: #e4003d" />
                </linearGradient>
                <path
                  class="staa10"
                  d="M265.8,385.9h-9.4c-3.2,0-5.8-2.6-5.8-5.8v-24.2c0-3.2,2.6-5.8,5.8-5.8h9.4c3.2,0,5.8,2.6,5.8,5.8v24.2
              C271.6,383.3,269,385.9,265.8,385.9z"
                />
                <linearGradient
                  id="SVGID_12aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="198.4851"
                  y1="437.8734"
                  x2="200.1871"
                  y2="453.8663"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #4f4a72" />
                  <stop offset="1" style="stop-color: #3b395f" />
                </linearGradient>
                <path
                  class="staa11"
                  d="M203.6,374.2H198c-4,0-7.3-3.3-7.3-7.3v-5.5c0-4,3.3-7.3,7.3-7.3h5.5c4,0,7.3,3.3,7.3,7.3v5.5
              C210.8,371,207.6,374.2,203.6,374.2z"
                />
                <linearGradient
                  id="SVGID_13aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="227.9692"
                  y1="437.8695"
                  x2="229.6722"
                  y2="453.8625"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #4f4a72" />
                  <stop offset="1" style="stop-color: #3b395f" />
                </linearGradient>
                <path
                  class="staa12"
                  d="M233.1,374.2h-5.5c-4,0-7.3-3.3-7.3-7.3v-5.5c0-4,3.3-7.3,7.3-7.3h5.5c4,0,7.3,3.3,7.3,7.3v5.5
              C240.4,371,237.1,374.2,233.1,374.2z"
                />
                <linearGradient
                  id="SVGID_14aa_"
                  gradientUnits="userSpaceOnUse"
                  x1="166.4089"
                  y1="437.8655"
                  x2="168.1118"
                  y2="453.8586"
                  gradientTransform="matrix(1.0039 0 0 1.0039 0.6396 -83.4294)"
                >
                  <stop offset="0" style="stop-color: #4f4a72" />
                  <stop offset="1" style="stop-color: #3b395f" />
                </linearGradient>
                <path
                  class="staa13"
                  d="M171.3,374.2h-5.5c-4,0-7.3-3.3-7.3-7.3v-5.5c0-4,3.3-7.3,7.3-7.3h5.5c4,0,7.3,3.3,7.3,7.3v5.5
              C178.6,371,175.3,374.2,171.3,374.2z"
                />
                <g>
                  <g>
                    <path
                      class="staa14"
                      d="M99.6,191.1c0-0.9,0-1.8,0.1-2.7c0.1-2.9,0.9-4.8,2.3-5.7c0.8-0.5,1.7-0.8,2.7-0.8c0.7,0,1.3,0.1,2.1,0.4
                  c1.7,0.7,2.5,2,2.4,4.1c-0.2,8.7,0.3,15.5,1.3,20.4c1.2,4.8,2.8,8.4,4.9,10.7c2.1,2.2,4.3,3.3,6.7,3.4c2.5-0.1,4.8-2,6.7-5.6
                  c2-3.6,3.5-7.7,4.4-12.2c0.9-4.5,1.7-9.4,2.2-14.7c0.3-2.8,1.2-4.6,2.8-5.4c0.8-0.4,1.6-0.6,2.3-0.6c0.8,0,1.5,0.2,2.2,0.6
                  c1.3,0.8,2,2,2.1,3.8c0,14.1-3.6,26.4-10.7,36.9c-3,4.4-6.9,6.7-11.7,6.8c-5.2-0.1-9.5-1.8-12.8-5c-3.2-3.2-5.7-7.7-7.5-13.6
                  C100.4,206.3,99.6,199.5,99.6,191.1z"
                    />
                    <path
                      class="staa14"
                      d="M164.6,191.2c-0.3,4.9-0.5,10.4-0.5,16.7c0.1,5.8,0.2,9.9,0.4,12.1c2-0.5,3.6-0.1,4.9,1.2
                  c1.3,1.2,1.7,2.8,1.2,4.7c-0.5,1.8-2,2.9-4.5,3.3c-1.4,0.2-3.3,0.4-5.6,0.5c-2.1-0.1-4.5-0.2-7.3-0.4c-2-0.1-3.4-1-4.2-2.7
                  c-0.3-0.7-0.5-1.4-0.5-2c0-0.9,0.4-1.9,1.1-2.8c1.2-1.5,2.6-2.1,4.4-1.9c0.5,0,0.9,0.1,1.3,0.1c-0.2-2.7-0.3-6.9-0.4-12.7
                  c-0.1-6,0-11.3,0.2-16c-1,0-1.7,0.1-2.1,0.2c-2,0.2-3.4-0.5-4.4-2.1c-0.4-0.9-0.7-1.8-0.7-2.5c0-0.8,0.2-1.5,0.6-2.1
                  c0.9-1.5,2.3-2.3,4.2-2.4c1.1-0.1,3.2-0.3,6.1-0.4l5.1-0.2c0.9,0,1.8,0,2.7,0.1c2.2,0.2,3.7,1.2,4.5,3c0.3,0.7,0.4,1.4,0.4,2.1
                  c0,0.9-0.3,1.7-0.9,2.5c-1,1.1-2.5,1.7-4.5,1.6C165.6,191.2,165.1,191.2,164.6,191.2z"
                    />
                    <path
                      class="staa14"
                      d="M220.6,202.7c0,7.3-1.3,13-3.8,17.2c-2.4,4.2-5.4,6.9-9,8.3c-2.9,1-5.9,1.5-9,1.5c-4.3,0-8.6-1-13.1-2.9
                  c-0.5,1.8-1.9,2.9-3.9,3.1c-1.3,0-2.4-0.4-3.3-1.2c-0.9-0.8-1.4-2-1.5-3.5c-0.2-3-0.4-8.5-0.8-16.2c-0.1-3.5-0.2-6.8-0.3-9.9
                  c0.1-3.9,0.3-7.4,0.4-10.7c0.2-3,1.8-4.6,4.7-4.6c0.8,0,1.5,0.2,2.1,0.5c3.5-1.1,8.2-1.7,14.1-1.9l1.2-0.1
                  c5.6,0,10.7,1.6,15.1,4.9C218.2,190.5,220.6,195.7,220.6,202.7z M207.9,214.8c1.5-3,2.3-7.1,2.3-12.1c0-3.8-1.3-6.8-4-9
                  c-2.7-2.1-6.4-3-11.2-2.6c-4,0.3-7.2,0.8-9.7,1.5c-0.2,5-0.2,10.6-0.1,16.8l0.2,5.9c0.7,0.2,1.4,0.6,2.1,1.3
                  c2.7,2,5.8,3.3,9.3,3.8c2,0.3,4,0.2,5.9-0.3C204.6,219.5,206.4,217.7,207.9,214.8z"
                    />
                    <path
                      class="staa14"
                      d="M224.9,207.2c0.1-4.4,0.5-10.7,1.2-19c-0.4-2.1-0.1-3.8,1.1-5c0.9-0.9,2.1-1.3,3.7-1.3l13.1-0.1
                  c6.3,0.1,11.8,0.4,16.6,0.9c1.6,0.1,2.8,0.6,3.5,1.6c0.7,0.9,1,2.1,1,3.5c-0.1,1.3-0.6,2.4-1.5,3.3c-0.9,0.8-2.1,1.1-3.8,1
                  c-4.8-0.6-10.4-0.9-16.7-0.9c-3.5,0-6.3,0-8.4-0.1c-0.3,3.6-0.5,6.8-0.6,9.7c2.1-0.1,5.8-0.2,11.1-0.2c5.3-0.1,10,0.1,14.1,0.4
                  c1.5,0.1,2.7,0.6,3.4,1.5c0.7,0.9,1.1,2,1.1,3.3c-0.1,1.2-0.5,2.3-1.4,3.2c-0.8,0.8-2,1.1-3.6,1.1c-4.1-0.4-8.8-0.5-14.1-0.5
                  s-8.8,0.1-10.4,0.2c-0.2,0-0.3,0-0.5-0.1c0.1,1.1,0.1,2.1,0.2,3c0,2.6,0.7,4.6,2.1,6.1s3.7,2.2,6.8,2.2c4.3,0.1,10-1,16.9-3.1
                  c1.5-0.8,3.1-0.6,4.6,0.6c1.2,0.9,1.8,2,1.8,3.3c0,0.4-0.1,0.8-0.2,1.3c-0.4,1.9-1.6,3.2-3.5,3.9c-6.3,2-12.3,3-18.2,3.1
                  c-6.6-0.1-11.6-1.6-14.9-4.5c-3.1-2.9-4.7-6.9-4.8-12.1l0.1-1.9C224.8,210.4,224.8,208.9,224.9,207.2z"
                    />
                    <path
                      class="staa14"
                      d="M269.4,209.5c0-4.8,1.1-9.4,3.2-14c2.1-4.6,5.1-8.1,9-10.7c3.8-2.6,7.8-3.9,12.2-4c4.4,0,8.3,1.1,11.8,3.1
                  s6.1,4.7,7.9,8.3c1.9,3.6,2.9,7.3,2.9,11.4c-0.1,3.6-0.8,6.8-2.2,9.5c-1.3,2.7-2.9,4.7-4.7,6.3c-1.8,1.5-5.1,3.4-9.8,5.9
                  c-0.2,0.1-0.4,0.2-0.6,0.2c0.4,1.1,0.2,2.2-0.6,3.5c-1,1.4-2.1,2.1-3.3,2.2c-0.3-0.1-0.6-0.1-0.9-0.1c-5-0.5-9.3-1.5-12.8-3
                  c-3.5-1.6-6.4-4-8.7-7.1C270.7,217.7,269.5,213.9,269.4,209.5z M280.9,199.6c-1.5,3.3-2.2,6.8-2.3,10.4c0,1.9,0.5,3.7,1.5,5.2
                  c0.9,1.5,2.5,2.9,4.8,4.1c2,1,4.6,1.7,7.9,2.1c0.2-2.1,1.2-3.7,3-4.7c4.7-2.2,7.7-4.3,9-6.2c1.4-1.9,2.1-4.1,2.1-6.6
                  c0-2.8-0.5-5.2-1.6-7.3c-1-2.2-2.4-3.8-4.2-4.9c-1.8-1.2-4-1.8-6.7-1.8c-2.6,0-5.1,0.8-7.5,2.4
                  C284.5,193.8,282.5,196.3,280.9,199.6z"
                    />
                    <path
                      class="staa14"
                      d="M333,183c3.8-0.8,7.5-1.2,11.1-1.3c4.6,0,8.9,0.7,13.1,2.1c4.1,1.3,6.3,3,6.7,5.1c0,0.3,0,0.7,0.1,1.1
                  c0,1.5-0.5,2.6-1.6,3.3c-1.4,0.9-3.7,0.7-6.9-0.4c-5.9-2.5-11.6-3.2-17-2.3c-5.4,1-8.1,4-8.1,9c0,1,0.6,1.6,1.9,1.9
                  c5.2,0.4,9.5,0.8,13,1.1c6.1,0.7,10.1,2.2,12,4.6c2,2.3,2.9,5.1,2.9,8.3c-0.1,5.1-2.6,8.9-7.6,11.3c-4.3,2-9.5,3.1-15.5,3.2
                  c-0.8-0.1-1.6-0.1-2.6-0.1c-2.5-0.2-4.3-1.2-5.2-2.8c-0.8-1.6-0.6-3.2,0.5-4.7c1.2-1.5,3.2-2.2,5.8-1.9c3.8,0.2,7-0.2,9.8-1.2
                  c2.8-0.9,4.1-2.4,4.1-4.3c0-1.8-1.6-2.8-4.8-3.2c-4.2-0.2-8.8-0.6-13.9-1.2c-7.2-1.1-10.9-4.8-11.1-11.2
                  C319.9,190.5,324.3,185.1,333,183z"
                    />
                  </g>
                  <g>
                    <path
                      d="M99.6,191.1c0-0.9,0-1.8,0.1-2.7c0.1-2.9,0.9-4.8,2.3-5.7c0.8-0.5,1.7-0.8,2.7-0.8c0.7,0,1.3,0.1,2.1,0.4
                  c1.7,0.7,2.5,2,2.4,4.1c-0.2,8.7,0.3,15.5,1.3,20.4c1.2,4.8,2.8,8.4,4.9,10.7c2.1,2.2,4.3,3.3,6.7,3.4c2.5-0.1,4.8-2,6.7-5.6
                  c2-3.6,3.5-7.7,4.4-12.2c0.9-4.5,1.7-9.4,2.2-14.7c0.3-2.8,1.2-4.6,2.8-5.4c0.8-0.4,1.6-0.6,2.3-0.6c0.8,0,1.5,0.2,2.2,0.6
                  c1.3,0.8,2,2,2.1,3.8c0,14.1-3.6,26.4-10.7,36.9c-3,4.4-6.9,6.7-11.7,6.8c-5.2-0.1-9.5-1.8-12.8-5c-3.2-3.2-5.7-7.7-7.5-13.6
                  C100.4,206.3,99.6,199.5,99.6,191.1z"
                    />
                    <path
                      d="M164.6,191.2c-0.3,4.9-0.5,10.4-0.5,16.7c0.1,5.8,0.2,9.9,0.4,12.1c2-0.5,3.6-0.1,4.9,1.2c1.3,1.2,1.7,2.8,1.2,4.7
                  c-0.5,1.8-2,2.9-4.5,3.3c-1.4,0.2-3.3,0.4-5.6,0.5c-2.1-0.1-4.5-0.2-7.3-0.4c-2-0.1-3.4-1-4.2-2.7c-0.3-0.7-0.5-1.4-0.5-2
                  c0-0.9,0.4-1.9,1.1-2.8c1.2-1.5,2.6-2.1,4.4-1.9c0.5,0,0.9,0.1,1.3,0.1c-0.2-2.7-0.3-6.9-0.4-12.7c-0.1-6,0-11.3,0.2-16
                  c-1,0-1.7,0.1-2.1,0.2c-2,0.2-3.4-0.5-4.4-2.1c-0.4-0.9-0.7-1.8-0.7-2.5c0-0.8,0.2-1.5,0.6-2.1c0.9-1.5,2.3-2.3,4.2-2.4
                  c1.1-0.1,3.2-0.3,6.1-0.4l5.1-0.2c0.9,0,1.8,0,2.7,0.1c2.2,0.2,3.7,1.2,4.5,3c0.3,0.7,0.4,1.4,0.4,2.1c0,0.9-0.3,1.7-0.9,2.5
                  c-1,1.1-2.5,1.7-4.5,1.6C165.6,191.2,165.1,191.2,164.6,191.2z"
                    />
                    <path
                      d="M220.6,202.7c0,7.3-1.3,13-3.8,17.2c-2.4,4.2-5.4,6.9-9,8.3c-2.9,1-5.9,1.5-9,1.5c-4.3,0-8.6-1-13.1-2.9
                  c-0.5,1.8-1.9,2.9-3.9,3.1c-1.3,0-2.4-0.4-3.3-1.2c-0.9-0.8-1.4-2-1.5-3.5c-0.2-3-0.4-8.5-0.8-16.2c-0.1-3.5-0.2-6.8-0.3-9.9
                  c0.1-3.9,0.3-7.4,0.4-10.7c0.2-3,1.8-4.6,4.7-4.6c0.8,0,1.5,0.2,2.1,0.5c3.5-1.1,8.2-1.7,14.1-1.9l1.2-0.1
                  c5.6,0,10.7,1.6,15.1,4.9C218.2,190.5,220.6,195.7,220.6,202.7z M207.9,214.8c1.5-3,2.3-7.1,2.3-12.1c0-3.8-1.3-6.8-4-9
                  c-2.7-2.1-6.4-3-11.2-2.6c-4,0.3-7.2,0.8-9.7,1.5c-0.2,5-0.2,10.6-0.1,16.8l0.2,5.9c0.7,0.2,1.4,0.6,2.1,1.3
                  c2.7,2,5.8,3.3,9.3,3.8c2,0.3,4,0.2,5.9-0.3C204.6,219.5,206.4,217.7,207.9,214.8z"
                    />
                    <path
                      d="M224.9,207.2c0.1-4.4,0.5-10.7,1.2-19c-0.4-2.1-0.1-3.8,1.1-5c0.9-0.9,2.1-1.3,3.7-1.3l13.1-0.1
                  c6.3,0.1,11.8,0.4,16.6,0.9c1.6,0.1,2.8,0.6,3.5,1.6c0.7,0.9,1,2.1,1,3.5c-0.1,1.3-0.6,2.4-1.5,3.3c-0.9,0.8-2.1,1.1-3.8,1
                  c-4.8-0.6-10.4-0.9-16.7-0.9c-3.5,0-6.3,0-8.4-0.1c-0.3,3.6-0.5,6.8-0.6,9.7c2.1-0.1,5.8-0.2,11.1-0.2c5.3-0.1,10,0.1,14.1,0.4
                  c1.5,0.1,2.7,0.6,3.4,1.5c0.7,0.9,1.1,2,1.1,3.3c-0.1,1.2-0.5,2.3-1.4,3.2c-0.8,0.8-2,1.1-3.6,1.1c-4.1-0.4-8.8-0.5-14.1-0.5
                  s-8.8,0.1-10.4,0.2c-0.2,0-0.3,0-0.5-0.1c0.1,1.1,0.1,2.1,0.2,3c0,2.6,0.7,4.6,2.1,6.1s3.7,2.2,6.8,2.2c4.3,0.1,10-1,16.9-3.1
                  c1.5-0.8,3.1-0.6,4.6,0.6c1.2,0.9,1.8,2,1.8,3.3c0,0.4-0.1,0.8-0.2,1.3c-0.4,1.9-1.6,3.2-3.5,3.9c-6.3,2-12.3,3-18.2,3.1
                  c-6.6-0.1-11.6-1.6-14.9-4.5c-3.1-2.9-4.7-6.9-4.8-12.1l0.1-1.9C224.8,210.4,224.8,208.9,224.9,207.2z"
                    />
                    <path
                      d="M269.4,209.5c0-4.8,1.1-9.4,3.2-14c2.1-4.6,5.1-8.1,9-10.7c3.8-2.6,7.8-3.9,12.2-4c4.4,0,8.3,1.1,11.8,3.1
                  s6.1,4.7,7.9,8.3c1.9,3.6,2.9,7.3,2.9,11.4c-0.1,3.6-0.8,6.8-2.2,9.5c-1.3,2.7-2.9,4.7-4.7,6.3c-1.8,1.5-5.1,3.4-9.8,5.9
                  c-0.2,0.1-0.4,0.2-0.6,0.2c0.4,1.1,0.2,2.2-0.6,3.5c-1,1.4-2.1,2.1-3.3,2.2c-0.3-0.1-0.6-0.1-0.9-0.1c-5-0.5-9.3-1.5-12.8-3
                  c-3.5-1.6-6.4-4-8.7-7.1C270.7,217.7,269.5,213.9,269.4,209.5z M280.9,199.6c-1.5,3.3-2.2,6.8-2.3,10.4c0,1.9,0.5,3.7,1.5,5.2
                  c0.9,1.5,2.5,2.9,4.8,4.1c2,1,4.6,1.7,7.9,2.1c0.2-2.1,1.2-3.7,3-4.7c4.7-2.2,7.7-4.3,9-6.2c1.4-1.9,2.1-4.1,2.1-6.6
                  c0-2.8-0.5-5.2-1.6-7.3c-1-2.2-2.4-3.8-4.2-4.9c-1.8-1.2-4-1.8-6.7-1.8c-2.6,0-5.1,0.8-7.5,2.4
                  C284.5,193.8,282.5,196.3,280.9,199.6z"
                    />
                    <path
                      d="M333,183c3.8-0.8,7.5-1.2,11.1-1.3c4.6,0,8.9,0.7,13.1,2.1c4.1,1.3,6.3,3,6.7,5.1c0,0.3,0,0.7,0.1,1.1
                  c0,1.5-0.5,2.6-1.6,3.3c-1.4,0.9-3.7,0.7-6.9-0.4c-5.9-2.5-11.6-3.2-17-2.3c-5.4,1-8.1,4-8.1,9c0,1,0.6,1.6,1.9,1.9
                  c5.2,0.4,9.5,0.8,13,1.1c6.1,0.7,10.1,2.2,12,4.6c2,2.3,2.9,5.1,2.9,8.3c-0.1,5.1-2.6,8.9-7.6,11.3c-4.3,2-9.5,3.1-15.5,3.2
                  c-0.8-0.1-1.6-0.1-2.6-0.1c-2.5-0.2-4.3-1.2-5.2-2.8c-0.8-1.6-0.6-3.2,0.5-4.7c1.2-1.5,3.2-2.2,5.8-1.9c3.8,0.2,7-0.2,9.8-1.2
                  c2.8-0.9,4.1-2.4,4.1-4.3c0-1.8-1.6-2.8-4.8-3.2c-4.2-0.2-8.8-0.6-13.9-1.2c-7.2-1.1-10.9-4.8-11.1-11.2
                  C319.9,190.5,324.3,185.1,333,183z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <ellipse
                      transform="matrix(0.1644 -0.9864 0.9864 0.1644 -92.7368 467.0645)"
                      class="staa15"
                      cx="229.3"
                      cy="288.3"
                      rx="41"
                      ry="41"
                    />
                    <path
                      class="staa16"
                      d="M188.3,288.3c0,22.6,18.3,41,41,41v-81.9C206.7,247.3,188.3,265.6,188.3,288.3z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        class="staa17"
                        d="M270.3,288.3c0,22.6-18.3,41-41,41c-4.2,0-8.2-0.6-12-1.8l-13.6-13.6c-6.5-6.5-10.6-15.6-10.6-25.6
                    c0-20,16.2-36.2,36.2-36.2c10,0,19,4.1,25.6,10.6l13.6,13.6C269.7,280.1,270.3,284.1,270.3,288.3z"
                      />
                      <path
                        class="staa18"
                        d="M229.3,252.1c-20,0-36.2,16.2-36.2,36.2c0,10,4.1,19,10.6,25.6l13.6,13.6c3.8,1.2,7.8,1.8,12,1.8
                    C229.3,329.2,229.3,252.1,229.3,252.1z"
                      />
                    </g>
                    <g>
                      <circle class="staa19" cx="229.3" cy="288.3" r="36.2" />
                      <path
                        class="staa20"
                        d="M193.1,288.3c0,20,16.2,36.2,36.2,36.2v-72.4C209.3,252.1,193.1,268.3,193.1,288.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        class="staa21"
                        d="M264.7,296c-3.5,16.2-17.9,28.3-35.1,28.4l-14-14c-0.6-0.6-0.9-1.4-0.9-2.3v-39.7c0-2.5,2.7-4.1,4.9-2.8
                    l34.4,19.9c0.3,0.2,0.5,0.3,0.7,0.5L264.7,296z"
                      />
                      <path
                        class="staa22"
                        d="M219.5,265.6c-2.2-1.3-4.9,0.3-4.9,2.8v39.7c0,0.9,0.4,1.7,0.9,2.3l13.8,13.8v-53L219.5,265.6z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          class="staa23"
                          d="M253.9,291.1L219.5,311c-2.2,1.3-4.9-0.3-4.9-2.8v-39.7c0-2.5,2.7-4.1,4.9-2.8l34.4,19.9
                      C256.1,286.7,256.1,289.8,253.9,291.1z"
                        />
                      </g>
                      <g>
                        <path
                          class="staa24"
                          d="M219.5,265.6c-2.2-1.3-4.9,0.3-4.9,2.8v39.7c0,2.5,2.7,4.1,4.9,2.8l9.8-5.7v-34L219.5,265.6z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </router-link>
          </svg>
        </svg>
        <svg
          id="sky_day"
          data-name="sky day"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="450 50 400 540"
          preserveAspectRatio="xMidYMax slice"
        >
          <g>
            <rect id="day" class="cls-1" x="-8.79" y="-27.96" />
            <g id="clouds-front">
              <g id="cloud-1" class="cloud-all">
                <path
                  class="cls-6"
                  d="M899.69,71.14a26.77,26.77,0,0,0-3,0c-8.74,0-15.84,4.89-16.61,11.11a28.73,28.73,0,0,0-10.76-2c-12.24,0-22.17,7.22-22.17,16.13,0,0,0,.07,0,0.1-9.18,2.27-15.78,8.58-15.78,16,0,9.36,10.42,16.94,23.28,16.94H899.5Z"
                />
                <path
                  class="cls-7"
                  d="M899.49,71.14a23.27,23.27,0,0,1,2.81,0c8.74,0,15.84,4.89,16.61,11.11a28.73,28.73,0,0,1,10.76-2c12.24,0,22.17,7.22,22.17,16.13,0,0,0,.07,0,0.1,9.18,2.27,15.78,8.58,15.78,16,0,9.36-10.42,16.94-23.28,16.94H899.49V71.14Z"
                />
              </g>
              <g id="cloud-4" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M1151.17,173.76a12.85,12.85,0,0,0-1.91-.14c-5.81,0-10.53,4-11,9.17a15.89,15.89,0,0,0-7.15-1.68c-8.14,0-14.73,6-14.73,13.32,0,0,0,.06,0,0.09-6.1,1.88-10.49,7.09-10.49,13.23,0,7.73,6.93,14,15.48,14h30Z"
                />
                <path
                  class="cls-7"
                  d="M1151.08,173.75a12.8,12.8,0,0,1,1.91-.13c5.81,0,10.53,4,11,9.17a15.89,15.89,0,0,1,7.15-1.68c8.14,0,14.73,6,14.73,13.32,0,0,0,.06,0,0.09,6.1,1.88,10.49,7.09,10.49,13.23,0,7.73-6.93,14-15.48,14h-29.81Z"
                />
              </g>
              <g id="cloud-6" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M361,176.6a34.45,34.45,0,0,0-3.83,0c-9.72,0-17.61,6.12-18.46,13.91a28.82,28.82,0,0,0-12-2.55c-13.61,0-24.64,9-24.64,20.21,0,0,0,.09,0,0.13-10.2,2.85-17.55,10.75-17.55,20.08,0,11.72,11.59,21.22,25.88,21.22H361v-73Z"
                />
                <path
                  class="cls-7"
                  d="M360.29,176.54a23.12,23.12,0,0,1,3.13.05c9.72,0,17.61,6.12,18.46,13.91a28.82,28.82,0,0,1,12-2.55c13.61,0,24.64,9,24.64,20.21,0,0,0,.09,0,0.13C428.68,211.14,436,219,436,228.37c0,11.72-11.59,21.22-25.88,21.22H360.29V176.54Z"
                />
              </g>
            </g>
            <g id="clouds-back">
              <g id="cloud-2" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M1075.9,143.73a25.08,25.08,0,0,0-3.06-.18c-9.21,0-16.69,5.29-17.5,12a29.61,29.61,0,0,0-11.34-2.2c-12.9,0-23.36,7.81-23.36,17.44,0,0,0,.07,0,0.11-9.67,2.46-16.63,9.28-16.63,17.33,0,10.12,11,18.31,24.53,18.31h47.67Z"
                />
                <path
                  class="cls-7"
                  d="M1075.8,143.72a23.49,23.49,0,0,1,3-.17c9.21,0,16.69,5.29,17.5,12a29.61,29.61,0,0,1,11.34-2.2c12.9,0,23.36,7.81,23.36,17.44,0,0,0,.07,0,0.11,9.67,2.46,16.63,9.28,16.63,17.33,0,10.12-11,18.31-24.53,18.31H1075.8V143.72Z"
                />
              </g>
              <g id="cloud-3" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M430.87,168.15a21.33,21.33,0,0,0-2.39-.07c-6.16,0-11.15,3.82-11.7,8.68a18.49,18.49,0,0,0-7.58-1.59c-8.62,0-15.61,5.64-15.61,12.61,0,0,0,.05,0,0.08-6.46,1.78-11.12,6.71-11.12,12.52,0,7.31,7.34,13.24,16.39,13.24,4.44,0,18.37-.07,32-0.07v-45.4Z"
                />
                <path
                  class="cls-7"
                  d="M430.46,168.11a14.69,14.69,0,0,1,2,0c6.16,0,11.15,3.82,11.7,8.68a18.49,18.49,0,0,1,7.58-1.59c8.62,0,15.61,5.64,15.61,12.61,0,0,0,.05,0,0.08,6.46,1.78,11.12,6.71,11.12,12.52,0,7.31-7.34,13.24-16.39,13.24-4.44,0-18-.07-31.58-0.07V168.11Z"
                />
              </g>
              <g id="cloud-5" class="cloud-all" data-name="bush">
                <path
                  class="cls-6"
                  d="M539.43,64.77a31.69,31.69,0,0,0-3.17-.08c-8,0-14.45,4.52-15.15,10.27a25.88,25.88,0,0,0-9.82-1.88c-11.17,0-20.22,6.68-20.22,14.92,0,0,0,.06,0,0.1-8.37,2.1-14.4,7.94-14.4,14.83,0,8.65,9.51,15.67,21.23,15.67,5.75,0,23.87-.08,41.51-0.08V64.77Z"
                />
                <path
                  class="cls-7"
                  d="M538.82,64.72a20.83,20.83,0,0,1,2.57,0c8,0,14.45,4.52,15.15,10.27a25.88,25.88,0,0,1,9.82-1.88c11.17,0,20.22,6.68,20.22,14.92,0,0,0,.06,0,0.1C594.93,90.2,601,96,601,102.92c0,8.65-9.51,15.67-21.23,15.67-5.75,0-23.26-.08-40.9-0.08V64.72Z"
                />
              </g>
            </g>
          </g>

          <g id="rollercoaster" class="cls-8">
            <g id="down">
              <g id="goriz">
                <path
                  class="cls-9"
                  d="M109.29,327.06c-1.34,2.64-5,8-6.3,10.69-1.91-3.77-3.86-9.12-5.77-12.88-1.92,3.77-3.8,9.12-5.72,12.88l-5.75-11.3L80,337.75l-5.75-11.3-5.75,11.3-2.3-4.52"
                />
                <path
                  class="cls-9"
                  d="M119.81,330.26c-0.19.38-5.13,7.12-5.33,7.49-1-1.88-4.23-8.8-5.19-10.69"
                />
                <path
                  class="cls-9"
                  d="M131.7,352.81c-0.3-.59-3.72-8.6-5.6-12.31"
                />
                <path
                  class="cls-9"
                  d="M404.1,332.18l-2.82,5.57-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-4.59-9"
                />
                <path
                  class="cls-9"
                  d="M141.84,355.65c-1,1.88-3.44,6.57-4.4,8.45l-5.74-11.3L126,364.11l-5.74-11.3-5.74,11.3-5.75-11.3L103,364.11l-5.74-11.3-5.75,11.3-5.75-11.3L80,364.11l-5.75-11.3-5.75,11.3-5.75-11.3L57,364.11"
                />
                <path class="cls-9" d="M348.55,355.07l-4.58,9-1.72-3.39" />
                <path
                  class="cls-9"
                  d="M414.59,360.46l-1.85,3.64L407,352.81l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.15-10.17"
                />
                <path
                  class="cls-9"
                  d="M161.54,386.32l-1.15,2.26-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,388.58l-5.74-11.3-5.74,11.3-5.75-11.3L103,388.58l-5.74-11.3-5.75,11.3-5.75-11.3L80,388.58l-5.75-11.3-5.75,11.3-5.75-11.3L57,388.58"
                />
                <path
                  class="cls-9"
                  d="M428.1,380.93l-3.89,7.65-5.74-11.3-5.74,11.3L407,377.29l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,388.58l-5.73-11.3-5.73,11.3-5.73-11.3L321,388.58l-2.87-5.65"
                />
                <path
                  class="cls-9"
                  d="M188.52,404.77l-5.17,10.17-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,414.94l-5.74-11.3-5.74,11.3-5.75-11.3L103,414.94l-5.74-11.3-5.75,11.3-5.75-11.3L80,414.94l-5.75-11.3-5.75,11.3-5.75-11.3L57,414.94"
                />
                <path class="cls-9" d="M194.83,414.94l-5.16-10.17" />
                <path
                  class="cls-9"
                  d="M449.64,410l-2.49,4.9-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3L407,403.64l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,414.94l-5.73-11.3-5.73,11.3-5.73-11.3L321,414.94l-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-2.87-5.65"
                />
                <path class="cls-9" d="M538.9,414.94l-3-5.94" />
                <path
                  class="cls-9"
                  d="M511.14,431.78L516,441.3,521.7,430l5.73,11.3,5.73-11.3c2.11,4.14,4.35,8.21,6.46,12.35"
                />
                <path
                  class="cls-9"
                  d="M57,441.3L62.77,430l5.75,11.3L74.27,430,80,441.3,85.76,430l5.75,11.3L97.25,430,103,441.3l5.74-11.3,5.75,11.3,5.74-11.3L126,441.3,131.7,430l5.74,11.3,5.74-11.3,5.73,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3L189.1,430l5.74,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.74,11.3L235,430l5.74,11.3,5.74-11.3,5.74,11.3L258,430l5.73,11.3,5.73-11.3,5.74,11.3L280.9,430l5.74,11.3,5.73-11.3,5.73,11.3,5.73-11.3,5.73,11.3L315.3,430,321,441.3l5.74-11.3,5.73,11.3,5.73-11.3L344,441.3,349.7,430l5.73,11.3,5.73-11.3,5.73,11.3,5.73-11.3,5.74,11.3,5.74-11.3,5.73,11.3,5.73-11.3,5.73,11.3L407,430l5.74,11.3,5.74-11.3,5.74,11.3,5.73-11.3,5.73,11.3,5.73-11.3,5.73,11.3,5.74-11.3,5.74,11.3,5.74-11.3,5.73,11.3,5.73-11.3,5.73,11.3,5.73-11.3L493,441.3l5.74-11.3,5.74,11.3,4.83-9.51"
                />
                <path
                  class="cls-9"
                  d="M539.31,468.91c-1.91-3.77-4.23-8.78-6.14-12.55l-5.73,11.3-5.73-11.3L516,467.66l-5.73-11.3-5.74,11.3-5.74-11.3L493,467.66l-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3L407,456.36l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,467.66l-5.73-11.3-5.73,11.3-5.73-11.3L321,467.66l-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.73,11.3L258,456.36l-5.74,11.3-5.74-11.3-5.74,11.3L235,456.36l-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,467.66l-5.74-11.3-5.74,11.3-5.75-11.3L103,467.66l-5.74-11.3-5.75,11.3-5.75-11.3L80,467.66l-5.75-11.3-5.75,11.3-5.75-11.3L57,467.66"
                />
                <path
                  class="cls-9"
                  d="M538.9,494l-5.73-11.3L527.44,494l-5.73-11.3L516,494l-5.73-11.3L504.5,494l-5.74-11.3L493,494l-5.73-11.3L481.56,494l-5.73-11.3L470.09,494l-5.73-11.3L458.62,494l-5.74-11.3L447.15,494l-5.73-11.3L435.68,494l-5.73-11.3L424.21,494l-5.74-11.3L412.74,494,407,482.72,401.28,494l-5.73-11.3L389.82,494l-5.73-11.3L378.35,494l-5.74-11.3L366.88,494l-5.73-11.3L355.43,494l-5.73-11.3L344,494l-5.73-11.3L332.51,494l-5.73-11.3L321,494l-5.74-11.3L309.57,494l-5.73-11.3L298.1,494l-5.73-11.3L286.64,494l-5.74-11.3L275.16,494l-5.74-11.3L263.69,494,258,482.72,252.22,494l-5.74-11.3L240.74,494,235,482.72,229.27,494l-5.74-11.3L217.79,494l-5.74-11.3L206.32,494l-5.74-11.3L194.83,494l-5.74-11.3L183.36,494l-5.74-11.3L171.87,494l-5.74-11.3L160.39,494l-5.74-11.3L148.91,494l-5.73-11.3L137.44,494l-5.74-11.3L126,494l-5.74-11.3L114.48,494l-5.75-11.3L103,494l-5.74-11.3L91.5,494l-5.75-11.3L80,494l-5.75-11.3L68.52,494l-5.75-11.3L57,494"
                />
                <path
                  class="cls-9"
                  d="M538.9,518.49l-5.73-11.3-5.73,11.3-5.73-11.3L516,518.49l-5.73-11.3-5.74,11.3-5.74-11.3L493,518.49l-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3L407,507.19l-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L344,518.49l-5.73-11.3-5.73,11.3-5.73-11.3L321,518.49l-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.73,11.3L258,507.19l-5.74,11.3-5.74-11.3-5.74,11.3L235,507.19l-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3L126,518.49l-5.74-11.3-5.74,11.3-5.75-11.3L103,518.49l-5.74-11.3-5.75,11.3-5.75-11.3L80,518.49l-5.75-11.3-5.75,11.3-5.75-11.3L57,518.49"
                />
                <path
                  class="cls-9"
                  d="M539.07,542.62c-1.79-.59-4.63-8.46-5.9-11L527.44,543l-5.73-11.3L516,543l-5.73-11.3L504.5,543l-5.74-11.3L493,543l-5.73-11.3L481.56,543l-5.73-11.3L470.09,543l-5.73-11.3L458.62,543l-5.74-11.3L447.15,543l-5.73-11.3L435.68,543l-5.73-11.3L424.21,543l-5.74-11.3L412.74,543,407,531.67,401.28,543l-5.73-11.3L389.82,543l-5.73-11.3L378.35,543l-5.74-11.3L366.88,543l-5.73-11.3L355.43,543l-5.73-11.3L344,543l-5.73-11.3L332.51,543l-5.73-11.3L321,543l-5.74-11.3L309.57,543l-5.73-11.3L298.1,543l-5.73-11.3L286.64,543l-5.74-11.3L275.16,543l-5.74-11.3L263.69,543,258,531.67,252.22,543l-5.74-11.3L240.74,543,235,531.67,229.27,543l-5.74-11.3L217.79,543l-5.74-11.3L206.32,543l-5.74-11.3L194.83,543l-5.74-11.3L183.36,543l-5.74-11.3L171.87,543l-5.74-11.3L160.39,543l-5.74-11.3L148.91,543l-5.73-11.3L137.44,543l-5.74-11.3L126,543l-5.74-11.3L114.48,543l-5.75-11.3L103,543l-5.74-11.3L91.5,543l-5.75-11.3L80,543l-5.75-11.3L68.52,543l-5.75-11.3L57,543"
                />
                <path
                  class="cls-9"
                  d="M126.1,338.84c-1.34,2.64-4.44,9.45-5.78,12.09l-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-4-7.91"
                />
                <path
                  class="cls-9"
                  d="M408.94,347.29l-1.85,3.64-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,339.63l-5.73,11.3-2.87-5.65"
                />
                <path
                  class="cls-9"
                  d="M148.45,366.23c-1.34,2.64-3.84,6.54-5.18,9.18l-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-5.75-11.3"
                />
                <path class="cls-9" d="M331.09,367.08l-4.22,8.32-1.15-2.26" />
                <path
                  class="cls-9"
                  d="M421,370.63l-2.42,4.77-5.74-11.3-5.74,11.3-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,364.11l-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-4.82-9.51"
                />
                <path
                  class="cls-9"
                  d="M170.24,393.85l-4,7.91-5.74-11.3-5.74,11.3L149,390.46l-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-5.75-11.3"
                />
                <path
                  class="cls-9"
                  d="M178.86,399.5l-1.15,2.26c-1-1.88-.42-2.28-1.38-4.16"
                />
                <path
                  class="cls-9"
                  d="M294.17,398.37l-1.72,3.39c-0.38-.75.72,0,0.34-0.77"
                />
                <path
                  class="cls-9"
                  d="M434.86,392.25L430,401.76l-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,390.46l-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3L300.48,395"
                />
                <path
                  class="cls-9"
                  d="M239.32,419.8l-4.23,8.32-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3L172,416.82l-5.74,11.3-5.74-11.3-5.74,11.3L149,416.82l-5.74,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.74,11.3-5.75-11.3-5.74,11.3-5.74-11.3-5.75,11.3-5.75-11.3-5.74,11.3-5.75-11.3-5.75,11.3-5.75-11.3"
                />
                <path
                  class="cls-9"
                  d="M467.76,421.58l-3.32,6.54-5.73-11.3L453,428.12l-5.74-11.3-5.74,11.3-5.73-11.3L430,428.12l-5.73-11.3-5.73,11.3-5.74-11.3-5.74,11.3-5.74-11.3-5.72,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3L367,416.82l-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3-5.74,11.3-5.74-11.3-5.73,11.3-5.73-11.3-5.73,11.3-5.73-11.3L281,428.12l-5.74-11.3-5.74,11.3-5.74-11.3L258,428.12l-5.73-11.3-5.74,11.3-3.93-7.73"
                />
                <path class="cls-9" d="M477.47,425.05l-1.56,3.06-2.72-5.35" />
                <path class="cls-9" d="M539,416.82l-5.73,11.3-2.72-5.35" />
                <polyline
                  class="cls-9"
                  points="539.63 442.35 533.25 454.48 527.52 443.18 521.79 454.48 516.05 443.18 510.32 454.48 504.59 443.18 498.85 454.48 493.12 443.18 487.38 454.48 481.64 443.18 475.91 454.48 470.18 443.18 464.44 454.48 458.71 443.18 452.98 454.48 447.24 443.18 441.5 454.48 435.77 443.18 430.03 454.48 424.3 443.18 418.56 454.48 412.83 443.18 407.09 454.48 401.36 443.18 395.63 454.48 389.9 443.18 384.18 454.48 378.44 443.18 372.71 454.48 366.97 443.18 361.24 454.48 355.51 443.18 349.78 454.48 344.05 443.18 338.32 454.48 332.59 443.18 326.86 454.48 321.13 443.18 315.39 454.48 309.65 443.18 303.92 454.48 298.19 443.18 292.46 454.48 286.72 443.18 280.99 454.48 275.25 443.18 269.51 454.48 263.78 443.18 258.04 454.48 252.31 443.18 246.57 454.48 240.83 443.18 235.09 454.48 229.36 443.18 223.62 454.48 217.88 443.18 212.14 454.48 206.4 443.18 200.67 454.48 194.93 443.18 189.19 454.48 183.45 443.18 177.71 454.48 171.97 443.18 166.23 454.48 160.49 443.18 154.74 454.48 149 443.18 143.26 454.48 137.1 444.27 132.04 453.77 126.05 443.18 120.32 454.48 114.57 443.18 108.83 454.48 103.09 443.18 97.35 454.48 91.6 443.18 85.86 454.48 80.11 443.18 74.37 454.48 68.62 443.18 62.87 454.48 57.12 443.18"
                />
                <polyline
                  class="cls-9"
                  points="539.31 468.9 533.25 480.83 527.52 469.54 521.79 480.83 516.05 469.54 510.32 480.83 504.59 469.54 498.85 480.83 493.12 469.54 487.38 480.83 481.64 469.54 475.91 480.83 470.18 469.54 464.44 480.83 458.71 469.54 452.98 480.83 447.24 469.54 441.5 480.83 435.77 469.54 430.03 480.83 424.3 469.54 418.56 480.83 412.83 469.54 407.09 480.83 401.36 469.54 395.63 480.83 389.9 469.54 384.18 480.83 378.44 469.54 372.71 480.83 366.97 469.54 361.24 480.83 355.51 469.54 349.78 480.83 344.05 469.54 338.32 480.83 332.59 469.54 326.86 480.83 321.13 469.54 315.39 480.83 309.65 469.54 303.92 480.83 298.19 469.54 292.46 480.83 286.72 469.54 280.99 480.83 275.25 469.54 269.51 480.83 263.78 469.54 258.04 480.83 252.31 469.54 246.57 480.83 240.83 469.54 235.09 480.83 229.36 469.54 223.62 480.83 217.88 469.54 212.14 480.83 206.4 469.54 200.67 480.83 194.93 469.54 189.19 480.83 183.45 469.54 177.71 480.83 171.97 469.54 166.23 480.83 160.49 469.54 154.74 480.83 149 469.54 144.75 482.32 139.02 471.03 131.8 480.83 126.05 469.54 120.32 480.83 114.57 469.54 108.83 480.83 103.09 469.54 97.35 480.83 91.6 469.54 85.86 480.83 80.11 469.54 74.37 480.83 68.62 469.54 62.87 480.83 57.12 469.54"
                />
                <polyline
                  class="cls-9"
                  points="538.99 494.01 533.25 505.31 527.52 494.01 521.79 505.31 516.05 494.01 510.32 505.31 504.59 494.01 498.85 505.31 493.12 494.01 487.38 505.31 481.64 494.01 475.91 505.31 470.18 494.01 464.44 505.31 458.71 494.01 452.98 505.31 447.24 494.01 441.5 505.31 435.77 494.01 430.03 505.31 424.3 494.01 418.56 505.31 412.83 494.01 407.09 505.31 401.36 494.01 395.63 505.31 389.9 494.01 384.18 505.31 378.44 494.01 372.71 505.31 366.97 494.01 361.24 505.31 355.51 494.01 349.78 505.31 344.05 494.01 338.32 505.31 332.59 494.01 326.86 505.31 321.13 494.01 315.39 505.31 309.65 494.01 303.92 505.31 298.19 494.01 292.46 505.31 286.72 494.01 280.99 505.31 275.25 494.01 269.51 505.31 263.78 494.01 258.04 505.31 252.31 494.01 246.57 505.31 240.83 494.01 235.09 505.31 229.36 494.01 223.62 505.31 217.88 494.01 212.14 505.31 206.4 494.01 200.67 505.31 194.93 494.01 189.19 505.31 183.45 494.01 177.71 505.31 171.97 494.01 166.23 505.31 160.49 494.01 154.74 505.31 149 494.01 143.26 505.31 137.53 494.01 131.8 505.31 126.05 494.01 120.32 505.31 114.57 494.01 108.83 505.31 103.09 494.01 97.35 505.31 91.6 494.01 85.86 505.31 80.11 494.01 74.37 505.31 68.62 494.01 62.87 505.31 57.12 494.01"
                />
                <polyline
                  class="cls-9"
                  points="538.99 518.49 533.25 529.78 527.52 518.49 521.79 529.78 516.05 518.49 510.32 529.78 504.59 518.49 498.85 529.78 493.12 518.49 487.38 529.78 481.64 518.49 475.91 529.78 470.18 518.49 464.44 529.78 458.71 518.49 452.98 529.78 447.24 518.49 441.5 529.78 435.77 518.49 430.03 529.78 424.3 518.49 418.56 529.78 412.83 518.49 407.09 529.78 401.36 518.49 395.63 529.78 389.9 518.49 384.18 529.78 378.44 518.49 372.71 529.78 366.97 518.49 361.24 529.78 355.51 518.49 349.78 529.78 344.05 518.49 338.32 529.78 332.59 518.49 326.86 529.78 321.13 518.49 315.39 529.78 309.65 518.49 303.92 529.78 298.19 518.49 292.46 529.78 286.72 518.49 280.99 529.78 275.25 518.49 269.51 529.78 263.78 518.49 258.04 529.78 252.31 518.49 246.57 529.78 240.83 518.49 235.09 529.78 229.36 518.49 223.62 529.78 217.88 518.49 212.14 529.78 206.4 518.49 200.67 529.78 194.93 518.49 189.19 529.78 183.45 518.49 177.71 529.78 171.97 518.49 166.23 529.78 160.49 518.49 154.74 529.78 149 518.49 143.26 529.78 137.53 518.49 131.8 529.78 126.05 518.49 120.32 529.78 114.57 518.49 108.83 529.78 103.09 518.49 97.35 529.78 91.6 518.49 85.86 529.78 80.11 518.49 74.37 529.78 68.62 518.49 62.87 529.78 57.12 518.49"
                />
                <path
                  class="cls-9"
                  d="M135.54,347.16c-0.19.38-3.65,5.27-3.84,5.65"
                />
              </g>
              <g id="vert">
                <path
                  class="cls-10"
                  d="M539,542.38c0.86-44.56.86-91.63,0-136.11"
                />
                <path
                  class="cls-10"
                  d="M527.69,541.13c0.86-41.43.86-137.81,0-178.4"
                />
                <path
                  class="cls-10"
                  d="M516.39,542.38c0.86-40.8.86-152.64,0-194"
                />
                <path
                  class="cls-10"
                  d="M504.6,542.81c-0.53-39.34-.53-166,0-206"
                />
                <path
                  class="cls-10"
                  d="M492.89,542.38c-0.12-40.8-.12-171.12,0-211.95"
                />
                <path
                  class="cls-10"
                  d="M481.65,542.23c-0.17-40.64-.17-176.22,0-217.33"
                />
                <path
                  class="cls-10"
                  d="M470.23,542.38c-0.05-42.68-.74-174.42-0.39-218"
                />
                <path
                  class="cls-10"
                  d="M458.5,542.38c0.39-44.56.39-167.66,0-213.68"
                />
                <path
                  class="cls-10"
                  d="M447.2,542.38c0.39-46.45.39-156.52,0-203"
                />
                <path
                  class="cls-10"
                  d="M435.9,542.38c0.39-50.21.39-134.52,0-186"
                />
                <path
                  class="cls-10"
                  d="M424.14,542.41c0.86-55.89.86-110.49,0-167.28"
                />
                <path
                  class="cls-10"
                  d="M412.84,542.38c0.86-61.51.86-123.64,0-186"
                />
                <line
                  class="cls-10"
                  x1="401.55"
                  y1="542.38"
                  x2="401.55"
                  y2="328.7"
                />
                <line
                  class="cls-10"
                  x1="378.96"
                  y1="541.96"
                  x2="378.96"
                  y2="324.9"
                />
                <path
                  class="cls-10"
                  d="M366.92,542.38c-0.29-69-.29-136.82,0-205.92"
                />
                <path
                  class="cls-10"
                  d="M355.54,543.15c-0.2-66-.2-130.06,0-195.36"
                />
                <line
                  class="cls-10"
                  x1="344.05"
                  y1="542.38"
                  x2="344.05"
                  y2="358.46"
                />
                <line
                  class="cls-10"
                  x1="332.67"
                  y1="542.29"
                  x2="332.67"
                  y2="368.11"
                />
                <path
                  class="cls-10"
                  d="M321.19,542.2c0.24-54.34.27-135.2,0.09-189.54"
                />
                <path
                  class="cls-10"
                  d="M309.8,542.38c0.27-50.92.41-158.24,0.27-209.15"
                />
                <path
                  class="cls-10"
                  d="M298,542.38c0.86-48.33.86-180.2,0-228.87"
                />
                <path
                  class="cls-10"
                  d="M286.7,542.38c0.86-46.45.86-209.45,0-257.12"
                />
                <path
                  class="cls-10"
                  d="M275.41,542.38c0.86-46.45.86-238.36,0-284.52"
                />
                <path
                  class="cls-10"
                  d="M264.11,542.38c0.86-44.56,1.5-251.51.64-297.26"
                />
                <path
                  class="cls-10"
                  d="M252.21,542.38c-0.42-44.56-.42-256.92,0-302.44"
                />
                <path
                  class="cls-10"
                  d="M240.72,542.38c-0.23-42.68-.23-256.63,0-300.56"
                />
                <path
                  class="cls-10"
                  d="M229.34,542.57c-0.14-42.87-.14-250.52,0-294.63"
                />
                <path
                  class="cls-10"
                  d="M217.34,542.57c-0.14-42.87-.14-240.59,0-284.7"
                />
                <path
                  class="cls-10"
                  d="M206.47,542.38c0.14-44.56.14-230.32,0-275.65"
                />
                <path
                  class="cls-10"
                  d="M194.71,542.38c0.6-44.56.6-215.73,0-261"
                />
                <path
                  class="cls-10"
                  d="M183.41,542.38c0.6-46.45.6-190.82,0-238.39"
                />
                <path
                  class="cls-10"
                  d="M172.12,542.38c0.6-48.33.6-166.64,0-215.32"
                />
                <path
                  class="cls-10"
                  d="M390.25,542.38c-0.25-73.59-.25-148.59,0-223.07"
                />
                <path class="cls-10" d="M160.82,542.38q0.9-78.14,0-158.24" />
                <path
                  class="cls-10"
                  d="M149.06,542.38c-0.82-57.74-.82-118.38,0-177.27"
                />
                <path
                  class="cls-10"
                  d="M137.58,542.38c-0.63-61.51-.63-127.57,0-190.22"
                />
                <path
                  class="cls-10"
                  d="M126.1,542.29c-0.45-65.18-.45-138.33,0-203.45"
                />
                <path
                  class="cls-10"
                  d="M114.52,542.38c-0.17-69-.17-141.59,0-212.13"
                />
                <path
                  class="cls-10"
                  d="M103.13,542.38c-0.08-72.8-.08-146.23,0-218.78"
                />
                <line
                  class="cls-10"
                  x1="91.16"
                  y1="542.38"
                  x2="91.16"
                  y2="322.65"
                />
                <line
                  class="cls-10"
                  x1="79.86"
                  y1="542.38"
                  x2="79.86"
                  y2="325.73"
                />
                <line
                  class="cls-10"
                  x1="68.57"
                  y1="542.38"
                  x2="68.57"
                  y2="332.43"
                />
                <line
                  class="cls-10"
                  x1="57.27"
                  y1="542.38"
                  x2="57.27"
                  y2="347.1"
                />
              </g>
            </g>
            <g id="up">
              <path
                class="cls-11"
                d="M538,418.2c5.09-11.15,3.08-24.91-.56-36.21C533,368.14,523.72,355.6,514,345c-9.92-10.79-22.49-22-37.57-24-11.88-1.58-20.6,2.8-28.18,11.76C437,346.05,430.51,362.22,423.69,378c-1.26,2.92,3,5.47,4.32,2.52,6.71-15.55,13-31.81,24.2-44.79,4.21-4.88,8.95-9.14,15.58-10,8.45-1.07,16.77,1.89,23.81,6.36,15,9.51,27.21,24.58,36,39.84,6.86,11.89,12.13,30.53,6.11,43.7-1.33,2.91,3,5.45,4.32,2.52h0Z"
              />
              <path
                class="cls-11"
                d="M332.58,368.09c-16.09-24.84-30.91-51-42.14-78.46-3.48-8.5-6.53-17.17-10-25.68-2.28-5.6-4.73-11.35-8.77-15.93-10-11.4-26.61-8.89-38.86-2.82-21.65,10.72-38.9,31.44-50.53,52.16A138.64,138.64,0,0,0,167,341.1c-2.2,12.26-2.54,26.4-10.09,36.83-1.89,2.61,2.45,5.11,4.32,2.52,6-8.27,7.46-18.59,9-28.43,2.16-13.6,4.77-27,10.25-39.76C190,290.07,206,268.8,226,255c11.2-7.69,29.85-16.15,41.34-4.35,3.9,4,6.19,9.49,8.27,14.59,3.23,7.9,6.06,16,9.26,23.86,11.53,28.5,26.69,55.69,43.38,81.47,1.74,2.69,6.07.19,4.32-2.52h0Z"
              />
              <path
                class="cls-12"
                d="M60.05,349.52c1.11-10.23,10.09-17.49,18.82-21.63,10.1-4.78,21.76-5.31,31.84-.19,9.62,4.89,17.54,13.23,24.57,21.25a158.09,158.09,0,0,1,18.93,26.93c9.24,16.34,22.38,27.38,39.42,35.17,16.64,7.6,37,14.72,55.39,11.23,21.32-4,38.82-19.23,56.45-30.86,18.67-12.31,35.19-27.77,51.56-42.93,8.42-7.79,16.53-16.14,25.28-23.56,12.27-10.4,19.31,6.34,23,15.68,7.49,18.8,13.78,38.18,25.87,54.68,12.75,17.4,30.74,29.36,51.39,35.44,19.19,5.65,45.88,7.43,56.48-13.43,1.45-2.86-2.86-5.4-4.32-2.52-11,21.74-41.59,15.29-59.84,8-19.94-8-35.37-21.83-46.11-40.34-5.19-8.94-9.13-18.45-12.81-28.09-3.57-9.35-6.57-19.62-11.8-28.22-4.2-6.9-11.39-11.74-19.64-8.54-3.8,1.47-6.69,4.63-9.58,7.36-4.33,4.1-8.73,8.12-13.09,12.18-17.46,16.26-34.85,33.33-54.51,47-9.12,6.32-18.46,12.33-27.6,18.63-9.47,6.53-19.86,12.23-31.23,14.55s-22.84-.41-33.85-3.69a134,134,0,0,1-26.6-10.92c-8.17-4.52-15.87-10.14-21.86-17.37-6.75-8.15-10.6-18.3-16.78-26.86-12-16.63-29.59-37.83-51.61-39.38C80,317.83,57.13,330.27,55.05,349.52c-0.35,3.19,4.66,3.17,5,0h0Z"
              />
            </g>
          </g>

          <g id="ground">
            <path
              id="ground2"
              class="cls-13"
              d="M1248.58,572.43V555.74a12,12,0,0,0-12-12H59.63a12,12,0,0,0-12,12v16.69h1201Z"
            />
            <path
              id="ground1"
              class="cls-14"
              d="M1248.58,571.74v16.69a12,12,0,0,1-12,12H59.63a12,12,0,0,1-12-12V571.74h1201Z"
            />
          </g>

          <g id="bush-7" data-name="bush">
            <path
              class="cls-13"
              d="M1004.25,491.46a10.82,10.82,0,0,0-12.65,9.78,14.43,14.43,0,0,0-21.45,12.62s0,0.06,0,.09A15.16,15.16,0,0,0,975,543.46h29.22v-52Z"
            />
            <path
              class="cls-14"
              d="M1004.28,491.46a10.82,10.82,0,0,1,12.65,9.78,14.43,14.43,0,0,1,21.45,12.62s0,0.06,0,.09a15.16,15.16,0,0,1-4.88,29.51h-29.22v-52Z"
            />
          </g>

          <g id="bush-8" data-name="bush">
            <path
              class="cls-13"
              d="M1194.25,482.05a11.26,11.26,0,0,0-2-.2c-6.36,0-11.52,5.29-12.08,12a15.1,15.1,0,0,0-7.83-2.2c-8.9,0-16.12,7.81-16.12,17.45,0,0,0,.07,0,0.11-6.67,2.46-11.48,9.29-11.48,17.34,0,10.12,7.58,18.33,16.93,18.33h32.62V482.05Z"
            />
            <path
              class="cls-14"
              d="M1194.07,482.05a11.26,11.26,0,0,1,2-.2c6.36,0,11.52,5.29,12.08,12a15.1,15.1,0,0,1,7.83-2.2c8.9,0,16.12,7.81,16.12,17.45,0,0,0,.07,0,0.11,6.67,2.46,11.48,9.29,11.48,17.34,0,10.12-7.58,18.33-16.93,18.33h-32.62V482.05Z"
            />
          </g>

          <g id="tree2">
            <path
              class="cls-18"
              d="M1223.27,343.52c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.52Z"
            />
            <path
              class="cls-18"
              d="M1223.27,343.52c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.52Z"
            />
            <path
              class="cls-19"
              d="M1223.27,343.52c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.52Z"
            />
            <path
              class="cls-19"
              d="M1223.27,343.52c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.52Z"
            />
            <polygon
              class="cls-20"
              points="1210.74 544.47 1219.48 498.94 1210.74 472.75 1219.48 485.67 1219.48 452.67 1201.32 408.25 1219.48 438.28 1219.48 392.23 1224.22 438.28 1244.71 423.27 1228.57 444.54 1229.58 472.75 1244.71 452.67 1234.62 476.45 1233.61 507.58 1240.67 544.47 1210.74 544.47"
            />
            <polygon
              class="cls-20"
              points="1210.74 544.47 1219.48 498.94 1210.74 472.75 1219.48 485.67 1219.48 452.67 1201.32 408.25 1219.48 438.28 1219.48 392.23 1224.22 438.28 1244.71 423.27 1228.57 444.54 1229.58 472.75 1244.71 452.67 1234.62 476.45 1233.61 507.58 1240.67 544.47 1210.74 544.47"
            />
          </g>

          <g id="tree2-2" data-name="tree2">
            <path
              class="cls-18"
              d="M91.48,343.29c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.29Z"
            />
            <path
              class="cls-18"
              d="M91.48,343.29c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.29Z"
            />
            <path
              class="cls-19"
              d="M91.16,342.83c33.86,0,61.3,34.79,61.3,77.71S125,498.24,91.16,498.24V342.83Z"
            />
            <path
              class="cls-19"
              d="M91.16,342.83c33.86,0,61.3,34.79,61.3,77.71S125,498.24,91.16,498.24V342.83Z"
            />
            <polygon
              class="cls-20"
              points="78.88 543.79 87.63 498.26 78.88 472.07 87.63 484.98 87.63 451.98 69.47 407.56 87.63 437.6 87.63 391.55 92.36 437.6 112.86 422.58 96.71 443.86 97.72 472.07 112.86 451.98 102.77 475.77 101.76 506.89 108.82 543.79 78.88 543.79"
            />
            <polygon
              class="cls-20"
              points="78.88 543.79 87.63 498.26 78.88 472.07 87.63 484.98 87.63 451.98 69.47 407.56 87.63 437.6 87.63 391.55 92.36 437.6 112.86 422.58 96.71 443.86 97.72 472.07 112.86 451.98 102.77 475.77 101.76 506.89 108.82 543.79 78.88 543.79"
            />
          </g>

          <g id="tree2-3" data-name="tree2">
            <path
              class="cls-18"
              d="M1010.88,343.09c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.09Z"
            />
            <path
              class="cls-18"
              d="M1010.88,343.09c-33.86,0-61.3,34.79-61.3,77.71s27.45,77.71,61.3,77.71V343.09Z"
            />
            <path
              class="cls-19"
              d="M1010.88,343.09c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.09Z"
            />
            <path
              class="cls-19"
              d="M1010.88,343.09c33.86,0,61.3,34.79,61.3,77.71s-27.45,77.71-61.3,77.71V343.09Z"
            />
            <polygon
              class="cls-20"
              points="998.35 544.04 1007.1 498.51 998.35 472.32 1007.1 485.24 1007.1 452.24 988.94 407.82 1007.1 437.86 1007.1 391.8 1011.83 437.86 1032.33 422.84 1016.18 444.12 1017.19 472.32 1032.33 452.24 1022.24 476.02 1021.23 507.15 1028.29 544.04 998.35 544.04"
            />
            <polygon
              class="cls-20"
              points="998.35 544.04 1007.1 498.51 998.35 472.32 1007.1 485.24 1007.1 452.24 988.94 407.82 1007.1 437.86 1007.1 391.8 1011.83 437.86 1032.33 422.84 1016.18 444.12 1017.19 472.32 1032.33 452.24 1022.24 476.02 1021.23 507.15 1028.29 544.04 998.35 544.04"
            />
          </g>

          <g id="bush-9" data-name="bush">
            <path
              class="cls-13"
              d="M808.91,501.82a8.64,8.64,0,0,0-1.47-.13,8.8,8.8,0,0,0-8.71,8.11,11.43,11.43,0,0,0-5.64-1.49,11.7,11.7,0,0,0-11.62,11.78s0,0,0,.08a12.39,12.39,0,0,0,3.93,24.08h23.51V501.82Z"
            />
            <path
              class="cls-14"
              d="M808.91,501.82a8.64,8.64,0,0,1,1.47-.13,8.8,8.8,0,0,1,8.71,8.11,11.43,11.43,0,0,1,5.64-1.49,11.7,11.7,0,0,1,11.62,11.78s0,0,0,.08a12.39,12.39,0,0,1-3.93,24.08H808.91V501.82Z"
            />
          </g>

          <g id="tree1">
            <path
              class="cls-21"
              d="M839.47,359.72V501.07A70.67,70.67,0,1,0,839.47,359.72Z"
            />
            <path
              class="cls-21"
              d="M839.47,359.72V501.07A70.67,70.67,0,1,0,839.47,359.72Z"
            />
            <path
              class="cls-13"
              d="M839.68,359.72V501.07A70.67,70.67,0,0,1,839.68,359.72Z"
            />
            <path
              class="cls-13"
              d="M839.68,359.72V501.07A70.67,70.67,0,0,1,839.68,359.72Z"
            />
            <polygon
              class="cls-20"
              points="827.4 544.36 836.15 498.83 827.4 472.64 836.15 485.56 836.15 452.56 817.99 408.14 836.15 438.17 836.15 392.12 840.88 438.17 861.38 423.16 845.23 444.44 846.24 472.64 861.38 452.56 851.29 476.34 850.28 507.47 857.34 544.36 827.4 544.36"
            />
            <polygon
              class="cls-20"
              points="827.4 544.36 836.15 498.83 827.4 472.64 836.15 485.56 836.15 452.56 817.99 408.14 836.15 438.17 836.15 392.12 840.88 438.17 861.38 423.16 845.23 444.44 846.24 472.64 861.38 452.56 851.29 476.34 850.28 507.47 857.34 544.36 827.4 544.36"
            />
          </g>

          <g id="tree1-2" data-name="tree1">
            <path
              class="cls-21"
              d="M236,360.65V502A70.67,70.67,0,1,0,236,360.65Z"
            />
            <path
              class="cls-21"
              d="M236,360.65V502A70.67,70.67,0,1,0,236,360.65Z"
            />
            <path
              class="cls-13"
              d="M236.18,360.65V502A70.67,70.67,0,0,1,236.18,360.65Z"
            />
            <path
              class="cls-13"
              d="M236.18,360.65V502A70.67,70.67,0,0,1,236.18,360.65Z"
            />
            <polygon
              class="cls-20"
              points="223.9 544.29 232.65 498.76 223.9 472.57 232.65 485.49 232.65 452.49 214.48 408.07 232.65 438.1 232.65 392.05 237.38 438.1 257.87 423.09 241.73 444.37 242.74 472.57 257.87 452.49 247.78 476.27 246.78 507.4 253.84 544.29 223.9 544.29"
            />
            <polygon
              class="cls-20"
              points="223.9 544.29 232.65 498.76 223.9 472.57 232.65 485.49 232.65 452.49 214.48 408.07 232.65 438.1 232.65 392.05 237.38 438.1 257.87 423.09 241.73 444.37 242.74 472.57 257.87 452.49 247.78 476.27 246.78 507.4 253.84 544.29 223.9 544.29"
            />
          </g>

          <g id="tree1-3" data-name="tree1">
            <path
              class="cls-21"
              d="M480.65,358.65V500A70.67,70.67,0,1,0,480.65,358.65Z"
            />
            <path
              class="cls-21"
              d="M480.65,358.65V500A70.67,70.67,0,1,0,480.65,358.65Z"
            />
            <path
              class="cls-13"
              d="M480.86,358.65V500A70.67,70.67,0,0,1,480.86,358.65Z"
            />
            <path
              class="cls-13"
              d="M480.86,358.65V500A70.67,70.67,0,0,1,480.86,358.65Z"
            />
            <polygon
              class="cls-20"
              points="468.58 542.29 477.33 496.76 468.58 470.57 477.33 483.49 477.33 450.49 459.17 406.07 477.33 436.1 477.33 390.05 482.06 436.1 502.56 421.09 486.41 442.37 487.42 470.57 502.56 450.49 492.47 474.27 491.46 505.4 498.52 542.29 468.58 542.29"
            />
            <polygon
              class="cls-20"
              points="468.58 542.29 477.33 496.76 468.58 470.57 477.33 483.49 477.33 450.49 459.17 406.07 477.33 436.1 477.33 390.05 482.06 436.1 502.56 421.09 486.41 442.37 487.42 470.57 502.56 450.49 492.47 474.27 491.46 505.4 498.52 542.29 468.58 542.29"
            />
          </g>

          <g id="bush-10" data-name="bush">
            <path
              class="cls-13"
              d="M447.92,500.42a7.39,7.39,0,0,0-1.39-.14c-4.31,0-7.8,3.7-8.18,8.41a10,10,0,0,0-5.3-1.54c-6,0-10.92,5.47-10.92,12.22,0,0,0,.05,0,0.08-4.52,1.72-7.78,6.5-7.78,12.14,0,7.09,5.14,12.83,11.47,12.83h22.1v-44Z"
            />
            <path
              class="cls-14"
              d="M447.92,500.46a7.39,7.39,0,0,1,1.39-.14c4.31,0,7.8,3.7,8.18,8.41a10,10,0,0,1,5.3-1.54c6,0,10.92,5.47,10.92,12.22,0,0,0,.05,0,0.08,4.52,1.72,7.78,6.5,7.78,12.14,0,7.09-5.14,12.83-11.47,12.83h-22.1v-44Z"
            />
          </g>

          <g>
            <path
              class="cls-22"
              d="M77.93,469c-0.08-.25-0.15-0.5-0.22-0.75l0-.18c0,0.32.11,0.63,0.18,0.93h0Z"
            />
            <path
              class="cls-22"
              d="M77.72,468.29c0.06,0.25.13,0.51,0.22,0.75-0.07-.31-0.13-0.61-0.18-0.93C77.74,468.17,77.73,468.23,77.72,468.29Z"
            />
            <path
              class="cls-22"
              d="M94.72,469c-0.08-.25-0.15-0.5-0.22-0.75l0-.18c0,0.32.11,0.63,0.18,0.93h0Z"
            />
            <path
              class="cls-22"
              d="M94.5,468.29c0.06,0.26.14,0.51,0.22,0.75-0.07-.31-0.13-0.62-0.18-0.93C94.53,468.17,94.51,468.23,94.5,468.29Z"
            />
            <path
              class="cls-22"
              d="M111.5,469c-0.08-.25-0.15-0.5-0.22-0.75l0-.18c0,0.32.11,0.63,0.18,0.93h0Z"
            />
            <path
              class="cls-22"
              d="M111.29,468.29c0.06,0.26.14,0.51,0.22,0.75-0.07-.31-0.13-0.62-0.18-0.93C111.31,468.17,111.3,468.23,111.29,468.29Z"
            />
            <path
              class="cls-23"
              d="M57.65,543.88a6.46,6.46,0,0,1-6.55-8.19l9.73-69.36v0.27c0.17,5.54,3.91,9.82,8.52,9.82,4.06,0,7.57-3.42,8.36-8.13,0.06,0.25.13,0.51,0.22,0.75,1,4.34,4.4,7.38,8.21,7.38,4.06,0,7.57-3.42,8.36-8.13,0.06,0.25.13,0.51,0.22,0.75,1,4.34,4.4,7.38,8.21,7.38,4.06,0,7.57-3.42,8.36-8.13,0.06,0.25.13,0.51,0.22,0.75,1,4.34,4.4,7.38,8.21,7.38,4.14,0,7.67-3.49,8.39-8.31,0-.05,0-0.29,0-0.54v-0.05c0,0.14,0,.23,0,0.31l0.05,13.07c-6.1,0-10.87,15.15-13.17,24.18-3.24,12.72-5.5,28.67-5.5,38.79H57.65Z"
            />
            <path
              class="cls-23"
              d="M128.21,480.9l-0.05-13.07c0-.09,0-0.19,0-0.35v-0.07c0,0.31,0,.63,0,0.7-0.71,4.72-4.2,8.31-8.39,8.31-3.91,0-7.21-3.12-8.21-7.38-0.08-.25-0.15-0.5-0.22-0.75-0.77,4.63-4.22,8.13-8.36,8.13-3.91,0-7.21-3.12-8.21-7.38-0.08-.25-0.15-0.5-0.22-0.75-0.77,4.63-4.22,8.13-8.36,8.13-3.91,0-7.21-3.12-8.21-7.38-0.08-.25-0.15-0.5-0.22-0.75-0.77,4.63-4.22,8.13-8.36,8.13-4.61,0-8.35-4.32-8.51-9.73,0,0,0-.06,0-0.09s0-.18,0-0.27L51.1,535.69a6.46,6.46,0,0,0,6.55,8.19h51.89C109.54,524.4,118,480.9,128.21,480.9Z"
            />
            <path
              class="cls-24"
              d="M146.88,543.88c0-20.74-8.64-63-18.67-63l-0.05-13.07a1.53,1.53,0,0,0,.06.33c0.72,4.79,4.27,8.26,8.43,8.26s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31,4.74,0,8.57-4.14,8.73-9.42v-0.57l9.32,69.27a6.46,6.46,0,0,1-6.55,8.19H146.88Z"
            />
            <path
              class="cls-24"
              d="M205.06,535.69l-9.32-69.36c0,0.09,0,.24,0,0.33s0,0.31,0,.34c-0.16,5.4-4.13,9.42-8.73,9.42-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.72-3.54-8.43-8.26a1.52,1.52,0,0,1-.06-0.33l0.05,13.07c10.22,0,18.67,43.5,18.67,63h51.62A6.46,6.46,0,0,0,205.06,535.69Z"
            />
            <polygon
              class="cls-23"
              points="60.83 466.33 128.16 415.25 128.15 466.33 60.83 466.33"
            />
            <polygon
              class="cls-23"
              points="61.2 466.33 77.62 466.33 77.89 466.33 94.4 466.33 94.67 466.33 111.19 466.33 111.46 466.33 127.97 466.33 128.15 466.33 128.15 466.33 128.21 415.21 128.16 415.25 60.83 466.33 60.96 466.33 61.2 466.33"
            />
            <path
              class="cls-24"
              d="M128.15,466.33l0.06-51.12-0.06-.08c0-1.54,0-1.82.08-6.67,0.51,0.17,1.07.39,1.65,0.62a16.8,16.8,0,0,0,5.33,1.46,18.58,18.58,0,0,0,5.54-1.28c0.91-.31,1.77-0.59,2.58-0.8l-3.75,4.13,4.69,1.68a8.07,8.07,0,0,1-5.72,2,31.39,31.39,0,0,1-6.2-.88c-1.38-.29-2.69-0.56-4-0.71l0,0.66,67.37,51H128.15Z"
            />
            <path
              class="cls-24"
              d="M128.15,466.33h67.58l-67.37-51,0-.66c5.31,0.63,11.66,3.46,15.88-.4l-4.69-1.68,3.75-4.13c-2.51.64-5.56,2.08-8.12,2.08-2.1,0-4.95-1.38-7-2.08-0.13,5-.12,5.13-0.07,6.8l0.05,0Z"
            />
            <path
              class="cls-25"
              d="M119.71,476.42c-3.81,0-7.19-3-8.21-7.38-0.07-.3-0.13-0.62-0.18-0.93l0,0.18c-0.79,4.71-4.3,8.13-8.36,8.13-3.81,0-7.19-3-8.21-7.38-0.07-.3-0.13-0.62-0.18-0.93l0,0.18c-0.79,4.71-4.3,8.13-8.36,8.13-3.81,0-7.19-3-8.21-7.38-0.07-.31-0.13-0.62-0.18-0.93l0,0.18c-0.79,4.71-4.3,8.13-8.36,8.13-4.61,0-8.35-4.27-8.51-9.73l0-.36h67.32v1.24c0,0.25,0,.49,0,0.54C127.38,472.92,123.85,476.42,119.71,476.42Z"
            />
            <path
              class="cls-25"
              d="M60.85,466.69c0.16,5.4,3.91,9.73,8.51,9.73,4.14,0,7.59-3.5,8.36-8.13,0-.06,0-0.12,0-0.18,0,0.32.11,0.63,0.18,0.93,1,4.25,4.3,7.38,8.21,7.38,4.14,0,7.59-3.5,8.36-8.13,0-.06,0-0.12,0-0.18,0,0.32.11,0.63,0.18,0.93,1,4.25,4.3,7.38,8.21,7.38,4.14,0,7.59-3.5,8.36-8.13,0-.06,0-0.12,0-0.18,0,0.32.11,0.63,0.18,0.93,1,4.25,4.3,7.38,8.21,7.38,4.2,0,7.68-3.59,8.39-8.31,0-.06,0-0.39,0-0.7v-1.08H60.83c0,0.09,0,.18,0,0.27S60.85,466.66,60.85,466.69Z"
            />
            <path
              class="cls-26"
              d="M187,476.42c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.71-3.47-8.43-8.26a1.53,1.53,0,0,1-.06-0.33c0-.08,0-0.17,0-0.31v-1.19h67.58V467C195.58,472.28,191.74,476.42,187,476.42Z"
            />
            <path
              class="cls-26"
              d="M178.75,466.33H128.15v1.15c0,0.16,0,.26,0,0.35a1.52,1.52,0,0,0,.06.33c0.71,4.72,4.24,8.26,8.43,8.26s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31,4.61,0,8.57-4,8.73-9.42,0,0,0-.31,0-0.34s0-.24,0-0.33h-17Z"
            />
            <path
              class="cls-25"
              d="M109.54,543.88c0-20.74,8.64-63,18.67-63v63H109.54Z"
            />
            <path
              class="cls-25"
              d="M128.21,480.9c-10.22,0-18.67,43.5-18.67,63h18.67v-63Z"
            />
            <path
              class="cls-26"
              d="M128.21,543.88v-63c6.1,0,10.87,15.15,13.17,24.18,3.24,12.72,5.5,28.67,5.5,38.79H128.21Z"
            />
            <path
              class="cls-26"
              d="M128.21,480.9v63h18.67C146.88,524.4,138.44,480.9,128.21,480.9Z"
            />
          </g>

          <g>
            <polygon
              class="cls-22"
              points="321.59 476.19 321.59 476.19 321.58 476.19 321.59 476.19"
            />
            <polygon
              class="cls-22"
              points="321.59 476.19 321.59 476.19 321.58 476.19 321.59 476.19"
            />
            <path
              class="cls-22"
              d="M371.95,476.19c-4.14,0-7.67-3.49-8.39-8.31,0.73,4.81,4.25,8.31,8.39,8.31h0Z"
            />
            <path
              class="cls-22"
              d="M363.56,467.88h0c0.71,4.72,4.2,8.31,8.39,8.31h0C367.75,476.19,364.27,472.6,363.56,467.88Z"
            />
            <path
              class="cls-22"
              d="M355.16,476.19a7.53,7.53,0,0,1-4.22-1.32,10.17,10.17,0,0,1-4.17-7,10.18,10.18,0,0,0,4.17,7h0a7.53,7.53,0,0,0,4.22,1.32h0Z"
            />
            <path
              class="cls-22"
              d="M346.77,467.88h0c0.71,4.72,4.2,8.31,8.39,8.31h0C351,476.19,347.48,472.6,346.77,467.88Z"
            />
            <path
              class="cls-23"
              d="M242.8,544.58a6.46,6.46,0,0,1-6.55-8.19l9.47-70.29v0.27c0.17,5.54,3.91,9.82,8.52,9.82,4.14,0,7.67-3.49,8.39-8.31,0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31a1.55,1.55,0,0,0,0-.18l0.06,13.89c-6.1,0-10.87,15.15-13.17,24.18-3.24,12.72-5.5,28.67-5.5,38.79H242.8Z"
            />
            <path
              class="cls-23"
              d="M313.1,481.6L313,467.71a1.61,1.61,0,0,1,0,.18c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31-4.61,0-8.35-4.32-8.51-9.73,0,0,0-.06,0-0.09s0-.18,0-0.27l-9.47,70.29a6.46,6.46,0,0,0,6.55,8.19h51.62C294.43,525.1,302.87,481.6,313.1,481.6Z"
            />
            <path
              class="cls-24"
              d="M331.77,544.58c0-20.74-8.64-63-18.67-63L313,467.71l0-.15,0,0.12c0,0.06,0,.14.06,0.26,0.72,4.78,4.26,8.25,8.42,8.26s7.68-3.49,8.4-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.25,8.31,8.39,8.31,4.61,0,8.35-4.27,8.51-9.73l9.48,69.93a6.46,6.46,0,0,1-6.55,8.19H331.77Z"
            />
            <path
              class="cls-24"
              d="M389.94,536.39l-9.42-69.93h-0.06c-0.16,5.4-3.91,9.73-8.51,9.73h0c-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31h0c-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31h0c-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31h0c-4.19,0-7.71-3.54-8.42-8.26,0-.19-0.07-0.29-0.08-0.38l0,0.15,0.06,13.89c10.22,0,18.67,43.5,18.67,63h51.62A6.46,6.46,0,0,0,389.94,536.39Z"
            />
            <polygon
              class="cls-23"
              points="245.72 466.1 313.05 415.03 313.1 466.1 245.72 466.1"
            />
            <polygon
              class="cls-23"
              points="262.5 466.1 262.67 466.1 262.77 466.1 262.94 466.1 279.29 466.1 279.46 466.1 279.56 466.1 279.73 466.1 296.07 466.1 296.24 466.1 296.34 466.1 296.51 466.1 312.86 466.1 313.03 466.1 313.1 466.1 313.1 414.99 313.05 415.03 245.72 466.1 246.09 466.1 262.5 466.1"
            />
            <path
              class="cls-24"
              d="M313.17,466.1L313.1,415a64.37,64.37,0,0,1,0-6.76c0.51,0.17,1.06.39,1.64,0.62a16.82,16.82,0,0,0,5.33,1.46,18.58,18.58,0,0,0,5.54-1.28c0.91-.31,1.77-0.59,2.58-0.8l-3.75,4.13,4.69,1.68a8.07,8.07,0,0,1-5.72,2,31.37,31.37,0,0,1-6.2-.88c-1.32-.28-2.69-0.56-4-0.71l0,0.66,66.85,51H313.17Z"
            />
            <path
              class="cls-24"
              d="M313.1,466.1h67l-66.85-51,0-.66c5.31,0.63,11.66,3.46,15.88-.4l-4.69-1.68,3.75-4.13c-2.51.64-5.56,2.08-8.12,2.08-2.1,0-4.95-1.38-7-2.08-0.13,5-.12,5.13-0.07,6.8l0.05,0V466.1Z"
            />
            <path
              class="cls-25"
              d="M304.6,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31-4.61,0-8.35-4.27-8.51-9.73l0-.36H313l0.07,1.16a0.66,0.66,0,0,0,0,.3l0,0.15a1.55,1.55,0,0,1,0,.18C312.27,472.7,308.74,476.19,304.6,476.19Z"
            />
            <path
              class="cls-25"
              d="M245.73,466.46c0.16,5.4,3.91,9.73,8.51,9.73,4.2,0,7.68-3.59,8.39-8.31,0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31a1.61,1.61,0,0,0,0-.18l0-.15a0.66,0.66,0,0,1,0-.3V466.1H245.72c0,0.09,0,.18,0,0.27S245.73,466.43,245.73,466.46Z"
            />
            <path
              class="cls-26"
              d="M371.95,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.73,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.25,8.31-8.39,8.31s-7.71-3.48-8.43-8.26c0-.12,0-0.2-0.06-0.26l0-.12a0.66,0.66,0,0,1,0-.3V466.1h67.38v0.27C380.3,471.92,376.56,476.19,371.95,476.19Z"
            />
            <path
              class="cls-26"
              d="M363.69,466.1H313.1v1.15a0.66,0.66,0,0,0,0,.3c0,0.09.05,0.19,0.08,0.38,0.71,4.72,4.23,8.25,8.42,8.26h0c4.2,0,7.68-3.59,8.39-8.31,0.71,4.72,4.2,8.31,8.39,8.31h0c4.2,0,7.68-3.59,8.39-8.31h0c0.71,4.72,4.2,8.31,8.39,8.31h0c4.2,0,7.68-3.59,8.39-8.31h0c0.71,4.72,4.2,8.31,8.39,8.31h0c4.61,0,8.35-4.32,8.51-9.73,0,0,0-.06,0-0.09s0-.18,0-0.27H363.69Z"
            />
            <path
              class="cls-25"
              d="M294.43,544.58c0-20.74,8.64-63,18.67-63v63H294.43Z"
            />
            <path
              class="cls-25"
              d="M313.1,481.6c-10.22,0-18.67,43.5-18.67,63H313.1v-63Z"
            />
            <path
              class="cls-26"
              d="M313.1,544.58v-63c6.1,0,10.87,15.15,13.17,24.18,3.24,12.72,5.5,28.67,5.5,38.79H313.1Z"
            />
            <path
              class="cls-26"
              d="M313.1,481.6v63h18.67C331.77,525.1,323.32,481.6,313.1,481.6Z"
            />
          </g>

          <g>
            <path
              class="cls-23"
              d="M853,544.08a5.58,5.58,0,0,1-4.46-2,6.25,6.25,0,0,1-1.06-5.38l9.39-69.13,0.07-.16,0.13-1.2v0.21c0.17,5.54,3.91,9.82,8.52,9.82,4.14,0,7.67-3.49,8.39-8.31,0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.26,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0,0,0-.12,0-0.24v2.58l0.07,10.53c-6.1,0-10.87,15.16-13.16,24.19-3.24,12.72-5.5,28.66-5.5,38.78h18.52l0,0.1-70.29.16a7.27,7.27,0,0,1-1,.08h0Z"
            />
            <path
              class="cls-23"
              d="M905.8,543.74c0-19.48,8.44-63,18.66-63l-0.07-10.53c0-1.19,0-2,0-2.58,0,0.12,0,.21,0,0.24-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31-4.61,0-8.35-4.32-8.51-9.73,0,0,0-.06,0-0.09s0-.14,0-0.2l-0.13,1.2-0.07.16-9.39,69.13c-1,4.45,2.1,8,6.55,7.35l70.29-.16v-0.1H905.8Z"
            />
            <path
              class="cls-24"
              d="M943.15,543.74c0-20.74-8.64-63-18.67-63l-0.08-10.53v-2.58l0-.46v0.15a1.77,1.77,0,0,0,.06.6c0.72,4.79,4.27,8.26,8.43,8.26s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.73,4.81,4.26,8.31,8.39,8.31s7.67-3.49,8.39-8.31c0.72,4.81,4.25,8.31,8.39,8.31,4.61,0,8.35-4.27,8.51-9.73v-0.1l9.39,69.19a6.46,6.46,0,0,1-6.55,8.19H943.15Z"
            />
            <path
              class="cls-24"
              d="M1001.17,535.56l-9.38-69.23v0s0,0.06,0,.09c-0.16,5.4-3.91,9.73-8.51,9.73-4.2,0-7.68-3.59-8.39-8.31-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.68-3.59-8.39-8.31c-0.71,4.72-4.2,8.31-8.39,8.31s-7.72-3.54-8.43-8.26a2,2,0,0,1-.06-0.68v-0.07c0,0.16,0,.33,0,0.46,0,0.57,0,1.39,0,2.58l0.07,10.53h0c10.22,0,18.67,43.5,18.67,63h51.48A6.46,6.46,0,0,0,1001.17,535.56Z"
            />
            <polygon
              class="cls-23"
              points="857.11 466.1 857.12 466 924.39 415.14 924.48 466.1 857.11 466.1"
            />
            <polygon
              class="cls-23"
              points="873.88 466.1 874.15 466.1 890.67 466.1 890.94 466.1 907.45 466.1 907.72 466.1 924.24 466.1 924.42 466.1 924.47 466.1 924.39 415.14 924.39 415.14 857.12 466 857.11 466.1 857.46 466.1 873.88 466.1"
            />
            <path
              class="cls-24"
              d="M924.55,466.1l-0.16-51,0-.24c0-1.54,0-1.82.08-6.67,0.51,0.17,1.06.39,1.64,0.62a16.81,16.81,0,0,0,5.33,1.46A18.59,18.59,0,0,0,937,409c0.91-.31,1.77-0.59,2.58-0.8l-3.75,4.13,4.69,1.68a8.07,8.07,0,0,1-5.72,2,31.36,31.36,0,0,1-6.2-.88c-1.32-.28-2.69-0.56-4-0.71l0,0.82L991.8,466.1H924.55Z"
            />
            <path
              class="cls-24"
              d="M924.55,466.1H991.8l-67.17-50.84,0-.82c5.31,0.63,11.66,3.46,15.88-.4l-4.69-1.68,3.75-4.13c-2.51.64-5.56,2.08-8.12,2.08-2.1,0-4.95-1.38-7-2.08-0.13,5.06-.12,5.13-0.07,6.88l0,0v0.05l0.08,51h0.08Z"
            />
            <path
              class="cls-25"
              d="M916,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.26,8.31-8.39,8.31-4.61,0-8.35-4.27-8.51-9.73v-0.32l0.37,0h67v1.08l0,0.46c0,0.12,0,.21,0,0.24C923.65,472.7,920.12,476.19,916,476.19Z"
            />
            <path
              class="cls-25"
              d="M857.11,466.46c0.16,5.4,3.91,9.73,8.51,9.73,4.2,0,7.68-3.59,8.39-8.31,0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0,0,0-.12,0-0.24s0-.3,0-0.46V466.1H857.1s0,0,0,.07,0,0.14,0,.2S857.11,466.43,857.11,466.46Z"
            />
            <path
              class="cls-26"
              d="M983.27,476.19c-4.14,0-7.67-3.49-8.39-8.31-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.72,4.81-4.25,8.31-8.39,8.31s-7.67-3.49-8.39-8.31c-0.73,4.81-4.26,8.31-8.39,8.31s-7.71-3.47-8.43-8.26a1.77,1.77,0,0,1-.06-0.6V466.1H991.8l0,0.36C991.62,471.92,987.88,476.19,983.27,476.19Z"
            />
            <path
              class="cls-26"
              d="M975,466.1H924.42v1.15a2,2,0,0,0,.06.68c0.71,4.72,4.24,8.26,8.43,8.26s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31s7.68-3.59,8.39-8.31c0.71,4.72,4.2,8.31,8.39,8.31,4.61,0,8.35-4.32,8.51-9.73,0,0,0-.06,0-0.09v0c0-.08,0-0.17,0-0.25H975Z"
            />
            <path
              class="cls-25"
              d="M905.8,543.74c0-20.72,8.64-63,18.66-63v63H905.8Z"
            />
            <path
              class="cls-25"
              d="M924.48,480.76h0c-10.22,0-18.66,43.5-18.66,63h18.67v-63Z"
            />
            <path
              class="cls-26"
              d="M924.47,543.74v-63c6.1,0,10.87,15.15,13.17,24.18,3.24,12.72,5.5,28.67,5.5,38.79H924.47Z"
            />
            <path
              class="cls-26"
              d="M924.48,480.76v63h18.67C943.15,524.26,934.7,480.76,924.48,480.76Z"
            />
          </g>

          <g id="carousel-2" data-name="carousel">
            <g id="caro">
              <rect
                class="cls-27"
                x="1082.34"
                y="441.86"
                width="15.63"
                height="85.72"
              />
              <polygon
                class="cls-28"
                points="1082.49 512.44 1098.12 503.41 1098.12 499.24 1082.49 508.26 1082.49 512.44"
              />
              <polygon
                class="cls-29"
                points="1082.49 503.67 1098.12 494.64 1098.12 490.47 1082.49 499.49 1082.49 503.67"
              />
              <polygon
                class="cls-28"
                points="1082.49 494.89 1098.12 485.87 1098.12 481.7 1082.49 490.72 1082.49 494.89"
              />
              <polygon
                class="cls-28"
                points="1082.49 529.98 1098.12 520.96 1098.12 516.78 1082.49 525.81 1082.49 529.98"
              />
              <polygon
                class="cls-28"
                points="1082.49 521.21 1098.12 512.18 1098.12 508.01 1082.49 517.04 1082.49 521.21"
              />
              <polygon
                class="cls-28"
                points="1098.12 449.69 1098.12 447.63 1082.49 447.63 1082.49 458.71 1098.12 449.69"
              />
              <polygon
                class="cls-28"
                points="1082.49 486.12 1098.12 477.1 1098.12 472.93 1082.49 481.95 1082.49 486.12"
              />
              <polygon
                class="cls-28"
                points="1082.49 477.35 1098.12 468.33 1098.12 464.16 1082.49 473.18 1082.49 477.35"
              />
              <polygon
                class="cls-28"
                points="1082.49 468.58 1098.12 459.56 1098.12 454.29 1082.49 463.31 1082.49 468.58"
              />
              <polygon
                class="cls-30"
                points="1090.35 447.6 1110.16 447.6 1110.24 447.6 1130.05 447.6 1130.13 447.6 1149.88 447.6 1090.16 404.14 1090.16 447.6 1090.27 447.6 1090.35 447.6"
              />
              <path
                class="cls-30"
                d="M1156,539.78l-5.13-7.71h-0.68v0.34h-60v11.76h63.18A2.89,2.89,0,0,0,1156,539.78Z"
              />
              <polygon
                class="cls-27"
                points="1151.24 532.07 1151.24 526.49 1090.16 526.49 1090.16 532.41 1151.24 532.41 1151.24 532.07"
              />
              <path
                class="cls-27"
                d="M1100.3,457.88a10.1,10.1,0,0,0,9.95-9.43,10,10,0,0,0,19.89,0,10,10,0,0,0,19.93-.85H1090.2a6.43,6.43,0,0,0,.15.85A10.1,10.1,0,0,0,1100.3,457.88Z"
              />
              <polygon
                class="cls-12"
                points="1090.01 447.6 1070.2 447.6 1070.12 447.6 1050.31 447.6 1050.22 447.6 1030.48 447.6 1090.2 404.14 1090.2 447.6 1090.09 447.6 1090.01 447.6"
              />
              <path
                class="cls-12"
                d="M1023.61,539.78l5.22-7.71h0.69v0.34h61.09v11.76h-64.27C1023.84,544.16,1022.31,541.7,1023.61,539.78Z"
              />
              <polygon
                class="cls-31"
                points="1029.11 532.07 1029.11 526.49 1090.2 526.49 1090.2 532.41 1029.11 532.41 1029.11 532.07"
              />
              <path
                class="cls-31"
                d="M1080.1,457.88a10.1,10.1,0,0,1-9.95-9.43,10,10,0,0,1-19.89,0,10,10,0,0,1-19.93-.85h59.87s-0.08.5-.15,0.85A10.1,10.1,0,0,1,1080.1,457.88Z"
              />
            </g>
            <g id="hors-line">
              <line
                class="cls-32"
                x1="1140.16"
                y1="526.49"
                x2="1140.16"
                y2="457.87"
              />
              <line
                class="cls-32"
                x1="1090.16"
                y1="526.49"
                x2="1090.35"
                y2="447.6"
              />
              <line
                class="cls-32"
                x1="1039.81"
                y1="526.49"
                x2="1039.81"
                y2="457.88"
              />
            </g>
            <g id="hors">
              <path
                class="cls-33"
                d="M1125.55,501.49c0,0.87,1.12,2.82.5,3.44s-3.11-.54-3.92,0a2,2,0,0,0-.61,1.48c0.12,0.7,1.49,1.52,1.92,2.08a10.13,10.13,0,0,1,1.48,2.36,6.48,6.48,0,0,1,0,3.48c-0.23.46-1.46,0.74-1.66,1.22-0.31.76-.24,2.92,0.52,3.22s1.75-1.25,2.27-1.74a9.33,9.33,0,0,0,2-2.4,3.53,3.53,0,0,0,0-1.69,12,12,0,0,0-1.31-1.83,8.12,8.12,0,0,1-1.22-2.18,2.29,2.29,0,0,1,.35-1.57c0.66-.56,3.37.16,4.06-0.31a8,8,0,0,0,.15-0.95Z"
              />
              <path
                class="cls-34"
                d="M1146.24,505.22c0,0.77,2.95,2.67,3.44,3.26,0.34,0.41,1.3,1.22,1.22,1.74s-1.19,1.13-1.7,1.31a4.23,4.23,0,0,1-2.66,0c-0.49-.31-0.47-1.78-1-2.1-0.85-.55-3.59-0.9-4.06,0-0.3.56,1,1.61,1.44,2.1a10.29,10.29,0,0,0,2.62,2.52,4.09,4.09,0,0,0,1.74,0,26.08,26.08,0,0,0,3.05-1.31,10.31,10.31,0,0,0,2.44-.87,3.19,3.19,0,0,0,.87-1.7c0-1.39-1.8-4.52-1.8-5.91,0.21,0-.67-1.25-2-1.73a8.64,8.64,0,0,0-3.38-.33Z"
              />
              <path
                class="cls-33"
                d="M1151.45,506c0.79-.83,3.73-2,4.43-1.53s0.13,2.73-.09,3.61a13.4,13.4,0,0,1-2.08,4.15c-0.41.48-1.83,0.86-2.08,1.44s-0.26,2.76.45,3.07c0.86,0.37,2.6-1.35,3.07-2.16,0.24-.41,0-1.46.18-1.89,0.3-.66,1.71-1.45,2-2.12s0-2.07.09-2.75,0.71-2.08.81-2.8a4.59,4.59,0,0,0,0-2.07,2.84,2.84,0,0,0-1.45-1c-0.76-.16-2.15-1-2.54-0.28l-0.55.2-2.94,1.63Z"
              />
              <path
                class="cls-34"
                d="M1162.83,490.52l-0.33-3.06v-0.14l-0.07-2.48c0-.25,0,0-0.13-0.21l-0.9-1.48a1.34,1.34,0,0,0-.31-0.35l-1.34-1.07a1.34,1.34,0,0,0-.48-0.25l-2.37-.64a1.9,1.9,0,0,0-.5-0.07h-2.51a1.34,1.34,0,0,0-.56.12l-2.62,1.2a1.34,1.34,0,0,0-.3.19l-1.67,1.39a1.34,1.34,0,0,0-.41.6l-1.34,3.94,0,0.1-0.36,1.19v0c-0.06.25-.18,0.67-0.21,0.77v0c-0.34.83-1.17,2.55-1.06,3.28,0.09,0.56.88,1.47,1,2a4.88,4.88,0,0,1,0,2.72,3.67,3.67,0,0,1-2.33,1.16,2.66,2.66,0,0,1-1.48-.78A4.67,4.67,0,0,1,1142,497a1.73,1.73,0,0,0,0-.84c-0.42-.34-1.56.44-2.1,0.45a4.94,4.94,0,0,1-2.26-.45c-0.76-.57-1.38-2.5-1.79-3.36a5.27,5.27,0,0,0-.79-1.45,15.74,15.74,0,0,1-1.63,0h0l-3.17.17-0.24,0-1.34.32a1.34,1.34,0,0,0-.64.36l-1.07,1.07-0.11.1-1.38,1.1a1.34,1.34,0,0,0-.43.61l-0.5,1.45a1.33,1.33,0,0,0-.07.43v2.35a1.34,1.34,0,0,0,.19.68l0.87,1.46,0.19,0.26c0.41,0.45,1.16,1.29,1.55,1.71s1.22,1.28,1.65,1.73l0.17,0.22,0.31,0.48a1.34,1.34,0,0,1-.15,1.64l-1.73,1.84a0.93,0.93,0,0,0,.68,1.58h0.68l2,0.15a1.34,1.34,0,0,1,.4.1l1.33,0.54a1.34,1.34,0,0,1,.63.53l1,1.6a1.34,1.34,0,0,1,.2.84l-0.16,1.73a0.93,0.93,0,0,0,.47.9c0.57,0.32,1.69,1.58,2.26,1.27,0.31-.17.07-1,0.09-1.39s0.06-1.25.08-1.7l0-.31-0.2-1a1.34,1.34,0,0,1,0-.37l0.09-1a0.93,0.93,0,0,0-.58-0.95l-0.84-.35-2.05-.82-0.24-.13-1.14-.76a1.28,1.28,0,0,1-.57-1.07h0a1.28,1.28,0,0,1,.38-0.91l1-1,0.14-.12,1.43-1.08a1.34,1.34,0,0,1,1.43-.12l1,0.5,0.24,0.1,2,0.6a1.34,1.34,0,0,0,.38.06h4.85a1.34,1.34,0,0,0,.53-0.11l0.81-.35a1.52,1.52,0,0,0,.75-2,1.13,1.13,0,0,1,.47-1.37l0.21-.13a1.29,1.29,0,0,1,.82-0.18l1.19,0.14a1.34,1.34,0,0,1,.89.5l0.18,0.22a1.34,1.34,0,0,0,1.83.26l0.9-.64a1.34,1.34,0,0,0,.27-0.26l1.11-1.39a1,1,0,0,0,.21-0.47c0.17-1.11.83-5.43,0.83-5.66s0.08-2.8.09-3.18a0.9,0.9,0,0,0,0-.09l-0.06-.8a1,1,0,0,1,1.83-.67,10.4,10.4,0,0,1,.58.86c0.35,0.65,1.2,1.86,1.54,2.5l0.11,0.17,0.72,1a1.34,1.34,0,0,0,1.36.51l0.11,0a1.34,1.34,0,0,0,1.06-1.31v-3.63Z"
              />
              <path
                class="cls-31"
                d="M1127.17,493.23c0-.72-1.65-1.15-2.34-1.36a5.2,5.2,0,0,0-2.17,0,7.81,7.81,0,0,0-2.62,1.61,6.52,6.52,0,0,0-1.4,2.57,7.74,7.74,0,0,0,.52,2.44c0.21,0.67.86,1.93,1,2.61s1,2.29.44,2.7-1.57-.6-2-0.94-1.56-1.24-2-.92c-0.25.18,0.06,0.93,0,1.23-0.1.53-.67,1.71-0.33,2.12,0.57,0.68,2.94.07,3.57,0a3.92,3.92,0,0,0,1.92-.44c0.62-.68.44-2.73,0.5-3.66s-0.27-2.7,0-3.55a6,6,0,0,1,1.42-2,11.29,11.29,0,0,1,1.66-.78,6.85,6.85,0,0,0,1.44-.91l0.11-.11A0.84,0.84,0,0,0,1127.17,493.23Z"
              />
              <path
                class="cls-31"
                d="M1147.11,488.09l0.73-2a16.33,16.33,0,0,1,1.3-2.68l0.94-.79A9.94,9.94,0,0,1,1151,482l0.74-.4a20.06,20.06,0,0,1,2.45-.84h1.06c0.16,0,1.47,0,1.63.07l1.85,0.5a4.66,4.66,0,0,1,1.1.46l1.62,1.46a7.43,7.43,0,0,0,1.2-2,3.43,3.43,0,0,0-1.78-2.3c-0.58-.21-1.81.36-2.41,0.49a3.91,3.91,0,0,1-1.54.35c-0.48-.18-0.76-1.38-1.19-1.67a3.69,3.69,0,0,0-2-.49,3.9,3.9,0,0,0-2,1.46c-0.17.34,0.27,1.26,0,1.53-0.61.61-2.67-.39-3.44,0a3.92,3.92,0,0,0-1.47,2.07c-0.06.34,0.5,1,.37,1.35s-1.37.61-1.69,1a3.48,3.48,0,0,0-.77,1.74,4.51,4.51,0,0,0,1,1.88l0.08,0.08a0.84,0.84,0,0,0,1.28-.47l0-.08Z"
              />
              <path
                class="cls-35"
                d="M1134.45,490.25h0a1.18,1.18,0,0,1,1.31.42l0.66,0.89a1.18,1.18,0,0,0,.66.44l2.94,0.74a1.18,1.18,0,0,0,.8-0.08l1.65-.79a1.18,1.18,0,0,0,.58-0.6l0.66-1.53a1.18,1.18,0,0,1,1.08-.71h0.58a1.18,1.18,0,0,1,1.17,1.36l-0.38,2.44a1.18,1.18,0,0,0,.1.68l0.9,1.93a1.18,1.18,0,0,1,.1.34l0.34,2.51a1.18,1.18,0,0,1-.47,1.1l-1.73,1.27a1.18,1.18,0,0,1-1.21.11l-1.17-.56a1.18,1.18,0,0,1-.66-0.93l-0.23-2v-0.72l-2,.68a1.18,1.18,0,0,1-.44.06l-1.7-.09a1.18,1.18,0,0,1-.29-0.05l-1-.3a1.18,1.18,0,0,1-.61-0.45l-0.85-1.22a1.18,1.18,0,0,1-.12-0.21l-0.89-2.06-0.47-1A1.18,1.18,0,0,1,1134.45,490.25Z"
              />
            </g>
            <g id="hors-2" data-name="hors">
              <path
                class="cls-34"
                d="M1075.55,501.49c0,0.87,1.12,2.82.5,3.44s-3.11-.54-3.92,0a2,2,0,0,0-.61,1.48c0.12,0.7,1.49,1.52,1.92,2.08a10.13,10.13,0,0,1,1.48,2.36,6.48,6.48,0,0,1,0,3.48c-0.23.46-1.46,0.74-1.66,1.22-0.31.76-.24,2.92,0.52,3.22s1.75-1.25,2.27-1.74a9.33,9.33,0,0,0,2-2.4,3.53,3.53,0,0,0,0-1.69,12,12,0,0,0-1.31-1.83,8.12,8.12,0,0,1-1.22-2.18,2.29,2.29,0,0,1,.35-1.57c0.66-.56,3.37.16,4.06-0.31a8,8,0,0,0,.15-0.95Z"
              />
              <path
                class="cls-36"
                d="M1096.24,505.22c0,0.77,2.95,2.67,3.44,3.26,0.34,0.41,1.3,1.22,1.22,1.74s-1.19,1.13-1.7,1.31a4.23,4.23,0,0,1-2.66,0c-0.49-.31-0.47-1.78-1-2.1-0.85-.55-3.59-0.9-4.06,0-0.3.56,1,1.61,1.44,2.1a10.29,10.29,0,0,0,2.62,2.52,4.09,4.09,0,0,0,1.74,0,26.08,26.08,0,0,0,3.05-1.31,10.31,10.31,0,0,0,2.44-.87,3.19,3.19,0,0,0,.87-1.7c0-1.39-1.8-4.52-1.8-5.91,0.21,0-.67-1.25-2-1.73a8.64,8.64,0,0,0-3.38-.33Z"
              />
              <path
                class="cls-34"
                d="M1101.45,506c0.79-.83,3.73-2,4.43-1.53s0.13,2.73-.09,3.61a13.4,13.4,0,0,1-2.08,4.15c-0.41.48-1.83,0.86-2.08,1.44s-0.26,2.76.45,3.07c0.86,0.37,2.6-1.35,3.07-2.16,0.24-.41,0-1.46.18-1.89,0.3-.66,1.71-1.45,2-2.12s0-2.07.09-2.75,0.71-2.08.81-2.8a4.59,4.59,0,0,0,0-2.07,2.84,2.84,0,0,0-1.45-1c-0.76-.16-2.15-1-2.54-0.28l-0.55.2-2.94,1.63Z"
              />
              <path
                class="cls-36"
                d="M1112.83,490.52l-0.33-3.06v-0.14l-0.07-2.48c0-.25,0,0-0.13-0.21l-0.9-1.48a1.34,1.34,0,0,0-.31-0.35l-1.34-1.07a1.34,1.34,0,0,0-.48-0.25l-2.37-.64a1.9,1.9,0,0,0-.5-0.07h-2.51a1.34,1.34,0,0,0-.56.12l-2.62,1.2a1.34,1.34,0,0,0-.3.19l-1.67,1.39a1.34,1.34,0,0,0-.41.6l-1.34,3.94,0,0.1-0.36,1.19v0c-0.06.25-.18,0.67-0.21,0.77v0c-0.34.83-1.17,2.55-1.06,3.28,0.09,0.56.88,1.47,1,2a4.88,4.88,0,0,1,0,2.72,3.67,3.67,0,0,1-2.33,1.16,2.66,2.66,0,0,1-1.48-.78A4.67,4.67,0,0,1,1092,497a1.73,1.73,0,0,0,0-.84c-0.42-.34-1.56.44-2.1,0.45a4.94,4.94,0,0,1-2.26-.45c-0.76-.57-1.38-2.5-1.79-3.36a5.27,5.27,0,0,0-.79-1.45,15.74,15.74,0,0,1-1.63,0h0l-3.17.17-0.24,0-1.34.32a1.34,1.34,0,0,0-.64.36l-1.07,1.07-0.11.1-1.38,1.1a1.34,1.34,0,0,0-.43.61l-0.5,1.45a1.33,1.33,0,0,0-.07.43v2.35a1.34,1.34,0,0,0,.19.68l0.87,1.46,0.19,0.26c0.41,0.45,1.16,1.29,1.55,1.71s1.22,1.28,1.65,1.73l0.17,0.22,0.31,0.48a1.34,1.34,0,0,1-.15,1.64l-1.73,1.84a0.93,0.93,0,0,0,.68,1.58h0.68l2,0.15a1.34,1.34,0,0,1,.4.1l1.33,0.54a1.34,1.34,0,0,1,.63.53l1,1.6a1.34,1.34,0,0,1,.2.84l-0.16,1.73a0.93,0.93,0,0,0,.47.9c0.57,0.32,1.69,1.58,2.26,1.27,0.31-.17.07-1,0.09-1.39s0.06-1.25.08-1.7l0-.31-0.2-1a1.34,1.34,0,0,1,0-.37l0.09-1a0.93,0.93,0,0,0-.58-0.95l-0.84-.35-2.05-.82-0.24-.13-1.14-.76a1.28,1.28,0,0,1-.57-1.07h0a1.28,1.28,0,0,1,.38-0.91l1-1,0.14-.12,1.43-1.08a1.34,1.34,0,0,1,1.43-.12l1,0.5,0.24,0.1,2,0.6a1.34,1.34,0,0,0,.38.06h4.85a1.34,1.34,0,0,0,.53-0.11l0.81-.35a1.52,1.52,0,0,0,.75-2,1.13,1.13,0,0,1,.47-1.37l0.21-.13a1.29,1.29,0,0,1,.82-0.18l1.19,0.14a1.34,1.34,0,0,1,.89.5l0.18,0.22a1.34,1.34,0,0,0,1.83.26l0.9-.64a1.34,1.34,0,0,0,.27-0.26l1.11-1.39a1,1,0,0,0,.21-0.47c0.17-1.11.83-5.43,0.83-5.66s0.08-2.8.09-3.18a0.9,0.9,0,0,0,0-.09l-0.06-.8a1,1,0,0,1,1.83-.67,10.4,10.4,0,0,1,.58.86c0.35,0.65,1.2,1.86,1.54,2.5l0.11,0.17,0.72,1a1.34,1.34,0,0,0,1.36.51l0.11,0a1.34,1.34,0,0,0,1.06-1.31v-3.63Z"
              />
              <path
                class="cls-31"
                d="M1077.17,493.23c0-.72-1.65-1.15-2.34-1.36a5.2,5.2,0,0,0-2.17,0,7.81,7.81,0,0,0-2.62,1.61,6.52,6.52,0,0,0-1.4,2.57,7.74,7.74,0,0,0,.52,2.44c0.21,0.67.86,1.93,1,2.61s1,2.29.44,2.7-1.57-.6-2-0.94-1.56-1.24-2-.92c-0.25.18,0.06,0.93,0,1.23-0.1.53-.67,1.71-0.33,2.12,0.57,0.68,2.94.07,3.57,0a3.92,3.92,0,0,0,1.92-.44c0.62-.68.44-2.73,0.5-3.66s-0.27-2.7,0-3.55a6,6,0,0,1,1.42-2,11.29,11.29,0,0,1,1.66-.78,6.85,6.85,0,0,0,1.44-.91l0.11-.11A0.84,0.84,0,0,0,1077.17,493.23Z"
              />
              <path
                class="cls-31"
                d="M1097.11,488.09l0.73-2a16.33,16.33,0,0,1,1.3-2.68l0.94-.79A9.94,9.94,0,0,1,1101,482l0.74-.4a20.06,20.06,0,0,1,2.45-.84h1.06c0.16,0,1.47,0,1.63.07l1.85,0.5a4.66,4.66,0,0,1,1.1.46l1.62,1.46a7.43,7.43,0,0,0,1.2-2,3.43,3.43,0,0,0-1.78-2.3c-0.58-.21-1.81.36-2.41,0.49a3.91,3.91,0,0,1-1.54.35c-0.48-.18-0.76-1.38-1.19-1.67a3.69,3.69,0,0,0-2-.49,3.9,3.9,0,0,0-2,1.46c-0.17.34,0.27,1.26,0,1.53-0.61.61-2.67-.39-3.44,0a3.92,3.92,0,0,0-1.47,2.07c-0.06.34,0.5,1,.37,1.35s-1.37.61-1.69,1a3.48,3.48,0,0,0-.77,1.74,4.51,4.51,0,0,0,1,1.88l0.08,0.08a0.84,0.84,0,0,0,1.28-.47l0-.08Z"
              />
              <path
                class="cls-35"
                d="M1084.45,490.25h0a1.18,1.18,0,0,1,1.31.42l0.66,0.89a1.18,1.18,0,0,0,.66.44l2.94,0.74a1.18,1.18,0,0,0,.8-0.08l1.65-.79a1.18,1.18,0,0,0,.58-0.6l0.66-1.53a1.18,1.18,0,0,1,1.08-.71h0.58a1.18,1.18,0,0,1,1.17,1.36l-0.38,2.44a1.18,1.18,0,0,0,.1.68l0.9,1.93a1.18,1.18,0,0,1,.1.34l0.34,2.51a1.18,1.18,0,0,1-.47,1.1l-1.73,1.27a1.18,1.18,0,0,1-1.21.11l-1.17-.56a1.18,1.18,0,0,1-.66-0.93l-0.23-2v-0.72l-2,.68a1.18,1.18,0,0,1-.44.06l-1.7-.09a1.18,1.18,0,0,1-.29-0.05l-1-.3a1.18,1.18,0,0,1-.61-0.45l-0.85-1.22a1.18,1.18,0,0,1-.12-0.21l-0.89-2.06-0.47-1A1.18,1.18,0,0,1,1084.45,490.25Z"
              />
            </g>
            <g id="hors-3" data-name="hors">
              <path
                class="cls-34"
                d="M1025.2,501.49c0,0.87,1.12,2.82.5,3.44s-3.11-.54-3.92,0a2,2,0,0,0-.61,1.48c0.12,0.7,1.49,1.52,1.92,2.08a10.13,10.13,0,0,1,1.48,2.36,6.48,6.48,0,0,1,0,3.48c-0.23.46-1.46,0.74-1.66,1.22-0.31.76-.24,2.92,0.52,3.22s1.75-1.25,2.27-1.74a9.33,9.33,0,0,0,2-2.4,3.53,3.53,0,0,0,0-1.69,12,12,0,0,0-1.31-1.83,8.12,8.12,0,0,1-1.22-2.18,2.29,2.29,0,0,1,.35-1.57c0.66-.56,3.37.16,4.06-0.31a8,8,0,0,0,.15-0.95Z"
              />
              <path
                class="cls-4"
                d="M1045.89,505.22c0,0.77,2.95,2.67,3.44,3.26,0.34,0.41,1.3,1.22,1.22,1.74s-1.19,1.13-1.7,1.31a4.23,4.23,0,0,1-2.66,0c-0.49-.31-0.47-1.78-1-2.1-0.85-.55-3.59-0.9-4.06,0-0.3.56,1,1.61,1.44,2.1a10.29,10.29,0,0,0,2.62,2.52,4.09,4.09,0,0,0,1.74,0,26.08,26.08,0,0,0,3.05-1.31,10.31,10.31,0,0,0,2.44-.87,3.19,3.19,0,0,0,.87-1.7c0-1.39-1.8-4.52-1.8-5.91,0.21,0-.67-1.25-2-1.73a8.64,8.64,0,0,0-3.38-.33Z"
              />
              <path
                class="cls-34"
                d="M1051.1,506c0.79-.83,3.73-2,4.43-1.53s0.13,2.73-.09,3.61a13.4,13.4,0,0,1-2.08,4.15c-0.41.48-1.83,0.86-2.08,1.44s-0.26,2.76.45,3.07c0.86,0.37,2.6-1.35,3.07-2.16,0.24-.41,0-1.46.18-1.89,0.3-.66,1.71-1.45,2-2.12s0-2.07.09-2.75,0.71-2.08.81-2.8a4.59,4.59,0,0,0,0-2.07,2.84,2.84,0,0,0-1.45-1c-0.76-.16-2.15-1-2.54-0.28l-0.55.2-2.94,1.63Z"
              />
              <path
                class="cls-4"
                d="M1062.49,490.52l-0.33-3.06v-0.14l-0.07-2.48c0-.25,0,0-0.13-0.21l-0.9-1.48a1.34,1.34,0,0,0-.31-0.35l-1.34-1.07a1.34,1.34,0,0,0-.48-0.25l-2.37-.64a1.9,1.9,0,0,0-.5-0.07h-2.51a1.34,1.34,0,0,0-.56.12l-2.62,1.2a1.34,1.34,0,0,0-.3.19l-1.67,1.39a1.34,1.34,0,0,0-.41.6l-1.34,3.94,0,0.1-0.36,1.19v0c-0.06.25-.18,0.67-0.21,0.77v0c-0.34.83-1.17,2.55-1.06,3.28,0.09,0.56.88,1.47,1,2a4.88,4.88,0,0,1,0,2.72,3.67,3.67,0,0,1-2.33,1.16,2.66,2.66,0,0,1-1.48-.78,4.67,4.67,0,0,1-.47-1.79,1.73,1.73,0,0,0,0-.84c-0.42-.34-1.56.44-2.1,0.45a4.94,4.94,0,0,1-2.26-.45c-0.76-.57-1.38-2.5-1.79-3.36a5.27,5.27,0,0,0-.79-1.45,15.74,15.74,0,0,1-1.63,0h0l-3.17.17-0.24,0-1.34.32a1.34,1.34,0,0,0-.64.36l-1.07,1.07-0.11.1-1.38,1.1a1.34,1.34,0,0,0-.43.61l-0.5,1.45a1.33,1.33,0,0,0-.07.43v2.35a1.34,1.34,0,0,0,.19.68l0.87,1.46,0.19,0.26c0.41,0.45,1.16,1.29,1.55,1.71s1.22,1.28,1.65,1.73l0.17,0.22,0.31,0.48a1.34,1.34,0,0,1-.15,1.64l-1.73,1.84a0.93,0.93,0,0,0,.68,1.58h0.68l2,0.15a1.34,1.34,0,0,1,.4.1l1.33,0.54a1.34,1.34,0,0,1,.63.53l1,1.6a1.34,1.34,0,0,1,.2.84l-0.16,1.73a0.93,0.93,0,0,0,.47.9c0.57,0.32,1.69,1.58,2.26,1.27,0.31-.17.07-1,0.09-1.39s0.06-1.25.08-1.7l0-.31-0.2-1a1.34,1.34,0,0,1,0-.37l0.09-1a0.93,0.93,0,0,0-.58-0.95l-0.84-.35-2.05-.82-0.24-.13-1.14-.76a1.28,1.28,0,0,1-.57-1.07h0a1.28,1.28,0,0,1,.38-0.91l1-1,0.14-.12,1.43-1.08a1.34,1.34,0,0,1,1.43-.12l1,0.5,0.24,0.1,2,0.6a1.34,1.34,0,0,0,.38.06h4.85a1.34,1.34,0,0,0,.53-0.11l0.81-.35a1.52,1.52,0,0,0,.75-2,1.13,1.13,0,0,1,.47-1.37l0.21-.13a1.29,1.29,0,0,1,.82-0.18l1.19,0.14a1.34,1.34,0,0,1,.89.5l0.18,0.22a1.34,1.34,0,0,0,1.83.26l0.9-.64a1.34,1.34,0,0,0,.27-0.26l1.11-1.39a1,1,0,0,0,.21-0.47c0.17-1.11.83-5.43,0.83-5.66s0.08-2.8.09-3.18a0.9,0.9,0,0,0,0-.09l-0.06-.8a1,1,0,0,1,1.83-.67,10.4,10.4,0,0,1,.58.86c0.35,0.65,1.2,1.86,1.54,2.5l0.11,0.17,0.72,1a1.34,1.34,0,0,0,1.36.51l0.11,0a1.34,1.34,0,0,0,1.06-1.31v-3.63Z"
              />
              <path
                class="cls-31"
                d="M1026.82,493.23c0-.72-1.65-1.15-2.34-1.36a5.2,5.2,0,0,0-2.17,0,7.81,7.81,0,0,0-2.62,1.61,6.52,6.52,0,0,0-1.4,2.57,7.74,7.74,0,0,0,.52,2.44c0.21,0.67.86,1.93,1,2.61s1,2.29.44,2.7-1.57-.6-2-0.94-3.56-1.24-4-.92c-0.25.18,0.06,0.93,0,1.23-0.1.53-.67,1.71-0.33,2.12,0.57,0.68,4.94.07,5.57,0a3.92,3.92,0,0,0,1.92-.44c0.62-.68.44-2.73,0.5-3.66s-0.27-2.7,0-3.55a6,6,0,0,1,1.42-2,11.29,11.29,0,0,1,1.66-.78,6.85,6.85,0,0,0,1.44-.91l0.11-.11A0.84,0.84,0,0,0,1026.82,493.23Z"
              />
              <path
                class="cls-31"
                d="M1046.76,488.09l0.73-2a16.33,16.33,0,0,1,1.3-2.68l0.94-.79a9.94,9.94,0,0,1,.94-0.65l0.74-.4a20.06,20.06,0,0,1,2.45-.84h1.06c0.16,0,1.47,0,1.63.07l1.85,0.5a4.66,4.66,0,0,1,1.1.46l1.62,1.46a7.43,7.43,0,0,0,1.2-2,3.43,3.43,0,0,0-1.78-2.3c-0.58-.21-1.81.36-2.41,0.49a3.91,3.91,0,0,1-1.54.35c-0.48-.18-0.76-1.38-1.19-1.67a3.69,3.69,0,0,0-2-.49,3.9,3.9,0,0,0-2,1.46c-0.17.34,0.27,1.26,0,1.53-0.61.61-2.67-.39-3.44,0a3.92,3.92,0,0,0-1.47,2.07c-0.06.34,0.5,1,.37,1.35s-1.37.61-1.69,1a3.48,3.48,0,0,0-.77,1.74,4.51,4.51,0,0,0,1,1.88l0.08,0.08a0.84,0.84,0,0,0,1.28-.47l0-.08Z"
              />
              <path
                class="cls-35"
                d="M1034.1,490.25h0a1.18,1.18,0,0,1,1.31.42l0.66,0.89a1.18,1.18,0,0,0,.66.44l2.94,0.74a1.18,1.18,0,0,0,.8-0.08l1.65-.79a1.18,1.18,0,0,0,.58-0.6l0.66-1.53a1.18,1.18,0,0,1,1.08-.71H1045a1.18,1.18,0,0,1,1.17,1.36l-0.38,2.44a1.18,1.18,0,0,0,.1.68l0.9,1.93a1.18,1.18,0,0,1,.1.34l0.34,2.51a1.18,1.18,0,0,1-.47,1.1l-1.73,1.27a1.18,1.18,0,0,1-1.21.11l-1.17-.56a1.18,1.18,0,0,1-.66-0.93l-0.23-2v-0.72l-2,.68a1.18,1.18,0,0,1-.44.06l-1.7-.09a1.18,1.18,0,0,1-.29-0.05l-1-.3a1.18,1.18,0,0,1-.61-0.45l-0.85-1.22a1.18,1.18,0,0,1-.12-0.21l-0.89-2.06-0.47-1A1.18,1.18,0,0,1,1034.1,490.25Z"
              />
            </g>
          </g>

          <g>
            <g id="leg">
              <path
                class="cls-2"
                d="M581.09,516.09s67.05-211.66,67.05-211.66l-95.8,211.65C554.53,516.17,581,516.11,581.09,516.09Z"
              />
              <path
                class="cls-37"
                d="M743.93,516.25L648.18,304.33v0l67.14,211.9C714.49,516.3,737.35,516.37,743.93,516.25Z"
              />
              <rect
                class="cls-23"
                x="524.4"
                y="516.13"
                width="247.24"
                height="12.44"
                rx="6.22"
                ry="6.22"
              />
              <rect
                class="cls-35"
                x="494.08"
                y="528.27"
                width="306.64"
                height="15.8"
                rx="7.9"
                ry="7.9"
              />
            </g>
            <g id="needles" class="cls-8">
              <polygon
                class="cls-38"
                points="648.42 181.87 657.13 260.57 697.1 192.22 673.04 267.66 737.36 221.47 684.7 280.6 762.25 264.56 690.08 297.17 767.45 314.06 688.26 314.49 752.07 361.38 679.55 329.57 718.77 398.37 665.46 339.81 673.31 418.61 648.42 343.43 623.54 418.61 631.39 339.81 578.08 398.37 617.29 329.57 544.78 361.38 608.59 314.49 529.4 314.06 606.77 297.17 534.6 264.56 612.15 280.6 559.49 221.47 623.8 267.66 599.75 192.22 639.72 260.57 648.42 181.87"
              />
              <polygon
                class="cls-38"
                points="673.69 184.6 665.4 263.35 719.04 205.09 679.43 273.67 752.13 242.27 688.05 288.8 767.24 289.68 689.78 306.13 761.75 339.14 684.3 322.67 736.63 382.1 672.57 335.54 696.2 411.12 657.22 342.54 648.06 421.19 639.2 342.44 598.85 410.57 623.33 335.26 558.75 381.09 611.75 322.25 534.12 337.85 606.46 305.66 529.2 288.33 608.38 288.35 544.84 241.09 617.18 273.31 578.35 204.3 631.33 263.15 623.93 184.31 648.38 259.63 673.69 184.6"
              />
            </g>
            <g id="wheels">
              <circle class="cls-38" cx="648.22" cy="301.55" r="158.88" />
              <path
                class="cls-35"
                d="M648.15,423.09h0.21a121.54,121.54,0,1,0,0-243.08h-0.21V159.66h0.21a141.89,141.89,0,0,1,0,283.79h-0.21V423.09Z"
              />
              <path
                class="cls-15"
                d="M648.42,423.09h-0.21a121.54,121.54,0,1,1,0-243.08h0.21V159.66h-0.21a141.89,141.89,0,0,0,0,283.79h0.21V423.09Z"
              />
              <path
                class="cls-37"
                d="M648.14,257.79a43.76,43.76,0,0,1,0,87.52V257.79Z"
              />
              <path
                class="cls-2"
                d="M648.15,257.79a43.76,43.76,0,0,0,0,87.52V257.79Z"
              />
            </g>
            <g id="arms">
              <line
                class="cls-39"
                x1="733.52"
                y1="435.61"
                x2="563.03"
                y2="167.41"
              />
              <line
                class="cls-39"
                x1="797.24"
                y1="356.77"
                x2="499.28"
                y2="246.11"
              />
              <line
                class="cls-39"
                x1="797.16"
                y1="246.11"
                x2="499.2"
                y2="356.77"
              />
              <line
                class="cls-39"
                x1="733.41"
                y1="167.41"
                x2="562.92"
                y2="435.61"
              />
              <line
                class="cls-39"
                x1="648.22"
                y1="142.66"
                x2="648.22"
                y2="460.43"
              />
            </g>
            <g id="cabins">
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M801.41,357.53a24.71,24.71,0,0,0-4.14-.35V361a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V377.94C814.64,370.9,809.6,359.08,801.41,357.53Z"
                />
                <path
                  class="cls-41"
                  d="M793.13,357.53a24.71,24.71,0,0,1,4.14-.35V361a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V377.94C779.89,370.9,784.93,359.08,793.13,357.53Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M503.08,357.53a24.71,24.71,0,0,0-4.14-.35V361a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V377.94C516.32,370.9,511.27,359.08,503.08,357.53Z"
                />
                <path
                  class="cls-41"
                  d="M494.8,357.53a24.71,24.71,0,0,1,4.14-.35V361a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V377.94C481.56,370.9,486.61,359.08,494.8,357.53Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M503.34,247.09a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1H503c7.51-.25,13.64-6.44,13.56-13.56V267.5C516.58,260.46,511.53,248.64,503.34,247.09Z"
                />
                <path
                  class="cls-41"
                  d="M495.06,247.09a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V267.5C481.82,260.46,486.87,248.64,495.06,247.09Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M567.39,168.41a24.71,24.71,0,0,0-4.14-.35v3.79A26.34,26.34,0,0,1,566,172c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V188.82C580.63,181.78,575.58,170,567.39,168.41Z"
                />
                <path
                  class="cls-41"
                  d="M559.11,168.41a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V188.82C545.87,181.78,550.92,170,559.11,168.41Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M566.81,436.21a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V456.62C580,449.57,575,437.75,566.81,436.21Z"
                />
                <path
                  class="cls-41"
                  d="M558.53,436.21a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V456.62C545.29,449.57,550.34,437.75,558.53,436.21Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M652.17,143a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V163.42C665.4,156.38,660.36,144.55,652.17,143Z"
                />
                <path
                  class="cls-41"
                  d="M643.89,143a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1H644.2c-7.51-.25-13.64-6.44-13.56-13.56V163.42C630.65,156.38,635.69,144.55,643.89,143Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M737.69,167.81a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V188.22C750.93,181.18,745.89,169.36,737.69,167.81Z"
                />
                <path
                  class="cls-41"
                  d="M729.42,167.81a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V188.22C716.18,181.18,721.22,169.36,729.42,167.81Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M801.3,247a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1H801c7.51-.25,13.64-6.44,13.56-13.56V267.36C814.53,260.32,809.49,248.49,801.3,247Z"
                />
                <path
                  class="cls-41"
                  d="M793,247a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V267.36C779.78,260.32,784.82,248.49,793,247Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M737.58,436a24.71,24.71,0,0,0-4.14-.35v3.79a26.34,26.34,0,0,1,2.79.15c7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74q-1.33-.06-2.67-0.06v20.1h3.83c7.51-.25,13.64-6.44,13.56-13.56V456.39C750.82,449.34,745.78,437.52,737.58,436Z"
                />
                <path
                  class="cls-41"
                  d="M729.3,436a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V456.39C716.07,449.34,721.11,437.52,729.3,436Z"
                />
              </g>
              <g class="cabin">
                <path
                  class="cls-40"
                  d="M652.28,461a27.42,27.42,0,0,0-4.36-.35v3.79c0.93,0,2.08,0,3,.15,7.3,0.88,12.21,10.6,12.14,17.29h0c0,6.77-5.32,10.87-12.26,10.74-0.89,0-2-.06-2.89-0.06v20.1H652c7.51-.25,13.64-6.44,13.56-13.56V481.42C665.52,474.38,660.47,462.55,652.28,461Z"
                />
                <path
                  class="cls-41"
                  d="M643.78,461a24.71,24.71,0,0,1,4.14-.35v3.79a26.34,26.34,0,0,0-2.79.15c-7.3.88-12.21,10.6-12.14,17.29h0c0,6.77,5.32,10.87,12.26,10.74q1.33-.06,2.67-0.06v20.1h-3.83c-7.51-.25-13.64-6.44-13.56-13.56V481.42C630.54,474.38,635.58,462.55,643.78,461Z"
                />
              </g>
            </g>
            <g id="mask">
              <path
                class="cls-35"
                d="M648.27,278.27a23.28,23.28,0,0,1,0,46.56V278.27Z"
              />
              <path
                class="cls-23"
                d="M648.14,278.27a23.28,23.28,0,0,0,0,46.56V278.27Z"
              />
            </g>
          </g>

          <g id="icecream">
            <g id="icekr">
              <g id="stall">
                <g>
                  <path
                    class="cls-22"
                    d="M988.64,469H968.93l-8.33,15.07h7.72v28.78a6.18,6.18,0,0,0-.27,1.76v14.12c0,3.89,3.65,7.08,8.11,7.08h12.48V469Z"
                  />
                  <path
                    class="cls-22"
                    d="M975.81,507.55a2.8,2.8,0,0,1-3.08-2.58V484.09h6.5c0,2.24,2.08,4.05,4.64,4.05a4.49,4.49,0,0,0,4.58-3.41,3.62,3.62,0,0,0,.19.67v22.15H975.81Z"
                  />
                  <path
                    class="cls-41"
                    d="M968.05,528.75V514.63a6.18,6.18,0,0,1,.27-1.76V484.09h4.41V505a2.8,2.8,0,0,0,3.08,2.58h12.84v28.29H976.16C971.7,535.83,968.05,532.65,968.05,528.75Z"
                  />
                  <polygon
                    class="cls-42"
                    points="988.39 484.09 979.23 484.09 972.73 484.09 968.32 484.09 960.6 484.09 968.93 469.02 988.64 469.02 988.64 484.09 988.51 484.09 988.39 484.09"
                  />
                  <path
                    class="cls-31"
                    d="M983.87,488.14c-2.56,0-4.64-1.81-4.64-4.05h9.41v1.31a3.62,3.62,0,0,1-.19-0.67A4.49,4.49,0,0,1,983.87,488.14Z"
                  />
                </g>
                <g>
                  <path
                    class="cls-22"
                    d="M988.33,469H1008l8.33,15.07h-7.72v28.78a6.18,6.18,0,0,1,.27,1.76v14.12c0,3.89-3.65,7.08-8.11,7.08H988.33V469Z"
                  />
                  <path
                    class="cls-22"
                    d="M1001.17,507.55a2.8,2.8,0,0,0,3.08-2.58V484.09h-6.5c0,2.24-2.08,4.05-4.64,4.05a4.49,4.49,0,0,1-4.58-3.41,3.62,3.62,0,0,1-.19.67v22.15h12.84Z"
                  />
                  <path
                    class="cls-40"
                    d="M1008.93,528.75V514.63a6.18,6.18,0,0,0-.27-1.76V484.09h-4.41V505a2.8,2.8,0,0,1-3.08,2.58H988.33v28.29h12.48C1005.27,535.83,1008.93,532.65,1008.93,528.75Z"
                  />
                  <polygon
                    class="cls-43"
                    points="988.59 484.09 997.74 484.09 1004.25 484.09 1008.66 484.09 1016.38 484.09 1008.05 469.02 988.33 469.02 988.33 484.09 988.46 484.09 988.59 484.09"
                  />
                  <path
                    class="cls-31"
                    d="M993.1,488.14c2.56,0,4.64-1.81,4.64-4.05h-9.41v1.31a3.62,3.62,0,0,0,.19-0.67A4.49,4.49,0,0,0,993.1,488.14Z"
                  />
                </g>
                <g id="телега3">
                  <path
                    class="cls-31"
                    d="M1002.39,488.14c2.56,0,4.64-1.81,4.64-4.05h-9.28C997.74,486.33,999.82,488.14,1002.39,488.14Z"
                  />
                  <path
                    class="cls-31"
                    d="M1011.67,488.14c2.56,0,4.64-1.81,4.64-4.05H1007C1007,486.33,1009.1,488.14,1011.67,488.14Z"
                  />
                </g>
                <g id="телега3-2" data-name="телега3">
                  <path
                    class="cls-31"
                    d="M974.59,488.14c-2.56,0-4.64-1.81-4.64-4.05h9.28C979.23,486.33,977.16,488.14,974.59,488.14Z"
                  />
                  <path
                    class="cls-31"
                    d="M965.31,488.14c-2.56,0-4.64-1.81-4.64-4.05H970C970,486.33,967.87,488.14,965.31,488.14Z"
                  />
                </g>
              </g>
              <g id="ice">
                <path
                  class="cls-31"
                  d="M995.16,477.73c-4.88-.1-8.52-3.82-8.14-8.32s4.65-8.07,9.53-8,8.52,3.82,8.14,8.32S1000,477.82,995.16,477.73Z"
                />
                <polygon
                  class="cls-36"
                  points="987.67 470.37 997.06 477.76 978.96 493.12 987.67 470.37"
                />
                <path
                  class="cls-44"
                  d="M993.19,472.16a6.27,6.27,0,0,1-7.79,2.52,4.83,4.83,0,0,1-2.39-6.95,6.27,6.27,0,0,1,7.79-2.52A4.83,4.83,0,0,1,993.19,472.16Z"
                />
                <path
                  class="cls-23"
                  d="M995.94,481a5.49,5.49,0,0,1-5.67-5.8,6.43,6.43,0,0,1,6.65-5.56,5.49,5.49,0,0,1,5.67,5.8A6.43,6.43,0,0,1,995.94,481Z"
                />
                <path
                  class="cls-45"
                  d="M992.82,464.59l0.93,0.16a0.66,0.66,0,0,0,.59-0.2l1.24-1.35a0.51,0.51,0,0,0,.09-0.6,0.52,0.52,0,0,0-.14-0.16,0.6,0.6,0,0,0-.43-0.11l-1.58.17a0.64,0.64,0,0,0-.49.31l-0.59,1a0.49,0.49,0,0,0,0,.48A0.54,0.54,0,0,0,992.82,464.59Z"
                />
                <path
                  class="cls-46"
                  d="M987.21,466.32a0.35,0.35,0,0,0-.09-0.11,0.4,0.4,0,0,0-.28-0.07l-1,.11a0.42,0.42,0,0,0-.32.2l-0.39.68a0.33,0.33,0,0,0,0,.31,0.36,0.36,0,0,0,.26.19l0.61,0.1a0.43,0.43,0,0,0,.39-0.13l0.82-.89A0.33,0.33,0,0,0,987.21,466.32Z"
                />
                <path
                  class="cls-47"
                  d="M996.17,471.13a0.35,0.35,0,0,0-.09-0.11,0.4,0.4,0,0,0-.28-0.07l-1,.11a0.42,0.42,0,0,0-.32.2l-0.39.68a0.33,0.33,0,0,0,0,.31,0.36,0.36,0,0,0,.26.19l0.61,0.1a0.43,0.43,0,0,0,.39-0.13l0.82-.89A0.33,0.33,0,0,0,996.17,471.13Z"
                />
              </g>
            </g>
            <g id="wheel">
              <path
                class="cls-31"
                d="M1008.93,522.6a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="1008.93"
                y1="543.24"
                x2="1008.93"
                y2="521.6"
              />
              <line
                class="cls-48"
                x1="1014.72"
                y1="541.56"
                x2="1003.13"
                y2="523.29"
              />
              <line
                class="cls-48"
                x1="999"
                y1="528.12"
                x2="1018.85"
                y2="536.72"
              />
              <line
                class="cls-48"
                x1="998.17"
                y1="533.55"
                x2="1019.68"
                y2="531.29"
              />
              <line
                class="cls-48"
                x1="1003.76"
                y1="541.92"
                x2="1014.09"
                y2="522.92"
              />
              <line
                class="cls-48"
                x1="1000.16"
                y1="538.76"
                x2="1017.69"
                y2="526.08"
              />
              <circle class="cls-49" cx="1008.93" cy="532.42" r="3.4" />
            </g>
            <g id="whel">
              <path
                class="cls-31"
                d="M968.21,522.6a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1A10.82,10.82,0,1,0,979,532.42a10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="968.21"
                y1="543.24"
                x2="968.21"
                y2="521.6"
              />
              <line
                class="cls-48"
                x1="974"
                y1="541.56"
                x2="962.42"
                y2="523.29"
              />
              <line
                class="cls-48"
                x1="958.29"
                y1="528.12"
                x2="978.13"
                y2="536.72"
              />
              <line
                class="cls-48"
                x1="957.45"
                y1="533.55"
                x2="978.97"
                y2="531.29"
              />
              <line
                class="cls-48"
                x1="963.04"
                y1="541.92"
                x2="973.38"
                y2="522.92"
              />
              <line
                class="cls-48"
                x1="959.45"
                y1="538.76"
                x2="976.97"
                y2="526.08"
              />
              <circle class="cls-36" cx="968.21" cy="532.42" r="3.4" />
            </g>
          </g>

          <g id="cotton_candy" data-name="cotton candy">
            <g id="stall-2" data-name="stall">
              <g id="stall-3" data-name="stall">
                <g id="тачка">
                  <g>
                    <path
                      class="cls-22"
                      d="M1194.25,469.22h-19.71l-8.33,15.07h7.72v28.78a6.18,6.18,0,0,0-.27,1.76v14.12c0,3.89,3.65,7.08,8.11,7.08h12.48V469.22Z"
                    />
                    <path
                      class="cls-22"
                      d="M1181.41,507.75a2.8,2.8,0,0,1-3.08-2.58V484.29h6.5c0,2.24,2.08,4.05,4.64,4.05a4.49,4.49,0,0,0,4.58-3.41,3.62,3.62,0,0,0,.19.67v22.15h-12.84Z"
                    />
                    <path
                      class="cls-41"
                      d="M1173.65,528.95V514.82a6.18,6.18,0,0,1,.27-1.76V484.29h4.41v20.88a2.8,2.8,0,0,0,3.08,2.58h12.84V536h-12.48C1177.31,536,1173.65,532.84,1173.65,528.95Z"
                    />
                    <polygon
                      class="cls-42"
                      points="1193.99 484.29 1184.84 484.29 1178.33 484.29 1173.92 484.29 1166.2 484.29 1174.53 469.22 1194.25 469.22 1194.25 484.29 1194.12 484.29 1193.99 484.29"
                    />
                    <path
                      class="cls-27"
                      d="M1189.48,488.34c-2.56,0-4.64-1.81-4.64-4.05h9.41v1.31a3.62,3.62,0,0,1-.19-0.67A4.49,4.49,0,0,1,1189.48,488.34Z"
                    />
                  </g>
                  <g>
                    <path
                      class="cls-22"
                      d="M1193.94,469.22h19.71l8.33,15.07h-7.72v28.78a6.18,6.18,0,0,1,.27,1.76v14.12c0,3.89-3.65,7.08-8.11,7.08h-12.48V469.22Z"
                    />
                    <path
                      class="cls-22"
                      d="M1206.77,507.75a2.8,2.8,0,0,0,3.08-2.58V484.29h-6.5c0,2.24-2.08,4.05-4.64,4.05a4.49,4.49,0,0,1-4.58-3.41,3.62,3.62,0,0,1-.19.67v22.15h12.84Z"
                    />
                    <path
                      class="cls-40"
                      d="M1214.53,528.95V514.82a6.18,6.18,0,0,0-.27-1.76V484.29h-4.41v20.88a2.8,2.8,0,0,1-3.08,2.58h-12.84V536h12.48C1210.88,536,1214.53,532.84,1214.53,528.95Z"
                    />
                    <polygon
                      class="cls-43"
                      points="1194.19 484.29 1203.35 484.29 1209.85 484.29 1214.26 484.29 1221.98 484.29 1213.65 469.22 1193.94 469.22 1193.94 484.29 1194.07 484.29 1194.19 484.29"
                    />
                    <path
                      class="cls-27"
                      d="M1198.71,488.34c2.56,0,4.64-1.81,4.64-4.05h-9.41v1.31a3.62,3.62,0,0,0,.19-0.67A4.49,4.49,0,0,0,1198.71,488.34Z"
                    />
                  </g>
                  <g id="телега3-3" data-name="телега3">
                    <path
                      class="cls-27"
                      d="M1208,488.34c2.56,0,4.64-1.81,4.64-4.05h-9.28C1203.35,486.52,1205.42,488.34,1208,488.34Z"
                    />
                    <path
                      class="cls-27"
                      d="M1217.27,488.34c2.56,0,4.64-1.81,4.64-4.05h-9.28C1212.63,486.52,1214.71,488.34,1217.27,488.34Z"
                    />
                  </g>
                  <g id="телега3-4" data-name="телега3">
                    <path
                      class="cls-27"
                      d="M1180.19,488.34c-2.56,0-4.64-1.81-4.64-4.05h9.28C1184.84,486.52,1182.76,488.34,1180.19,488.34Z"
                    />
                    <path
                      class="cls-27"
                      d="M1170.91,488.34c-2.56,0-4.64-1.81-4.64-4.05h9.28C1175.55,486.52,1173.48,488.34,1170.91,488.34Z"
                    />
                  </g>
                </g>
              </g>
              <g id="wheel-2" data-name="wheel">
                <path
                  class="cls-27"
                  d="M1214.53,522.8a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
                />
                <line
                  class="cls-50"
                  x1="1214.53"
                  y1="543.43"
                  x2="1214.53"
                  y2="521.8"
                />
                <line
                  class="cls-50"
                  x1="1220.32"
                  y1="541.75"
                  x2="1208.74"
                  y2="523.48"
                />
                <line
                  class="cls-50"
                  x1="1204.6"
                  y1="528.31"
                  x2="1224.45"
                  y2="536.92"
                />
                <line
                  class="cls-50"
                  x1="1203.77"
                  y1="533.75"
                  x2="1225.28"
                  y2="531.49"
                />
                <line
                  class="cls-50"
                  x1="1209.36"
                  y1="542.12"
                  x2="1219.7"
                  y2="523.11"
                />
                <line
                  class="cls-50"
                  x1="1205.76"
                  y1="538.96"
                  x2="1223.29"
                  y2="526.28"
                />
                <circle class="cls-49" cx="1214.53" cy="532.62" r="3.4" />
              </g>
              <g id="wheel-3" data-name="wheel">
                <path
                  class="cls-27"
                  d="M1173.81,522.8a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
                />
                <line
                  class="cls-50"
                  x1="1173.81"
                  y1="543.43"
                  x2="1173.81"
                  y2="521.8"
                />
                <line
                  class="cls-50"
                  x1="1179.6"
                  y1="541.75"
                  x2="1168.02"
                  y2="523.48"
                />
                <line
                  class="cls-50"
                  x1="1163.89"
                  y1="528.31"
                  x2="1183.74"
                  y2="536.92"
                />
                <line
                  class="cls-50"
                  x1="1163.05"
                  y1="533.75"
                  x2="1184.57"
                  y2="531.49"
                />
                <line
                  class="cls-50"
                  x1="1168.64"
                  y1="542.12"
                  x2="1178.98"
                  y2="523.11"
                />
                <line
                  class="cls-50"
                  x1="1165.05"
                  y1="538.96"
                  x2="1182.57"
                  y2="526.28"
                />
                <circle class="cls-36" cx="1173.81" cy="532.62" r="3.4" />
              </g>
            </g>
            <g id="candy">
              <g>
                <rect
                  class="cls-51"
                  x="1199.18"
                  y="478.71"
                  width="3.22"
                  height="14.47"
                  rx="0.81"
                  ry="0.81"
                  transform="matrix(0.8, -0.6, 0.6, 0.8, -51.79, 816.2)"
                />
                <path
                  class="cls-23"
                  d="M1202.33,466.35a3,3,0,0,1-1.73-1.36q-0.14-.26-0.3-0.51a11.8,11.8,0,1,0-19.91,12.65q0.17,0.27.36,0.53a3.16,3.16,0,0,1,.6,2.2,10.17,10.17,0,0,0,1.93,7.11,10.42,10.42,0,0,0,14,2.4l7.09-4.5A10.46,10.46,0,0,0,1208,471,10.15,10.15,0,0,0,1202.33,466.35Z"
                />
              </g>
              <path
                class="cls-52"
                d="M1200.06,473a7.59,7.59,0,0,0,2.1-4.15,8.59,8.59,0,0,0-1-2.47c0.07-.44-1.41-2.76-1.51-2.45s0.29,3.84.32,4.06c0.18,1.44-1.36,3.41-1.77,4s-4.23,4.43-4.23,4.43C1194,476.71,1199.82,473.23,1200.06,473Z"
              />
            </g>
          </g>

          <g id="tickets">
            <g id="part2">
              <path
                class="cls-41"
                d="M478.29,482.09h0a8.75,8.75,0,0,1-17.48,0h0a8.72,8.72,0,0,1-1.66,4.72v52.72a5.14,5.14,0,0,0,5.12,5.12H487V522.19H469.19a5.14,5.14,0,0,1-5.12-5.12V501.38a5.14,5.14,0,0,1,5.12-5.12H487v-5.83A8.75,8.75,0,0,1,478.29,482.09Z"
              />
              <path
                class="cls-53"
                d="M464.07,501.38v15.68a5.14,5.14,0,0,0,5.12,5.12H487l-9.87,0L481,513.5c1.46-2.83,3.52-4.08,6-4.16V496.26H469.19A5.14,5.14,0,0,0,464.07,501.38Z"
              />
              <polygon
                class="cls-42"
                points="443.78 481.66 460.76 481.66 460.79 481.66 478.27 481.66 478.29 481.66 487.03 481.66 487.03 456.7 486.92 456.64 443.78 481.66"
              />
              <path
                class="cls-31"
                d="M478.27,481.66h-35c0,0.1,0,.2,0,0.29s0,0.09,0,.13a8.75,8.75,0,0,0,17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,0,17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,0,8.74,8.34v-8.77h-8.77Z"
              />
              <path
                class="cls-25"
                d="M480.85,513.89l-3.68,8.28,9.87,0V509.33A7.12,7.12,0,0,0,480.85,513.89Z"
              />
            </g>
            <g id="part1">
              <path
                class="cls-40"
                d="M495.33,482.09h0a8.75,8.75,0,0,0,17.48,0h0a8.72,8.72,0,0,0,1.66,4.72v52.72a5.14,5.14,0,0,1-5.12,5.12H486.59V522.19h17.84a5.14,5.14,0,0,0,5.12-5.12V501.38a5.14,5.14,0,0,0-5.12-5.12H486.59v-5.83A8.75,8.75,0,0,0,495.33,482.09Z"
              />
              <path
                class="cls-54"
                d="M509.56,501.38v15.68a5.14,5.14,0,0,1-5.12,5.12H486.59v-0.42l9.87,0-3.81-8.13a7,7,0,0,0-6.06-4.28V496.26h17.84A5.14,5.14,0,0,1,509.56,501.38Z"
              />
              <polygon
                class="cls-43"
                points="530.1 481.66 513.11 481.66 513.09 481.66 495.61 481.66 495.58 481.66 486.84 481.66 486.84 456.7 486.96 456.64 530.1 481.66"
              />
              <path
                class="cls-31"
                d="M495.36,481.66h35c0,0.1,0,.2,0,0.29s0,0.09,0,.13a8.75,8.75,0,0,1-17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,1-17.48,0c0-.06,0-0.12,0-0.17s0,0.12,0,.17a8.75,8.75,0,0,1-8.74,8.34v-8.77h8.77Z"
              />
              <path
                class="cls-26"
                d="M492.78,513.89l3.68,8.28-9.87,0V509.33A7.12,7.12,0,0,1,492.78,513.89Z"
              />
            </g>
            <g id="tickets-2" data-name="tickets">
              <path
                class="cls-24"
                d="M508.61,467.25a2.78,2.78,0,1,0-1.77-5.27,0.15,0.15,0,1,1-.09-0.27,2.78,2.78,0,0,0-1.77-5.27L461,471.15a2.78,2.78,0,1,0,1.77,5.27c0.07,0,.16.25,0.09,0.27a2.78,2.78,0,0,0,1.77,5.27c0.07,0,.16.25,0.09,0.27a2.78,2.78,0,1,0,1.77,5.27,0.52,0.52,0,0,1,.15.45,2.78,2.78,0,0,0,1.77,5.27l43.95-14.73a2.78,2.78,0,1,0-1.77-5.27c-0.21.07-.36-0.38-0.15-0.45a2.78,2.78,0,1,0-1.77-5.27A0.15,0.15,0,1,1,508.61,467.25Z"
              />
              <rect
                class="cls-55"
                x="465.29"
                y="465.17"
                width="42.45"
                height="19.59"
                rx="3.1"
                ry="3.1"
                transform="translate(-125.72 179.24) rotate(-18.53)"
              />
              <polygon
                class="cls-23"
                points="499.93 468.86 500.7 470.36 502.37 470.57 501.18 471.77 501.49 473.43 499.99 472.66 498.51 473.47 498.77 471.81 497.54 470.65 499.21 470.38 499.93 468.86"
              />
              <polygon
                class="cls-23"
                points="492.23 466.89 493.4 468.79 495.62 468.9 494.18 470.59 494.75 472.74 492.7 471.89 490.83 473.11 491.01 470.89 489.28 469.49 491.44 468.97 492.23 466.89"
              />
              <polygon
                class="cls-23"
                points="471.31 478.45 471.6 480.11 470.4 481.29 472.07 481.53 472.82 483.04 473.56 481.52 475.23 481.27 474.02 480.1 474.3 478.44 472.81 479.23 471.31 478.45"
              />
              <polygon
                class="cls-23"
                points="476.27 472.24 476.48 474.45 474.78 475.88 476.95 476.37 477.78 478.43 478.91 476.51 481.13 476.36 479.66 474.69 480.19 472.53 478.16 473.42 476.27 472.24"
              />
              <polygon
                class="cls-23"
                points="483.54 467.45 485.27 469.19 487.69 468.76 486.57 470.94 487.73 473.11 485.31 472.72 483.61 474.49 483.23 472.06 481.02 471 483.21 469.89 483.54 467.45"
              />
              <path
                class="cls-38"
                d="M473.76,485.66s9-7.42,12.72-8.53c9.69-2.93,15.42-2,15.42-2"
              />
            </g>
          </g>

          <g id="stall_of_balloons" data-name="stall of balloons">
            <path
              class="cls-41"
              d="M378.15,510.07H357.8v21.19a7.1,7.1,0,0,0,7.08,7.08h13.28V510.07Z"
            />
            <path
              class="cls-40"
              d="M377.86,510.07h20.36v21.19a7.1,7.1,0,0,1-7.08,7.08H377.86V510.07Z"
            />
            <g>
              <path
                class="cls-31"
                d="M398.51,523.57a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="398.51"
                y1="544.2"
                x2="398.51"
                y2="522.57"
              />
              <line
                class="cls-48"
                x1="404.3"
                y1="542.52"
                x2="392.72"
                y2="524.25"
              />
              <line
                class="cls-48"
                x1="388.59"
                y1="529.09"
                x2="408.44"
                y2="537.69"
              />
              <line
                class="cls-48"
                x1="387.76"
                y1="534.52"
                x2="409.27"
                y2="532.26"
              />
              <line
                class="cls-48"
                x1="393.34"
                y1="542.89"
                x2="403.68"
                y2="523.89"
              />
              <line
                class="cls-48"
                x1="389.75"
                y1="539.73"
                x2="407.28"
                y2="527.05"
              />
              <circle class="cls-49" cx="398.51" cy="533.39" r="3.4" />
            </g>
            <g>
              <path
                class="cls-31"
                d="M357.8,523.57a9.82,9.82,0,1,1-9.82,9.82,9.83,9.83,0,0,1,9.82-9.82m0-1a10.82,10.82,0,1,0,10.82,10.82,10.82,10.82,0,0,0-10.82-10.82h0Z"
              />
              <line
                class="cls-48"
                x1="357.8"
                y1="544.2"
                x2="357.8"
                y2="522.57"
              />
              <line
                class="cls-48"
                x1="363.59"
                y1="542.52"
                x2="352"
                y2="524.25"
              />
              <line
                class="cls-48"
                x1="347.87"
                y1="529.09"
                x2="367.72"
                y2="537.69"
              />
              <line
                class="cls-48"
                x1="347.04"
                y1="534.52"
                x2="368.55"
                y2="532.26"
              />
              <line
                class="cls-48"
                x1="352.63"
                y1="542.89"
                x2="362.97"
                y2="523.89"
              />
              <line
                class="cls-48"
                x1="349.03"
                y1="539.73"
                x2="366.56"
                y2="527.05"
              />
              <circle class="cls-36" cx="357.8" cy="533.39" r="3.4" />
            </g>
          </g>

          <g id="bunch_of_balloons1" data-name="bunch of balloons1">
            <ellipse
              class="cls-13"
              cx="362.51"
              cy="380.68"
              rx="10.87"
              ry="8.66"
              transform="translate(-79.86 664.66) rotate(-79.1)"
            />
            <ellipse
              class="cls-31"
              cx="347.58"
              cy="384.21"
              rx="7.83"
              ry="11.84"
              transform="translate(-78.99 90.16) rotate(-13.29)"
            />
            <ellipse
              class="cls-12"
              cx="372.19"
              cy="395.32"
              rx="10.96"
              ry="8.66"
              transform="translate(-158.23 511.31) rotate(-59.06)"
            />
            <circle
              class="cls-36"
              cx="358.4"
              cy="393.42"
              r="9.36"
              transform="translate(-166.76 479.7) rotate(-57)"
            />
            <polygon
              class="cls-56"
              points="352.63 401.64 353.16 404.35 350.63 402.71 352.63 401.64"
            />
            <circle
              class="cls-2"
              cx="368.06"
              cy="425.24"
              r="9.36"
              transform="translate(-185.9 522.31) rotate(-59.06)"
            />
            <ellipse
              class="cls-42"
              cx="337.1"
              cy="392.74"
              rx="7.83"
              ry="11.84"
              transform="translate(-152.19 223.98) rotate(-30.33)"
            />
            <path
              class="cls-57"
              d="M360.64,416.9c0.54-.36,2,0.65,2.29,1.2s0.74,2.94,0,3-1.69-.95-2.29-1.23C359.87,419.56,359.94,417.36,360.64,416.9Z"
            />
            <path
              class="cls-2"
              d="M346.52,407.27l3.75-1.78a1.52,1.52,0,0,1,1.41.06l3.59,2.08a1.52,1.52,0,0,0,2.27-1.51L357,402a1.52,1.52,0,0,1,.49-1.33l3.08-2.77a1.52,1.52,0,0,0-.74-2.62l-4.08-.76a1.52,1.52,0,0,1-1.11-.88L353,389.84a1.52,1.52,0,0,0-2.72-.11l-2,3.64a1.52,1.52,0,0,1-1.18.78l-4.13.43a1.52,1.52,0,0,0-.95,2.56l2.85,3a1.52,1.52,0,0,1,.38,1.36l-0.87,4.06A1.52,1.52,0,0,0,346.52,407.27Z"
            />
            <circle
              class="cls-23"
              cx="373.79"
              cy="410.92"
              r="9.36"
              transform="translate(-100.41 700.25) rotate(-79.1)"
            />
            <polygon
              class="cls-56"
              points="343.63 401.75 345.62 403.68 342.59 403.77 343.63 401.75"
            />
            <ellipse
              class="cls-13"
              cx="361.17"
              cy="410.5"
              rx="10.96"
              ry="8.66"
              transform="translate(-157.66 583.04) rotate(-67.05)"
            />
            <circle
              class="cls-23"
              cx="337.65"
              cy="406.69"
              r="9.36"
              transform="translate(-23.51 20.72) rotate(-3.4)"
            />
            <ellipse
              class="cls-31"
              cx="349.77"
              cy="415.21"
              rx="11.84"
              ry="7.83"
              transform="translate(-85.61 739.52) rotate(-86.57)"
            />
            <ellipse
              class="cls-36"
              cx="358.06"
              cy="422.83"
              rx="10.96"
              ry="8.66"
              transform="translate(-105.96 725.6) rotate(-82.87)"
            />
            <path
              class="cls-12"
              d="M340.92,432.64l3.18-2.67a1.52,1.52,0,0,1,1.38-.3l4,1.1a1.52,1.52,0,0,0,1.81-2l-1.56-3.85a1.52,1.52,0,0,1,.14-1.41l2.29-3.46a1.52,1.52,0,0,0-1.37-2.35l-4.14.29a1.52,1.52,0,0,1-1.3-.57l-2.59-3.24a1.52,1.52,0,0,0-2.66.58l-1,4a1.52,1.52,0,0,1-.94,1.06l-3.88,1.46A1.52,1.52,0,0,0,334,424l3.52,2.2a1.52,1.52,0,0,1,.71,1.22l0.19,4.14A1.52,1.52,0,0,0,340.92,432.64Z"
            />
            <path
              class="cls-58"
              d="M358.1,374.17c-0.39-.52.52-2,1.05-2.36s2.89-.93,3-0.18-0.84,1.74-1.08,2.36C360.81,374.76,358.6,374.83,358.1,374.17Z"
            />
            <path
              class="cls-58"
              d="M358,404.09c-0.35-.54.67-1.94,1.23-2.27s3-.71,3,0-1,1.68-1.25,2.27C360.66,404.89,358.45,404.79,358,404.09Z"
            />
            <path
              class="cls-59"
              d="M352.7,388.55c-0.46-.46.24-2,0.72-2.48s2.73-1.32,3-.6-0.59,1.84-.74,2.49C355.46,388.76,353.28,389.14,352.7,388.55Z"
            />
            <path
              class="cls-59"
              d="M353.87,416c-0.37-.53.6-2,1.15-2.31s2.93-.81,3-0.06-0.91,1.71-1.17,2.31C356.55,416.71,354.34,416.69,353.87,416Z"
            />
            <path
              class="cls-60"
              d="M332.34,402c-0.49-.36.06-1.89,0.49-2.35s2.59-1.56,2.89-.94-0.42,1.76-.52,2.36C335.09,401.79,333,402.41,332.34,402Z"
            />
            <path
              class="cls-61"
              d="M342.58,378.29c-0.6-.24-0.59-2-0.33-2.56s2-2.3,2.5-1.73,0.19,1.93.31,2.58C345.2,377.39,343.36,378.59,342.58,378.29Z"
            />
            <path
              class="cls-61"
              d="M346,408.11c-0.34-.55.7-1.93,1.27-2.25s3-.66,3,0.1-1,1.66-1.29,2.25C348.69,408.95,346.48,408.82,346,408.11Z"
            />
            <path
              class="cls-60"
              d="M370.09,404.88c-0.34-.55.69-1.94,1.25-2.26s3-.69,3,0.07-1,1.67-1.27,2.26C372.74,405.69,370.54,405.58,370.09,404.88Z"
            />
            <path
              class="cls-62"
              d="M370,388.58c-0.26-.59,1-1.82,1.56-2.06s3-.25,3,0.51-1.21,1.51-1.59,2.05S370.29,389.34,370,388.58Z"
            />
            <path
              class="cls-62"
              d="M336.35,421.76c-0.26-.44,3-1.29,3.36-1.57s1.8-3.24,1.88-2.62a15.46,15.46,0,0,1-.71,3.66C340.64,421.85,336.68,422.32,336.35,421.76Z"
            />
            <path
              class="cls-63"
              d="M344.79,395.59c-0.22-.46,3.08-1,3.49-1.27s2.07-3.07,2.1-2.45a15.46,15.46,0,0,1-1,3.59C349.06,396,345.08,396.18,344.79,395.59Z"
            />
            <path
              class="cls-64"
              d="M342.19,416.87c0.3-.41,2.36,2.23,2.77,2.47"
            />
            <path
              class="cls-65"
              d="M329.83,387.09c-0.54-.36-0.17-2.05.21-2.57s2.42-1.83,2.8-1.18-0.21,1.92-.23,2.58C332.58,386.75,330.52,387.55,329.83,387.09Z"
            />
          </g>

          <g id="bunch_of_balloons" data-name="bunch of balloons">
            <path
              class="cls-36"
              d="M433.05,403.31l-3.47,2.27a1.52,1.52,0,0,0-.69,1.24l-0.1,4.15a1.52,1.52,0,0,1-2.47,1.15l-3.23-2.6a1.52,1.52,0,0,0-1.39-.27l-4,1.19a1.52,1.52,0,0,1-1.86-2l1.47-3.88a1.52,1.52,0,0,0-.17-1.4l-2.36-3.41a1.52,1.52,0,0,1,1.33-2.38l4.14,0.2a1.52,1.52,0,0,0,1.28-.6l2.52-3.3a1.52,1.52,0,0,1,2.67.52l1.09,4a1.52,1.52,0,0,0,1,1l3.91,1.37A1.52,1.52,0,0,1,433.05,403.31Z"
            />
            <path
              class="cls-59"
              d="M421.07,393.93c-0.22-.46.42,5.32,0,5.48s-6.84-3.9-6.38-3.49a15.46,15.46,0,0,0,3.43,1.46C418.74,397.54,421.35,394.53,421.07,393.93Z"
            />
            <ellipse
              class="cls-13"
              cx="400.11"
              cy="434.3"
              rx="8.66"
              ry="10.87"
            />
            <ellipse
              class="cls-31"
              cx="414.1"
              cy="440.26"
              rx="11.84"
              ry="7.83"
              transform="translate(-157.18 637.63) rotate(-65.81)"
            />
            <ellipse
              class="cls-12"
              cx="387.84"
              cy="446.52"
              rx="8.66"
              ry="10.96"
              transform="translate(-129.52 159.91) rotate(-20.04)"
            />
            <circle
              class="cls-36"
              cx="401.73"
              cy="447.27"
              r="9.36"
              transform="translate(-138.77 184.02) rotate(-22.1)"
            />
            <circle
              class="cls-2"
              cx="386.23"
              cy="476.68"
              r="9.36"
              transform="translate(-139.95 161.19) rotate(-20.04)"
            />
            <ellipse
              class="cls-42"
              cx="422.78"
              cy="450.62"
              rx="11.84"
              ry="7.83"
              transform="translate(-194.77 471.57) rotate(-48.77)"
            />
            <path
              class="cls-63"
              d="M383.63,469.67c-0.46-.45-2,0.27-2.47.75s-1.29,2.75-.56,3,1.84-.61,2.48-0.77C383.88,472.43,384.23,470.25,383.63,469.67Z"
            />
            <path
              class="cls-2"
              d="M410.78,463.11l-3.34-2.46a1.52,1.52,0,0,0-1.4-.21l-3.92,1.36a1.52,1.52,0,0,1-1.94-1.91l1.3-3.94a1.52,1.52,0,0,0-.23-1.4l-2.5-3.31a1.52,1.52,0,0,1,1.22-2.44l4.15,0a1.52,1.52,0,0,0,1.26-.65l2.37-3.4a1.52,1.52,0,0,1,2.7.41l1.26,4a1.52,1.52,0,0,0,1,1l4,1.2a1.52,1.52,0,0,1,.45,2.69l-3.37,2.42a1.52,1.52,0,0,0-.63,1.26l0.08,4.15A1.52,1.52,0,0,1,410.78,463.11Z"
            />
            <circle class="cls-23" cx="383.31" cy="461.54" r="9.36" />
            <ellipse
              class="cls-13"
              cx="395.78"
              cy="463.51"
              rx="8.66"
              ry="10.96"
              transform="translate(-88.02 92.81) rotate(-12.05)"
            />
            <circle
              class="cls-23"
              cx="419.6"
              cy="464.22"
              r="9.36"
              transform="translate(-133.87 756.17) rotate(-75.7)"
            />
            <ellipse
              class="cls-31"
              cx="406.09"
              cy="470.29"
              rx="11.84"
              ry="7.83"
              transform="translate(-113.02 811.77) rotate(-82.53)"
            />
            <ellipse
              class="cls-36"
              cx="396.51"
              cy="476.2"
              rx="10.96"
              ry="8.66"
              transform="translate(-104.71 840.57) rotate(-86.23)"
            />
            <path
              class="cls-12"
              d="M411.49,489.08l-2.62-3.22a1.52,1.52,0,0,0-1.3-.56l-4.14.33a1.52,1.52,0,0,1-1.4-2.34l2.25-3.48a1.52,1.52,0,0,0,.13-1.41l-1.59-3.83a1.52,1.52,0,0,1,1.8-2.05l4,1.07a1.52,1.52,0,0,0,1.38-.31l3.15-2.7a1.52,1.52,0,0,1,2.51,1.07l0.22,4.14a1.52,1.52,0,0,0,.72,1.21l3.54,2.17a1.52,1.52,0,0,1-.25,2.71L416,483.38a1.52,1.52,0,0,0-.93,1.06l-1,4A1.52,1.52,0,0,1,411.49,489.08Z"
            />
            <path
              class="cls-58"
              d="M398.7,427.08c-0.41-.5-2.06,0-2.54.46s-1.59,2.59-.9,2.9,1.89-.4,2.55-0.49C398.63,429.85,399.23,427.72,398.7,427.08Z"
            />
            <path
              class="cls-58"
              d="M392.24,456c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C392.75,458.75,392.88,456.55,392.24,456Z"
            />
            <path
              class="cls-59"
              d="M399.19,440.24c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C399.7,443,399.84,440.76,399.19,440.24Z"
            />
            <path
              class="cls-59"
              d="M393.94,468.59c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C394.45,471.32,394.59,469.11,393.94,468.59Z"
            />
            <path
              class="cls-60"
              d="M418.77,456.47c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C419.29,459.19,419.42,457,418.77,456.47Z"
            />
            <path
              class="cls-61"
              d="M415.4,431.41c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C415.92,434.13,416.05,431.93,415.4,431.41Z"
            />
            <path
              class="cls-61"
              d="M406.09,460.92c-0.36-.54-2.05-0.17-2.57.2s-1.84,2.41-1.19,2.8,1.92-.21,2.58-0.23C405.74,463.67,406.54,461.61,406.09,460.92Z"
            />
            <path
              class="cls-60"
              d="M380,454.67c-0.5-.4-2,0.46-2.39,1s-1,2.86-.27,3,1.77-.79,2.39-1C380.47,457.4,380.6,455.19,380,454.67Z"
            />
            <path
              class="cls-62"
              d="M382.75,439.94c-0.6-.23-1.77,1.05-2,1.66s-0.1,3,.66,3,1.45-1.29,2-1.69S383.52,440.24,382.75,439.94Z"
            />
            <path
              class="cls-62"
              d="M413.22,473.36c-0.22-.46-2.74,1.74-3.19,1.91s-3.69-.33-3.23.09a15.46,15.46,0,0,0,3.43,1.46C410.88,477,413.49,473.95,413.22,473.36Z"
            />
            <path
              class="cls-63"
              d="M408.12,448.29c-0.22-.46-2.74,1.74-3.19,1.91s-3.69-.33-3.23.09a15.46,15.46,0,0,0,3.43,1.46C405.79,451.89,408.4,448.88,408.12,448.29Z"
            />
            <path
              class="cls-64"
              d="M413.22,473.36c-0.22-.46-2.74,1.74-3.19,1.91"
            />
            <path
              class="cls-65"
              d="M427.37,442.88c-0.17-.62-1.89-0.8-2.51-0.61s-2.5,1.72-2,2.29,1.89,0.4,2.53.59C426.19,445.39,427.59,443.68,427.37,442.88Z"
            />
          </g>

          <g id="bench1">
            <rect
              class="cls-36"
              x="207.87"
              y="530.96"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
              transform="translate(2.06 -0.79) rotate(0.22)"
            />
            <rect
              class="cls-49"
              x="250.28"
              y="509.59"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
              transform="translate(500.92 1054.68) rotate(-179.78)"
            />
            <rect
              class="cls-49"
              x="213.28"
              y="509.45"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
              transform="translate(426.92 1054.25) rotate(-179.78)"
            />
            <rect
              class="cls-49"
              x="256.01"
              y="531.14"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
              transform="translate(512.33 1079.64) rotate(-179.78)"
            />
            <path
              class="cls-36"
              d="M233.08,526.68l-22.41-.09c-1.27,0-2.31-1.32-2.3-2.93h0c0-1.61,1.05-2.92,2.33-2.91l22.41,0.09"
            />
            <path
              class="cls-36"
              d="M233.06,533l-25-.1a1.79,1.79,0,0,1,0-3.49l25,0.1V533Z"
            />
            <path
              class="cls-36"
              d="M233.11,518.65l-22.41-.09c-1.27,0-2.31-1.32-2.3-2.93s1.05-2.92,2.33-2.91l22.41,0.09"
            />
            <path
              class="cls-49"
              d="M233.08,526.68l22.41,0.09c1.27,0,2.32-1.31,2.33-2.91h0c0-1.61-1-2.93-2.3-2.93l-22.41-.09"
            />
            <path
              class="cls-49"
              d="M233.06,533l25,0.1a1.79,1.79,0,0,0,0-3.49l-25-.1"
            />
            <path
              class="cls-49"
              d="M233.11,518.65l22.41,0.09c1.27,0,2.32-1.31,2.33-2.91s-1-2.93-2.3-2.93l-22.41-.09"
            />
          </g>

          <g id="bench2">
            <rect
              class="cls-49"
              x="814.65"
              y="509.77"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
            />
            <rect
              class="cls-36"
              x="808.88"
              y="531.3"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
            />
            <rect
              class="cls-49"
              x="851.23"
              y="509.77"
              width="2.37"
              height="34.54"
              rx="1.19"
              ry="1.19"
              transform="translate(1704.84 1054.08) rotate(-180)"
            />
            <rect
              class="cls-49"
              x="857.01"
              y="531.3"
              width="2.37"
              height="16.38"
              rx="1.19"
              ry="1.19"
              transform="translate(1716.39 1078.98) rotate(180)"
            />
            <path
              class="cls-36"
              d="M834,526.93H811.63c-1.27,0-2.31-1.32-2.31-2.92h0c0-1.61,1-2.92,2.31-2.92H834"
            />
            <path
              class="cls-36"
              d="M834,533.22H809a1.79,1.79,0,0,1,0-3.49h25v3.49Z"
            />
            <path
              class="cls-36"
              d="M834,518.89H811.63c-1.27,0-2.31-1.32-2.31-2.92s1-2.92,2.31-2.92H834"
            />
            <path
              class="cls-49"
              d="M834,526.93h22.41c1.27,0,2.31-1.32,2.31-2.92h0c0-1.61-1-2.92-2.31-2.92H834"
            />
            <path
              class="cls-49"
              d="M834,533.22h25a1.79,1.79,0,0,0,0-3.49H834"
            />
            <path
              class="cls-49"
              d="M834,518.89h22.41c1.27,0,2.31-1.32,2.31-2.92s-1-2.92-2.31-2.92H834"
            />
          </g>

          <g id="light4">
            <g>
              <path
                class="cls-36"
                d="M58.58,512.54l0.08,0h1.73V443.31H58.58v69.23Z"
              />
              <path
                class="cls-42"
                d="M58.58,512.54a1.8,1.8,0,0,0-1.73,1.79v8.35a1.87,1.87,0,0,1,.34-0.07h3.2V512.52H58.65Z"
              />
              <path
                class="cls-30"
                d="M57.19,522.61a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V522.61h-3.2Z"
              />
              <path
                class="cls-25"
                d="M56.59,541.48H54.66a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86H56.59Z"
              />
              <path
                class="cls-25"
                d="M57.31,439H55.74a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64V439H57.31Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M61.71,512.54l-0.08,0H59.9V443.31h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M61.71,512.54a1.8,1.8,0,0,1,1.73,1.79v8.35a1.87,1.87,0,0,0-.34-0.07H59.9V512.52h1.73Z"
              />
              <path
                class="cls-30"
                d="M63.1,522.61a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9H59.9V522.61h3.2Z"
              />
              <path
                class="cls-25"
                d="M63.7,541.48h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91H59.9v-4.86H63.7Z"
              />
              <path
                class="cls-25"
                d="M63,439h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69H59.9V439H63Z"
              />
              <circle class="cls-5" cx="60.39" cy="428.26" r="11.66" />
            </g>
            <path
              class="cls-66"
              d="M55.86,419.58c-0.39-.51-2.06,0-2.55.38s-1.67,2.53-1,2.87,1.91-.34,2.56-0.4C55.7,422.35,56.36,420.24,55.86,419.58Z"
            />
          </g>

          <g id="light3">
            <g>
              <path
                class="cls-36"
                d="M450.48,511.39l0.08,0h1.73V442.16h-1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M450.48,511.39a1.8,1.8,0,0,0-1.73,1.79v8.35a1.87,1.87,0,0,1,.34-0.07h3.2V511.38h-1.73Z"
              />
              <path
                class="cls-30"
                d="M449.1,521.46a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V521.46h-3.2Z"
              />
              <path
                class="cls-25"
                d="M448.5,540.34h-1.93a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86H448.5Z"
              />
              <path
                class="cls-25"
                d="M449.22,437.84h-1.57a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64v-4.32h-3.08Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M453.62,511.39l-0.08,0h-1.73V442.16h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M453.62,511.39a1.8,1.8,0,0,1,1.73,1.79v8.35a1.87,1.87,0,0,0-.34-0.07h-3.2V511.38h1.73Z"
              />
              <path
                class="cls-30"
                d="M455,521.46a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9h-5.18V521.46H455Z"
              />
              <path
                class="cls-25"
                d="M455.6,540.34h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91h-5.73v-4.86h3.79Z"
              />
              <path
                class="cls-25"
                d="M454.89,437.84h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69h-4.64v-4.32h3.08Z"
              />
              <circle class="cls-5" cx="452.29" cy="427.12" r="11.66" />
            </g>
            <path
              class="cls-66"
              d="M447.86,419.58c-0.39-.51-2.06,0-2.55.38s-1.67,2.53-1,2.87,1.91-.34,2.56-0.4C447.7,422.35,448.36,420.24,447.86,419.58Z"
            />
          </g>

          <g id="light2">
            <g>
              <path
                class="cls-36"
                d="M865.57,510.59l0.08,0h1.73V441.36h-1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M865.57,510.59a1.8,1.8,0,0,0-1.73,1.79v8.35a1.87,1.87,0,0,1,.34-0.07h3.2V510.57h-1.73Z"
              />
              <path
                class="cls-30"
                d="M864.18,520.66a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V520.66h-3.2Z"
              />
              <path
                class="cls-25"
                d="M863.59,539.53h-1.93a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86h-3.79Z"
              />
              <path
                class="cls-25"
                d="M864.3,437h-1.57a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64V437H864.3Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M868.71,510.59l-0.08,0H866.9V441.36h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M868.71,510.59a1.8,1.8,0,0,1,1.73,1.79v8.35a1.87,1.87,0,0,0-.34-0.07h-3.2V510.57h1.73Z"
              />
              <path
                class="cls-30"
                d="M870.09,520.66a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9H866.9V520.66h3.2Z"
              />
              <path
                class="cls-25"
                d="M870.69,539.53h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91H866.9v-4.86h3.79Z"
              />
              <path
                class="cls-25"
                d="M870,437h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69H866.9V437H870Z"
              />
              <circle class="cls-5" cx="867.38" cy="426.31" r="11.66" />
            </g>
          </g>

          <g id="light1">
            <g>
              <path
                class="cls-36"
                d="M1232.63,510.82l0.08,0h1.73V441.59h-1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M1232.63,510.82a1.8,1.8,0,0,0-1.73,1.79V521a1.87,1.87,0,0,1,.34-0.07h3.2V510.81h-1.73Z"
              />
              <path
                class="cls-30"
                d="M1231.25,520.89a1.87,1.87,0,0,0-.34.07,2,2,0,0,0-1.64,1.91v16.9h5.18V520.89h-3.2Z"
              />
              <path
                class="cls-25"
                d="M1230.65,539.77h-1.93a1.91,1.91,0,0,0-1.91,1.91v1.05a1.91,1.91,0,0,0,1.91,1.91h5.73v-4.86h-3.79Z"
              />
              <path
                class="cls-25"
                d="M1231.37,437.27h-1.57a1.62,1.62,0,0,0-1.55,1.69v0.93a1.62,1.62,0,0,0,1.55,1.69h4.64v-4.32h-3.08Z"
              />
            </g>
            <g>
              <path
                class="cls-36"
                d="M1235.77,510.82l-0.08,0H1234V441.59h1.81v69.23Z"
              />
              <path
                class="cls-42"
                d="M1235.77,510.82a1.8,1.8,0,0,1,1.73,1.79V521a1.87,1.87,0,0,0-.34-0.07H1234V510.81h1.73Z"
              />
              <path
                class="cls-30"
                d="M1237.15,520.89a1.87,1.87,0,0,1,.34.07,2,2,0,0,1,1.64,1.91v16.9H1234V520.89h3.2Z"
              />
              <path
                class="cls-25"
                d="M1237.75,539.77h1.93a1.91,1.91,0,0,1,1.91,1.91v1.05a1.91,1.91,0,0,1-1.91,1.91H1234v-4.86h3.79Z"
              />
              <path
                class="cls-25"
                d="M1237,437.27h1.57a1.62,1.62,0,0,1,1.55,1.69v0.93a1.62,1.62,0,0,1-1.55,1.69H1234v-4.32H1237Z"
              />
              <circle class="cls-5" cx="1234.44" cy="426.55" r="11.66" />
            </g>
            <path
              class="cls-66"
              d="M1229.86,419.58c-0.39-.51-2.06,0-2.55.38s-1.67,2.53-1,2.87,1.91-.34,2.56-0.4C1229.7,422.35,1230.36,420.24,1229.86,419.58Z"
            />
          </g>
        </svg>
      </div>

      <!--效果html开始-->
      <div class="mnBody">
        <div class="kai animate" id="running">
          <div class="eyes"></div>
        </div>
        <div class="slide">
          <div class="stage">
            <div class="foreground-trees"></div>
            <div class="landscape"></div>
            <div class="behind-trees"></div>
            <div class="behind-mountains"></div>
          </div>
        </div>
      </div>
      <!--效果html结束-->

      <!-- ====================================
——— STAF SECTION
===================================== -->
      <section class="pb-7" style="background-color: #fff4e0">
        <div class="container">
          <div
            class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp"
          >
            <span class="shape shape-left bg-info"></span>
            <h2 class="text-danger">最新文章</h2>
            <span class="shape shape-right bg-info"></span>
          </div>

          <div
            class="team-slider owl-carousel owl-theme wow fadeInUp"
            dir="ltr"
          >
            <!-- Card -->
            <div class="card" v-for="item in articles" :key="item.id">
              <router-link :to="`/article/${item.id}`" class="position-relative">
                <img
                  class="card-img-top"
                  :src="item.banner"
                  alt="Card image"
                />
                <div class="card-img-overlay">
                  <span :class="`badge-${item.themeClass}`" class="badge badge-rounded-circle"
                    ><i class="fa fa-file-text-o" aria-hidden="true"></i
                  ></span>
                </div>
              </router-link>
              <div
                :class="`border-${item.themeClass}`"
                class="card-body border-top-5 px-3 rounded-bottom"
              >
                <h3 class="card-title">
                  <router-link :to="`/article/${item.id}`"
                    :class="`text-${item.themeClass}`"
                    class="text-capitalize d-block text-truncate">
                    {{ item.title }}
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- ====================================
——— STAF SECTION
===================================== -->
      <section class="pt-7" style="background-color: #fff4e0">
        <div class="container">
          <div
            class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp"
          >
            <span class="shape shape-left bg-info"></span>
            <h2 class="text-danger">熱門影音</h2>
            <span class="shape shape-right bg-info"></span>
          </div>

          <!-- THE YOUTUBE PLAYER -->
          <div
            class="row flex-column justify-content-center align-items-center"
          >
            <div class="col-lg-9">
              <div class="card wow fadeInUp">
                <div
                  class="embed-responsive embed-responsive-16by9 rounded-top"
                >
                  <iframe
                    v-if="bannerVideo"
                    id="vid_frame"
                    class="embed-responsive-item"
                    :src="`https://www.youtube.com/embed/${bannerVideo}`"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="card-body px-3 rounded-bottom">
                  <div
                    class="vid-slider owl-carousel owl-theme wow fadeInUp"
                    dir="ltr"
                  >
                    <div
                      v-for="item in videos" :key="item.id"
                      class="position-relative"
                      :onClick="`document.getElementById('vid_frame').src='https://youtube.com/embed/${item.video}?autoplay=1&rel=0&showinfo=0&autohide=1'`"
                    >
                      <div class="">
                        <img :src="item.banner" />
                      </div>
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style="background-color: #fff4e0">
        <svg
          version="1.1"
          id="Layer_222"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 320 2702 1160"
          enable-background="new 0 320 2702 1160"
          xml:space="preserve"
        >
          <defs>
            <g id="cabin1">
              <polygon
                fill="#413A5D"
                points="2.959,32.001 5.229,39.273 68.499,39.24 74.809,31.965  "
              />
              <circle fill="#413A5D" cx="9.278" cy="40.577" r="4.941" />
              <circle fill="#413A5D" cx="64.727" cy="40.55" r="4.941" />
              <polygon
                fill="#EC6739"
                points="76.183,34.254 73.249,35.604 2.302,35.639 0.011,33.42 0,9.701 59.085,9.673 68.144,14.197
                76.175,18.92  "
              />
              <rect
                x="2.009"
                y="0.214"
                fill="#EC6739"
                width="4.528"
                height="22.438"
              />
              <rect
                x="32.36"
                y="-0.067"
                fill="#EC6739"
                width="4.527"
                height="22.438"
              />

              <rect
                x="0.027"
                y="21.498"
                transform="matrix(-1 5.127509e-04 -5.127509e-04 -1 76.2347 47.1426)"
                fill="#F7FBFB"
                width="76.168"
                height="4.166"
              />
            </g>
            <g id="cabin2">
              <polygon
                fill="#413A5D"
                points="1.401,31.965 7.71,39.24 70.98,39.273 73.251,32.001  "
              />
              <circle fill="#413A5D" cx="66.931" cy="40.577" r="4.941" />
              <circle fill="#413A5D" cx="11.483" cy="40.55" r="4.941" />
              <polygon
                fill="#EC6739"
                points="0.034,18.92 8.066,14.197 17.124,9.673 76.21,9.701 76.199,33.42 73.907,35.639 2.96,35.604
          0.026,34.254  "
              />
              <rect
                x="69.673"
                y="0.214"
                fill="#EC6739"
                width="4.529"
                height="22.438"
              />
              <rect
                x="39.321"
                y="-0.067"
                fill="#EC6739"
                width="4.529"
                height="22.438"
              />
              <rect
                x="0.014"
                y="21.498"
                fill="#F7FBFB"
                width="76.168"
                height="4.166"
              />
            </g>
          </defs>

          <pattern
            x="0.5"
            y="1819"
            width="400.552"
            height="313.691"
            patternUnits="userSpaceOnUse"
            id="New_Pattern_10"
            viewBox="0 -321.326 400.552 313.691"
            overflow="visible"
          >
            <g>
              <polygon
                fill="none"
                points="0,-7.635 400.552,-7.635 400.552,-321.326 0,-321.326    "
              />
              <g>
                <polygon
                  fill="none"
                  points="0,-7.635 400.552,-7.635 400.552,-321.327 0,-321.327      "
                />
                <g>
                  <polygon
                    fill="#FFFFFF"
                    points="77.17,-268.03 61.837,-268.03 61.837,-266.03 77.17,-266.03         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="74.218,-296.491 63.375,-285.648 64.79,-284.234 75.632,-295.077        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="14.561,-271.488 23.93,-259.35 25.513,-260.571 16.144,-272.711         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="114.608,-256.672 128.635,-250.477 129.442,-252.305 115.416,-258.502         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="165.341,-295.366 175.065,-307.223 173.52,-308.491 163.795,-296.635        "
                  />
                  <polyline
                    fill="#FFFFFF"
                    points="181.057,-263.143 170.528,-274.29 169.074,-272.918 179.603,-261.77        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="398.167,-246.57 389.178,-258.992 387.558,-257.82 396.547,-245.398         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="131.234,-313.691 138.041,-327.433 136.25,-328.32 129.442,-314.58        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="350.486,-304.387 348.308,-289.211 350.288,-288.926 352.466,-304.104         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="321.894,-305.508 331.087,-293.235 332.688,-294.434 323.495,-306.706         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="338.17,-242.904 351.517,-250.454 350.532,-252.194 337.186,-244.645        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="276.525,-260.899 289.872,-268.45 288.887,-270.19 275.541,-262.641         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="389.637,-285.908 382.472,-299.465 380.704,-298.53 387.869,-284.973        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="228.089,-245.799 215.454,-254.488 214.32,-252.842 226.955,-244.151        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="225.279,-288.723 240.559,-290.01 240.39,-292.002 225.112,-290.717         "
                  />
                </g>
                <g>
                  <polygon
                    fill="#FFFFFF"
                    points="77.17,-111.185 61.837,-111.185 61.837,-109.185 77.17,-109.185         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="74.218,-139.646 63.375,-128.803 64.79,-127.389 75.632,-138.231        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="14.561,-114.643 23.93,-102.504 25.513,-103.726 16.144,-115.864        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="23.619,-178.219 32.988,-166.08 34.571,-167.301 25.202,-179.44         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="114.608,-99.826 128.635,-93.631 129.442,-95.461 115.416,-101.655        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="154.649,-200.308 158.094,-185.366 160.042,-185.815 156.599,-200.758         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="89.916,-180.476 102.916,-172.346 103.976,-174.041 90.976,-182.172         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="165.341,-138.521 175.065,-150.377 173.52,-151.646 163.795,-139.789        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="69.073,-193.893 54.154,-197.439 53.691,-195.495 68.609,-191.946         "
                  />
                  <polyline
                    fill="#FFFFFF"
                    points="181.057,-106.297 170.528,-117.444 169.074,-116.071 179.603,-104.924        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="140.288,-222.395 155.321,-225.41 154.928,-227.37 139.894,-224.355         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="202.355,-231.315 190.782,-241.374 189.469,-239.865 201.043,-229.805         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="205.974,-167.199 194.402,-177.258 193.089,-175.749 204.663,-165.69        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="398.167,-89.725 389.178,-102.146 387.558,-100.975 396.547,-88.553         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="1.792,-224.02 8.599,-237.76 6.808,-238.648 0,-224.908         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="66.302,-228.312 69.747,-213.37 71.695,-213.819 68.251,-228.76         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="131.234,-156.846 138.041,-170.588 136.25,-171.475 129.442,-157.734        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="350.486,-147.543 348.308,-132.364 350.288,-132.08 352.466,-147.258        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="321.894,-148.661 331.087,-136.389 332.688,-137.588 323.495,-149.86        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="338.17,-86.059 351.517,-93.608 350.532,-95.348 337.186,-87.799        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="276.525,-104.053 289.872,-111.604 288.887,-113.344 275.541,-105.795         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="354.696,-192.252 362.82,-205.258 361.125,-206.315 353,-193.312        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="253.405,-166.479 240.771,-175.168 239.637,-173.521 252.271,-164.831         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="213.649,-206.315 228.928,-207.603 228.761,-209.594 213.481,-208.309         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="283.707,-169.999 293.601,-181.715 292.074,-183.004 282.18,-171.289        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="341.865,-212.415 331.509,-223.723 330.034,-222.374 340.39,-211.064        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="332.258,-174.59 321.903,-185.899 320.428,-184.549 330.783,-173.239        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="389.637,-129.062 382.472,-142.618 380.704,-141.685 387.869,-128.129         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="249.367,-225.814 248.518,-241.124 246.521,-241.012 247.371,-225.703         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="228.089,-88.953 215.454,-97.643 214.32,-95.996 226.955,-87.305        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="225.279,-131.877 240.559,-133.164 240.39,-135.156 225.112,-133.871        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="312.966,-207.543 300.331,-216.232 299.198,-214.586 311.832,-205.896         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="378.197,-228.275 385.003,-242.017 383.212,-242.904 376.405,-229.164         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="400.552,-178.641 385.633,-182.188 385.17,-180.243 400.088,-176.694        "
                  />
                </g>
                <g>
                  <polygon
                    fill="#FFFFFF"
                    points="23.619,-21.373 32.988,-9.234 34.571,-10.456 25.202,-22.594        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="154.649,-43.463 158.094,-28.521 160.042,-28.969 156.599,-43.911         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="89.916,-23.631 102.916,-15.5 103.976,-17.195 90.976,-25.325         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="69.073,-37.047 54.154,-40.594 53.691,-38.649 68.609,-35.101         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="140.288,-65.549 155.321,-68.564 154.928,-70.524 139.894,-67.51        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="202.355,-74.469 190.782,-84.528 189.469,-83.02 201.043,-72.961        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="205.974,-10.354 194.402,-20.412 193.089,-18.903 204.663,-8.844        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="1.792,-67.174 8.599,-80.915 6.808,-81.803 0,-68.062         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="66.302,-71.467 69.747,-56.524 71.695,-56.974 68.251,-71.915         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="131.234,0 138.041,-13.741 136.25,-14.629 129.442,-0.889         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="354.696,-35.406 362.82,-48.411 361.125,-49.469 353,-36.467        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="253.405,-9.633 240.771,-18.322 239.637,-16.674 252.271,-7.985         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="213.649,-49.469 228.928,-50.758 228.761,-52.749 213.481,-51.463         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="283.707,-13.153 293.601,-24.868 292.074,-26.157 282.18,-14.443        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="341.865,-55.569 331.509,-66.879 330.034,-65.528 340.39,-54.219        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="332.258,-17.744 321.903,-29.053 320.428,-27.703 330.783,-16.394         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="249.367,-68.969 248.518,-84.278 246.521,-84.168 247.371,-68.857         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="312.966,-50.697 300.331,-59.387 299.198,-57.739 311.832,-49.049         "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="378.197,-71.43 385.003,-85.172 383.212,-86.059 376.405,-72.318        "
                  />
                  <polygon
                    fill="#FFFFFF"
                    points="400.552,-21.795 385.633,-25.342 385.17,-23.397 400.088,-19.849        "
                  />
                </g>
              </g>
            </g>
          </pattern>

          <pattern
            x="0"
            y="1819"
            width="107.793"
            height="107.793"
            patternUnits="userSpaceOnUse"
            id="New_pattern4"
            viewBox="0 -107.793 107.793 107.793"
            overflow="visible"
          >
            <!-- <rect width="20" height="20" x="0" y="0" fill="red" /> -->
            <polygon
              fill="none"
              points="0,0 107.793,0 107.793,-107.793 0,-107.793    "
            />
            <polygon
              fill="none"
              points="0,0 107.793,0 107.793,-107.793 0,-107.793      "
            />
            <path
              fill="#00A0B1"
              d="M107.793,0v-107.793H0V0H107.793z M5.886-10.632v-86.53L49.15-53.898L5.886-10.632z M53.969-49.08
        L97.472-5.576H10.463L53.969-49.08z M10.773-101.908h86.386L53.967-58.715L10.773-101.908z M101.907-97.018v86.24L58.786-53.896
        L101.907-97.018z"
            />
            <path
              fill="#00A0B1"
              d="M0-5.576v-14.867c11.291,0,20.443,9.152,20.443,20.443H5.576C5.576-3.08,3.08-5.576,0-5.576z"
            />
            <path
              fill="#008F9E"
              d="M0,0v-5.576C3.08-5.576,5.576-3.08,5.576,0H0z"
            />
            <path
              fill="#00A0B1"
              d="M102.217,0H87.35c0-11.291,9.152-20.443,20.443-20.443v14.867C104.713-5.576,102.217-3.08,102.217,0z"
            />
            <path
              fill="#008F9E"
              d="M107.793,0h-5.576c0-3.08,2.496-5.576,5.576-5.576V0z"
            />
            <path
              fill="#00A0B1"
              d="M5.575-107.793h14.868C20.443-96.502,11.291-87.35,0-87.35v-14.867
        C3.08-102.217,5.575-104.713,5.575-107.793z"
            />
            <path
              fill="#008F9E"
              d="M0-107.793h5.575c0,3.08-2.495,5.576-5.575,5.576V-107.793z"
            />
            <path
              fill="#00A0B1"
              d="M107.793-102.218v14.868c-11.291,0-20.443-9.152-20.443-20.443h14.867
        C102.217-104.713,104.713-102.218,107.793-102.218z"
            />
            <path
              fill="#008F9E"
              d="M107.793-107.793v5.575c-3.08,0-5.576-2.495-5.576-5.575H107.793z"
            />
          </pattern>

          <pattern
            x="0.5"
            y="1819"
            width="107.793"
            height="107.793"
            patternUnits="userSpaceOnUse"
            id="New_Pattern_5"
            viewBox="0 -107.793 107.793 107.793"
            overflow="visible"
          >
            <g>
              <polygon
                fill="none"
                points="0,0 107.793,0 107.793,-107.793 0,-107.793    "
              />
              <g>
                <polygon
                  fill="none"
                  points="0,0 107.793,0 107.793,-107.793 0,-107.793      "
                />
                <path
                  fill="#EECA43"
                  d="M0,0v-107.793h107.793V0H0z M49.15-53.898L5.886-97.162v86.53L49.15-53.898z M10.463-5.576h87.009
            L53.969-49.08L10.463-5.576z M53.967-58.715l43.192-43.193H10.773L53.967-58.715z M58.786-53.896l43.121,43.119v-86.24
            L58.786-53.896z"
                />
                <path
                  fill="#EECA43"
                  d="M0-5.576v-14.867c11.291,0,20.443,9.152,20.443,20.443H5.576C5.576-3.08,3.08-5.576,0-5.576z"
                />
                <path
                  fill="#D5B43C"
                  d="M0,0v-5.576C3.08-5.576,5.576-3.08,5.576,0H0z"
                />
                <path
                  fill="#EECA43"
                  d="M102.217,0H87.35c0-11.291,9.152-20.443,20.443-20.443v14.867C104.713-5.576,102.217-3.08,102.217,0z"
                />
                <path
                  fill="#EECA43"
                  d="M5.575-107.793h14.868C20.443-96.502,11.291-87.35,0-87.35v-14.867
            C3.08-102.217,5.575-104.713,5.575-107.793z"
                />
                <path
                  fill="#D5B43C"
                  d="M0-107.793h5.575c0,3.08-2.495,5.576-5.575,5.576V-107.793z"
                />
                <path
                  fill="#EECA43"
                  d="M107.793-102.218v14.868c-11.291,0-20.443-9.152-20.443-20.443h14.867
            C102.217-104.713,104.713-102.218,107.793-102.218z"
                />
                <path
                  fill="#D5B43C"
                  d="M107.793,0h-5.576c0-3.08,2.496-5.576,5.576-5.576V0z"
                />
                <path
                  fill="#D5B43C"
                  d="M107.793-107.793v5.576c-3.08,0-5.576-2.496-5.576-5.576H107.793z"
                />
              </g>
            </g>
          </pattern>

          <pattern
            x="0.5"
            y="1819"
            width="107.793"
            height="107.793"
            patternUnits="userSpaceOnUse"
            id="New_Pattern_8"
            viewBox="0 -107.793 107.793 107.793"
            overflow="visible"
          >
            <g>
              <polygon
                fill="none"
                points="0,0 107.793,0 107.793,-107.793 0,-107.793    "
              />
              <g>
                <polygon
                  fill="none"
                  points="0,0 107.793,0 107.793,-107.793 0,-107.793      "
                />
                <path
                  fill="#008F9E"
                  d="M0,0v-107.793h107.793V0H0z M49.15-53.898L5.886-97.162v86.53L49.15-53.898z M10.463-5.576h87.009
            L53.969-49.08L10.463-5.576z M53.967-58.715l43.192-43.193H10.773L53.967-58.715z M58.786-53.896l43.121,43.119v-86.24
            L58.786-53.896z"
                />
                <path
                  fill="#008F9E"
                  d="M0-5.576v-14.867c11.291,0,20.443,9.152,20.443,20.443H5.576C5.576-3.08,3.08-5.576,0-5.576z"
                />
                <path
                  fill="#007785"
                  d="M0,0v-5.576C3.08-5.576,5.576-3.08,5.576,0H0z"
                />
                <path
                  fill="#008F9E"
                  d="M102.217,0H87.35c0-11.291,9.152-20.443,20.443-20.443v14.867C104.713-5.576,102.217-3.08,102.217,0z"
                />
                <path
                  fill="#007785"
                  d="M107.793,0h-5.576c0-3.08,2.496-5.576,5.576-5.576V0z"
                />
                <path
                  fill="#008F9E"
                  d="M5.575-107.793h14.868C20.443-96.502,11.291-87.35,0-87.35v-14.867
            C3.08-102.217,5.575-104.713,5.575-107.793z"
                />
                <path
                  fill="#007785"
                  d="M0-107.793h5.575c0,3.08-2.495,5.576-5.575,5.576V-107.793z"
                />
                <path
                  fill="#008F9E"
                  d="M107.793-102.218v14.868c-11.291,0-20.443-9.152-20.443-20.443h14.867
            C102.217-104.713,104.713-102.218,107.793-102.218z"
                />
                <path
                  fill="#007785"
                  d="M107.793-107.793v5.575c-3.08,0-5.576-2.495-5.576-5.575H107.793z"
                />
              </g>
            </g>
          </pattern>

          <pattern
            x="0.5"
            y="1819"
            width="107.793"
            height="107.793"
            patternUnits="userSpaceOnUse"
            id="New_Pattern_Swatch_3"
            viewBox="0 -107.793 107.793 107.793"
            overflow="visible"
          >
            <g>
              <polygon
                fill="none"
                points="0,0 107.793,0 107.793,-107.793 0,-107.793    "
              />
              <g>
                <polygon
                  fill="none"
                  points="0,-107.793 107.793,-107.793 107.793,0 0,0      "
                />
                <path
                  fill="#D5B43C"
                  d="M107.793,0v-107.793H0V0H107.793z M5.886-10.632v-86.53L49.15-53.898L5.886-10.632z M53.969-49.08
            L97.472-5.576H10.463L53.969-49.08z M10.773-101.908h86.386L53.967-58.715L10.773-101.908z M101.907-97.018v86.24L58.786-53.896
            L101.907-97.018z"
                />
                <path
                  fill="#D5B43C"
                  d="M0-5.576v-14.867c11.291,0,20.443,9.152,20.443,20.443H5.576C5.576-3.08,3.08-5.576,0-5.576z"
                />
                <path
                  fill="#CAA92D"
                  d="M0,0v-5.576C3.08-5.576,5.576-3.08,5.576,0H0z"
                />
                <path
                  fill="#D5B43C"
                  d="M102.217,0H87.35c0-11.291,9.152-20.443,20.443-20.443v14.867C104.713-5.576,102.217-3.08,102.217,0z"
                />
                <path
                  fill="#D5B43C"
                  d="M5.575-107.793h14.868C20.443-96.502,11.291-87.35,0-87.35v-14.867
            C3.08-102.217,5.575-104.713,5.575-107.793z"
                />
                <path
                  fill="#CAA92D"
                  d="M0-107.793h5.575c0,3.08-2.495,5.576-5.575,5.576V-107.793z"
                />
                <path
                  fill="#D5B43C"
                  d="M107.793-102.218v14.868c-11.291,0-20.443-9.152-20.443-20.443h14.867
            C102.217-104.713,104.713-102.218,107.793-102.218z"
                />
                <path
                  fill="#CAA92D"
                  d="M107.793,0h-5.576c0-3.08,2.496-5.576,5.576-5.576V0z"
                />
                <path
                  fill="#CAA92D"
                  d="M107.793-107.793v5.576c-3.08,0-5.576-2.496-5.576-5.576H107.793z"
                />
              </g>
            </g>
          </pattern>

          <g>
            <polygon
              fill="#64629A"
              points="-0.083,1278.267 2210.857,1333.579 1800.605,1016.819 1679.713,1160.834 1190.756,788.797
        838.707,1163.834 53.643,1133.831 -0.083,1104.281  "
            />
            <polygon
              fill="#48466E"
              points="1403.777,1163.834 1100.746,1058.823 426.969,317.749 -0.083,1028.726 -0.083,1300.471
        1184.633,1559.255 1963.41,1295.234  "
            />
            <path
              fill="#6CA830"
              d="M1206.812,1818.984l-10.305-105.613c0-236.256-507.619-434.996-1196.591-492.997v598.61H1206.812z"
            />
            <path
              fill="#78B938"
              d="M2702.188,1366.129c-304.271-78.789-703.087-126.556-1139.711-126.556
        c-703.911,0-1288.446,257.549-1562.561,407.429v102.684h2702.272V1366.129z"
            />
            <g>
              <rect
                x="2389.143"
                y="1526.029"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M2395.018,1527.279c1.375,0.125,28.625-1.375,28.625-1.375l-7.625-17l9,1.375l-18.625-37.875l8.125,4.875
          l-15.375-29.375l6.625,3l-11.75-24l-13.125,28.625l6.25-3.625l-14.25,33.875l9.5-6.125l-18.625,32.25l12.375-8.375l-11.625,21.75
          L2395.018,1527.279z"
              />
              <g opacity="0.3">
                <polygon
                  points="2424.643,1509.553 2425.018,1510.314 2424.643,1510.258      "
                />
                <polygon
                  points="2363.768,1511.939 2382.393,1479.689 2372.893,1485.814 2387.143,1451.939 2380.893,1455.564 2394.018,1426.939
            2394.018,1527.25 2364.518,1525.314 2376.143,1503.564      "
                />
              </g>
            </g>
            <g>
              <rect
                x="2584.217"
                y="1399.895"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M2590.092,1401.145c1.375,0.125,28.625-1.375,28.625-1.375l-7.625-17l9,1.375l-18.625-37.875l8.125,4.875
          l-15.375-29.375l6.625,3l-11.75-24l-13.125,28.625l6.25-3.625l-14.25,33.875l9.5-6.125l-18.625,32.25l12.375-8.375l-11.625,21.75
          L2590.092,1401.145z"
              />
              <g opacity="0.3">
                <polygon
                  points="2619.717,1383.417 2620.092,1384.18 2619.717,1384.122       "
                />
                <polygon
                  points="2558.842,1385.805 2577.467,1353.555 2567.967,1359.68 2582.217,1325.805 2575.967,1329.43 2589.092,1300.805
            2589.092,1401.114 2559.592,1399.18 2571.217,1377.43       "
                />
              </g>
            </g>
            <g>
              <rect
                x="2542.898"
                y="1396.586"
                fill="#5F4234"
                width="13.52"
                height="14.809"
              />
              <path
                fill="#34A936"
                d="M2550.463,1398.195c1.77,0.16,36.856-1.771,36.856-1.771l-9.818-21.89l11.591,1.77l-23.982-48.768
          l10.461,6.275l-19.797-37.821l8.531,3.862l-15.129-30.902l-16.9,36.857l8.047-4.668l-18.348,43.617l12.232-7.887l-23.982,41.525
          l15.936-10.783l-14.969,28.005L2550.463,1398.195z"
              />
              <g opacity="0.3">
                <polygon
                  points="2588.607,1375.371 2589.092,1376.354 2588.607,1376.278      "
                />
                <polygon
                  points="2510.225,1378.445 2534.207,1336.92 2521.975,1344.807 2540.322,1301.188 2532.275,1305.855 2549.176,1268.999
            2549.176,1398.158 2511.191,1395.667 2526.16,1367.662      "
                />
              </g>
            </g>
            <g>
              <rect
                x="2337.309"
                y="1524.838"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M2343.184,1526.088c1.375,0.125,28.625-1.375,28.625-1.375l-7.625-17l9,1.375l-18.625-37.875l8.125,4.875
          l-15.375-29.375l6.625,3l-11.75-24l-13.125,28.625l6.25-3.625l-14.25,33.875l9.5-6.125l-18.625,32.25l12.375-8.375l-11.625,21.75
          L2343.184,1526.088z"
              />
              <g opacity="0.3">
                <polygon
                  points="2372.809,1508.361 2373.184,1509.124 2372.809,1509.066      "
                />
                <polygon
                  points="2311.934,1510.749 2330.559,1478.499 2321.059,1484.624 2335.309,1450.749 2329.059,1454.374 2342.184,1425.749
            2342.184,1526.059 2312.684,1524.124 2324.309,1502.374       "
                />
              </g>
            </g>
            <g>
              <rect
                x="1988.785"
                y="1423.58"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M1994.66,1424.83c1.375,0.125,28.625-1.375,28.625-1.375l-7.625-17l9,1.375l-18.625-37.875l8.125,4.875
          l-15.375-29.375l6.625,3l-11.75-24l-13.125,28.625l6.25-3.625l-14.25,33.875l9.5-6.125l-18.625,32.25l12.375-8.375l-11.625,21.75
          L1994.66,1424.83z"
              />
              <g opacity="0.3">
                <polygon
                  points="2024.285,1407.104 2024.66,1407.866 2024.285,1407.809       "
                />
                <polygon
                  points="1963.41,1409.491 1982.035,1377.241 1972.535,1383.366 1986.785,1349.491 1980.535,1353.116 1993.66,1324.491
            1993.66,1424.801 1964.16,1422.866 1975.785,1401.116       "
                />
              </g>
            </g>
            <g>
              <rect
                x="2063.578"
                y="1422.868"
                fill="#5F4234"
                width="13.521"
                height="14.808"
              />
              <path
                fill="#34A936"
                d="M2071.143,1424.479c1.77,0.16,36.857-1.771,36.857-1.771l-9.818-21.89l11.588,1.77l-23.98-48.768
          l10.461,6.275l-19.797-37.821l8.531,3.861l-15.131-30.901l-16.898,36.856l8.047-4.668l-18.348,43.618l12.232-7.887l-23.982,41.525
          l15.934-10.783l-14.967,28.005L2071.143,1424.479z"
              />
              <g opacity="0.3">
                <polygon
                  points="2109.287,1401.653 2109.77,1402.635 2109.287,1402.561       "
                />
                <polygon
                  points="2030.904,1404.729 2054.887,1363.202 2042.654,1371.089 2061.002,1327.471 2052.955,1332.139 2069.854,1295.281
            2069.854,1424.439 2031.871,1421.949 2046.838,1393.944       "
                />
              </g>
            </g>
            <g>
              <rect
                x="1942.951"
                y="1423.389"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M1948.826,1424.639c1.375,0.125,28.625-1.375,28.625-1.375l-7.625-17l9,1.375l-18.625-37.875l8.125,4.875
          l-15.375-29.375l6.625,3l-11.75-24l-13.125,28.625l6.25-3.625l-14.25,33.875l9.5-6.125l-18.625,32.25l12.375-8.375l-11.625,21.75
          L1948.826,1424.639z"
              />
              <g opacity="0.3">
                <polygon
                  points="1978.451,1406.912 1978.826,1407.675 1978.451,1407.617      "
                />
                <polygon
                  points="1917.576,1409.3 1936.201,1377.05 1926.701,1383.175 1940.951,1349.3 1934.701,1352.925 1947.826,1324.3
            1947.826,1424.609 1918.326,1422.675 1929.951,1400.925       "
                />
              </g>
            </g>
            <path
              fill="#FFFFFF"
              d="M426.969,317.749l366.088,402.652l-85.451-4.771l-85,86l-133-147l-175,117c0,0-18-78-21-75
        s-71.139,62-71.139,62l-16.572-72.826L426.969,317.749z"
            />
            <path
              fill="#FFFFFF"
              d="M1387.508,938.502l-196.752-149.705c0,0-118.922,126.688-121.961,129.925
        c-3.041,3.238,37.811,56.908,37.811,56.908l100.424-37.128l50.997,46.535l62.997-62.997L1387.508,938.502z"
            />
            <polygon
              fill="#FFFFFF"
              points="1800.605,1016.819 1932.701,1118.812 1844.822,1118.812 1814.818,1142.832 1787.816,1097.827
        1745.812,1142.832 1738.605,1116.63 1721.605,1126.63 1716.938,1116.489   "
            />
          </g>

          <g transform="translate(0, -100)" id="js-yellow-train-cabin1">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-yellow-train-cabin2">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-yellow-train-cabin3">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-yellow-train-cabin4">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-yellow-train-cabin5">
            <use xlink:href="#cabin1"></use>
          </g>

          <path
            id="js-yellow-path"
            fill="none"
            stroke="#D5B43C"
            stroke-width="20"
            stroke-miterlimit="10"
            d="M-411.832,1956.669L1.34,1365.229
      C52.049,1290,116.049,1224,198.049,1180c1-1,2-2,3-3c43-22,85-41,133-52c1,0,3-2,4-2c103-25,203-28,306-18c3,0,7,0,10,0
      c14,1,28,4,43,5c34,4,65,10,99,16c36,7,69,15,104,23c54,14,102,29,157,40c94,20,188.226,36.538,277-1
      c100.259-42.394,195.922-194.537,225-228c36.255-41.722,50.168-59.233,96-108c51.057-54.327,45.881-48.845,104-99
      c48.287-41.67,179.685-136.595,251-165c40.359-16.075,87.797-41.835,210-54c147.411-14.674,333,46,476.273,130.016L4467.5,1749.561"
          />

          <g>
            <pattern
              id="pattern2"
              xlink:href="#New_Pattern_5"
              patternTransform="matrix(1 0 0 -1 861.8438 -17374.4141)"
            ></pattern>
            <path
              fill="url(#pattern2)"
              d="M1546.277,970.104c-147.051,176.068-176.574,309.627-579.211,198.142
        c-371.088-102.747-751.143-126.896-967.15,193.342v318.528l2700.887-12.974V669.144
        C2299.832,429.749,1950.7,485.882,1546.277,970.104z"
            />
          </g>
          <g>
            <pattern
              id="pattern3"
              xlink:href="#New_Pattern_Swatch_3"
              patternTransform="matrix(1 0 0 -1 861.8438 -17374.4141)"
            ></pattern>
            <path
              fill="url(#pattern3)"
              d="M2702.188,668.326c-400.391-238.439-749.184-181.886-1153.143,301.779
        c-147.051,176.068-176.574,309.627-579.211,198.142c-372.543-103.15-754.13-127.101-969.691,197.106v41.23
        c215.561-324.207,597.148-300.26,969.691-197.109c402.637,111.486,432.16-22.071,579.211-198.141
        c403.959-483.665,752.752-540.218,1153.143-301.779V668.326z"
            />
          </g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2688.895,659.52c-1.129,1.946-3.616,2.621-5.56,1.509c-1.94-1.113-2.613-3.601-1.499-5.555
              s3.604-2.629,5.559-1.508C2689.352,655.086,2690.021,657.573,2688.895,659.52z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2671.249,649.406c-1.099,1.963-3.583,2.687-5.549,1.616s-2.681-3.535-1.597-5.507
              c1.084-1.971,3.567-2.694,5.549-1.616C2671.633,644.977,2672.348,647.443,2671.249,649.406z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2653.387,639.682c-1.066,1.98-3.524,2.719-5.49,1.647c-1.966-1.07-2.707-3.549-1.653-5.537
              c1.052-1.988,3.511-2.726,5.489-1.648C2653.713,635.222,2654.454,637.701,2653.387,639.682z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2635.405,630.182c-1.034,1.998-3.484,2.784-5.472,1.756c-1.987-1.026-2.773-3.485-1.756-5.491
              c1.019-2.006,3.469-2.792,5.472-1.756C2635.653,625.726,2636.439,628.185,2635.405,630.182z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2617.264,620.993c-1,2.015-3.438,2.85-5.445,1.864c-2.009-0.985-2.84-3.426-1.856-5.448
              c0.981-2.024,3.42-2.858,5.444-1.864C2617.431,616.538,2618.263,618.979,2617.264,620.993z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2598.966,612.116c-0.962,2.033-3.388,2.918-5.416,1.977c-2.028-0.939-2.907-3.357-1.964-5.399
              s3.369-2.928,5.416-1.979C2599.049,607.665,2599.929,610.083,2598.966,612.116z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2580.512,603.567c-0.924,2.051-3.334,2.988-5.383,2.096c-2.05-0.894-2.979-3.287-2.074-5.346
              c0.904-2.061,3.314-2.998,5.385-2.097C2580.509,599.123,2581.436,601.516,2580.512,603.567z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2561.869,595.441c-0.887,2.075-3.254,3.007-5.315,2.143c-2.069-0.844-3.048-3.212-2.187-5.29
              s3.256-3.07,5.346-2.218C2561.795,590.949,2562.748,593.378,2561.869,595.441z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2543.068,587.686c-0.84,2.088-3.197,3.096-5.268,2.25c-2.062-0.863-3.106-3.166-2.285-5.271
              c0.812-2.085,3.203-3.172,5.285-2.301C2542.893,583.216,2543.907,585.599,2543.068,587.686z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2524.107,580.333c-0.793,2.104-3.129,3.168-5.22,2.375c-2.088-0.793-3.157-3.149-2.387-5.262
              c0.771-2.113,3.106-3.177,5.219-2.375C2523.832,575.872,2524.9,578.229,2524.107,580.333z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2504.99,573.401c-0.745,2.123-3.059,3.243-5.166,2.505c-2.107-0.739-3.232-3.065-2.512-5.196
              c0.722-2.131,3.033-3.252,5.166-2.505C2504.609,568.952,2505.734,571.278,2504.99,573.401z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2485.718,566.911c-0.694,2.14-2.98,3.321-5.107,2.639c-2.126-0.682-3.309-2.976-2.64-5.124
              c0.67-2.146,2.957-3.329,5.107-2.639C2485.229,562.477,2486.411,564.771,2485.718,566.911z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2466.296,560.88c-0.642,2.156-2.897,3.399-5.042,2.776s-3.385-2.882-2.77-5.046
              c0.613-2.164,2.871-3.407,5.041-2.776C2465.696,556.465,2466.938,558.724,2466.296,560.88z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2446.727,555.349c-0.584,2.173-2.81,3.478-4.971,2.914c-2.158-0.562-3.459-2.784-2.9-4.964
              c0.558-2.179,2.781-3.483,4.97-2.914C2446.012,550.954,2447.312,553.177,2446.727,555.349z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2427.02,550.331c-0.526,2.188-2.717,3.555-4.893,3.056c-2.175-0.499-3.535-2.682-3.037-4.875
              c0.498-2.192,2.688-3.562,4.893-3.056C2426.186,545.961,2427.546,548.144,2427.02,550.331z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2407.184,545.85c-0.467,2.201-2.618,3.635-4.808,3.201c-2.188-0.433-3.608-2.572-3.174-4.779
              c0.435-2.206,2.587-3.64,4.806-3.2C2406.227,541.509,2407.648,543.649,2407.184,545.85z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2387.227,541.926c-0.4,2.213-2.512,3.711-4.713,3.348c-2.198-0.365-3.684-2.459-3.312-4.679
              c0.372-2.219,2.481-3.717,4.713-3.347S2387.629,539.712,2387.227,541.926z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2367.164,538.598c-0.336,2.218-2.4,3.785-4.611,3.488c-2.211-0.295-3.756-2.342-3.451-4.571
              c0.305-2.229,2.369-3.792,4.611-3.491C2365.955,534.326,2367.501,536.367,2367.164,538.598z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2347.006,535.896c-0.267,2.218-2.279,3.815-4.5,3.605c-2.218-0.224-3.824-2.22-3.59-4.457
              c0.236-2.237,2.252-3.866,4.504-3.637C2345.673,531.623,2347.275,533.646,2347.006,535.896z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2326.775,533.816c-0.199,2.244-2.156,3.849-4.384,3.723c-2.225-0.151-3.894-2.093-3.729-4.336
              c0.166-2.244,2.131-3.938,4.389-3.783C2325.312,529.549,2326.975,531.579,2326.775,533.816z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2306.49,532.373c-0.129,2.267-2.032,3.896-4.262,3.837c-2.228-0.077-3.957-1.961-3.864-4.208
              c0.094-2.248,2.003-4.006,4.265-3.927C2304.893,528.136,2306.617,530.146,2306.49,532.373z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2286.17,531.579c-0.056,2.261-1.905,3.981-4.134,3.958c-2.229-0.002-4.021-1.827-4-4.076
              c0.021-2.25,1.872-4.071,4.135-4.068C2284.436,527.418,2286.225,529.342,2286.17,531.579z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2265.834,531.448c0.02,2.252-1.773,4.063-4.002,4.075c-2.229,0.073-4.077-1.689-4.131-3.938
              c-0.055-2.249,1.736-4.132,3.999-4.206C2263.964,527.368,2265.814,529.202,2265.834,531.448z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2245.505,531.986c0.093,2.248-1.638,4.128-3.865,4.202c-2.225,0.122-4.13-1.558-4.259-3.81
              c-0.129-2.24,1.598-4.203,3.857-4.327C2243.502,527.977,2245.412,529.738,2245.505,531.986z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2225.205,533.196c0.168,2.243-1.498,4.183-3.723,4.332c-2.223,0.156-4.182-1.426-4.385-3.682
              c-0.202-2.225,1.455-4.276,3.713-4.437C2223.069,529.258,2225.037,530.953,2225.205,533.196z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2204.958,535.085c0.243,2.236-1.358,4.232-3.575,4.458c-2.221,0.196-4.229-1.304-4.509-3.555
              c-0.274-2.214,1.31-4.336,3.565-4.536C2202.691,531.223,2204.715,532.849,2204.958,535.085z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2184.784,537.645c0.316,2.228-1.218,4.277-3.425,4.58c-2.213,0.278-4.276-1.209-4.627-3.425
              c-0.354-2.228,1.17-4.347,3.416-4.63C2182.393,533.862,2184.468,535.418,2184.784,537.645z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2164.706,540.868c0.39,2.215-1.075,4.316-3.272,4.694c-2.196,0.371-4.319-1.102-4.739-3.291
              c-0.429-2.229,1.034-4.343,3.268-4.719C2162.191,537.167,2164.316,538.652,2164.706,540.868z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2144.744,544.744c0.462,2.202-0.935,4.353-3.116,4.805c-2.183,0.452-4.354-0.961-4.851-3.155
              c-0.494-2.193,0.899-4.345,3.116-4.804C2142.111,541.13,2144.282,542.543,2144.744,544.744z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2124.92,549.271c0.532,2.186-0.793,4.384-2.96,4.909c-2.166,0.526-4.381-0.812-4.946-2.989
              c-0.563-2.178,0.76-4.375,2.959-4.909C2122.173,545.747,2124.387,547.085,2124.92,549.271z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2105.247,554.418c0.601,2.168-0.653,4.41-2.802,5.009c-2.147,0.6-4.402-0.666-5.034-2.825
              c-0.633-2.158,0.621-4.4,2.801-5.009C2102.393,550.985,2104.646,552.25,2105.247,554.418z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2085.743,560.171c0.667,2.148-0.518,4.433-2.646,5.103c-2.127,0.669-4.418-0.522-5.115-2.66
              c-0.697-2.14,0.485-4.422,2.645-5.102C2082.785,556.833,2085.076,558.023,2085.743,560.171z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2066.422,566.512c0.731,2.127-0.383,4.449-2.488,5.187c-2.104,0.736-4.429-0.381-5.188-2.498
              c-0.762-2.117,0.353-4.439,2.487-5.188C2063.366,563.265,2065.689,564.385,2066.422,566.512z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2047.297,573.424c0.793,2.105-0.251,4.463-2.332,5.269c-2.081,0.804-4.436-0.241-5.256-2.335
              c-0.822-2.095,0.223-4.452,2.331-5.268C2044.15,570.274,2046.504,571.319,2047.297,573.424z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2028.371,580.866c0.854,2.082-0.122,4.474-2.178,5.344s-4.435-0.104-5.314-2.174
              c-0.879-2.07,0.096-4.462,2.178-5.343C2025.139,577.812,2027.52,578.785,2028.371,580.866z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M2009.657,588.826c0.909,2.058,0.002,4.479-2.026,5.413c-2.027,0.932-4.432,0.028-5.365-2.018
              c-0.937-2.045-0.028-4.469,2.024-5.412C2006.345,585.866,2008.747,586.769,2009.657,588.826z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1991.176,597.315c0.959,2.022,0.098,4.432-1.895,5.439c-2.001,0.99-4.424,0.156-5.412-1.865
              c-0.988-2.021-0.146-4.472,1.878-5.474C1987.764,594.396,1990.207,595.273,1991.176,597.315z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1972.934,606.305c1.017,2.011,0.213,4.433-1.783,5.435c-1.972,1.048-4.412,0.28-5.45-1.715
              c-1.039-1.995-0.263-4.473,1.73-5.532C1969.45,603.479,1971.919,604.3,1972.934,606.305z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1954.924,615.752c1.064,1.982,0.327,4.438-1.645,5.486c-1.971,1.05-4.454,0.297-5.536-1.664
              c-1.093-1.979-0.351-4.43,1.643-5.491C1951.381,613.023,1953.859,613.77,1954.924,615.752z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1937.135,625.605c1.11,1.956,0.437,4.438-1.506,5.541c-1.941,1.104-4.436,0.424-5.564-1.521
              c-1.133-1.944-0.457-4.425,1.506-5.541S1936.025,623.649,1937.135,625.605z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1919.568,635.85c1.154,1.932,0.539,4.435-1.373,5.591c-1.911,1.155-4.414,0.538-5.588-1.381
              c-1.175-1.919-0.561-4.421,1.371-5.59C1915.912,633.302,1918.414,633.919,1919.568,635.85z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1902.223,646.467c1.196,1.905,0.641,4.427-1.242,5.632c-1.883,1.206-4.393,0.648-5.606-1.245
              c-1.215-1.894-0.658-4.414,1.242-5.632C1898.518,644.005,1901.027,644.562,1902.223,646.467z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1885.1,657.438c1.234,1.881,0.734,4.421-1.117,5.674s-4.367,0.755-5.62-1.114
              c-1.253-1.868-0.753-4.408,1.114-5.672C1881.346,655.06,1883.863,655.558,1885.1,657.438z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1868.256,668.837c1.271,1.855,0.803,4.375-1.05,5.628c-1.864,1.236-4.361,0.829-5.657-1.022
              c-1.281-1.834-0.861-4.426,1.02-5.673C1864.438,666.504,1866.982,666.982,1868.256,668.837z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1851.617,680.53c1.307,1.831,0.891,4.368-0.93,5.667s-4.369,0.878-5.692-0.941
              c-1.323-1.818-0.907-4.355,0.929-5.667C1847.761,678.278,1850.311,678.7,1851.617,680.53z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1835.179,692.503c1.341,1.806,0.978,4.358-0.812,5.701c-1.79,1.342-4.341,0.975-5.695-0.82
              s-0.991-4.348,0.813-5.701C1831.288,690.33,1833.838,690.697,1835.179,692.503z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1818.938,704.745c1.373,1.782,1.059,4.348-0.701,5.729c-1.76,1.382-4.312,1.065-5.696-0.706
              c-1.386-1.771-1.071-4.337,0.702-5.729C1815.018,702.646,1817.566,702.962,1818.938,704.745z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1802.941,717.303c1.404,1.764,1.104,4.3-0.646,5.694c-1.729,1.419-4.278,1.153-5.692-0.596
              c-1.416-1.749-1.148-4.326,0.594-5.757C1798.961,715.24,1801.542,715.546,1802.941,717.303z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1787.171,730.144c1.431,1.737,1.187,4.297-0.545,5.717c-1.729,1.42-4.301,1.172-5.743-0.556
              c-1.441-1.727-1.197-4.286,0.545-5.716C1783.171,728.159,1785.742,728.407,1787.171,730.144z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1771.566,743.185c1.455,1.716,1.257,4.287-0.442,5.744s-4.267,1.256-5.733-0.448
              c-1.468-1.705-1.271-4.276,0.441-5.743C1767.543,741.269,1770.109,741.47,1771.566,743.185z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1756.127,756.422c1.48,1.693,1.326,4.275-0.344,5.768c-1.67,1.49-4.233,1.335-5.725-0.349
              c-1.492-1.684-1.339-4.266,0.342-5.766C1752.082,754.574,1754.646,754.729,1756.127,756.422z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1740.956,769.968c1.504,1.673,1.369,4.237-0.302,5.729c-1.67,1.491-4.252,1.353-5.768-0.312
              c-1.515-1.663-1.38-4.228,0.301-5.728C1736.869,768.156,1739.451,768.295,1740.956,769.968z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1725.939,783.683c1.525,1.652,1.433,4.227-0.211,5.749c-1.642,1.522-4.218,1.427-5.754-0.217
              c-1.537-1.643-1.442-4.217,0.209-5.749C1721.836,781.933,1724.413,782.03,1725.939,783.683z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1711.076,797.562c1.548,1.634,1.493,4.216-0.12,5.77c-1.614,1.553-4.185,1.497-5.741-0.127
              s-1.503-4.207,0.12-5.77C1706.958,795.874,1709.527,795.931,1711.076,797.562z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1696.423,811.667c1.567,1.613,1.529,4.181-0.085,5.734c-1.62,1.545-4.191,1.521-5.762-0.078
              c-1.583-1.61-1.561-4.193,0.068-5.748C1692.27,810.012,1694.855,810.053,1696.423,811.667z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1681.939,825.943c1.586,1.595,1.586,4.171,0,5.753c-1.586,1.583-4.164,1.58-5.759-0.006
              c-1.595-1.587-1.596-4.163-0.001-5.753C1677.773,824.346,1680.354,824.348,1681.939,825.943z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1667.575,840.34c1.604,1.577,1.642,4.16,0.083,5.771c-1.559,1.608-4.129,1.643-5.741,0.075
              c-1.612-1.568-1.649-4.152-0.083-5.77C1663.4,838.798,1665.971,838.764,1667.575,840.34z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1653.429,854.952c1.621,1.559,1.673,4.128,0.113,5.738c-1.56,1.608-4.144,1.657-5.772,0.105
              c-1.629-1.551-1.68-4.12-0.112-5.738C1649.225,853.44,1651.809,853.393,1653.429,854.952z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1639.421,869.696c1.638,1.542,1.722,4.118,0.188,5.752c-1.532,1.635-4.107,1.716-5.754,0.182
              c-1.645-1.535-1.729-4.11-0.188-5.752C1635.207,868.235,1637.783,868.153,1639.421,869.696z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1625.527,884.548c1.653,1.526,1.778,4.115,0.281,5.782c-1.498,1.667-4.059,1.789-5.718,0.27
              c-1.659-1.519-1.785-4.106-0.28-5.781C1621.314,883.144,1623.875,883.021,1625.527,884.548z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1611.938,899.678c1.666,1.512,1.804,4.087,0.306,5.755s-4.068,1.801-5.741,0.298
              c-1.673-1.505-1.811-4.081-0.306-5.756C1607.7,898.3,1610.271,898.167,1611.938,899.678z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1598.346,914.808c1.682,1.496,1.828,4.062,0.33,5.729c-1.498,1.668-4.079,1.812-5.766,0.324
              c-1.688-1.488-1.834-4.054-0.33-5.729S1596.666,913.312,1598.346,914.808z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1584.755,929.938c1.683,1.471,1.874,4.053,0.429,5.766c-1.447,1.71-4,1.902-5.701,0.43
              c-1.699-1.472-1.897-4.06-0.442-5.776C1580.491,928.634,1583.049,928.446,1584.755,929.938z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1571.595,945.442c1.708,1.464,1.917,4.036,0.47,5.747c-1.448,1.71-4.014,1.916-5.729,0.46
              s-1.925-4.029-0.469-5.747C1567.322,944.185,1569.887,943.979,1571.595,945.442z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1558.448,960.96c1.722,1.447,1.942,4.007,0.493,5.717c-1.447,1.71-4.023,1.931-5.752,0.49
              c-1.729-1.44-1.95-3.999-0.496-5.717C1554.15,959.732,1556.727,959.513,1558.448,960.96z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1545.302,976.479c1.751,1.44,1.938,3.949,0.554,5.712c-1.401,1.747-3.951,2.012-5.695,0.591
              s-2.014-3.997-0.604-5.754C1540.947,975.254,1543.579,975.059,1545.302,976.479z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1532.521,992.297c1.752,1.409,2.035,3.968,0.635,5.715c-1.402,1.747-3.967,2.028-5.729,0.629
              c-1.761-1.398-2.045-3.959-0.635-5.715C1528.204,991.168,1530.768,990.887,1532.521,992.297z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1519.836,1008.193c1.77,1.39,2.085,3.951,0.707,5.723c-1.379,1.77-3.932,2.082-5.704,0.696
              c-1.771-1.387-2.088-3.947-0.707-5.724C1515.514,1007.115,1518.066,1006.803,1519.836,1008.193z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1507.343,1024.241c1.776,1.38,2.099,3.935,0.722,5.705c-1.379,1.77-3.938,2.09-5.719,0.714
              c-1.779-1.376-2.102-3.931-0.721-5.704C1503.007,1023.181,1505.566,1022.86,1507.343,1024.241z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1494.872,1040.307c1.778,1.379,2.104,3.937,0.728,5.713c-1.375,1.778-3.931,2.102-5.707,0.722
              c-1.775-1.381-2.102-3.938-0.729-5.714C1490.539,1039.252,1493.094,1038.929,1494.872,1040.307z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1482.424,1056.39c1.78,1.387,2.087,3.938,0.682,5.702c-1.4,1.765-3.97,2.065-5.735,0.672
              c-1.767-1.393-2.068-3.942-0.675-5.695C1478.089,1055.316,1480.654,1055.012,1482.424,1056.39z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1469.777,1072.317c1.756,1.404,2.043,3.976,0.641,5.738c-1.402,1.766-3.955,2.046-5.702,0.63
              c-1.747-1.418-2.032-3.986-0.641-5.739C1465.469,1071.193,1468.021,1070.911,1469.777,1072.317z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1456.994,1088.134c1.73,1.437,1.969,4.011,0.529,5.75c-1.426,1.751-4.041,1.941-5.747,0.491
              c-1.722-1.463-1.908-3.989-0.496-5.723C1452.704,1086.931,1455.262,1086.698,1456.994,1088.134z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1443.825,1103.631c1.692,1.482,1.833,4.049,0.315,5.732c-1.517,1.683-4.097,1.822-5.763,0.312
              c-1.666-1.513-1.807-4.079-0.315-5.731C1439.553,1102.288,1442.133,1102.148,1443.825,1103.631z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1430.084,1118.622c1.633,1.548,1.686,4.132,0.117,5.774c-1.567,1.641-4.137,1.688-5.736,0.107
              s-1.652-4.166-0.117-5.772C1425.883,1117.124,1428.451,1117.075,1430.084,1118.622z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1415.754,1133.05c1.551,1.621,1.521,4.255-0.188,5.766c-1.687,1.53-4.277,1.421-5.788-0.246
              s-1.404-4.226,0.235-5.713C1411.674,1131.39,1414.193,1131.42,1415.754,1133.05z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1400.607,1146.615c1.455,1.716,1.213,4.291-0.541,5.75c-1.754,1.46-4.312,1.216-5.711-0.546
              s-1.156-4.335,0.541-5.75C1396.598,1144.655,1399.154,1144.899,1400.607,1146.615z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1384.643,1159.203c1.312,1.79,0.972,4.46-0.951,5.704c-1.896,1.282-4.457,0.812-5.72-1.052
              c-1.262-1.862-0.804-4.371,1.022-5.604C1380.848,1157.057,1383.295,1157.363,1384.643,1159.203z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1367.737,1170.494c1.181,1.914,0.546,4.419-1.419,5.595c-1.965,1.175-4.456,0.54-5.562-1.419
              c-1.105-1.958-0.471-4.463,1.419-5.594C1364.064,1167.944,1366.557,1168.579,1367.737,1170.494z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1349.989,1180.409c1.008,1.983,0.225,4.544-1.855,5.505c-2.087,0.947-4.54,0.057-5.477-1.988
              c-0.938-2.045-0.072-4.438,1.933-5.346C1346.588,1177.658,1348.96,1178.381,1349.989,1180.409z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1331.461,1188.767c0.844,2.085-0.206,4.438-2.347,5.255c-2.14,0.815-4.493-0.238-5.255-2.354
              c-0.76-2.117,0.291-4.47,2.347-5.254C1328.264,1185.627,1330.616,1186.682,1331.461,1188.767z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1312.288,1195.521c0.671,2.146-0.556,4.441-2.739,5.125c-1.091,0.332-2.195,0.231-3.115-0.296
              c-0.921-0.526-1.634-1.396-1.933-2.48c-0.299-1.085-0.129-2.177,0.378-3.064c0.508-0.888,1.328-1.653,2.385-1.977
              C1309.367,1192.169,1311.616,1193.375,1312.288,1195.521z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1292.667,1200.849c0.25,1.098,0.034,2.201-0.526,3.103c-0.563,0.887-1.482,1.513-2.6,1.736
              c-2.241,0.438-4.409-0.995-4.842-3.202c-0.434-2.208,0.974-4.341,3.142-4.765c1.085-0.218,2.17,0.055,3.042,0.608
              C1291.75,1198.87,1292.417,1199.752,1292.667,1200.849z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1272.691,1204.626c0.354,2.222-1.189,4.279-3.447,4.596c-2.257,0.315-4.321-1.235-4.61-3.468
              c-0.288-2.23,1.255-4.288,3.446-4.595C1270.271,1200.852,1272.336,1202.404,1272.691,1204.626z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1252.514,1207.133c0.219,2.238-1.442,4.213-3.709,4.41c-2.268,0.196-4.235-1.463-4.396-3.707
              c-0.159-2.244,1.502-4.219,3.71-4.41C1250.328,1203.234,1252.295,1204.895,1252.514,1207.133z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1232.229,1208.503c0.098,2.248-1.664,4.144-3.935,4.232c-2.271,0.09-4.149-1.66-4.194-3.909
              s1.715-4.146,3.935-4.232C1230.253,1204.507,1232.13,1206.256,1232.229,1208.503z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1211.898,1208.875c-0.006,2.249-1.853,4.069-4.121,4.064c-2.27,0.002-4.066-1.832-4.014-4.104
              c0.053-2.227,1.897-4.04,4.12-4.041C1210.107,1204.799,1211.904,1206.625,1211.898,1208.875z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1191.571,1208.329c-0.097,2.247-2.011,3.992-4.274,3.896c-2.264-0.095-3.99-1.99-3.854-4.235
              c0.136-2.245,2.05-3.99,4.273-3.898C1189.941,1204.186,1191.668,1206.081,1191.571,1208.329z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1171.277,1207.064c-0.176,2.242-2.148,3.912-4.404,3.729s-3.915-2.146-3.704-4.386
              c0.21-2.239,2.183-3.909,4.404-3.729C1169.794,1202.858,1171.453,1204.822,1171.277,1207.064z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1151.034,1205.135c-0.246,2.235-2.267,3.838-4.515,3.579c-2.246-0.259-3.844-2.276-3.567-4.51
              c0.276-2.232,2.297-3.835,4.514-3.58C1149.684,1200.879,1151.28,1202.898,1151.034,1205.135z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1130.854,1202.619c-0.307,2.229-2.369,3.771-4.604,3.449c-2.234-0.323-3.779-2.39-3.447-4.614
              c0.333-2.226,2.395-3.77,4.604-3.448C1129.618,1198.325,1131.161,1200.391,1130.854,1202.619z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1110.746,1199.585c-0.359,2.221-2.454,3.709-4.678,3.324c-2.226-0.386-3.719-2.493-3.338-4.71
              s2.476-3.705,4.678-3.324C1109.609,1195.256,1111.105,1197.365,1110.746,1199.585z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1090.706,1196.119c-0.404,2.204-2.542,3.714-4.758,3.289c-2.211-0.444-3.66-2.596-3.234-4.805
              c0.425-2.209,2.548-3.642,4.741-3.2C1089.65,1191.824,1091.112,1193.896,1090.706,1196.119z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1070.75,1192.201c-0.447,2.205-2.604,3.631-4.814,3.187c-2.211-0.443-3.627-2.588-3.16-4.788
              c0.466-2.2,2.621-3.627,4.812-3.188C1069.781,1187.854,1071.197,1189.996,1070.75,1192.201z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1050.855,1187.983c-0.484,2.195-2.66,3.574-4.86,3.079c-2.199-0.495-3.576-2.676-3.075-4.868
              s2.677-3.571,4.86-3.079C1049.963,1183.607,1051.34,1185.787,1050.855,1187.983z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1031.021,1183.482c-0.519,2.188-2.713,3.525-4.9,2.986c-2.188-0.539-3.53-2.746-2.998-4.933
              c0.533-2.187,2.727-3.522,4.9-2.987C1030.199,1179.086,1031.541,1181.294,1031.021,1183.482z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M1011.275,1178.617c-0.547,2.182-2.766,3.514-4.953,2.974c-2.191-0.524-3.495-2.774-2.938-4.952
              c0.56-2.181,2.769-3.464,4.944-2.941C1010.504,1174.232,1011.823,1176.436,1011.275,1178.617z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M991.591,1173.506c-0.563,2.178-2.783,3.475-4.957,2.897c-2.173-0.577-3.475-2.81-2.905-4.985
              c0.568-2.176,2.787-3.474,4.955-2.896C990.854,1169.097,992.155,1171.328,991.591,1173.506z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M971.936,1168.285c-0.573,2.175-2.801,3.47-4.975,2.893c-2.173-0.577-3.466-2.807-2.888-4.98
              s2.805-3.469,4.974-2.894C971.215,1163.88,972.509,1166.11,971.936,1168.285z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M952.279,1163.064c-0.583,2.174-2.82,3.459-4.99,2.898c-2.169-0.562-3.459-2.783-2.881-4.957
              c0.579-2.174,2.813-3.477,4.992-2.91C951.581,1158.658,952.861,1160.893,952.279,1163.064z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M932.598,1157.938c-0.568,2.178-2.789,3.483-4.959,2.92c-2.169-0.563-3.475-2.786-2.914-4.966
              c0.562-2.179,2.781-3.484,4.959-2.919C931.862,1153.538,933.167,1155.762,932.598,1157.938z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M912.914,1152.822c-0.551,2.181-2.756,3.49-4.926,2.928c-2.169-0.564-3.489-2.791-2.947-4.974
              c0.542-2.185,2.748-3.494,4.926-2.929C912.146,1148.414,913.465,1150.641,912.914,1152.822z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M893.217,1147.763c-0.531,2.187-2.734,3.546-4.918,3.04s-3.53-2.689-3.007-4.878
              c0.522-2.188,2.725-3.549,4.917-3.04C892.403,1143.393,893.75,1145.576,893.217,1147.763z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M873.405,1143.168c-0.513,2.189-2.698,3.556-4.882,3.049c-2.184-0.506-3.547-2.693-3.043-4.886
              c0.502-2.193,2.688-3.558,4.881-3.049C872.554,1138.79,873.917,1140.979,873.405,1143.168z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M853.593,1138.574c-0.491,2.194-2.662,3.563-4.845,3.058c-2.183-0.507-3.562-2.697-3.08-4.895
              c0.482-2.198,2.652-3.565,4.845-3.059C852.706,1134.188,854.085,1136.38,853.593,1138.574z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M833.727,1134.23c-0.472,2.198-2.633,3.617-4.828,3.168c-2.194-0.449-3.602-2.6-3.142-4.802
              c0.459-2.202,2.621-3.62,4.827-3.168S834.197,1132.03,833.727,1134.23z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M813.802,1130.148c-0.448,2.203-2.59,3.627-4.786,3.177c-2.194-0.45-3.621-2.604-3.184-4.811
              c0.438-2.207,2.579-3.629,4.785-3.177C812.824,1125.79,814.25,1127.943,813.802,1130.148z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M793.878,1126.066c-0.427,2.222-2.543,3.604-4.749,3.214c-2.204-0.403-3.655-2.522-3.242-4.733
              s2.544-3.675,4.76-3.27C792.865,1121.671,794.301,1123.87,793.878,1126.066z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M773.883,1122.352c-0.398,2.215-2.51,3.683-4.714,3.278s-3.676-2.526-3.288-4.742
              c0.389-2.216,2.498-3.683,4.713-3.277C772.811,1118.016,774.283,1120.138,773.883,1122.352z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M753.847,1118.871c-0.374,2.218-2.467,3.716-4.677,3.347c-2.209-0.369-3.708-2.469-3.347-4.688
              c0.36-2.221,2.455-3.719,4.676-3.348C752.721,1114.553,754.22,1116.652,753.847,1118.871z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M733.771,1115.613c-0.346,2.224-2.422,3.754-4.636,3.42c-2.214-0.333-3.739-2.407-3.407-4.632
              c0.332-2.226,2.407-3.756,4.636-3.42C732.592,1111.317,734.118,1113.391,733.771,1115.613z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M713.66,1112.59c-0.317,2.228-2.373,3.792-4.594,3.496c-2.219-0.295-3.772-2.342-3.471-4.57
              c0.303-2.229,2.359-3.795,4.592-3.496C712.423,1108.316,713.979,1110.362,713.66,1112.59z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M693.501,1109.903c-0.287,2.23-2.318,3.8-4.538,3.504c-2.219-0.302-3.805-2.285-3.533-4.528
              c0.271-2.223,2.312-3.855,4.543-3.554C692.208,1105.623,693.787,1107.672,693.501,1109.903z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M673.308,1107.483c-0.254,2.234-2.264,3.84-4.487,3.583c-2.225-0.256-3.833-2.275-3.594-4.514
              c0.238-2.237,2.248-3.841,4.486-3.583C671.953,1103.229,673.562,1105.249,673.308,1107.483z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M653.084,1105.345c-0.221,2.238-2.206,3.88-4.434,3.665c-2.229-0.214-3.868-2.203-3.664-4.442
              c0.205-2.24,2.189-3.882,4.434-3.665C651.665,1101.117,653.305,1103.105,653.084,1105.345z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M632.832,1103.497c-0.186,2.242-2.145,3.922-4.376,3.753c-2.229-0.17-3.903-2.125-3.735-4.368
              s2.127-3.923,4.376-3.753C631.344,1099.3,633.018,1101.256,632.832,1103.497z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M612.552,1101.958c-0.147,2.236-2.08,3.963-4.313,3.839c-2.233-0.121-3.939-2.041-3.81-4.286
              c0.13-2.246,2.062-3.967,4.313-3.844C610.996,1097.792,612.701,1099.705,612.552,1101.958z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M592.245,1100.846c-0.109,2.246-2.009,3.969-4.243,3.848c-2.232-0.125-3.974-1.969-3.883-4.229
              c0.09-2.234,1.994-4.036,4.246-3.91C590.617,1096.678,592.354,1098.599,592.245,1100.846z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M571.923,1100.069c-0.067,2.249-1.937,4.013-4.172,3.938c-2.235-0.072-4.008-1.954-3.959-4.203
              s1.917-4.013,4.172-3.938C570.219,1095.939,571.992,1097.821,571.923,1100.069z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M551.591,1099.659c-0.024,2.249-1.858,4.056-4.095,4.035c-2.235-0.021-4.044-1.861-4.04-4.11
              c0.005-2.25,1.838-4.057,4.095-4.035C549.808,1095.569,551.617,1097.41,551.591,1099.659z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M531.254,1099.629c0.021,2.249-1.777,4.102-4.012,4.135s-4.08-1.762-4.122-4.011
              s1.755-4.101,4.012-4.135C529.39,1095.584,531.235,1097.38,531.254,1099.629z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M510.921,1099.995c0.066,2.248-1.69,4.146-3.923,4.237s-4.116-1.655-4.206-3.902
              c-0.089-2.248,1.667-4.146,3.923-4.238S510.854,1097.746,510.921,1099.995z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M490.602,1100.832c0.115,2.257-1.604,4.111-3.83,4.286c-2.229,0.151-4.149-1.544-4.291-3.789
              c-0.141-2.245,1.574-4.188,3.827-4.344C488.561,1096.811,490.485,1098.596,490.602,1100.832z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M470.307,1102.16c0.168,2.243-1.503,4.185-3.731,4.337c-2.23,0.126-4.185-1.454-4.378-3.714
              c-0.191-2.223,1.471-4.277,3.726-4.406C468.176,1098.224,470.139,1099.917,470.307,1102.16z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M450.05,1103.956c0.222,2.238-1.401,4.228-3.624,4.441s-4.226-1.425-4.474-3.66s1.375-4.225,3.624-4.441
              S449.828,1101.717,450.05,1103.956z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M429.843,1106.237c0.278,2.231-1.292,4.269-3.507,4.549c-2.214,0.28-4.258-1.299-4.562-3.527
              c-0.306-2.228,1.265-4.265,3.507-4.549C427.521,1102.426,429.564,1104.005,429.843,1106.237z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M409.7,1109.023c0.337,2.225-1.177,4.311-3.382,4.659c-2.204,0.35-4.288-1.167-4.653-3.387
              s1.149-4.306,3.381-4.659C407.279,1105.283,409.363,1106.8,409.7,1109.023z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M389.635,1112.332c0.397,2.215-1.057,4.35-3.248,4.771c-2.19,0.42-4.313-1.029-4.742-3.238
              c-0.428-2.208,1.027-4.344,3.248-4.771C387.114,1108.669,389.237,1110.118,389.635,1112.332z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M369.667,1116.194c0.461,2.202-0.929,4.387-3.104,4.88c-2.175,0.494-4.337-0.885-4.829-3.08
              c-0.492-2.195,0.898-4.38,3.104-4.88C367.044,1112.613,369.207,1113.993,369.667,1116.194z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M349.833,1120.692c0.529,2.196-0.82,4.324-2.969,4.922c-2.156,0.568-4.356-0.736-4.914-2.915
              c-0.558-2.181,0.764-4.414,2.951-4.99C347.083,1117.104,349.31,1118.516,349.833,1120.692z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M330.152,1125.812c0.594,2.168-0.673,4.389-2.829,4.957c-2.152,0.58-4.375-0.599-5.006-2.773
              c-0.622-2.146,0.613-4.479,2.798-5.066C327.303,1122.352,329.558,1123.643,330.152,1125.812z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M310.639,1131.538c0.663,2.149-0.529,4.416-2.662,5.062c-2.132,0.648-4.428-0.566-5.121-2.697
              c-0.699-2.147,0.495-4.408,2.66-5.066C307.683,1128.179,309.977,1129.389,310.639,1131.538z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M291.32,1137.883c0.733,2.126-0.379,4.438-2.486,5.165c-2.107,0.728-4.437-0.396-5.205-2.513
              c-0.768-2.114,0.346-4.427,2.486-5.165C288.256,1134.631,290.586,1135.756,291.32,1137.883z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M272.222,1144.862c0.806,2.101-0.225,4.458-2.301,5.265c-2.076,0.809-4.44-0.229-5.281-2.314
              c-0.84-2.088,0.19-4.444,2.301-5.266S271.416,1142.763,272.222,1144.862z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M253.371,1152.484c0.878,2.069-0.065,4.471-2.108,5.358c-2.042,0.891-4.438-0.056-5.351-2.11
              c-0.913-2.058,0.031-4.456,2.107-5.36C250.096,1149.468,252.492,1150.413,253.371,1152.484z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M234.793,1160.754c0.952,2.038,0.098,4.479-1.906,5.45c-2.003,0.972-4.428,0.12-5.415-1.901
              c-0.986-2.021-0.133-4.461,1.905-5.449C231.417,1157.864,233.841,1158.715,234.793,1160.754z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M216.518,1169.676c1.025,2.002,0.267,4.48-1.694,5.536c-1.96,1.056-4.41,0.302-5.47-1.682
              c-1.06-1.984-0.302-4.463,1.694-5.536S215.492,1167.674,216.518,1169.676z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M198.607,1179.312c1.1,1.964,0.386,4.384-1.512,5.55c-1.914,1.138-4.384,0.484-5.517-1.458
              s-0.472-4.456,1.476-5.614C194.986,1176.604,197.509,1177.35,198.607,1179.312z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M181.089,1189.639c1.171,1.92,0.568,4.399-1.346,5.537c-1.895,1.168-4.359,0.653-5.571-1.258
              c-1.198-1.889-0.66-4.47,1.269-5.659C177.389,1187.101,179.917,1187.718,181.089,1189.639z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M163.96,1200.597c1.242,1.876,0.738,4.385-1.125,5.604c-1.86,1.223-4.415,0.692-5.674-1.137
              c-1.291-1.876-0.777-4.369,1.117-5.614C160.173,1198.207,162.718,1198.722,163.96,1200.597z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M147.246,1212.176c1.312,1.827,0.908,4.36-0.901,5.658c-1.809,1.298-4.365,0.888-5.709-0.916
              c-1.344-1.804-0.94-4.337,0.9-5.658C143.377,1209.939,145.934,1210.349,147.246,1212.176z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M130.974,1224.367c1.379,1.776,1.079,4.333-0.671,5.71c-1.75,1.376-4.312,1.071-5.723-0.681
              c-1.411-1.753-1.11-4.309,0.67-5.71C127.031,1222.287,129.594,1222.591,130.974,1224.367z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M115.145,1237.131c1.445,1.725,1.248,4.3-0.439,5.753s-4.25,1.254-5.725-0.443
              c-1.475-1.699-1.278-4.275,0.439-5.753C111.136,1235.208,113.7,1235.407,115.145,1237.131z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M99.778,1250.451c1.507,1.67,1.413,4.261-0.209,5.786c-1.622,1.524-4.183,1.43-5.718-0.215
              c-1.536-1.645-1.441-4.234,0.209-5.785C95.71,1248.686,98.271,1248.781,99.778,1250.451z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M84.958,1264.38c1.586,1.634,1.475,4.113-0.054,5.734c-1.556,1.594-4.109,1.599-5.703,0.011
              c-1.594-1.588-1.602-4.188-0.019-5.809C80.738,1262.669,83.411,1262.785,84.958,1264.38z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M70.674,1278.854c1.623,1.559,1.676,4.112,0.119,5.705c-1.58,1.571-4.1,1.696-5.749,0.165
              c-1.647-1.528-1.78-4.157-0.174-5.756C66.453,1277.348,69.051,1277.297,70.674,1278.854z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M56.903,1293.814c1.676,1.5,1.829,4.062,0.343,5.723c-1.486,1.659-4.07,1.811-5.77,0.338
              c-1.7-1.474-1.853-4.035-0.342-5.722C52.645,1292.466,55.228,1292.314,56.903,1293.814z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M43.604,1309.195c1.726,1.442,1.978,4.01,0.564,5.731c-1.414,1.724-3.977,1.973-5.725,0.557
              s-2-3.982-0.564-5.731C39.315,1308.002,41.878,1307.752,43.604,1309.195z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M30.782,1324.979c1.772,1.387,2.121,3.952,0.779,5.733c-1.341,1.78-3.882,2.122-5.675,0.765
              c-1.793-1.359-2.142-3.927-0.779-5.732C26.469,1323.938,29.01,1323.594,30.782,1324.979z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M18.542,1341.221c1.826,1.339,2.173,3.828,0.88,5.652c-1.276,1.841-3.792,2.271-5.619,0.959
              c-1.828-1.312-2.268-3.876-0.985-5.728C14.123,1340.265,16.739,1339.899,18.542,1341.221z"
                  />
                </g>
                <g>
                  <path
                    fill="#FFFFFF"
                    d="M6.851,1357.86c1.835,1.301,2.288,3.848,1.012,5.688c-1.276,1.841-3.804,2.288-5.646,0.997
              c-1.842-1.291-2.295-3.838-1.012-5.688C2.488,1357.006,5.016,1356.561,6.851,1357.86z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <pattern
              id="SVGID_44_"
              xlink:href="#New_pattern4"
              patternTransform="matrix(1 0 0 -1 861.8438 -17374.4141)"
            ></pattern>
            <path
              fill="url(#SVGID_44_)"
              d="M2701.522,1818.984l0.654-463.638c-69.97-132.632-229.685-293.622-504.211-293.622
        c-418.013,0-456.843,219.668-756.267,219.668c-298.481-8.003-612.265-439.339-979.502-439.339
        c-140.164,0-342.062,36.149-462.282,219.87v757.061H2701.522L2701.522,1818.984z"
            />
          </g>
          <g>
            <pattern
              id="pattern5"
              xlink:href="#New_Pattern_8"
              patternTransform="matrix(1 0 0 -1 861.8438 -17374.4141)"
            ></pattern>
            <path
              fill="url(#pattern5)"
              d="M2193.611,1061.6c-418.012,0-456.84,219.668-756.264,219.668
        c-298.482-8.002-612.266-439.338-979.502-439.338c-138.559,0-337.442,35.329-458.118,213.617v38
        C120.403,915.259,319.288,879.93,457.845,879.93c367.238,0,681.021,431.336,979.503,439.338
        c299.424,0,338.252-219.668,756.264-219.668c274.527,0,434.242,160.99,504.213,293.623v-38
        C2627.854,1222.59,2468.14,1061.6,2193.611,1061.6z"
            />
          </g>

          <g transform="translate(0, -100)" id="js-blue-train-cabin1">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-blue-train-cabin2">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-blue-train-cabin3">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-blue-train-cabin4">
            <use xlink:href="#cabin1"></use>
          </g>
          <g transform="translate(0, -100)" id="js-blue-train-cabin5">
            <use xlink:href="#cabin1"></use>
          </g>

          <path
            id="js-blue-path"
            fill="none"
            stroke="#008F9E"
            stroke-width="20"
            stroke-miterlimit="10"
            d="M3688.611,3298l-986.622-1938.619
      c-10.886-20.635-23.173-40.529-36.646-59.574c-14.835-20.972-31.121-40.918-48.696-59.65
      c-18.963-20.213-39.437-39.014-61.223-56.147c-23.338-18.354-48.183-34.79-74.222-49.061
      c-28.022-15.356-57.414-28.191-87.695-38.394c-33.051-11.135-67.135-19.123-101.643-24.144
      c-38.457-5.595-77.212-7.183-116.035-6.446c-36.716,0.695-73.418,3.188-109.822,8.057c-30.979,4.146-61.731,10.027-91.978,17.927
      c-26.409,6.896-52.406,15.338-77.838,25.25c-22.928,8.938-45.373,19.065-67.399,30.032c-20.508,10.211-40.646,21.14-60.666,32.271
      c-19.202,10.674-38.306,21.527-57.638,31.965c-19.12,10.322-38.479,20.226-58.312,29.115c-20.402,9.146-41.301,17.196-62.688,23.725
      c-23.146,7.064-46.821,12.333-70.771,15.789c-27.45,3.961-55.368,5.797-83.104,5.278c-31.83-0.596-63.534-6.073-93.687-16.298
      c-29.197-9.9-56.865-24.207-81.867-42.24c-24.813-17.896-46.978-39.435-65.606-63.701c-18.813-24.506-34-51.772-44.903-80.682
      c-11.253-29.835-17.902-61.355-19.757-93.185c-1.885-32.381,1.125-65.041,9.046-96.5c7.612-30.227,19.743-59.287,35.871-85.961
      c15.855-26.225,35.545-50.113,58.264-70.682c22.733-20.584,48.48-37.821,76.211-50.923c28.33-13.387,58.68-22.413,89.719-26.685
      c32.012-4.405,64.725-3.814,96.552,1.774c30.737,5.397,60.612,15.496,88.332,29.829c27.218,14.072,52.313,32.188,74.293,53.53
      c22.009,21.372,40.872,45.958,55.812,72.752c15.232,27.321,26.353,56.902,32.886,87.493c6.796,31.816,8.643,64.68,5.562,97.063
      c-3.003,31.571-10.764,62.662-23.009,91.92c-11.903,28.438-28.007,55.097-47.623,78.881c-19.465,23.603-42.364,44.353-67.793,61.363
      c-25.682,17.181-53.913,30.514-83.521,39.362c-29.903,8.938-62.039,14.062-93.307,12.789c-25.019-1.019-49.859-4.986-74.134-11.06
      c-25.587-6.401-50.562-15.112-74.894-25.257c-25.902-10.799-51.106-23.228-75.778-36.586
      c-26.103-14.135-51.624-29.323-76.794-45.051c-26.276-16.418-52.176-33.435-77.937-50.648
      c-26.472-17.689-52.797-35.598-79.207-53.381c-26.71-17.986-53.506-35.85-80.604-53.246c-27.009-17.34-54.319-34.221-82.13-50.246
      c-27.388-15.781-55.267-30.738-83.785-44.38c-27.885-13.337-56.392-25.418-85.567-35.647c-28.56-10.012-57.768-18.24-87.478-24.048
      c-29.489-5.764-59.462-9.12-89.516-9.583c-25.559-0.394-51.197,0.713-76.653,2.99c-27.483,2.458-54.84,6.454-81.829,12.212
      c-28.697,6.123-56.983,14.246-84.431,24.636c-28.766,10.891-56.599,24.281-82.865,40.301c-27.729,16.912-53.662,36.746-77.13,59.205
      c-22.807,21.823-43.242,46.078-61.069,72.123L-560.5,1831.9"
          />

          <g>
            <g>
              <rect
                x="329.471"
                y="1606.009"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M334.096,1607.259c-1.375,0.125-28.625-1.375-28.625-1.375l7.625-17l-9,1.375l18.625-37.875l-8.125,4.875
          l15.375-29.375l-6.625,3l11.75-24l13.125,28.625l-6.25-3.625l14.25,33.875l-9.5-6.125l18.625,32.25l-12.375-8.375l11.625,21.75
          L334.096,1607.259z"
              />
              <g opacity="0.3">
                <polygon
                  points="304.471,1589.532 304.096,1590.295 304.471,1590.237       "
                />
                <polygon
                  points="365.346,1591.92 346.721,1559.67 356.221,1565.795 341.971,1531.92 348.221,1535.545 335.096,1506.92
            335.096,1607.229 364.596,1605.295 352.971,1583.545      "
                />
              </g>
            </g>
            <g>
              <rect
                x="60.13"
                y="1605.306"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M64.755,1606.556c-1.375,0.125-28.625-1.375-28.625-1.375l7.625-17l-9,1.375l18.625-37.875l-8.125,4.875
          l15.375-29.375l-6.625,3l11.75-24l13.125,28.625l-6.25-3.625l14.25,33.875l-9.5-6.125l18.625,32.25l-12.375-8.375l11.625,21.75
          L64.755,1606.556z"
              />
              <g opacity="0.3">
                <polygon
                  points="35.13,1588.829 34.755,1589.592 35.13,1589.534      "
                />
                <polygon
                  points="96.005,1591.217 77.38,1558.967 86.88,1565.092 72.63,1531.217 78.88,1534.842 65.755,1506.217 65.755,1606.526
            95.255,1604.592 83.63,1582.842      "
                />
              </g>
            </g>
            <g>
              <rect
                x="98.428"
                y="1601.998"
                fill="#5F4234"
                width="13.521"
                height="14.808"
              />
              <path
                fill="#34A936"
                d="M104.383,1603.607c-1.771,0.16-36.858-1.771-36.858-1.771l9.818-21.89l-11.588,1.77l23.981-48.768
          l-10.461,6.275l19.797-37.821l-8.53,3.862l15.129-30.902l16.9,36.857l-8.048-4.668l18.348,43.617l-12.232-7.887l23.981,41.525
          l-15.934-10.783l14.968,28.005L104.383,1603.607z"
              />
              <g opacity="0.3">
                <polygon
                  points="66.238,1580.783 65.755,1581.765 66.238,1581.69       "
                />
                <polygon
                  points="144.621,1583.857 120.639,1542.332 132.871,1550.219 114.523,1506.602 122.571,1511.27 105.671,1474.411
            105.671,1603.57 143.655,1601.079 128.687,1573.074       "
                />
              </g>
            </g>
            <g>
              <rect
                x="381.304"
                y="1604.817"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M385.929,1606.067c-1.375,0.125-28.625-1.375-28.625-1.375l7.625-17l-9,1.375l18.625-37.875l-8.125,4.875
          l15.375-29.375l-6.625,3l11.75-24l13.125,28.625l-6.25-3.625l14.25,33.875l-9.5-6.125l18.625,32.25l-12.375-8.375l11.625,21.75
          L385.929,1606.067z"
              />
              <g opacity="0.3">
                <polygon
                  points="356.304,1588.341 355.929,1589.104 356.304,1589.046       "
                />
                <polygon
                  points="417.179,1590.729 398.554,1558.479 408.054,1564.604 393.804,1530.729 400.054,1534.354 386.929,1505.729
            386.929,1606.038 416.429,1604.104 404.804,1582.354      "
                />
              </g>
            </g>
            <g>
              <rect
                x="354.586"
                y="1601.744"
                fill="#5F4234"
                width="13.52"
                height="14.808"
              />
              <path
                fill="#34A936"
                d="M360.542,1603.354c-1.771,0.16-36.858-1.771-36.858-1.771l9.818-21.89l-11.588,1.77l23.981-48.768
          l-10.461,6.275l19.797-37.821l-8.53,3.861l15.129-30.901l16.9,36.856l-8.048-4.668l18.348,43.618l-12.232-7.887l23.981,41.525
          l-15.935-10.783l14.968,28.005L360.542,1603.354z"
              />
              <g opacity="0.3">
                <polygon
                  points="322.396,1580.529 321.913,1581.511 322.396,1581.436       "
                />
                <polygon
                  points="400.779,1583.604 376.797,1542.078 389.029,1549.965 370.681,1506.347 378.729,1511.015 361.829,1474.157
            361.829,1603.316 399.812,1600.825 384.845,1572.82       "
                />
              </g>
            </g>
            <g>
              <rect
                x="856.941"
                y="1605.051"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M861.566,1606.301c-1.375,0.125-28.625-1.375-28.625-1.375l7.625-17l-9,1.375l18.625-37.875l-8.125,4.875
          l15.375-29.375l-6.625,3l11.75-24l13.125,28.625l-6.25-3.625l14.25,33.875l-9.5-6.125l18.625,32.25l-12.375-8.375l11.625,21.75
          L861.566,1606.301z"
              />
              <g opacity="0.3">
                <polygon
                  points="831.941,1588.574 831.566,1589.337 831.941,1589.279       "
                />
                <polygon
                  points="892.816,1590.962 874.191,1558.712 883.691,1564.837 869.441,1530.962 875.691,1534.587 862.566,1505.962
            862.566,1606.271 892.066,1604.337 880.441,1582.587      "
                />
              </g>
            </g>
            <g>
              <rect
                x="779.129"
                y="1604.339"
                fill="#5F4234"
                width="13.521"
                height="14.808"
              />
              <path
                fill="#34A936"
                d="M785.084,1605.948c-1.77,0.161-36.857-1.771-36.857-1.771l9.818-21.89l-11.589,1.77l23.981-48.768
          l-10.461,6.276l19.797-37.822l-8.531,3.862l15.13-30.902l16.899,36.857l-8.048-4.668l18.349,43.618l-12.232-7.888l23.982,41.525
          l-15.936-10.783l14.969,28.005L785.084,1605.948z"
              />
              <g opacity="0.3">
                <polygon
                  points="746.939,1583.124 746.456,1584.105 746.939,1584.031       "
                />
                <polygon
                  points="825.322,1586.198 801.34,1544.673 813.572,1552.561 795.225,1508.941 803.271,1513.609 786.372,1476.752
            786.372,1605.911 824.355,1603.42 809.388,1575.415       "
                />
              </g>
            </g>
            <g>
              <rect
                x="902.773"
                y="1604.859"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M907.398,1606.109c-1.375,0.125-28.625-1.375-28.625-1.375l7.625-17l-9,1.375l18.625-37.875l-8.125,4.875
          l15.375-29.375l-6.625,3l11.75-24l13.125,28.625l-6.25-3.625l14.25,33.875l-9.5-6.125l18.625,32.25l-12.375-8.375l11.625,21.75
          L907.398,1606.109z"
              />
              <g opacity="0.3">
                <polygon
                  points="877.773,1588.383 877.398,1589.145 877.773,1589.088       "
                />
                <polygon
                  points="938.648,1590.77 920.023,1558.52 929.523,1564.645 915.273,1530.77 921.523,1534.395 908.398,1505.77
            908.398,1606.08 937.898,1604.145 926.273,1582.395       "
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <rect
                x="2216.48"
                y="1606.163"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M2221.105,1607.413c-1.375,0.125-28.625-1.375-28.625-1.375l7.625-17l-9,1.375l18.625-37.875l-8.125,4.875
          l15.375-29.375l-6.625,3l11.75-24l13.125,28.625l-6.25-3.625l14.25,33.875l-9.5-6.125l18.625,32.25l-12.375-8.375l11.625,21.75
          L2221.105,1607.413z"
              />
              <g opacity="0.3">
                <polygon
                  points="2191.48,1589.687 2191.105,1590.448 2191.48,1590.392      "
                />
                <polygon
                  points="2252.355,1592.073 2233.73,1559.823 2243.23,1565.948 2228.98,1532.073 2235.23,1535.698 2222.105,1507.073
            2222.105,1607.384 2251.605,1605.448 2239.98,1583.698      "
                />
              </g>
            </g>
            <g>
              <g opacity="0.3">
                <polygon
                  points="1922.141,1588.983 1921.766,1589.746 1922.141,1589.688      "
                />
              </g>
            </g>
            <g>
              <g opacity="0.3">
                <polygon
                  points="1953.249,1580.938 1952.766,1581.919 1953.249,1581.845      "
                />
              </g>
            </g>
            <g>
              <rect
                x="2268.314"
                y="1604.972"
                fill="#5F4234"
                width="10.5"
                height="11.5"
              />
              <path
                fill="#34A936"
                d="M2272.939,1606.222c-1.375,0.125-28.625-1.375-28.625-1.375l7.625-17l-9,1.375l18.625-37.875l-8.125,4.875
          l15.375-29.375l-6.625,3l11.75-24l13.125,28.625l-6.25-3.625l14.25,33.875l-9.5-6.125l18.625,32.25l-12.375-8.375l11.625,21.75
          L2272.939,1606.222z"
              />
              <g opacity="0.3">
                <polygon
                  points="2243.314,1588.495 2242.939,1589.258 2243.314,1589.2      "
                />
                <polygon
                  points="2304.189,1590.883 2285.564,1558.633 2295.064,1564.758 2280.814,1530.883 2287.064,1534.508 2273.939,1505.883
            2273.939,1606.192 2303.439,1604.258 2291.814,1582.508       "
                />
              </g>
            </g>
            <g>
              <rect
                x="2241.597"
                y="1601.898"
                fill="#5F4234"
                width="13.521"
                height="14.809"
              />
              <path
                fill="#34A936"
                d="M2247.552,1603.508c-1.77,0.161-36.856-1.771-36.856-1.771l9.818-21.89l-11.59,1.771l23.981-48.769
          l-10.461,6.276l19.797-37.822l-8.531,3.862l15.13-30.902l16.899,36.857l-8.048-4.668l18.349,43.618l-12.232-7.887l23.982,41.524
          l-15.936-10.783l14.968,28.005L2247.552,1603.508z"
              />
              <g opacity="0.3">
                <polygon
                  points="2209.407,1580.684 2208.924,1581.665 2209.407,1581.591      "
                />
                <polygon
                  points="2287.79,1583.758 2263.809,1542.232 2276.04,1550.119 2257.691,1506.501 2265.739,1511.169 2248.84,1474.312
            2248.84,1603.471 2286.823,1600.979 2271.855,1572.975      "
                />
              </g>
            </g>
            <g>
              <g opacity="0.3">
                <polygon
                  points="2633.95,1583.278 2633.467,1584.26 2633.95,1584.186       "
                />
              </g>
            </g>
          </g>
          <g>
            <rect
              x="0.275"
              y="1616"
              fill="#5F4234"
              width="2701.725"
              height="203"
            />
            <pattern
              id="SVGID_6_"
              xlink:href="#New_Pattern_10"
              patternTransform="matrix(1 0 0 -1 800.6035 -18239.6465)"
            ></pattern>
            <rect
              x="2.511"
              y="1616"
              opacity="0.2"
              fill="url(#SVGID_6_)"
              enable-background="new    "
              width="2697.253"
              height="203"
            />
          </g>
          <g>
            <rect
              x="-16.356"
              y="1061.972"
              transform="matrix(-0.63 0.7766 -0.7766 -0.63 821.9807 1752.2028)"
              fill="#008F9E"
              width="19.891"
              height="19.875"
            />
            <circle fill="#FFFFFF" cx="-6.438" cy="1071.815" r="4.504" />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M19.849,1054.028c0,0-2.799,3.981-5.599,7.965l-0.524,0.745l-0.42,0.603
        c-0.216,0.316-0.397,0.521-0.594,0.767c-0.389,0.479-0.771,0.949-1.141,1.405c-0.734,0.905-1.42,1.75-2.007,2.476
        c-1.175,1.447-1.958,2.412-1.958,2.412l-15.436-12.521c0,0,0.783-0.965,1.958-2.413c0.587-0.725,1.272-1.568,2.007-2.475
        c0.364-0.448,0.74-0.912,1.123-1.385c-0.007,0.006,0.017-0.021-0.03,0.038c-0.007,0.01-0.046,0.068-0.024,0.038l0.136-0.192
        l0.547-0.778c2.92-4.153,5.841-8.307,5.841-8.307L19.849,1054.028z"
            />
            <path
              fill="#FFFFFF"
              d="M8.595,1052.624c2.03,1.434,2.529,4.228,1.115,6.239c-0.177,0.251-0.376,0.479-0.593,0.684
        c-0.173,0.129-0.565,0.578-0.878,0.758c-0.577,0.35-1.095,0.465-1.681,0.541c-1.146,0.12-2.337-0.196-3.302-0.979
        c-0.965-0.783-1.521-1.883-1.64-3.029c-0.074-0.556,0.022-1.194,0.121-1.681c0.04-0.227-0.064-0.253,0.111-0.6
        c0.121-0.275,0.271-0.545,0.452-0.802C3.746,1051.697,6.564,1051.189,8.595,1052.624z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M31.692,1038.503c0,0-0.75,0.959-1.873,2.396c-1.114,1.444-2.65,3.333-4.077,5.306
        c-2.896,3.912-5.791,7.823-5.791,7.823l-16.122-11.622c0,0,3.021-4.083,6.042-8.165c1.489-2.058,3.091-4.029,4.254-5.537
        c1.173-1.501,1.955-2.501,1.955-2.501L31.692,1038.503z"
            />
            <path
              fill="#FFFFFF"
              d="M20.473,1036.593c1.975,1.524,2.311,4.304,0.865,6.292c-1.464,1.978-4.274,2.383-6.276,0.91
        c-2.002-1.474-2.41-4.307-0.911-6.327C15.631,1035.433,18.513,1035.08,20.473,1036.593z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M44.061,1023.482c0,0-0.797,0.918-1.994,2.295c-1.191,1.381-2.715,3.278-4.269,5.149
        c-1.538,1.886-3.114,3.738-4.234,5.179c-1.124,1.438-1.873,2.396-1.873,2.396l-15.612-12.3c0,0,0.782-1.001,1.956-2.501
        c1.17-1.503,2.814-3.44,4.42-5.408c1.623-1.955,3.214-3.936,4.459-5.378c1.249-1.438,2.082-2.398,2.082-2.398L44.061,1023.482z"
            />
            <path
              fill="#FFFFFF"
              d="M32.931,1021.085c1.9,1.603,2.173,4.438,0.61,6.337c-1.563,1.897-4.401,2.174-6.338,0.616
        s-2.21-4.396-0.61-6.337C28.193,1019.759,31.031,1019.482,32.931,1021.085z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M57.045,1008.998c0,0-3.284,3.586-6.568,7.173c-1.665,1.772-3.214,3.649-4.423,5.016
        c-1.196,1.378-1.993,2.297-1.993,2.297l-15.064-12.966c0,0,0.833-0.959,2.082-2.396c1.262-1.428,2.883-3.385,4.622-5.24
        c3.433-3.747,6.865-7.495,6.865-7.495L57.045,1008.998z"
            />
            <path
              fill="#FFFFFF"
              d="M46.071,1006.15c1.828,1.684,1.965,4.519,0.305,6.332c-1.678,1.798-4.461,2.007-6.338,0.356
        c-1.857-1.632-2.071-4.535-0.353-6.375C41.383,1004.607,44.243,1004.467,46.071,1006.15z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M70.698,995.152c0,0-0.867,0.852-2.167,2.13c-1.286,1.292-3.066,2.948-4.735,4.717
        c-3.375,3.499-6.751,6.999-6.751,6.999l-14.481-13.613c0,0,3.529-3.659,7.058-7.316c1.746-1.849,3.606-3.583,4.952-4.936
        c1.36-1.336,2.266-2.227,2.266-2.227L70.698,995.152z"
            />
            <path
              fill="#FFFFFF"
              d="M59.833,991.789c1.766,1.777,1.718,4.557,0.03,6.344c-1.707,1.771-4.543,1.81-6.336,0.088
        c-1.793-1.721-1.83-4.585-0.083-6.396C55.174,989.994,58.097,990.04,59.833,991.789z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M84.938,981.916c0,0-0.912,0.803-2.28,2.008c-1.37,1.203-3.123,2.889-4.91,4.535
        c-1.774,1.66-3.567,3.301-4.883,4.562c-1.3,1.277-2.167,2.131-2.167,2.131L56.84,980.906c0,0,0.907-0.893,2.267-2.229
        c1.376-1.318,3.25-3.038,5.107-4.773c1.869-1.725,3.704-3.484,5.136-4.742c1.43-1.261,2.384-2.102,2.384-2.102L84.938,981.916z"
            />
            <path
              fill="#FFFFFF"
              d="M74.225,978.056c1.67,1.841,1.566,4.689-0.232,6.364c-1.799,1.675-4.647,1.573-6.361-0.227
        c-1.714-1.799-1.61-4.648,0.232-6.364C69.706,976.114,72.554,976.215,74.225,978.056z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M99.736,969.3c0,0-3.735,3.113-7.47,6.228c-1.89,1.53-3.666,3.192-5.048,4.382
        c-1.368,1.204-2.28,2.008-2.28,2.008l-13.204-14.854c0,0,0.954-0.841,2.384-2.101c1.445-1.243,3.306-2.979,5.279-4.581
        c3.905-3.254,7.81-6.508,7.81-6.508L99.736,969.3z"
            />
            <path
              fill="#FFFFFF"
              d="M89.241,965.011c1.587,1.913,1.342,4.739-0.546,6.312c-1.906,1.554-4.683,1.397-6.327-0.49
        c-1.619-1.86-1.452-4.776,0.5-6.368C84.801,962.854,87.655,963.098,89.241,965.011z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M115.123,957.401c0,0-0.975,0.727-2.437,1.815c-1.46,1.094-3.428,2.524-5.319,4.053
        c-1.909,1.507-3.818,3.012-5.25,4.142c-1.433,1.128-2.38,1.889-2.381,1.889l-12.529-15.428c0,0,0.991-0.796,2.487-1.977
        c1.496-1.18,3.492-2.754,5.488-4.326c1.978-1.597,4.032-3.093,5.558-4.234c1.527-1.14,2.546-1.898,2.546-1.898L115.123,957.401z"
            />
            <path
              fill="#FFFFFF"
              d="M104.795,952.61c1.501,1.98,1.107,4.763-0.809,6.303c-1.93,1.521-4.749,1.18-6.296-0.767
        c-1.547-1.945-1.199-4.784,0.778-6.344C100.431,950.229,103.294,950.629,104.795,952.61z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M130.994,946.149c0,0-1.011,0.676-2.528,1.688c-1.491,1.052-3.479,2.453-5.466,3.854
        c-4.008,2.76-7.877,5.708-7.877,5.708l-11.838-15.966c0,0,4.044-3.078,8.229-5.963c2.077-1.464,4.153-2.927,5.71-4.023
        c1.583-1.061,2.639-1.767,2.639-1.767L130.994,946.149z"
            />
            <path
              fill="#FFFFFF"
              d="M120.894,940.925c1.413,2.045,0.929,4.853-1.081,6.269c-2.01,1.417-4.822,0.935-6.282-1.077
        c-1.46-2.011-0.976-4.817,1.081-6.269C116.668,938.396,119.481,938.88,120.894,940.925z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M147.342,935.595c0,0-4.12,2.589-8.24,5.177c-4.054,2.688-8.107,5.379-8.107,5.379l-11.131-16.467
        c0,0,4.234-2.808,8.468-5.615c4.302-2.702,8.603-5.404,8.603-5.404L147.342,935.595z"
            />
            <path
              fill="#FFFFFF"
              d="M137.488,929.958c1.323,2.104,0.707,4.87-1.375,6.178c-2.065,1.335-4.822,0.779-6.197-1.3
        c-1.367-2.066-0.793-4.898,1.32-6.264C133.366,927.233,136.166,927.854,137.488,929.958z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M164.18,925.819c0,0-4.217,2.432-8.434,4.863c-2.109,1.216-4.218,2.432-5.8,3.343
        c-0.792,0.453-1.452,0.832-1.896,1.125c-0.451,0.282-0.708,0.443-0.708,0.443l-10.408-16.932c0,0,0.269-0.169,0.74-0.465
        c0.465-0.306,1.153-0.7,1.979-1.175c1.649-0.951,3.848-2.221,6.046-3.488c4.399-2.536,8.797-5.072,8.797-5.072L164.18,925.819z"
            />
            <path
              fill="#FFFFFF"
              d="M154.585,919.792c1.232,2.158,0.504,4.904-1.627,6.133c-2.131,1.229-4.896,0.498-6.177-1.633
        c-1.28-2.13-0.552-4.876,1.627-6.133C150.588,916.902,153.353,917.634,154.585,919.792z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M181.398,916.712c0,0-4.332,2.229-8.665,4.456c-2.154,1.136-4.364,2.171-5.919,3.13
        c-1.581,0.913-2.635,1.521-2.635,1.521l-9.684-17.356c0,0,1.1-0.634,2.75-1.584c1.625-0.998,3.922-2.081,6.166-3.264
        c4.512-2.321,9.024-4.643,9.024-4.643L181.398,916.712z"
            />
            <path
              fill="#FFFFFF"
              d="M172.042,910.262c1.142,2.208,0.293,4.91-1.895,6.035s-4.926,0.269-6.116-1.914s-0.341-4.884,1.895-6.035
        C168.161,907.198,170.899,908.055,172.042,910.262z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M198.971,908.284c0,0-4.434,2.024-8.868,4.051c-0.555,0.253-1.109,0.506-1.654,0.755
        c-0.534,0.272-1.06,0.541-1.568,0.8c-1.015,0.522-1.962,1.012-2.775,1.43c-1.624,0.836-2.707,1.394-2.707,1.394l-8.963-17.739
        c0,0,1.128-0.58,2.82-1.449c0.846-0.436,1.833-0.942,2.891-1.486c0.53-0.271,1.077-0.548,1.634-0.832
        c0.567-0.259,1.144-0.523,1.72-0.787c4.611-2.108,9.223-4.217,9.223-4.217L198.971,908.284z"
            />
            <path
              fill="#FFFFFF"
              d="M189.864,901.435c1.052,2.251,0.09,4.906-2.149,5.93c-2.259,0.981-4.883,0.172-5.994-2.08
        c-1.088-2.205-0.207-5.028,2.099-6.033C186.106,898.205,188.812,899.183,189.864,901.435z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M216.864,900.53c0,0-1.131,0.457-2.827,1.142c-1.687,0.706-3.979,1.549-6.199,2.559
        c-4.434,2.026-8.867,4.055-8.867,4.055l-8.249-18.083c0,0,4.612-2.107,9.224-4.213c2.309-1.047,4.687-1.927,6.438-2.66
        c1.762-0.712,2.938-1.188,2.938-1.188L216.864,900.53z"
            />
            <path
              fill="#FFFFFF"
              d="M208.017,893.303c0.973,2.312-0.127,4.848-2.369,5.865c-2.239,1.024-4.873,0.013-5.883-2.258
        c-1.011-2.271,0.023-4.958,2.31-6.002C204.363,889.869,207.062,891.033,208.017,893.303z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M235.043,893.438c0,0-1.15,0.409-2.875,1.021c-1.74,0.576-3.999,1.495-6.257,2.417
        c-4.523,1.827-9.046,3.654-9.046,3.654l-7.542-18.389c0,0,4.699-1.896,9.396-3.794c2.346-0.956,4.694-1.908,6.497-2.508
        c1.789-0.636,2.981-1.061,2.981-1.061L235.043,893.438z"
            />
            <path
              fill="#FFFFFF"
              d="M226.465,885.859c0.871,2.313-0.267,4.956-2.551,5.876c-2.283,0.922-4.882-0.201-5.805-2.51
        c-0.922-2.308,0.218-4.94,2.548-5.881C222.99,882.406,225.585,883.521,226.465,885.859z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M253.488,887.023c0,0-1.167,0.362-2.916,0.906c-1.734,0.587-4.027,1.426-6.329,2.237
        c-4.6,1.635-9.2,3.271-9.2,3.271l-6.846-18.658c0,0,4.771-1.696,9.542-3.394c2.388-0.841,4.767-1.708,6.564-2.315
        c1.812-0.565,3.02-0.941,3.02-0.941L253.488,887.023z"
            />
            <path
              fill="#FFFFFF"
              d="M245.209,879.195c0.791,2.355-0.45,4.936-2.771,5.761s-4.88-0.403-5.716-2.743
        c-0.836-2.341,0.404-4.92,2.771-5.761C241.86,875.61,244.419,876.839,245.209,879.195z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M272.161,881.257c0,0-1.188,0.294-2.93,0.86c-1.749,0.545-4.082,1.271-6.414,2
        c-4.665,1.453-9.329,2.906-9.329,2.906l-6.166-18.896c0,0,4.831-1.505,9.661-3.011c2.416-0.752,4.831-1.504,6.643-2.068
        c1.806-0.585,3.033-0.89,3.033-0.89L272.161,881.257z"
            />
            <path
              fill="#FFFFFF"
              d="M264.178,873.203c0.708,2.383-0.626,4.909-2.979,5.642c-2.354,0.733-4.869-0.593-5.621-2.962
        c-0.751-2.369,0.582-4.895,2.979-5.642C260.954,869.495,263.47,870.821,264.178,873.203z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="291.041,876.15 272.161,881.257 266.658,862.159 286.181,856.879  "
            />
            <path
              fill="#FFFFFF"
              d="M283.338,867.86c0.626,2.404-0.795,4.877-3.175,5.521c-2.38,0.645-4.852-0.773-5.521-3.168
        c-0.669-2.394,0.751-4.865,3.174-5.521C280.239,864.036,282.711,865.456,283.338,867.86z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M310.099,871.704c0,0-4.767,1.104-9.533,2.211c-2.383,0.552-4.767,1.104-6.554,1.519
        c-0.894,0.207-1.638,0.379-2.16,0.5c-0.517,0.138-0.812,0.217-0.812,0.217l-4.86-19.271c0,0,0.305-0.081,0.839-0.223
        c0.539-0.125,1.308-0.305,2.23-0.519c1.845-0.428,4.306-1,6.767-1.569c4.922-1.142,9.844-2.282,9.844-2.282L310.099,871.704z"
            />
            <path
              fill="#FFFFFF"
              d="M302.662,863.148c0.548,2.424-0.956,4.841-3.358,5.397c-2.402,0.558-4.828-0.949-5.417-3.363
        c-0.59-2.415,0.914-4.831,3.357-5.397C299.688,859.219,302.114,860.725,302.662,863.148z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M329.294,867.834c0,0-4.805,0.944-9.609,1.889c-2.402,0.473-4.804,0.944-6.606,1.298
        c-1.789,0.41-2.98,0.685-2.98,0.685l-4.237-19.418c0,0,1.23-0.282,3.078-0.705c1.858-0.365,4.335-0.854,6.812-1.34
        c4.955-0.975,9.909-1.947,9.909-1.947L329.294,867.834z"
            />
            <path
              fill="#FFFFFF"
              d="M322.123,859.037c0.472,2.439-1.108,4.804-3.529,5.28c-2.421,0.476-4.798-1.11-5.311-3.542
        c-0.512-2.433,1.068-4.797,3.529-5.279C319.273,855.011,321.651,856.597,322.123,859.037z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M348.609,864.548c0,0-4.834,0.793-9.668,1.587c-2.416,0.405-4.838,0.775-6.644,1.108
        c-1.802,0.354-3.002,0.591-3.002,0.591l-3.634-19.54c0,0,1.239-0.243,3.097-0.608c1.861-0.342,4.355-0.726,6.844-1.143
        c4.98-0.817,9.959-1.634,9.959-1.634L348.609,864.548z"
            />
            <path
              fill="#FFFFFF"
              d="M341.702,855.533c0.398,2.453-1.253,4.766-3.688,5.166c-2.436,0.398-4.764-1.26-5.201-3.707
        s1.214-4.759,3.688-5.166S341.304,853.08,341.702,855.533z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M368.023,861.828c0,0-4.859,0.646-9.718,1.294c-4.869,0.589-9.695,1.427-9.696,1.426l-3.049-19.64
        c0,0.001,4.974-0.86,9.981-1.468c5-0.666,9.999-1.332,9.999-1.332L368.023,861.828z"
            />
            <path
              fill="#FFFFFF"
              d="M361.377,852.609c0.327,2.464-1.392,4.725-3.839,5.051c-2.447,0.326-4.726-1.403-5.09-3.862
        c-0.364-2.458,1.354-4.72,3.839-5.051C358.771,848.417,361.05,850.146,361.377,852.609z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M387.517,859.653c0,0-4.879,0.506-9.757,1.012c-2.438,0.254-4.881,0.486-6.699,0.757
        c-1.822,0.243-3.037,0.405-3.037,0.405l-2.484-19.719c0,0,1.25-0.167,3.125-0.416c1.872-0.278,4.381-0.518,6.888-0.779
        c5.014-0.521,10.028-1.041,10.028-1.041L387.517,859.653z"
            />
            <path
              fill="#FFFFFF"
              d="M381.127,850.247c0.258,2.472-1.524,4.682-3.98,4.937c-2.457,0.255-4.686-1.539-4.98-4.007
        s1.488-4.678,3.98-4.937C378.64,845.981,380.869,847.775,381.127,850.247z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M407.08,857.979c0,0-4.896,0.381-9.793,0.761c-1.225,0.095-2.448,0.188-3.596,0.277
        c-1.144,0.117-2.211,0.227-3.126,0.32c-1.83,0.19-3.049,0.316-3.049,0.316l-1.937-19.78c0,0,1.253-0.13,3.133-0.325
        c0.94-0.096,2.037-0.208,3.212-0.328c1.176-0.091,2.431-0.188,3.686-0.287c5.019-0.389,10.039-0.777,10.039-0.777L407.08,857.979z"
            />
            <path
              fill="#FFFFFF"
              d="M400.933,848.41c0.193,2.478-1.646,4.642-4.11,4.833c-2.464,0.188-4.645-1.661-4.869-4.119
        c-0.228-2.492,1.613-4.658,4.109-4.85C398.561,844.081,400.74,845.933,400.933,848.41z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M426.678,856.811c0,0-4.899,0.26-9.801,0.52c-2.45,0.129-4.9,0.245-6.736,0.411
        c-1.836,0.143-3.061,0.237-3.061,0.237l-1.431-19.822c0,0,1.254-0.099,3.137-0.243c1.88-0.17,4.396-0.29,6.912-0.423
        c5.03-0.267,10.062-0.532,10.062-0.532L426.678,856.811z"
            />
            <path
              fill="#FFFFFF"
              d="M420.781,847.094c0.13,2.482-1.765,4.601-4.231,4.73s-4.6-1.774-4.765-4.254
        c-0.165-2.48,1.729-4.599,4.23-4.73S420.651,844.612,420.781,847.094z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M446.284,856.167c0,0-4.901,0.131-9.804,0.262c-4.901,0.079-9.802,0.384-9.802,0.382l-0.918-19.854
        c0,0,5.028-0.31,10.072-0.393c5.04-0.136,10.08-0.271,10.08-0.271L446.284,856.167z"
            />
            <path
              fill="#FFFFFF"
              d="M440.656,846.298c0.062,2.485-1.887,4.552-4.355,4.618c-2.468,0.066-4.55-1.895-4.648-4.377
        c-0.1-2.483,1.851-4.551,4.354-4.618C438.512,841.854,440.593,843.814,440.656,846.298z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M465.882,856.077c0,0-4.898-0.005-9.798-0.009c-2.45,0.008-4.898-0.03-6.737,0.016
        c-1.838,0.05-3.062,0.083-3.062,0.083l-0.372-19.872c0,0,1.26-0.033,3.15-0.083c1.89-0.047,4.414-0.01,6.938-0.019
        c5.046,0.005,10.092,0.01,10.092,0.01L465.882,856.077z"
            />
            <path
              fill="#FFFFFF"
              d="M460.545,846.057c-0.009,2.485-2.017,4.498-4.484,4.496c-2.469-0.003-4.493-2.02-4.523-4.505
        s1.978-4.498,4.484-4.495C458.529,841.555,460.555,843.572,460.545,846.057z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M485.367,856.719c0,0-4.866-0.189-9.731-0.381c-2.434-0.094-4.866-0.188-6.69-0.258
        c-1.837-0.001-3.062-0.002-3.062-0.002l0.209-19.874c0,0,1.262,0.001,3.154,0.004c1.902,0.072,4.438,0.168,6.974,0.264
        c5.072,0.199,10.146,0.398,10.146,0.398L485.367,856.719z"
            />
            <path
              fill="#FFFFFF"
              d="M480.431,846.503c-0.101,2.483-2.175,4.418-4.633,4.322c-2.458-0.097-4.414-2.189-4.369-4.674
        c0.045-2.485,2.12-4.42,4.634-4.322C478.575,841.927,480.531,844.02,480.431,846.503z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M504.801,858.114c0,0-4.854-0.416-9.711-0.833c-1.214-0.104-2.428-0.208-3.564-0.307
        c-1.14-0.078-2.205-0.095-3.117-0.138c-1.824-0.071-3.041-0.118-3.041-0.118l0.997-19.85c0,0,1.268,0.05,3.17,0.125
        c0.951,0.045,2.062,0.063,3.248,0.146c1.185,0.102,2.448,0.209,3.711,0.316c5.053,0.432,10.106,0.864,10.106,0.864L504.801,858.114
        z"
            />
            <path
              fill="#FFFFFF"
              d="M500.285,847.675c-0.202,2.479-2.354,4.315-4.807,4.105c-2.451-0.208-4.317-2.401-4.171-4.852
        c0.151-2.512,2.303-4.338,4.808-4.126C498.62,843.017,500.486,845.198,500.285,847.675z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M524.179,860.267c0,0-4.836-0.632-9.672-1.264c-1.207-0.181-2.423-0.267-3.561-0.365
        c-1.139-0.097-2.2-0.188-3.111-0.265c-1.821-0.156-3.035-0.259-3.035-0.259l1.799-19.793c0,0,1.264,0.107,3.158,0.271
        c0.948,0.081,2.054,0.176,3.237,0.278c1.185,0.103,2.45,0.194,3.704,0.38c5.024,0.655,10.051,1.311,10.051,1.311L524.179,860.267z"
            />
            <path
              fill="#FFFFFF"
              d="M520.078,849.632c-0.299,2.467-2.521,4.208-4.962,3.891c-1.222-0.181-2.279-0.763-3.002-1.642
        c-0.722-0.879-1.109-2.041-0.983-3.276c0.125-1.236,0.743-2.312,1.646-3.053c0.901-0.741,2.09-1.157,3.332-0.976
        C518.6,844.901,520.377,847.165,520.078,849.632z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M543.477,863.15c0,0-1.201-0.209-3.005-0.523c-1.807-0.291-4.2-0.787-6.618-1.1
        c-4.838-0.63-9.674-1.261-9.674-1.261l2.571-19.708c0,0,5.025,0.657,10.05,1.313c2.514,0.327,4.995,0.837,6.87,1.139
        c1.871,0.325,3.118,0.542,3.118,0.542L543.477,863.15z"
            />
            <path
              fill="#FFFFFF"
              d="M539.784,852.342c-0.396,2.479-2.671,4.048-5.113,3.731c-2.44-0.318-4.142-2.571-3.799-5.033
        s2.642-4.193,5.134-3.868S540.173,849.912,539.784,852.342z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M562.673,866.736c0,0-1.194-0.26-2.985-0.646c-0.896-0.19-1.94-0.414-3.061-0.652
        c-1.127-0.195-2.33-0.404-3.532-0.613c-4.809-0.836-9.618-1.673-9.618-1.673l3.312-19.599c0,0,4.989,0.869,9.979,1.737
        c1.246,0.218,2.493,0.435,3.663,0.639c1.16,0.247,2.242,0.478,3.171,0.675c1.854,0.4,3.09,0.668,3.09,0.668L562.673,866.736z"
            />
            <path
              fill="#FFFFFF"
              d="M559.378,855.776c-0.478,2.411-2.837,4.046-5.264,3.626c-2.427-0.422-4.042-2.748-3.606-5.195
        s2.794-4.081,5.27-3.65C558.251,850.987,559.866,853.312,559.378,855.776z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M581.751,870.987c0,0-1.185-0.306-2.961-0.763c-1.791-0.388-4.178-0.905-6.566-1.423
        c-4.775-1.033-9.552-2.065-9.552-2.065l4.018-19.465c0,0,4.945,1.069,9.891,2.139c2.472,0.537,4.943,1.074,6.797,1.477
        c1.836,0.472,3.061,0.785,3.061,0.785L581.751,870.987z"
            />
            <path
              fill="#FFFFFF"
              d="M578.821,859.983c-0.567,2.42-2.979,3.959-5.389,3.438c-2.409-0.521-3.938-2.913-3.417-5.343
        c0.522-2.43,2.936-3.969,5.39-3.438C577.859,855.171,579.389,857.562,578.821,859.983z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="600.699,875.87 581.751,870.987 586.438,851.672 606.011,856.718  "
            />
            <path
              fill="#FFFFFF"
              d="M598.107,864.85c-0.646,2.4-3.105,3.849-5.493,3.235c-2.39-0.614-3.835-3.065-3.23-5.476
        c0.604-2.411,3.064-3.859,5.494-3.235C597.309,859.999,598.755,862.45,598.107,864.85z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="619.482,881.424 600.699,875.87 606.011,856.718 625.376,862.443  "
            />
            <path
              fill="#FFFFFF"
              d="M617.232,870.316c-0.72,2.379-3.222,3.739-5.588,3.041c-2.365-0.699-3.731-3.205-3.051-5.596
        c0.682-2.39,3.184-3.751,5.588-3.04C616.588,865.433,617.954,867.938,617.232,870.316z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M638.109,887.538c0,0-4.651-1.547-9.304-3.095c-2.325-0.774-4.65-1.548-6.396-2.128
        c-0.868-0.304-1.61-0.492-2.121-0.652c-0.514-0.152-0.807-0.239-0.807-0.239l5.893-18.98c0,0,0.303,0.089,0.832,0.246
        c0.527,0.164,1.292,0.36,2.186,0.672c1.795,0.597,4.188,1.392,6.581,2.188c4.786,1.592,9.571,3.185,9.571,3.185L638.109,887.538z"
            />
            <path
              fill="#FFFFFF"
              d="M636.188,876.344c-0.789,2.357-3.327,3.637-5.67,2.856c-2.342-0.78-3.63-3.331-2.877-5.7
        c0.754-2.368,3.292-3.646,5.67-2.856C635.689,871.435,636.979,873.988,636.188,876.344z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M656.578,894.165c0,0-4.605-1.695-9.21-3.391c-2.304-0.844-4.599-1.721-6.353-2.27
        c-1.744-0.58-2.906-0.966-2.906-0.966l6.436-18.805c0,0,1.197,0.397,2.991,0.996c1.806,0.566,4.159,1.465,6.524,2.33
        c4.729,1.74,9.457,3.48,9.457,3.48L656.578,894.165z"
            />
            <path
              fill="#FFFFFF"
              d="M654.968,882.899c-0.854,2.334-3.424,3.534-5.741,2.681c-2.318-0.854-3.532-3.447-2.712-5.793
        s3.392-3.546,5.742-2.682C654.607,877.972,655.821,880.565,654.968,882.899z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M674.89,901.26c0,0-4.558-1.833-9.114-3.666c-2.275-0.928-4.599-1.717-6.318-2.371
        c-1.727-0.636-2.878-1.059-2.878-1.059l6.938-18.625c0,0,1.183,0.435,2.955,1.088c1.766,0.671,4.153,1.484,6.483,2.434
        c4.67,1.878,9.34,3.755,9.34,3.755L674.89,901.26z"
            />
            <path
              fill="#FFFFFF"
              d="M673.572,889.936c-0.913,2.312-3.512,3.438-5.805,2.517c-2.289-0.935-3.475-3.451-2.586-5.792
        c0.875-2.307,3.52-3.545,5.838-2.601C673.342,884.994,674.485,887.624,673.572,889.936z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M693.043,908.793c0,0-1.127-0.489-2.818-1.224c-1.699-0.713-3.93-1.761-6.22-2.646
        c-4.558-1.832-9.116-3.664-9.116-3.664l7.406-18.442c0,0,4.67,1.877,9.339,3.755c2.349,0.91,4.625,1.976,6.364,2.707
        c1.729,0.75,2.883,1.251,2.883,1.251L693.043,908.793z"
            />
            <path
              fill="#FFFFFF"
              d="M692.001,897.423c-0.976,2.308-3.575,3.305-5.878,2.408c-2.293-0.922-3.391-3.535-2.451-5.835
        c0.939-2.301,3.583-3.409,5.906-2.475C691.911,892.43,692.961,895.152,692.001,897.423z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M711.042,916.734c0,0-4.447-2.114-8.978-4.025c-4.511-1.958-9.021-3.916-9.021-3.916l7.838-18.264
        c0,0,4.612,2.002,9.226,4.004c4.634,1.958,9.171,4.113,9.171,4.113L711.042,916.734z"
            />
            <path
              fill="#FFFFFF"
              d="M710.249,905.343c-1.021,2.268-3.685,3.307-5.953,2.322c-2.268-0.984-3.303-3.629-2.311-5.908
        c0.991-2.278,3.657-3.317,5.952-2.322C710.234,900.431,711.268,903.077,710.249,905.343z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M728.887,925.053c0-0.001-1.097-0.562-2.776-1.327c-1.675-0.775-3.907-1.812-6.14-2.848
        c-4.465-2.072-8.93-4.144-8.93-4.144l8.236-18.089c0,0,4.557,2.114,9.113,4.229c2.278,1.057,4.558,2.115,6.266,2.908
        c1.715,0.779,2.832,1.353,2.832,1.353L728.887,925.053z"
            />
            <path
              fill="#FFFFFF"
              d="M728.3,913.701c-1.064,2.246-3.748,3.222-5.992,2.181c-2.243-1.041-3.22-3.715-2.179-5.972
        s3.725-3.233,5.992-2.182C728.39,908.782,729.365,911.456,728.3,913.701z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="746.568,933.754 728.887,925.053 737.488,907.135 755.5,916   "
            />
            <path
              fill="#FFFFFF"
              d="M746.186,922.404c-1.106,2.226-3.805,3.143-6.025,2.05s-3.141-3.791-2.057-6.027
        c1.086-2.235,3.783-3.152,6.026-2.05C746.373,917.481,747.293,920.18,746.186,922.404z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M764.095,942.793c0,0-4.377-2.271-8.753-4.541c-2.188-1.136-4.377-2.271-6.018-3.122
        c-1.645-0.848-2.755-1.376-2.755-1.376L755.5,916c0,0,1.134,0.539,2.806,1.4c1.669,0.865,3.896,2.021,6.121,3.175
        c4.451,2.31,8.903,4.618,8.903,4.618L764.095,942.793z"
            />
            <path
              fill="#FFFFFF"
              d="M763.917,931.418c-1.146,2.206-3.856,3.069-6.055,1.929s-3.067-3.859-1.94-6.075
        c1.126-2.217,3.837-3.079,6.055-1.93C764.195,926.493,765.062,929.213,763.917,931.418z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M781.489,952.111c0,0-4.336-2.356-8.672-4.712c-2.163-1.189-4.339-2.352-5.987-3.188
        c-1.643-0.852-2.736-1.419-2.736-1.419l9.235-17.6c0,0,1.112,0.577,2.782,1.442c1.677,0.854,3.888,2.032,6.083,3.239
        c4.401,2.392,8.805,4.784,8.805,4.784L781.489,952.111z"
            />
            <path
              fill="#FFFFFF"
              d="M781.5,940.719c-1.182,2.188-3.904,3.001-6.081,1.818c-2.176-1.187-2.998-3.922-1.84-6.106
        c1.169-2.207,3.894-3.022,6.087-1.829C781.861,935.794,782.682,938.532,781.5,940.719z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M798.759,961.688c0,0-4.299-2.433-8.597-4.864c-4.336-2.355-8.673-4.711-8.673-4.711L791,934.66
        c0,0,4.402,2.392,8.805,4.784c4.357,2.465,8.715,4.93,8.715,4.93L798.759,961.688z"
            />
            <path
              fill="#FFFFFF"
              d="M798.941,950.283c-1.215,2.169-3.946,2.938-6.104,1.717c-2.177-1.183-2.971-3.906-1.774-6.085
        c1.197-2.178,3.947-2.978,6.142-1.784C799.378,945.36,800.155,948.114,798.941,950.283z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M815.893,971.535c0,0-1.062-0.632-2.655-1.581c-0.798-0.474-1.728-1.025-2.724-1.617
        c-1.007-0.57-2.082-1.178-3.156-1.786c-4.3-2.432-8.599-4.863-8.599-4.863l9.761-17.312c0,0,4.357,2.466,8.716,4.931
        c1.089,0.616,2.178,1.232,3.2,1.812c1.008,0.599,1.947,1.157,2.754,1.636c1.61,0.959,2.686,1.599,2.686,1.599L815.893,971.535z"
            />
            <path
              fill="#FFFFFF"
              d="M816.247,960.092c-1.236,2.141-3.99,2.892-6.149,1.674c-2.156-1.221-2.91-3.962-1.683-6.123
        c1.228-2.16,3.984-2.916,6.157-1.688C816.746,955.182,817.497,957.927,816.247,960.092z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="832.886,981.654 815.893,971.535 825.875,954.35 843.062,964.583  "
            />
            <path
              fill="#FFFFFF"
              d="M833.338,970.268c-1.267,2.14-4.021,2.845-6.152,1.574c-2.131-1.269-2.841-4.036-1.586-6.182
        c1.254-2.146,4.008-2.852,6.151-1.574C833.895,965.362,834.605,968.13,833.338,970.268z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="849.88,991.771 832.886,981.654 843.062,964.583 860.25,974.817   "
            />
            <path
              fill="#FFFFFF"
              d="M850.429,980.444c-1.291,2.124-4.064,2.816-6.195,1.548c-2.132-1.27-2.822-4.024-1.544-6.156
        c1.278-2.131,4.053-2.824,6.196-1.548C851.03,975.564,851.721,978.321,850.429,980.444z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M866.675,1002.264c0,0-4.192-2.634-8.385-5.269c-2.096-1.317-4.192-2.635-5.765-3.623
        c-0.782-0.502-1.452-0.886-1.915-1.165c-0.466-0.276-0.73-0.435-0.73-0.435l10.37-16.955c0,0,0.27,0.159,0.738,0.438
        c0.468,0.282,1.146,0.672,1.935,1.177c1.586,0.997,3.7,2.325,5.814,3.653c4.229,2.657,8.457,5.313,8.457,5.313L866.675,1002.264z"
            />
            <path
              fill="#FFFFFF"
              d="M867.368,990.871c-1.312,2.111-4.078,2.753-6.179,1.432c-2.102-1.319-2.75-4.105-1.448-6.224
        s4.067-2.759,6.179-1.433C868.03,985.974,868.679,988.76,867.368,990.871z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="883.445,1012.802 866.675,1002.264 877.194,985.4 894.105,996.028   "
            />
            <path
              fill="#FFFFFF"
              d="M884.21,1001.454c-1.329,2.101-4.11,2.731-6.211,1.412c-2.101-1.32-2.735-4.099-1.416-6.204
        c1.319-2.106,4.1-2.738,6.211-1.412C884.905,996.577,885.539,999.354,884.21,1001.454z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M900.117,1023.527c0,0-4.144-2.729-8.287-5.456c-4.192-2.636-8.385-5.271-8.385-5.271l10.662-16.772
        c0,0,4.229,2.656,8.457,5.313c4.162,2.741,8.325,5.482,8.325,5.482L900.117,1023.527z"
            />
            <path
              fill="#FFFFFF"
              d="M901.004,1012.111c-1.348,2.096-4.107,2.662-6.195,1.316c-2.103-1.319-2.722-4.089-1.384-6.184
        s4.132-2.718,6.243-1.391C901.763,1007.203,902.345,1010.027,901.004,1012.111z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="916.675,1034.467 900.117,1023.527 910.889,1006.825 927.523,1017.812   "
            />
            <path
              fill="#FFFFFF"
              d="M917.602,1023.075c-1.354,2.084-4.133,2.663-6.207,1.294c-2.072-1.369-2.658-4.171-1.31-6.259
        c1.349-2.087,4.127-2.666,6.206-1.294C918.369,1018.189,918.955,1020.991,917.602,1023.075z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="933.234,1045.406 916.675,1034.467 927.523,1017.812 944.157,1028.801   "
            />
            <path
              fill="#FFFFFF"
              d="M934.198,1034.039c-1.364,2.078-4.149,2.652-6.224,1.283c-2.072-1.37-2.651-4.167-1.293-6.248
        c1.359-2.081,4.146-2.655,6.223-1.282C934.982,1029.164,935.562,1031.961,934.198,1034.039z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="949.793,1056.345 933.234,1045.406 944.157,1028.801 960.791,1039.789   "
            />
            <circle fill="#FFFFFF" cx="947.037" cy="1042.521" r="4.504" />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="966.354,1067.283 949.793,1056.345 960.791,1039.789 977.424,1050.778   "
            />
            <path
              fill="#FFFFFF"
              d="M967.391,1055.967c-1.382,2.065-4.183,2.629-6.256,1.26c-2.072-1.368-2.637-4.155-1.259-6.225
        c1.378-2.068,4.178-2.633,6.256-1.261C968.21,1051.114,968.773,1053.901,967.391,1055.967z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M982.875,1078.333c0,0-4.125-2.78-8.25-5.562c-4.137-2.743-8.273-5.488-8.273-5.488l11.072-16.505
        c0,0,4.15,2.754,8.301,5.508c4.122,2.778,8.244,5.556,8.244,5.556L982.875,1078.333z"
            />
            <path
              fill="#FFFFFF"
              d="M983.953,1066.98c-1.389,2.062-4.186,2.606-6.248,1.217c-2.059-1.397-2.622-4.166-1.233-6.231
        c1.384-2.061,4.203-2.63,6.261-1.233C984.794,1062.122,985.341,1064.92,983.953,1066.98z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="999.376,1089.453 982.875,1078.333 993.969,1061.842 1010.458,1072.954  "
            />
            <path
              fill="#FFFFFF"
              d="M1000.448,1078.098c-1.386,2.063-4.183,2.608-6.245,1.22c-2.063-1.392-2.611-4.189-1.225-6.252
        c1.388-2.063,4.184-2.607,6.245-1.219C1001.286,1073.236,1001.833,1076.035,1000.448,1078.098z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1015.876,1100.574 999.376,1089.453 1010.458,1072.954 1026.946,1084.066  "
            />
            <path
              fill="#FFFFFF"
              d="M1016.942,1089.215c-1.384,2.063-4.18,2.609-6.242,1.22c-2.063-1.391-2.612-4.189-1.228-6.254
        c1.387-2.062,4.181-2.608,6.242-1.22C1017.777,1084.351,1018.327,1087.15,1016.942,1089.215z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1032.377,1111.694 1015.876,1100.574 1026.946,1084.066 1043.436,1095.179   "
            />
            <path
              fill="#FFFFFF"
              d="M1033.438,1100.331c-1.384,2.063-4.178,2.611-6.24,1.222c-2.063-1.391-2.613-4.19-1.229-6.256
        c1.384-2.064,4.177-2.61,6.239-1.221S1034.819,1098.266,1033.438,1100.331z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1048.877,1122.814 1032.377,1111.694 1043.436,1095.179 1059.923,1106.291   "
            />
            <path
              fill="#FFFFFF"
              d="M1049.931,1111.447c-1.381,2.065-4.174,2.613-6.236,1.224c-2.063-1.391-2.615-4.192-1.232-6.258
        c1.383-2.066,4.175-2.612,6.237-1.224C1050.761,1106.58,1051.312,1109.381,1049.931,1111.447z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1065.487,1133.832c0,0-4.159-2.748-8.318-5.496c-2.078-1.376-4.155-2.752-5.714-3.783
        c-1.547-1.043-2.578-1.737-2.578-1.737l11.045-16.523c0,0,1.03,0.695,2.576,1.736c1.551,1.026,3.62,2.396,5.688,3.767
        c4.138,2.734,8.275,5.47,8.275,5.47L1065.487,1133.832z"
            />
            <path
              fill="#FFFFFF"
              d="M1066.479,1122.483c-1.375,2.069-4.174,2.635-6.25,1.262c-2.078-1.373-2.644-4.161-1.264-6.229
        c1.38-2.066,4.18-2.632,6.251-1.263C1067.289,1117.624,1067.854,1120.413,1066.479,1122.483z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1082.124,1144.825 1065.487,1133.832 1076.464,1117.264 1093.018,1128.202   "
            />
            <path
              fill="#FFFFFF"
              d="M1083.073,1133.449c-1.365,2.076-4.155,2.646-6.233,1.273s-2.65-4.167-1.281-6.239
        c1.37-2.073,4.161-2.646,6.233-1.275S1084.438,1131.372,1083.073,1133.449z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1098.852,1155.706c0,0-4.205-2.692-8.41-5.384c-4.158-2.749-8.317-5.497-8.317-5.497l10.894-16.623
        c0,0,4.14,2.735,8.277,5.47c4.171,2.67,8.343,5.341,8.343,5.341L1098.852,1155.706z"
            />
            <path
              fill="#FFFFFF"
              d="M1099.701,1144.363c-1.352,2.082-4.157,2.693-6.248,1.338c-2.078-1.373-2.66-4.172-1.3-6.252
        c1.359-2.08,4.142-2.656,6.214-1.287C1100.451,1139.513,1101.055,1142.275,1099.701,1144.363z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1115.68,1166.46 1098.852,1155.706 1109.637,1139.013 1126.33,1149.68   "
            />
            <path
              fill="#FFFFFF"
              d="M1116.461,1155.074c-1.336,2.096-4.121,2.707-6.222,1.363c-2.101-1.342-2.714-4.123-1.368-6.214
        c1.345-2.091,4.13-2.7,6.222-1.364C1117.184,1150.196,1117.796,1152.979,1116.461,1155.074z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1132.545,1177.165c0,0-1.066-0.652-2.666-1.632c-1.578-1.008-3.681-2.353-5.785-3.696
        c-4.207-2.688-8.414-5.377-8.414-5.377l10.65-16.78c0,0,4.173,2.667,8.346,5.334c2.086,1.333,4.174,2.666,5.738,3.666
        c1.582,0.969,2.636,1.613,2.636,1.613L1132.545,1177.165z"
            />
            <path
              fill="#FFFFFF"
              d="M1133.221,1165.784c-1.318,2.105-4.09,2.727-6.189,1.385c-2.102-1.343-2.728-4.134-1.399-6.233
        c1.328-2.101,4.101-2.722,6.191-1.385C1133.913,1160.887,1134.54,1163.678,1133.221,1165.784z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1149.643,1187.557 1132.545,1177.165 1143.05,1160.293 1159.948,1170.562  "
            />
            <path
              fill="#FFFFFF"
              d="M1150.186,1176.166c-1.293,2.122-4.071,2.791-6.203,1.496c-2.133-1.296-2.801-4.061-1.493-6.173
        c1.308-2.114,4.085-2.783,6.204-1.495C1150.812,1171.281,1151.48,1174.045,1150.186,1176.166z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1166.741,1197.946 1149.643,1187.557 1159.948,1170.562 1176.846,1180.832   "
            />
            <path
              fill="#FFFFFF"
              d="M1167.184,1186.496c-1.271,2.137-4.027,2.818-6.16,1.521c-2.131-1.296-2.819-4.07-1.537-6.199
        c1.281-2.129,4.039-2.812,6.158-1.522C1167.764,1181.584,1168.452,1184.359,1167.184,1186.496z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1184.152,1207.876c0,0-4.361-2.47-8.724-4.938c-2.181-1.233-4.362-2.47-5.998-3.396
        c-0.823-0.455-1.489-0.863-1.956-1.149c-0.467-0.283-0.734-0.445-0.734-0.445l10.104-17.114c0,0,0.265,0.16,0.726,0.44
        c0.461,0.282,1.121,0.688,1.93,1.134c1.609,0.911,3.757,2.126,5.902,3.341c4.293,2.429,8.586,4.857,8.586,4.857L1184.152,1207.876z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1184.375,1196.493c-1.238,2.155-4.004,2.904-6.178,1.674s-2.917-3.966-1.661-6.11
        c1.257-2.145,4.022-2.894,6.178-1.674C1184.87,1191.604,1185.613,1194.338,1184.375,1196.493z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1201.604,1217.75 1184.152,1207.876 1193.99,1190.605 1211.162,1200.325   "
            />
            <path
              fill="#FFFFFF"
              d="M1201.686,1206.289c-1.205,2.175-3.941,2.939-6.115,1.71c-2.174-1.23-2.945-3.981-1.723-6.146
        c1.222-2.163,3.959-2.93,6.114-1.71C1202.118,1201.363,1202.888,1204.115,1201.686,1206.289z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1219.256,1227.302c0,0-4.439-2.347-8.879-4.691c-2.234-1.15-4.417-2.384-6.047-3.316
        c-1.637-0.926-2.727-1.543-2.727-1.543l9.559-17.425c0,0,1.072,0.606,2.683,1.519c1.604,0.92,3.753,2.134,5.941,3.259
        c4.354,2.302,8.707,4.603,8.707,4.603L1219.256,1227.302z"
            />
            <path
              fill="#FFFFFF"
              d="M1219.1,1215.892c-1.164,2.195-3.9,3.028-6.11,1.859c-1.104-0.584-1.866-1.555-2.2-2.653
        c-0.323-1.119-0.227-2.354,0.368-3.447c0.593-1.093,1.567-1.833,2.663-2.148c1.104-0.336,2.335-0.256,3.43,0.323
        C1219.438,1210.979,1220.266,1213.696,1219.1,1215.892z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1237.15,1236.457c0,0-4.496-2.251-8.992-4.502c-2.262-1.104-4.455-2.32-6.127-3.188
        c-1.665-0.88-2.774-1.467-2.774-1.467l9.238-17.598c0,0,1.088,0.575,2.721,1.438c1.64,0.85,3.794,2.047,6.003,3.123
        c4.397,2.2,8.794,4.4,8.794,4.4L1237.15,1236.457z"
            />
            <path
              fill="#FFFFFF"
              d="M1236.75,1225.057c-1.12,2.22-3.841,3.11-6.078,1.99c-2.248-1.102-3.09-3.875-1.949-6.07
        c1.149-2.217,3.832-3.026,6.053-1.939C1236.984,1220.143,1237.87,1222.838,1236.75,1225.057z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1255.273,1245.217c0,0-4.56-2.14-9.119-4.279c-4.502-2.239-9.004-4.479-9.004-4.479l8.861-17.791
        c0,0,4.401,2.191,8.803,4.382c4.444,2.085,8.89,4.17,8.89,4.17L1255.273,1245.217z"
            />
            <path
              fill="#FFFFFF"
              d="M1254.596,1233.836c-1.068,2.244-3.771,3.201-6.037,2.138c-2.258-1.081-3.168-3.823-2.076-6.046
        c1.1-2.24,3.768-3.122,5.996-2.056C1254.716,1228.922,1255.663,1231.592,1254.596,1233.836z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1273.636,1253.524c0,0-4.623-2.017-9.245-4.031c-4.56-2.14-9.119-4.276-9.119-4.276l8.432-17.999
        c0,0,4.443,2.086,8.889,4.172c4.494,1.959,8.988,3.917,8.988,3.917L1273.636,1253.524z"
            />
            <path
              fill="#FFFFFF"
              d="M1272.65,1242.175c-1.008,2.269-3.692,3.311-5.983,2.295c-2.267-1.062-3.261-3.755-2.22-6.012
        s3.697-3.235,5.934-2.187C1272.638,1237.271,1273.66,1239.899,1272.65,1242.175z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1292.25,1261.331c0,0-1.173-0.468-2.934-1.17c-1.75-0.72-4.125-1.599-6.432-2.614
        c-4.625-2.011-9.25-4.021-9.25-4.021l7.945-18.219c0,0,4.496,1.956,8.99,3.911c2.244,0.99,4.539,1.835,6.236,2.534
        c1.704,0.68,2.842,1.133,2.842,1.133L1292.25,1261.331z"
            />
            <path
              fill="#FFFFFF"
              d="M1290.924,1250.021c-0.934,2.28-3.611,3.45-5.906,2.439c-2.298-1-3.367-3.661-2.389-5.946
        c0.979-2.284,3.605-3.339,5.87-2.354C1290.757,1245.157,1291.874,1247.701,1290.924,1250.021z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1311.141,1268.548c0,0-1.19-0.429-2.977-1.072c-1.777-0.661-4.186-1.462-6.527-2.401
        c-4.692-1.871-9.386-3.742-9.386-3.742l7.397-18.446c0,0,4.546,1.812,9.091,3.626c2.271,0.913,4.588,1.68,6.305,2.318
        c1.724,0.621,2.872,1.035,2.872,1.035L1311.141,1268.548z"
            />
            <path
              fill="#FFFFFF"
              d="M1309.436,1257.288c-0.858,2.311-3.5,3.569-5.828,2.634c-2.33-0.929-3.482-3.557-2.574-5.87
        c0.908-2.312,3.501-3.448,5.791-2.535C1309.111,1252.436,1310.309,1254.939,1309.436,1257.288z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1330.309,1275.104c0,0-1.207-0.385-3.02-0.963c-1.805-0.597-4.244-1.306-6.626-2.16
        c-4.763-1.717-9.522-3.433-9.522-3.433l6.775-18.684c0,0,4.594,1.655,9.189,3.312c2.297,0.827,4.638,1.503,6.373,2.077
        c1.741,0.556,2.902,0.925,2.902,0.925L1330.309,1275.104z"
            />
            <path
              fill="#FFFFFF"
              d="M1328.186,1263.915c-0.774,2.341-3.371,3.696-5.734,2.847c-2.362-0.851-3.605-3.439-2.778-5.783
        c0.828-2.344,3.377-3.565,5.694-2.729C1327.686,1259.082,1328.973,1261.536,1328.186,1263.915z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1349.758,1280.91c0,0-1.225-0.336-3.062-0.839c-1.831-0.522-4.303-1.132-6.723-1.891
        c-4.832-1.538-9.664-3.077-9.664-3.077l6.071-18.926c0,0,4.645,1.48,9.288,2.962c2.324,0.729,4.688,1.308,6.441,1.809
        c1.759,0.48,2.933,0.802,2.933,0.802L1349.758,1280.91z"
            />
            <path
              fill="#FFFFFF"
              d="M1347.173,1269.819c-0.679,2.368-3.221,3.829-5.62,3.077c-2.395-0.763-3.738-3.305-3-5.679
        c0.737-2.373,3.236-3.69,5.58-2.942C1346.479,1265.012,1347.863,1267.41,1347.173,1269.819z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1369.452,1285.983c0,0-1.237-0.296-3.095-0.737c-1.853-0.457-4.342-1.002-6.797-1.655
        c-4.901-1.341-9.803-2.681-9.803-2.681l5.285-19.16c0,0,4.691,1.285,9.383,2.57c2.35,0.627,4.721,1.144,6.488,1.579
        c1.771,0.423,2.951,0.704,2.951,0.704L1369.452,1285.983z"
            />
            <path
              fill="#FFFFFF"
              d="M1366.384,1274.956c-0.573,2.403-3.042,3.935-5.474,3.287c-2.428-0.663-3.878-3.149-3.241-5.552
        c0.638-2.403,3.074-3.823,5.444-3.174C1365.487,1270.15,1366.966,1272.525,1366.384,1274.956z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1389.436,1290.016c0,0-0.313-0.053-0.864-0.146c-0.548-0.104-1.33-0.253-2.271-0.433
        c-1.879-0.355-4.384-0.832-6.889-1.31c-2.49-0.533-4.98-1.066-6.85-1.466c-1.866-0.407-3.11-0.679-3.11-0.679l4.413-19.38
        c0,0,1.186,0.26,2.963,0.646c1.779,0.381,4.15,0.889,6.521,1.396c2.381,0.452,4.762,0.904,6.547,1.243
        c0.893,0.171,1.637,0.312,2.156,0.411c0.521,0.088,0.82,0.14,0.82,0.14L1389.436,1290.016z"
            />
            <path
              fill="#FFFFFF"
              d="M1385.82,1279.152c-0.459,2.441-2.84,4.041-5.315,3.569c-2.465-0.521-4.035-2.925-3.512-5.353
        c0.524-2.432,2.896-3.979,5.295-3.471C1384.698,1274.358,1386.279,1276.71,1385.82,1279.152z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1409.645,1292.998c0,0-0.316-0.035-0.873-0.098c-0.558-0.057-1.35-0.154-2.297-0.292
        c-1.897-0.264-4.43-0.614-6.959-0.966c-2.531-0.352-5.041-0.812-6.933-1.107c-1.89-0.312-3.147-0.521-3.147-0.521l3.438-19.574
        c0,0,1.194,0.195,2.985,0.49c1.793,0.281,4.176,0.722,6.57,1.053c2.396,0.332,4.791,0.663,6.586,0.913
        c0.897,0.132,1.646,0.225,2.174,0.276c0.523,0.058,0.824,0.092,0.824,0.092L1409.645,1292.998z"
            />
            <path
              fill="#FFFFFF"
              d="M1405.443,1282.355c-0.328,2.463-2.62,4.18-5.12,3.832c-2.499-0.348-4.186-2.713-3.787-5.151
        c0.401-2.467,2.681-4.087,5.108-3.751C1404.071,1277.622,1405.771,1279.892,1405.443,1282.355z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1430.033,1294.84c0,0-1.278-0.073-3.197-0.184c-1.913-0.16-4.465-0.374-7.016-0.588
        c-2.556-0.186-5.092-0.513-6.998-0.715c-1.906-0.214-3.178-0.355-3.178-0.355l2.368-19.732c0,0,1.2,0.135,3.001,0.335
        c1.801,0.19,4.198,0.503,6.604,0.675c2.403,0.201,4.809,0.403,6.61,0.557c1.806,0.102,3.008,0.17,3.008,0.17L1430.033,1294.84z"
            />
            <path
              fill="#FFFFFF"
              d="M1425.213,1284.471c-0.186,2.479-2.376,4.316-4.895,4.105c-2.52-0.188-4.336-2.452-4.072-4.917
        c0.264-2.479,2.447-4.228,4.888-4.047C1423.572,1279.817,1425.398,1281.992,1425.213,1284.471z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1450.671,1295.342c0,0-0.323,0.01-0.888,0.028c-0.564,0.031-1.374,0.024-2.346,0.005
        c-1.943-0.029-4.533-0.069-7.125-0.109c-1.295-0.022-2.588-0.047-3.803-0.067c-1.199-0.066-2.318-0.128-3.277-0.182
        c-1.92-0.105-3.199-0.177-3.199-0.177l1.205-19.839c0,0,1.203,0.066,3.008,0.167c0.902,0.05,1.955,0.108,3.083,0.171
        c1.117,0.02,2.31,0.038,3.5,0.06c2.381,0.036,4.763,0.074,6.549,0.103c0.894,0.021,1.638,0.027,2.16-0.004
        c0.521-0.019,0.82-0.027,0.82-0.027L1450.671,1295.342z"
            />
            <path
              fill="#FFFFFF"
              d="M1445.074,1285.324c-0.008,2.483-2.076,4.468-4.618,4.428c-1.267-0.02-2.397-0.541-3.191-1.368
        c-0.793-0.835-1.245-2.004-1.19-3.24c0.054-1.247,0.605-2.324,1.441-3.118c0.836-0.802,1.955-1.288,3.174-1.27
        C1443.12,1280.793,1445.083,1282.839,1445.074,1285.324z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1471.285,1294.206c0,0-5.143,0.397-10.283,0.796c-5.166,0.17-10.331,0.34-10.331,0.34l-0.312-19.873
        c0,0,4.773-0.154,9.548-0.312c4.774-0.371,9.548-0.741,9.548-0.741L1471.285,1294.206z"
            />
            <path
              fill="#FFFFFF"
              d="M1464.939,1284.659c0.186,2.475-1.713,4.656-4.241,4.836c-2.537,0.084-4.663-1.861-4.748-4.346
        c-0.084-2.483,1.819-4.562,4.252-4.642C1462.633,1280.335,1464.753,1282.177,1464.939,1284.659z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1491.688,1291.566c0,0-1.268,0.207-3.168,0.517c-0.953,0.147-2.058,0.351-3.252,0.514
        c-1.196,0.146-2.475,0.304-3.752,0.459c-2.555,0.312-5.107,0.621-7.023,0.854c-0.956,0.13-1.762,0.184-2.323,0.228
        s-0.884,0.069-0.884,0.069l-1.831-19.79c0,0,0.299-0.022,0.821-0.063c0.521-0.041,1.269-0.088,2.16-0.212
        c1.787-0.218,4.168-0.508,6.551-0.799c1.189-0.145,2.382-0.288,3.498-0.424c1.115-0.151,2.15-0.344,3.041-0.481
        c1.779-0.291,2.966-0.485,2.966-0.485L1491.688,1291.566z"
            />
            <path
              fill="#FFFFFF"
              d="M1484.708,1282.559c0.366,2.486-1.379,4.719-3.894,5.026c-2.516,0.307-4.772-1.448-5.043-3.919
        s1.475-4.713,3.896-5.008C1482.09,1278.361,1484.35,1280.127,1484.708,1282.559z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1511.83,1287.635c0,0-0.312,0.075-0.855,0.207c-0.545,0.133-1.32,0.327-2.268,0.508
        c-1.885,0.384-4.398,0.896-6.912,1.405c-1.257,0.255-2.514,0.511-3.691,0.749c-1.184,0.221-2.297,0.385-3.246,0.545
        c-1.9,0.312-3.168,0.519-3.168,0.519l-3.197-19.616c0,0,1.188-0.193,2.966-0.483c0.89-0.149,1.931-0.301,3.038-0.508
        c1.105-0.225,2.285-0.465,3.465-0.705c2.359-0.479,4.719-0.959,6.488-1.317c0.888-0.167,1.617-0.353,2.13-0.478
        s0.806-0.194,0.806-0.194L1511.83,1287.635z"
            />
            <path
              fill="#FFFFFF"
              d="M1504.273,1279.031c0.521,2.431-1.064,4.809-3.543,5.312s-4.842-1.065-5.284-3.538
        c-0.433-2.421,1.151-4.806,3.548-5.293C1501.391,1275.025,1503.754,1276.602,1504.273,1279.031z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1531.648,1282.455c0,0-4.931,1.381-9.86,2.761c-4.979,1.209-9.958,2.419-9.958,2.419l-4.446-19.371
        c0,0,4.687-1.138,9.37-2.273c4.646-1.303,9.294-2.604,9.294-2.604L1531.648,1282.455z"
            />
            <path
              fill="#FFFFFF"
              d="M1523.588,1274.312c0.667,2.395-0.764,4.888-3.195,5.567c-2.453,0.608-4.926-0.876-5.516-3.287
        c-0.591-2.418,0.859-4.831,3.234-5.42C1520.469,1270.514,1522.92,1271.919,1523.588,1274.312z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1551.138,1276.016c0,0-1.207,0.438-3.017,1.093c-0.905,0.325-1.962,0.708-3.094,1.116
        c-1.135,0.396-2.365,0.768-3.586,1.164c-2.444,0.781-4.891,1.562-6.725,2.148c-0.916,0.295-1.681,0.54-2.222,0.681
        c-0.539,0.151-0.849,0.237-0.849,0.237l-5.601-19.069c0,0,0.29-0.081,0.799-0.224c0.513-0.132,1.228-0.362,2.086-0.641
        c1.72-0.549,4.011-1.281,6.302-2.015c1.145-0.372,2.297-0.717,3.361-1.089c1.061-0.384,2.051-0.742,2.898-1.05
        c1.697-0.613,2.828-1.023,2.828-1.023L1551.138,1276.016z"
            />
            <path
              fill="#FFFFFF"
              d="M1542.587,1268.459c0.406,1.174,0.299,2.412-0.215,3.459c-0.521,1.021-1.45,1.847-2.651,2.23
        c-2.41,0.771-4.959-0.529-5.694-2.903s0.556-4.901,2.883-5.646c1.167-0.373,2.382-0.231,3.413,0.293
        C1541.343,1266.395,1542.182,1267.283,1542.587,1268.459z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1570.184,1268.368c0,0-1.171,0.524-2.927,1.312c-1.782,0.727-4.159,1.692-6.536,2.66
        c-2.385,0.948-4.742,1.966-6.566,2.583c-1.81,0.655-3.017,1.093-3.017,1.093l-6.814-18.671c0,0,1.131-0.407,2.827-1.021
        c1.713-0.575,3.922-1.536,6.158-2.424c2.229-0.906,4.457-1.812,6.129-2.492c1.646-0.74,2.744-1.233,2.744-1.233L1570.184,1268.368z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1561.159,1261.359c0.966,2.29-0.15,4.916-2.495,5.863c-2.345,0.949-4.965-0.164-5.854-2.484
        c-0.887-2.322,0.229-4.947,2.496-5.865C1557.572,1257.956,1560.191,1259.069,1561.159,1261.359z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1588.714,1259.547c0,0-4.599,2.277-9.196,4.555c-0.576,0.283-1.15,0.566-1.718,0.847
        c-0.576,0.259-1.143,0.514-1.69,0.76c-1.098,0.493-2.121,0.953-3,1.348c-1.754,0.788-2.926,1.313-2.926,1.313l-8.002-18.193
        c0,0,1.098-0.493,2.745-1.231c0.823-0.369,1.784-0.801,2.813-1.263c0.515-0.23,1.046-0.47,1.587-0.712
        c0.53-0.262,1.069-0.527,1.61-0.795c4.312-2.136,8.627-4.271,8.627-4.271L1588.714,1259.547z"
            />
            <path
              fill="#FFFFFF"
              d="M1579.229,1253.072c1.111,2.223,0.176,4.934-2.093,6.055c-1.126,0.571-2.375,0.604-3.475,0.189
        c-1.097-0.4-2.027-1.217-2.547-2.345c-0.521-1.129-0.533-2.355-0.142-3.425c0.396-1.057,1.186-1.982,2.276-2.539
        C1575.441,1249.925,1578.119,1250.849,1579.229,1253.072z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1606.604,1249.488c0,0-1.104,0.651-2.762,1.631c-1.669,0.958-3.84,2.332-6.104,3.539
        c-4.512,2.443-9.024,4.889-9.024,4.889l-9.147-17.645c0,0,4.231-2.293,8.465-4.588c2.126-1.13,4.158-2.425,5.725-3.32
        c1.555-0.918,2.591-1.53,2.591-1.53L1606.604,1249.488z"
            />
            <path
              fill="#FFFFFF"
              d="M1596.75,1243.677c1.238,2.126,0.525,4.979-1.706,6.169c-2.224,1.205-4.979,0.407-6.156-1.781
        s-0.389-4.907,1.761-6.071C1592.807,1240.845,1595.486,1241.508,1596.75,1243.677z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1623.826,1238.332c0,0-1.053,0.733-2.633,1.832c-1.56,1.13-3.737,2.487-5.894,3.88
        c-2.159,1.386-4.317,2.771-5.938,3.812c-1.656,0.979-2.761,1.633-2.761,1.633l-10.258-17.024c0,0,1.036-0.61,2.592-1.529
        c1.519-0.976,3.543-2.275,5.567-3.577c2.021-1.307,4.067-2.574,5.527-3.639c1.48-1.031,2.47-1.72,2.47-1.72L1623.826,1238.332z"
            />
            <path
              fill="#FFFFFF"
              d="M1613.648,1233.202c1.386,2.062,0.785,4.844-1.345,6.211c-2.128,1.367-4.918,0.765-6.231-1.345
        c-1.314-2.11-0.713-4.892,1.344-6.211C1609.473,1230.536,1612.264,1231.139,1613.648,1233.202z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1640.318,1226.129c0,0-0.25,0.201-0.686,0.555c-0.438,0.354-1.053,0.866-1.829,1.436
        c-1.536,1.156-3.587,2.698-5.638,4.24c-1.025,0.771-2.051,1.541-3.012,2.263c-0.987,0.688-1.908,1.329-2.697,1.879
        c-1.579,1.101-2.632,1.832-2.632,1.832L1612.501,1222c0,0,0.988-0.688,2.47-1.719c0.741-0.516,1.605-1.116,2.532-1.761
        c0.901-0.679,1.863-1.402,2.825-2.126c1.925-1.446,3.849-2.895,5.292-3.979c0.729-0.533,1.307-1.016,1.717-1.348
        c0.409-0.332,0.644-0.521,0.644-0.521L1640.318,1226.129z"
            />
            <path
              fill="#FFFFFF"
              d="M1629.801,1221.607c1.514,1.972,1.104,4.802-0.918,6.32c-2.016,1.527-4.839,1.104-6.298-0.935
        c-1.434-2.003-1.011-4.817,0.937-6.295C1625.477,1219.229,1628.287,1219.637,1629.801,1221.607z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1656.002,1212.904c0,0-3.864,3.373-7.729,6.746c-3.979,3.238-7.955,6.479-7.955,6.479l-12.338-15.582
        c0,0,3.733-3.04,7.469-6.081c3.628-3.167,7.256-6.334,7.256-6.334L1656.002,1212.904z"
            />
            <path
              fill="#FFFFFF"
              d="M1645.203,1209.038c1.635,1.871,1.417,4.735-0.488,6.397c-1.936,1.627-4.822,1.333-6.388-0.585
        c-1.577-1.934-1.291-4.718,0.58-6.291C1640.749,1206.953,1643.566,1207.167,1645.203,1209.038z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1670.786,1198.686c0,0-0.902,0.911-2.256,2.277c-1.361,1.358-3.13,3.218-5.026,4.945
        c-1.87,1.756-3.738,3.511-5.142,4.827c-0.702,0.656-1.287,1.204-1.696,1.589c-0.423,0.369-0.664,0.58-0.664,0.58l-13.297-14.772
        c0,0,0.227-0.197,0.624-0.544c0.384-0.359,0.933-0.875,1.592-1.493c1.316-1.235,3.072-2.885,4.827-4.532
        c1.784-1.619,3.441-3.368,4.721-4.645c1.271-1.283,2.117-2.139,2.117-2.139L1670.786,1198.686z"
            />
            <path
              fill="#FFFFFF"
              d="M1659.803,1195.543c1.745,1.761,1.749,4.676-0.115,6.384c-1.844,1.731-4.707,1.657-6.396-0.166
        c-1.689-1.821-1.614-4.657,0.168-6.33C1655.263,1193.78,1658.048,1193.774,1659.803,1195.543z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1684.667,1183.584c0,0-0.834,0.975-2.085,2.436c-1.229,1.48-3.02,3.321-4.739,5.224
        c-1.747,1.877-3.452,3.794-4.802,5.164c-1.354,1.366-2.255,2.278-2.255,2.278l-14.2-13.905c0,0,0.848-0.855,2.119-2.14
        c1.268-1.285,2.865-3.089,4.507-4.851c1.615-1.786,3.3-3.51,4.451-4.902c1.174-1.372,1.956-2.286,1.956-2.286L1684.667,1183.584z"
            />
            <path
              fill="#FFFFFF"
              d="M1673.521,1181.151c1.856,1.65,1.976,4.497,0.263,6.356c-1.714,1.86-4.562,1.979-6.363,0.268
        c-1.801-1.713-1.918-4.561-0.263-6.358C1668.812,1179.619,1671.662,1179.5,1673.521,1181.151z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1697.593,1167.655c0,0-0.741,1.047-1.954,2.541c-1.195,1.506-2.791,3.515-4.387,5.521
        c-1.568,2.031-3.246,3.972-4.5,5.431c-1.251,1.462-2.085,2.436-2.085,2.436l-15.048-12.982c0,0,0.783-0.916,1.957-2.287
        c1.179-1.369,2.757-3.188,4.227-5.098c1.498-1.886,2.995-3.771,4.118-5.185c1.142-1.401,1.835-2.386,1.835-2.386L1697.593,1167.655
        z"
            />
            <path
              fill="#FFFFFF"
              d="M1686.268,1165.895c1.959,1.529,2.271,4.377,0.697,6.355c-0.788,0.985-1.879,1.569-3.031,1.683
        c-1.164,0.107-2.356-0.229-3.312-1.023c-0.955-0.796-1.492-1.897-1.597-3.028c-0.111-1.139,0.178-2.335,0.942-3.292
        C1681.489,1164.674,1684.309,1164.363,1686.268,1165.895z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1709.46,1150.924c0,0-2.894,4.234-5.785,8.471c-3.041,4.131-6.082,8.262-6.082,8.262l-15.837-12.01
        c0,0,2.855-3.878,5.711-7.756c2.716-3.978,5.432-7.955,5.432-7.955L1709.46,1150.924z"
            />
            <path
              fill="#FFFFFF"
              d="M1698.052,1149.883c2.046,1.401,2.567,4.242,1.126,6.319c-1.499,2.036-4.337,2.493-6.34,1.021
        c-2.002-1.472-2.449-4.262-1-6.229C1693.23,1148.984,1695.997,1148.476,1698.052,1149.883z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1720.237,1133.473c0,0-0.644,1.109-1.607,2.773c-0.481,0.832-1.045,1.803-1.647,2.843
        c-0.639,1.02-1.318,2.104-2,3.191c-1.365,2.171-2.73,4.341-3.756,5.969c-0.504,0.819-0.948,1.485-1.271,1.945
        c-0.315,0.464-0.496,0.729-0.496,0.729l-16.562-10.988c0,0,0.17-0.249,0.467-0.685c0.302-0.432,0.722-1.057,1.193-1.827
        c0.961-1.528,2.242-3.567,3.524-5.604c0.64-1.021,1.28-2.041,1.881-2.996c0.564-0.978,1.093-1.89,1.546-2.672
        c0.904-1.562,1.508-2.604,1.508-2.604L1720.237,1133.473z"
            />
            <path
              fill="#FFFFFF"
              d="M1708.834,1133.179c2.152,1.283,2.816,4.065,1.459,6.198c-1.346,2.141-4.133,2.786-6.225,1.442
        c-2.09-1.345-2.729-4.11-1.428-6.181C1703.955,1132.578,1706.717,1131.917,1708.834,1133.179z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1729.925,1115.393c0,0-0.561,1.153-1.399,2.885c-0.901,1.698-2.104,3.965-3.306,6.229
        c-1.21,2.262-2.38,4.545-3.377,6.191c-0.963,1.664-1.604,2.773-1.604,2.773l-17.221-9.926c0,0,0.604-1.042,1.511-2.604
        c0.938-1.546,2.032-3.69,3.169-5.813c1.13-2.127,2.259-4.254,3.104-5.849c0.788-1.626,1.312-2.709,1.312-2.709L1729.925,1115.393z"
            />
            <path
              fill="#FFFFFF"
              d="M1718.502,1115.805c2.211,1.137,3.041,3.867,1.855,6.101c-1.188,2.232-3.91,3.062-6.08,1.854
        c-2.172-1.21-3.002-3.94-1.855-6.1C1713.568,1115.499,1716.291,1114.67,1718.502,1115.805z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1738.482,1096.745c0,0-2.067,4.694-4.135,9.391c-1.014,2.358-2.207,4.631-3.023,6.372
        c-0.84,1.73-1.399,2.885-1.399,2.885l-17.811-8.82c0,0,0.524-1.083,1.312-2.708c0.765-1.637,1.894-3.768,2.841-5.98
        c1.939-4.406,3.881-8.812,3.881-8.812L1738.482,1096.745z"
            />
            <path
              fill="#FFFFFF"
              d="M1727.052,1097.855c2.278,0.994,3.3,3.676,2.278,5.989c-0.997,2.325-3.786,3.267-6.006,2.207
        c-2.268-1.081-3.143-3.696-2.182-5.943C1722.128,1097.871,1724.773,1096.863,1727.052,1097.855z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1745.754,1077.559c0,0-0.427,1.209-1.066,3.023c-0.666,1.805-1.436,4.255-2.422,6.624
        c-1.892,4.77-3.783,9.539-3.783,9.539l-18.332-7.675c0,0,1.772-4.477,3.548-8.952c0.93-2.222,1.645-4.524,2.271-6.219
        c0.602-1.702,1.002-2.838,1.002-2.838L1745.754,1077.559z"
            />
            <path
              fill="#FFFFFF"
              d="M1734.479,1079.415c2.315,0.839,3.601,3.486,2.636,5.824c-0.933,2.352-3.558,3.507-5.863,2.581
        s-3.446-3.519-2.545-5.79C1729.641,1079.771,1732.12,1078.561,1734.479,1079.415z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1751.841,1057.97c0,0-0.335,1.238-0.837,3.095c-0.5,1.857-1.312,4.293-2.059,6.745
        c-0.773,2.445-1.494,4.907-2.125,6.726c-0.641,1.813-1.066,3.023-1.066,3.023l-18.783-6.496c0,0,0.4-1.135,1.002-2.837
        c0.594-1.706,1.266-4.02,1.994-6.313c0.699-2.304,1.467-4.588,1.934-6.332c0.472-1.744,0.785-2.906,0.785-2.906L1751.841,1057.97z"
            />
            <path
              fill="#FFFFFF"
              d="M1740.72,1060.538c2.386,0.698,3.712,3.221,2.96,5.635c-0.751,2.415-3.272,3.741-5.635,2.964
        c-2.359-0.776-3.686-3.3-2.96-5.635C1735.812,1061.168,1738.334,1059.841,1740.72,1060.538z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1756.729,1038.049c0,0-1.146,4.999-2.293,9.998c-0.535,2.51-1.277,4.967-1.759,6.828
        c-0.502,1.857-0.837,3.095-0.837,3.095l-19.156-5.297c0,0,0.314-1.163,0.785-2.906c0.451-1.749,1.155-4.055,1.653-6.411
        c1.077-4.694,2.155-9.39,2.155-9.39L1756.729,1038.049z"
            />
            <path
              fill="#FFFFFF"
              d="M1745.714,1041.295c2.425,0.546,3.931,2.986,3.362,5.45c-0.533,2.472-3.08,3.927-5.469,3.304
        c-2.422-0.632-3.791-3.037-3.276-5.429C1740.879,1042.237,1743.289,1040.749,1745.714,1041.295z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1760.268,1017.846c0,0-0.192,1.269-0.481,3.168c-0.146,0.951-0.314,2.062-0.496,3.248
        c-0.17,1.19-0.378,2.456-0.634,3.713c-0.963,5.037-1.926,10.074-1.926,10.074l-19.451-4.083c0,0,0.902-4.73,1.807-9.462
        c0.243-1.181,0.438-2.368,0.596-3.486c0.17-1.115,0.328-2.157,0.464-3.051c0.272-1.785,0.454-2.976,0.454-2.976L1760.268,1017.846z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1749.536,1021.785c1.228,0.199,2.261,0.88,2.94,1.828c0.69,0.949,0.998,2.16,0.748,3.396
        c-0.476,2.484-2.837,4.112-5.276,3.637c-2.439-0.475-4.046-2.807-3.585-5.207c0.244-1.201,0.924-2.209,1.836-2.875
        C1747.124,1021.902,1748.311,1021.586,1749.536,1021.785z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1762.568,997.467c0,0-0.099,1.277-0.244,3.194c-0.125,1.919-0.48,4.462-0.751,7.011
        c-0.302,2.547-0.562,5.1-0.824,7.004c-0.289,1.901-0.481,3.17-0.481,3.17l-19.668-2.854c0,0,0.182-1.19,0.455-2.977
        c0.245-1.789,0.486-4.187,0.771-6.578c0.253-2.395,0.592-4.778,0.708-6.584c0.136-1.802,0.229-3.003,0.229-3.003L1762.568,997.467z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1752.132,1002.073c2.474,0.236,4.247,2.467,3.961,4.979c-0.286,2.513-2.518,4.288-4.981,3.967
        c-2.465-0.319-4.238-2.55-3.961-4.979C1747.427,1003.611,1749.658,1001.835,1752.132,1002.073z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1763.65,976.993c0,0-0.192,5.123-0.387,10.245c-0.054,2.563-0.332,5.114-0.453,7.033
        c-0.146,1.916-0.242,3.194-0.242,3.194l-19.81-1.616c0,0,0.091-1.2,0.229-3.003c0.112-1.804,0.382-4.2,0.427-6.61
        c0.184-4.815,0.366-9.631,0.366-9.631L1763.65,976.993z"
            />
            <path
              fill="#FFFFFF"
              d="M1753.434,982.234c2.482,0.084,4.42,2.199,4.322,4.726c-0.061,2.528-2.297,4.433-4.757,4.269
        c-2.499-0.169-4.288-2.275-4.231-4.722C1748.859,984.062,1750.948,982.15,1753.434,982.234z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1763.367,956.493c0,0,0.049,1.281,0.121,3.202c0.062,1.921,0.212,4.48,0.172,7.046
        c-0.004,5.126-0.01,10.252-0.01,10.252l-19.871-0.388c0,0,0.004-4.819,0.007-9.64c0.042-2.41-0.104-4.815-0.162-6.623
        c-0.068-1.806-0.114-3.01-0.114-3.01L1763.367,956.493z"
            />
            <path
              fill="#FFFFFF"
              d="M1753.566,962.354c2.479-0.068,4.61,1.923,4.578,4.45c0,2.527-2.015,4.566-4.5,4.555
        c-2.484-0.013-4.5-2.007-4.5-4.451C1749.179,964.46,1751.077,962.424,1753.566,962.354z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1761.877,936.046c0,0,0.145,1.273,0.359,3.186c0.146,1.917,0.342,4.473,0.535,7.027
        c0.172,2.559,0.441,5.109,0.473,7.033c0.074,1.921,0.123,3.201,0.123,3.201l-19.857,0.841c0,0-0.045-1.203-0.113-3.009
        c-0.024-1.81-0.285-4.206-0.444-6.61c-0.183-2.401-0.364-4.805-0.501-6.605c-0.204-1.795-0.34-2.991-0.34-2.991L1761.877,936.046z"
            />
            <path
              fill="#FFFFFF"
              d="M1752.441,942.503c2.477-0.223,4.64,1.64,4.83,4.16c0.191,2.52-1.664,4.679-4.146,4.819
        c-2.481,0.14-4.644-1.723-4.829-4.16C1748.112,944.884,1749.968,942.727,1752.441,942.503z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1759.122,915.724c0,0,0.778,5.068,1.558,10.139c0.35,2.539,0.603,5.092,0.833,7
        c0.22,1.911,0.364,3.185,0.364,3.185l-19.768,2.071c0,0-0.137-1.197-0.344-2.992c-0.218-1.793-0.451-4.191-0.779-6.577
        c-0.731-4.761-1.463-9.521-1.463-9.521L1759.122,915.724z"
            />
            <path
              fill="#FFFFFF"
              d="M1750.072,922.765c2.457-0.377,4.759,1.344,5.143,3.843c0.346,2.505-1.463,4.784-3.918,5.077
        c-2.48,0.297-4.666-1.438-5-3.86C1745.926,925.406,1747.615,923.142,1750.072,922.765z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1755.049,895.618c0,0,0.291,1.249,0.729,3.123c0.217,0.937,0.47,2.03,0.74,3.202
        c0.229,1.18,0.474,2.438,0.717,3.697c0.486,2.52,0.975,5.036,1.339,6.926c0.183,0.943,0.342,1.729,0.415,2.286
        c0.086,0.555,0.135,0.871,0.135,0.871l-19.6,3.303c0,0-0.045-0.298-0.125-0.818c-0.067-0.522-0.219-1.26-0.391-2.146
        c-0.344-1.772-0.801-4.138-1.259-6.501c-0.228-1.182-0.456-2.363-0.67-3.473c-0.255-1.099-0.493-2.125-0.697-3.004
        c-0.411-1.758-0.685-2.931-0.685-2.931L1755.049,895.618z"
            />
            <path
              fill="#FFFFFF"
              d="M1746.533,903.201c2.451-0.536,4.82,1.043,5.294,3.526c0.479,2.483-1.112,4.859-3.558,5.309
        c-2.443,0.448-4.803-1.134-5.267-3.534C1742.547,906.101,1744.129,903.727,1746.533,903.201z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1749.755,875.798c0.001,0,0.401,1.218,0.94,3.065c0.507,1.854,1.183,4.33,1.857,6.804
        c0.682,2.473,1.362,4.946,1.768,6.828c0.437,1.874,0.729,3.123,0.729,3.123l-19.352,4.533c0,0-0.272-1.173-0.684-2.932
        c-0.376-1.767-1.018-4.088-1.658-6.409c-0.634-2.322-1.268-4.646-1.742-6.388c-0.506-1.732-0.883-2.877-0.885-2.877
        L1749.755,875.798z"
            />
            <path
              fill="#FFFFFF"
              d="M1741.693,883.916c2.391-0.684,4.867,0.739,5.533,3.179c0.663,2.44-0.746,4.905-3.166,5.509
        c-2.401,0.602-4.886-0.82-5.527-3.18C1737.891,887.066,1739.305,884.6,1741.693,883.916z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1743.227,856.351c0,0,1.715,4.834,3.431,9.669c0.825,2.427,1.553,4.888,2.141,6.719
        c0.575,1.836,0.958,3.06,0.958,3.06l-19.025,5.749c0,0-0.359-1.147-0.899-2.872c-0.554-1.719-1.229-4.03-2.009-6.31
        c-1.609-4.539-3.221-9.078-3.221-9.078L1743.227,856.351z"
            />
            <path
              fill="#FFFFFF"
              d="M1735.659,864.974c2.342-0.833,4.925,0.423,5.771,2.808c0.815,2.393-0.533,4.974-2.889,5.724
        c-2.382,0.758-4.852-0.536-5.641-2.85C1732.082,868.35,1733.316,865.807,1735.659,864.974z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1735.432,837.376c0,0,0.518,1.173,1.293,2.934c0.774,1.762,1.838,4.097,2.75,6.494
        c0.945,2.384,1.893,4.768,2.603,6.556c0.354,0.895,0.647,1.64,0.854,2.162c0.188,0.528,0.295,0.83,0.295,0.83l-18.625,6.938
        c0,0-0.102-0.284-0.276-0.78c-0.194-0.49-0.472-1.188-0.804-2.028c-0.668-1.679-1.557-3.918-2.444-6.154
        c-0.854-2.253-1.854-4.444-2.582-6.098c-0.729-1.652-1.214-2.754-1.214-2.754L1735.432,837.376z"
            />
            <path
              fill="#FFFFFF"
              d="M1728.479,846.435c2.289-0.979,4.982,0.096,5.89,2.457c0.934,2.351-0.188,4.985-2.503,5.888
        c-2.316,0.901-4.926-0.209-5.828-2.481C1725.163,850.012,1726.198,847.412,1728.479,846.435z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1726.491,818.914c0,0,0.603,1.132,1.507,2.83c0.879,1.711,1.975,4.031,3.104,6.333
        c1.102,2.315,2.289,4.592,3.034,6.366c0.776,1.76,1.296,2.933,1.296,2.933l-18.151,8.097c0,0-0.485-1.102-1.214-2.754
        c-0.696-1.667-1.818-3.8-2.851-5.976c-1.062-2.161-2.087-4.341-2.911-5.946c-0.851-1.593-1.418-2.655-1.418-2.655L1726.491,818.914
        z"
            />
            <path
              fill="#FFFFFF"
              d="M1720.121,828.395c2.218-1.12,4.912-0.187,6.02,2.088c1.104,2.273,0.172,4.965-2.082,6.011
        c-2.255,1.046-4.949,0.11-6.019-2.087C1716.971,832.208,1717.902,829.516,1720.121,828.395z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1716.43,801.033c0,0,0.176,0.269,0.453,0.756c0.283,0.485,0.687,1.178,1.17,2.009
        c0.969,1.663,2.258,3.88,3.547,6.097c0.322,0.555,0.646,1.109,0.963,1.655c0.298,0.558,0.59,1.104,0.873,1.635
        c0.565,1.062,1.095,2.052,1.548,2.899c0.905,1.698,1.509,2.83,1.509,2.83l-17.604,9.228c0,0-0.566-1.062-1.416-2.654
        c-0.426-0.797-0.92-1.727-1.45-2.723c-0.267-0.498-0.54-1.012-0.818-1.534c-0.299-0.513-0.603-1.032-0.903-1.553
        c-1.212-2.081-2.423-4.162-3.33-5.723c-0.455-0.781-0.832-1.431-1.098-1.887c-0.26-0.458-0.426-0.709-0.426-0.709L1716.43,801.033z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1710.617,810.933c2.143-1.26,4.91-0.509,6.182,1.678c1.305,2.168,0.424,4.979-1.738,6.153
        c-2.205,1.199-4.834,0.371-6.096-1.724C1707.734,814.926,1708.475,812.192,1710.617,810.933z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1705.199,783.867c0,0,0.724,1.059,1.81,2.646c1.069,1.6,2.571,3.681,3.941,5.848
        c2.739,4.336,5.479,8.673,5.479,8.673l-16.983,10.325c0,0-2.572-4.072-5.146-8.144c-1.285-2.036-2.699-3.988-3.703-5.489
        c-1.02-1.49-1.699-2.484-1.699-2.484L1705.199,783.867z"
            />
            <path
              fill="#FFFFFF"
              d="M1700.057,794.089c2.043-1.381,4.924-0.854,6.273,1.284c1.352,2.137,0.74,4.942-1.364,6.264
        c-2.104,1.322-4.869,0.719-6.175-1.349C1697.486,798.221,1697.98,795.492,1700.057,794.089z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1692.929,767.433c0,0,0.8,1.003,1.997,2.509c1.225,1.483,2.746,3.548,4.264,5.614
        c1.523,2.063,3.047,4.126,4.189,5.673c1.092,1.583,1.82,2.639,1.82,2.639l-16.301,11.374c0,0-0.683-0.992-1.707-2.479
        c-1.074-1.453-2.505-3.39-3.937-5.326c-1.425-1.941-2.853-3.882-4.004-5.273c-1.126-1.413-1.876-2.354-1.876-2.354
        L1692.929,767.433z"
            />
            <path
              fill="#FFFFFF"
              d="M1688.48,777.924c1.977-1.524,4.782-1.104,6.287,0.93c1.502,2.032,1.084,4.858-0.933,6.311
        c-2.019,1.452-4.83,1.035-6.283-0.932C1686.1,782.267,1686.521,779.436,1688.48,777.924z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1679.674,751.781c0,0,0.219,0.235,0.598,0.647c0.373,0.419,0.941,0.987,1.56,1.725
        c1.252,1.46,2.922,3.405,4.592,5.353c0.834,0.975,1.669,1.947,2.451,2.86c0.771,0.922,1.454,1.812,2.06,2.561
        c1.197,1.504,1.996,2.507,1.996,2.507l-15.553,12.374c0,0-0.75-0.941-1.875-2.354c-0.566-0.703-1.208-1.54-1.934-2.406
        c-0.733-0.855-1.52-1.77-2.303-2.684c-1.567-1.828-3.136-3.657-4.311-5.028c-0.579-0.693-1.114-1.225-1.464-1.618
        c-0.357-0.387-0.562-0.607-0.562-0.607L1679.674,751.781z"
            />
            <path
              fill="#FFFFFF"
              d="M1675.854,762.566c1.867-1.641,4.718-1.413,6.362,0.507c1.646,1.92,1.429,4.748-0.514,6.339
        c-1.903,1.56-4.761,1.339-6.351-0.519C1673.761,767.039,1673.984,764.206,1675.854,762.566z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1665.463,736.986c0,0,3.614,3.64,7.229,7.28c1.789,1.837,3.494,3.756,4.81,5.158
        c1.306,1.414,2.174,2.356,2.174,2.356l-14.744,13.327c0,0-0.817-0.886-2.042-2.212c-1.234-1.317-2.833-3.121-4.514-4.846
        c-3.393-3.417-6.786-6.835-6.786-6.835L1665.463,736.986z"
            />
            <path
              fill="#FFFFFF"
              d="M1662.305,748.018c1.762-1.754,4.633-1.721,6.414,0.074c1.764,1.812,1.646,4.725-0.164,6.406
        c-1.83,1.704-4.613,1.586-6.318-0.164C1660.512,752.6,1660.543,749.771,1662.305,748.018z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1650.304,723.162c0,0,0.968,0.841,2.419,2.104c1.438,1.277,3.422,2.907,5.268,4.691
        c1.873,1.752,3.746,3.505,5.149,4.82c0.702,0.657,1.286,1.206,1.696,1.589c0.406,0.386,0.627,0.62,0.627,0.62l-13.875,14.229
        c0,0-0.206-0.219-0.588-0.582c-0.385-0.359-0.936-0.874-1.594-1.491c-1.318-1.233-3.076-2.879-4.834-4.524
        c-1.729-1.677-3.596-3.203-4.944-4.402c-1.362-1.185-2.271-1.975-2.271-1.975L1650.304,723.162z"
            />
            <path
              fill="#FFFFFF"
              d="M1647.885,734.331c1.637-1.851,4.559-2.06,6.383-0.306c1.847,1.728,1.957,4.592,0.248,6.396
        c-1.709,1.805-4.539,1.914-6.324,0.243C1646.43,738.969,1646.229,736.204,1647.885,734.331z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1634.322,710.302c0,0,1.025,0.77,2.566,1.922c1.563,1.122,3.515,2.79,5.522,4.385
        c1.989,1.618,4.005,3.206,5.473,4.449c1.451,1.263,2.419,2.104,2.419,2.104l-12.945,15.08c0,0-0.908-0.791-2.271-1.977
        c-1.377-1.168-3.271-2.654-5.138-4.176c-1.888-1.495-3.716-3.066-5.187-4.117c-1.445-1.082-2.408-1.804-2.408-1.804
        L1634.322,710.302z"
            />
            <path
              fill="#FFFFFF"
              d="M1632.629,721.581c1.526-1.962,4.357-2.265,6.325-0.676c1.967,1.589,2.271,4.425,0.681,6.334
        s-4.423,2.212-6.324,0.676C1631.408,726.378,1631.104,723.542,1632.629,721.581z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1617.575,698.451c0,0,0.271,0.173,0.741,0.476c0.479,0.294,1.139,0.751,1.928,1.302
        c1.581,1.096,3.688,2.558,5.797,4.019c1.049,0.738,2.118,1.448,3.084,2.163c0.963,0.721,1.861,1.393,2.631,1.969
        c1.54,1.153,2.566,1.922,2.566,1.922l-11.969,15.867c0,0-0.964-0.722-2.408-1.804c-0.724-0.542-1.565-1.173-2.469-1.852
        c-0.906-0.672-1.912-1.336-2.896-2.028c-1.979-1.372-3.956-2.744-5.44-3.773c-0.74-0.517-1.357-0.947-1.808-1.221
        c-0.443-0.284-0.697-0.446-0.697-0.446L1617.575,698.451z"
            />
            <path
              fill="#FFFFFF"
              d="M1616.566,709.864c1.397-2.056,4.217-2.552,6.295-1.111c1.035,0.719,1.684,1.779,1.882,2.911
        c0.183,1.154-0.074,2.367-0.806,3.372s-1.795,1.613-2.918,1.791c-1.138,0.199-2.343-0.021-3.349-0.72
        C1615.662,714.714,1615.168,711.919,1616.566,709.864z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1600.093,687.706c0,0,4.421,2.606,8.841,5.214c4.32,2.766,8.643,5.531,8.643,5.531l-10.939,16.594
        c0,0-4.055-2.596-8.109-5.192c-4.146-2.444-8.293-4.89-8.293-4.89L1600.093,687.706z"
            />
            <path
              fill="#FFFFFF"
              d="M1599.811,699.166c1.266-2.139,4.058-2.832,6.234-1.547c2.133,1.359,2.773,4.166,1.438,6.26
        c-1.338,2.096-4.088,2.723-6.148,1.408C1599.227,704.046,1598.546,701.305,1599.811,699.166z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1581.938,678.128c0,0,1.151,0.567,2.879,1.419c0.863,0.426,1.869,0.923,2.947,1.456
        c1.082,0.529,2.193,1.17,3.327,1.771c2.259,1.221,4.515,2.441,6.207,3.357c1.704,0.897,2.794,1.575,2.794,1.575l-9.859,17.257
        c0,0-1.021-0.639-2.619-1.477c-1.588-0.859-3.705-2.003-5.821-3.148c-1.063-0.562-2.104-1.167-3.119-1.662
        c-1.012-0.499-1.956-0.963-2.766-1.361c-1.618-0.799-2.697-1.33-2.697-1.33L1581.938,678.128z"
            />
            <path
              fill="#FFFFFF"
              d="M1582.42,689.532c0.564-1.107,1.53-1.886,2.643-2.244c1.104-0.343,2.338-0.238,3.449,0.358
        c2.225,1.203,3.055,3.943,1.854,6.119c-1.2,2.177-3.916,2.998-6.065,1.835c-1.078-0.581-1.814-1.562-2.156-2.658
        C1581.793,691.863,1581.854,690.639,1582.42,689.532z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1563.223,669.706c0,0,1.189,0.479,2.976,1.198c1.759,0.781,4.104,1.823,6.448,2.867
        c2.334,1.064,4.707,2.051,6.416,2.938c1.727,0.851,2.877,1.418,2.877,1.418l-8.729,17.855c0,0-1.079-0.533-2.697-1.331
        c-1.601-0.837-3.827-1.755-6.016-2.754c-2.198-0.979-4.396-1.957-6.046-2.691c-1.676-0.673-2.791-1.122-2.791-1.122
        L1563.223,669.706z"
            />
            <path
              fill="#FFFFFF"
              d="M1564.429,681.068c0.979-2.284,3.646-3.301,5.956-2.269c2.311,1.032,3.323,3.692,2.266,5.941
        c-1.059,2.249-3.725,3.265-5.955,2.268C1564.464,686.012,1563.449,683.354,1564.429,681.068z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1544.014,662.469c0,0,4.832,1.733,9.661,3.467c1.214,0.418,2.407,0.89,3.521,1.347
        c1.116,0.448,2.157,0.867,3.051,1.226c1.785,0.718,2.976,1.197,2.976,1.197l-7.562,18.38c0,0-1.116-0.449-2.791-1.122
        c-0.837-0.337-1.813-0.729-2.86-1.15c-1.043-0.431-2.162-0.872-3.301-1.263c-4.53-1.626-9.061-3.253-9.061-3.253L1544.014,662.469z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1545.927,673.795c0.831-2.342,3.436-3.548,5.815-2.693c1.191,0.412,2.078,1.3,2.544,2.375
        c0.472,1.067,0.53,2.305,0.077,3.461c-0.452,1.157-1.328,2.019-2.369,2.477c-1.038,0.449-2.249,0.516-3.407,0.117
        C1546.286,678.706,1545.096,676.137,1545.927,673.795z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1524.354,656.567c0,0,1.237,0.341,3.094,0.853c0.928,0.256,2.011,0.555,3.17,0.875
        c0.58,0.16,1.179,0.326,1.788,0.494c0.602,0.19,1.214,0.383,1.825,0.577c4.893,1.552,9.783,3.104,9.783,3.104l-6.365,18.828
        c0,0-4.588-1.455-9.176-2.91c-0.572-0.182-1.146-0.362-1.711-0.542c-0.57-0.156-1.133-0.312-1.677-0.461
        c-1.088-0.3-2.103-0.578-2.974-0.818c-1.739-0.479-2.898-0.799-2.898-0.799L1524.354,656.567z"
            />
            <path
              fill="#FFFFFF"
              d="M1527.008,667.688c0.672-2.364,3.225-3.844,5.624-3.044c2.412,0.765,3.75,3.302,2.989,5.667
        c-0.761,2.366-3.266,3.687-5.596,2.947C1527.706,672.483,1526.321,670.104,1527.008,667.688z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1504.363,651.906c0,0,1.259,0.246,3.147,0.616c1.898,0.327,4.38,0.994,6.884,1.558
        c2.496,0.596,5.002,1.159,6.865,1.636c1.856,0.511,3.094,0.853,3.094,0.853l-5.142,19.198c0,0-1.16-0.319-2.899-0.801
        c-1.748-0.447-4.097-0.972-6.438-1.531c-2.348-0.525-4.672-1.158-6.452-1.462c-1.771-0.346-2.951-0.576-2.951-0.576
        L1504.363,651.906z"
            />
            <path
              fill="#FFFFFF"
              d="M1507.734,662.802c0.523-2.43,2.943-3.93,5.406-3.351c2.463,0.58,3.967,3.003,3.359,5.414
        c-0.605,2.41-3.027,3.911-5.406,3.351C1508.717,667.655,1507.212,665.231,1507.734,662.802z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1484.12,648.501c0,0,0.317,0.04,0.875,0.109c0.555,0.086,1.347,0.208,2.298,0.355
        c1.902,0.296,4.438,0.69,6.975,1.084c2.541,0.357,5.058,0.87,6.946,1.241c1.89,0.369,3.149,0.615,3.149,0.615l-3.891,19.49
        c0,0-1.182-0.23-2.951-0.577c-1.771-0.35-4.127-0.833-6.513-1.164c-2.378-0.37-4.756-0.74-6.54-1.018
        c-0.892-0.139-1.634-0.255-2.154-0.336c-0.521-0.063-0.82-0.101-0.82-0.101L1484.12,648.501z"
            />
            <path
              fill="#FFFFFF"
              d="M1488.176,659.238c0.365-2.458,2.688-4.137,5.188-3.748c2.504,0.358,4.149,2.742,3.7,5.193
        c-0.448,2.438-2.756,4.029-5.177,3.685C1489.473,663.994,1487.812,661.696,1488.176,659.238z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1463.699,646.479c0,0,1.279,0.098,3.197,0.243c1.916,0.173,4.48,0.286,7.027,0.6
        c5.098,0.59,10.195,1.18,10.195,1.18l-2.626,19.701c0,0-4.782-0.553-9.564-1.106c-2.389-0.297-4.797-0.396-6.595-0.56
        c-1.8-0.138-3-0.23-3-0.23L1463.699,646.479z"
            />
            <path
              fill="#FFFFFF"
              d="M1468.438,656.884c0.203-2.451,2.425-4.385,4.935-4.076c2.513,0.29,4.313,2.526,4.021,4.995
        c-0.291,2.469-2.496,4.241-4.924,3.959C1470.042,661.462,1468.227,659.387,1468.438,656.884z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1443.197,645.723c0,0,1.281,0,3.206-0.002c1.924-0.006,4.485,0.146,7.05,0.225
        c2.562,0.105,5.129,0.172,7.049,0.29c1.918,0.146,3.197,0.242,3.197,0.242l-1.364,19.828c0,0-1.2-0.092-3.001-0.23
        c-1.802-0.11-4.209-0.168-6.613-0.27c-2.406-0.072-4.812-0.22-6.617-0.213c-1.806,0.002-3.01,0.004-3.01,0.004L1443.197,645.723z"
            />
            <path
              fill="#FFFFFF"
              d="M1448.584,655.784c0.05-2.484,2.139-4.422,4.666-4.327s4.467,2.185,4.333,4.666
        c-0.134,2.482-2.224,4.419-4.665,4.328C1450.475,660.359,1448.535,658.27,1448.584,655.784z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1422.686,646.22c0,0,5.127-0.204,10.254-0.408c0.641-0.024,1.281-0.05,1.912-0.074
        c0.631-0.002,1.252-0.003,1.854-0.005c1.202-0.002,2.325-0.004,3.286-0.005c1.924-0.002,3.207-0.003,3.207-0.003l-0.104,19.875
        c0,0-1.203,0.001-3.01,0.003c-0.902,0-1.956,0.001-3.084,0.002c-0.564,0.001-1.146,0.003-1.74,0.005
        c-0.592,0.023-1.192,0.048-1.795,0.071c-4.811,0.19-9.621,0.381-9.621,0.381L1422.686,646.22z"
            />
            <path
              fill="#FFFFFF"
              d="M1428.705,656.002c-0.108-2.482,1.854-4.577,4.379-4.677c2.525-0.138,4.598,1.958,4.621,4.419
        c0.023,2.509-1.939,4.441-4.382,4.578C1430.881,660.419,1428.812,658.485,1428.705,656.002z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1402.253,648.116c0,0,1.274-0.15,3.187-0.375c1.912-0.212,4.455-0.569,7.017-0.729
        c2.558-0.201,5.115-0.401,7.033-0.553c0.959-0.075,1.758-0.138,2.317-0.181c0.56-0.05,0.879-0.06,0.879-0.06l1.157,19.841
        c0,0-4.8,0.373-9.601,0.746c-2.404,0.146-4.788,0.485-6.582,0.686c-1.793,0.211-2.988,0.352-2.988,0.352L1402.253,648.116z"
            />
            <path
              fill="#FFFFFF"
              d="M1408.875,657.42c-0.266-2.464,1.552-4.75,4.076-4.915c2.521-0.199,4.712,1.65,4.895,4.129
        c0.181,2.479-1.647,4.645-4.084,4.834C1411.32,661.626,1409.143,659.899,1408.875,657.42z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1381.97,651.261c0,0,1.259-0.247,3.147-0.618c1.896-0.333,4.436-0.708,6.971-1.113
        c5.062-0.872,10.165-1.412,10.165-1.413l2.419,19.728c0,0-4.79,0.502-9.532,1.324c-2.375,0.381-4.757,0.729-6.532,1.042
        c-1.771,0.349-2.951,0.582-2.951,0.582L1381.97,651.261z"
            />
            <path
              fill="#FFFFFF"
              d="M1389.178,660.124c-0.424-2.449,1.258-4.753,3.758-5.146c2.498-0.394,4.799,1.284,5.139,3.747
        c0.339,2.462-1.344,4.767-3.758,5.146C1391.903,664.252,1389.602,662.573,1389.178,660.124z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1361.921,655.671c0,0,4.995-1.182,9.99-2.362c1.245-0.312,2.501-0.573,3.684-0.794
        c1.181-0.231,2.282-0.448,3.228-0.635c1.889-0.371,3.147-0.618,3.147-0.618l3.687,19.53c0,0-1.182,0.232-2.951,0.58
        c-0.887,0.174-1.919,0.376-3.025,0.594c-1.109,0.205-2.287,0.451-3.453,0.745c-4.685,1.107-9.367,2.213-9.367,2.213
        L1361.921,655.671z"
            />
            <path
              fill="#FFFFFF"
              d="M1369.703,664.121c-0.581-2.417,0.943-4.847,3.404-5.429c0.613-0.146,1.227-0.16,1.806-0.063
        c0.584,0.117,1.135,0.345,1.623,0.664c0.978,0.638,1.701,1.642,1.952,2.859c0.252,1.217-0.014,2.416-0.635,3.366
        c-0.312,0.477-0.713,0.892-1.188,1.217c-0.469,0.345-1.01,0.602-1.605,0.742C1372.684,668.04,1370.284,666.537,1369.703,664.121z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1342.227,661.45c0,0,1.223-0.39,3.057-0.974c0.917-0.292,1.986-0.632,3.133-0.995
        c0.573-0.183,1.166-0.371,1.768-0.562c0.608-0.169,1.228-0.341,1.844-0.512c4.947-1.368,9.895-2.736,9.895-2.736l4.938,19.252
        c0,0-4.64,1.284-9.277,2.568c-0.58,0.161-1.159,0.32-1.73,0.479c-0.563,0.181-1.119,0.357-1.655,0.528
        c-1.075,0.342-2.078,0.662-2.938,0.936c-1.72,0.547-2.865,0.912-2.865,0.912L1342.227,661.45z"
            />
            <path
              fill="#FFFFFF"
              d="M1350.516,669.322c-0.728-2.35,0.604-4.98,3.053-5.619c2.438-0.675,4.943,0.722,5.598,3.12
        c0.654,2.397-0.727,4.871-3.082,5.521C1353.717,672.96,1351.258,671.721,1350.516,669.322z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1322.929,668.444c0,0,1.19-0.48,2.976-1.201c1.771-0.753,4.213-1.554,6.619-2.446
        c2.416-0.862,4.821-1.759,6.646-2.373c1.834-0.584,3.058-0.974,3.058-0.974l6.162,18.895c0,0-1.146,0.364-2.867,0.911
        c-1.709,0.575-3.963,1.42-6.23,2.229c-2.255,0.838-4.547,1.582-6.207,2.292c-1.673,0.677-2.788,1.128-2.788,1.128L1322.929,668.444
        z"
            />
            <path
              fill="#FFFFFF"
              d="M1331.698,675.74c-0.886-2.322,0.323-4.9,2.702-5.758c2.38-0.858,4.963,0.353,5.769,2.703
        c0.806,2.352-0.404,4.93-2.703,5.759S1332.584,678.062,1331.698,675.74z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1304.104,676.63c0,0,0.287-0.143,0.791-0.392c0.513-0.23,1.244-0.561,2.121-0.956
        c1.756-0.789,4.098-1.841,6.438-2.894c2.326-1.088,4.718-2.02,6.502-2.744c1.784-0.721,2.974-1.201,2.974-1.201l7.366,18.459
        c0,0-1.116,0.45-2.789,1.127c-1.672,0.679-3.917,1.548-6.096,2.57c-2.194,0.986-4.39,1.973-6.035,2.711
        c-0.823,0.37-1.508,0.679-1.988,0.895c-0.472,0.233-0.741,0.368-0.741,0.368L1304.104,676.63z"
            />
            <path
              fill="#FFFFFF"
              d="M1313.355,683.412c-1.035-2.26-0.002-4.934,2.306-5.969c2.296-1.065,4.979,0.044,5.937,2.341
        c0.954,2.292-0.115,4.875-2.332,5.906C1317.036,686.692,1314.391,685.672,1313.355,683.412z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1285.872,686.06c0,0,1.128-0.611,2.819-1.529c1.696-0.909,3.936-2.163,6.213-3.343
        c4.6-2.278,9.199-4.558,9.199-4.558l8.543,17.945c0,0-4.312,2.137-8.625,4.272c-4.236,2.286-8.473,4.571-8.473,4.571
        L1285.872,686.06z"
            />
            <path
              fill="#FFFFFF"
              d="M1295.531,692.213c-1.174-2.179-0.342-4.962,1.904-6.125c2.266-1.124,4.996-0.228,6.099,2
        c1.103,2.229,0.22,4.913-1.97,5.998C1299.395,695.209,1296.713,694.411,1295.531,692.213z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1268.288,696.635c0,0,1.08-0.692,2.698-1.731c0.811-0.52,1.756-1.124,2.769-1.771
        c1.035-0.612,2.14-1.266,3.243-1.92c2.208-1.306,4.417-2.611,6.073-3.591c0.821-0.5,1.534-0.868,2.026-1.139
        c0.493-0.269,0.775-0.422,0.775-0.422l9.678,17.359c0,0-0.265,0.145-0.728,0.396c-0.462,0.253-1.132,0.597-1.901,1.067
        c-1.554,0.919-3.625,2.146-5.697,3.371c-1.035,0.612-2.072,1.225-3.043,1.799c-0.951,0.609-1.837,1.178-2.598,1.665
        c-1.52,0.977-2.532,1.626-2.532,1.626L1268.288,696.635z"
            />
            <path
              fill="#FFFFFF"
              d="M1278.29,702.114c-1.327-2.128-0.635-4.894,1.545-6.174c2.177-1.288,4.948-0.587,6.191,1.565
        c1.241,2.153,0.544,4.907-1.561,6.151C1282.357,704.895,1279.593,704.204,1278.29,702.114z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1251.396,708.278c0,0,1.026-0.771,2.565-1.925c1.579-1.099,3.685-2.562,5.791-4.027
        c2.106-1.463,4.2-2.948,5.838-3.958c1.618-1.04,2.697-1.733,2.697-1.733l10.764,16.708c0,0-1.013,0.65-2.532,1.626
        c-1.539,0.945-3.5,2.342-5.479,3.715c-1.977,1.374-3.954,2.748-5.438,3.779c-1.443,1.084-2.405,1.809-2.405,1.809L1251.396,708.278
        z"
            />
            <path
              fill="#FFFFFF"
              d="M1261.744,713.139c-1.446-2.021-0.936-4.831,1.141-6.273c2.077-1.444,4.876-0.937,6.254,1.133
        c1.375,2.069,0.865,4.878-1.142,6.273C1265.99,715.668,1263.189,715.161,1261.744,713.139z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1235.257,720.945c0,0,3.986-3.229,7.972-6.459c1.98-1.63,4.082-3.107,5.604-4.283
        c1.539-1.155,2.563-1.925,2.563-1.925l11.802,15.991c0,0-0.962,0.724-2.406,1.809c-1.428,1.104-3.404,2.484-5.261,4.019
        c-3.742,3.031-7.484,6.062-7.484,6.062L1235.257,720.945z"
            />
            <path
              fill="#FFFFFF"
              d="M1245.93,725.188c-1.57-1.927-1.252-4.777,0.713-6.368c1.953-1.608,4.861-1.172,6.352,0.786
        c1.521,1.998,1.105,4.727-0.78,6.282C1250.314,727.426,1247.501,727.112,1245.93,725.188z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1220.007,734.67c0,0,0.935-0.878,2.337-2.195c1.419-1.298,3.233-3.114,5.182-4.784
        c3.867-3.373,7.732-6.745,7.732-6.745l12.79,15.213c0,0-3.628,3.166-7.256,6.332c-1.831,1.563-3.528,3.274-4.862,4.492
        c-1.316,1.235-2.194,2.061-2.194,2.061L1220.007,734.67z"
            />
            <path
              fill="#FFFFFF"
              d="M1230.891,738.188c-1.674-1.803-1.604-4.744,0.316-6.391c1.905-1.663,4.77-1.488,6.396,0.391
        c1.627,1.88,1.453,4.704-0.39,6.312C1235.355,740.087,1232.598,740.029,1230.891,738.188z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1205.636,749.306c0,0,0.868-0.942,2.171-2.357c1.276-1.44,3.101-3.245,4.901-5.07
        c1.806-1.822,3.608-3.645,4.963-5.012c1.401-1.317,2.336-2.196,2.336-2.196l13.729,14.373c0,0-0.878,0.824-2.194,2.061
        c-1.271,1.283-2.964,2.995-4.656,4.707c-1.692,1.714-3.408,3.405-4.604,4.76c-1.224,1.33-2.038,2.216-2.038,2.216L1205.636,749.306
        z"
            />
            <path
              fill="#FFFFFF"
              d="M1216.684,752.098c-1.802-1.712-1.82-4.557-0.041-6.354c1.777-1.797,4.633-1.818,6.375-0.046
        c1.743,1.771,1.762,4.617,0.043,6.354C1221.34,753.79,1218.484,753.81,1216.684,752.098z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1192.191,764.795c0,0,0.199-0.25,0.549-0.689c0.352-0.438,0.837-1.075,1.472-1.799
        c1.25-1.462,2.917-3.41,4.583-5.359c0.833-0.975,1.667-1.948,2.449-2.862c0.812-0.885,1.57-1.712,2.221-2.42
        c1.303-1.416,2.17-2.359,2.17-2.359l14.606,13.479c0,0-0.815,0.886-2.039,2.215c-0.611,0.665-1.324,1.44-2.088,2.272
        c-0.733,0.857-1.517,1.772-2.298,2.688c-1.565,1.83-3.131,3.66-4.306,5.033c-0.597,0.678-1.052,1.278-1.382,1.688
        c-0.327,0.413-0.515,0.648-0.515,0.648L1192.191,764.795z"
            />
            <path
              fill="#FFFFFF"
              d="M1203.438,766.926c-1.905-1.595-2.119-4.447-0.475-6.368c1.639-1.926,4.48-2.13,6.35-0.456
        c1.834,1.644,2.037,4.485,0.453,6.35C1208.177,768.309,1205.344,768.521,1203.438,766.926z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1179.748,781.101c0,0,3.042-4.129,6.084-8.258c1.557-2.038,3.178-4.024,4.366-5.538
        c1.196-1.506,1.993-2.51,1.993-2.51l15.425,12.535c0,0-2.987,3.778-5.974,7.557c-2.856,3.878-5.714,7.756-5.714,7.756
        L1179.748,781.101z"
            />
            <path
              fill="#FFFFFF"
              d="M1191.128,782.537c-2.002-1.473-2.409-4.316-0.909-6.353c1.535-2.009,4.418-2.346,6.361-0.812
        c1.96,1.545,2.281,4.324,0.798,6.267C1195.929,783.607,1193.13,784.009,1191.128,782.537z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1168.395,798.188c0,0,0.685-1.086,1.709-2.714c1.035-1.62,2.358-3.819,3.836-5.917
        c1.445-2.119,2.891-4.236,3.975-5.825c0.542-0.794,0.995-1.456,1.312-1.919c0.332-0.452,0.521-0.711,0.521-0.711l16.182,11.542
        c0,0-0.18,0.242-0.492,0.666c-0.297,0.437-0.721,1.058-1.229,1.804c-1.018,1.492-2.375,3.48-3.731,5.471
        c-1.391,1.967-2.629,4.034-3.601,5.556c-0.962,1.528-1.604,2.546-1.604,2.546L1168.395,798.188z"
            />
            <path
              fill="#FFFFFF"
              d="M1179.798,798.874c-2.086-1.34-2.721-4.186-1.271-6.257c1.425-2.089,4.236-2.637,6.281-1.225
        c2.045,1.414,2.586,4.197,1.207,6.217C1184.612,799.611,1181.895,800.22,1179.798,798.874z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1158.118,815.941c0,0,0.603-1.134,1.504-2.832c0.892-1.705,2.229-3.896,3.499-6.125
        c1.298-2.212,2.545-4.455,3.566-6.085c1.024-1.628,1.707-2.714,1.707-2.714l16.877,10.498c0,0-0.643,1.02-1.605,2.547
        c-0.96,1.529-2.126,3.637-3.345,5.713c-1.191,2.092-2.451,4.146-3.286,5.748c-0.846,1.596-1.41,2.658-1.41,2.658L1158.118,815.941z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1169.53,815.899c-2.173-1.209-2.905-3.962-1.638-6.15c1.269-2.188,4.022-2.923,6.151-1.641
        c2.13,1.281,2.863,4.034,1.638,6.149C1174.455,816.373,1171.702,817.108,1169.53,815.899z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1148.949,834.292c0,0,0.13-0.293,0.359-0.805c0.243-0.505,0.592-1.228,1.012-2.094
        c0.838-1.73,1.955-4.04,3.074-6.348c1.082-2.326,2.328-4.569,3.222-6.272c0.901-1.699,1.503-2.832,1.503-2.832l17.507,9.408
        c0,0-0.563,1.063-1.411,2.658c-0.838,1.601-2.013,3.703-3.025,5.889c-1.049,2.168-2.1,4.336-2.887,5.961
        c-0.394,0.812-0.722,1.489-0.951,1.963c-0.215,0.48-0.337,0.756-0.337,0.756L1148.949,834.292z"
            />
            <path
              fill="#FFFFFF"
              d="M1160.398,833.559c-2.244-1.068-3.17-3.78-2.067-6.057c1.073-2.29,3.856-3.156,6.058-2.014
        c2.21,1.146,3.037,3.807,2,6.021C1165.324,833.711,1162.643,834.628,1160.398,833.559z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1141.012,853.208c0,0,0.473-1.192,1.18-2.981c0.723-1.781,1.617-4.188,2.631-6.543
        c2.062-4.695,4.125-9.392,4.125-9.392l18.065,8.286c0,0-1.937,4.41-3.874,8.818c-0.953,2.212-1.789,4.472-2.469,6.145
        c-0.665,1.679-1.108,2.798-1.108,2.798L1141.012,853.208z"
            />
            <path
              fill="#FFFFFF"
              d="M1152.362,851.743c-2.291-0.919-3.456-3.597-2.456-5.919c1.016-2.315,3.684-3.379,5.958-2.376
        s3.319,3.63,2.336,5.869C1157.234,851.562,1154.684,852.675,1152.362,851.743z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1134.273,872.583c0,0,0.383-1.224,0.957-3.06c0.541-1.849,1.406-4.263,2.265-6.68
        c0.854-2.418,1.71-4.836,2.353-6.648c0.698-1.792,1.166-2.986,1.166-2.986l18.551,7.131c0,0-0.438,1.122-1.096,2.803
        c-0.603,1.703-1.404,3.974-2.207,6.244c-0.806,2.27-1.623,4.535-2.125,6.271c-0.539,1.724-0.896,2.873-0.896,2.873
        L1134.273,872.583z"
            />
            <path
              fill="#FFFFFF"
              d="M1145.452,870.386c-2.362-0.778-3.593-3.342-2.75-5.727c0.843-2.384,3.417-3.622,5.75-2.765
        s3.563,3.422,2.749,5.728C1150.386,869.927,1147.811,871.164,1145.452,870.386z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1128.739,892.338c0,0,0.071-0.312,0.198-0.859c0.123-0.547,0.304-1.328,0.566-2.254
        c0.504-1.856,1.178-4.333,1.85-6.808c0.336-1.237,0.674-2.475,0.989-3.635c0.357-1.148,0.69-2.22,0.976-3.139
        c0.574-1.836,0.955-3.061,0.955-3.061l18.965,5.946c0,0-0.358,1.148-0.896,2.873c-0.27,0.861-0.582,1.867-0.918,2.944
        c-0.295,1.089-0.611,2.25-0.926,3.412c-0.632,2.323-1.264,4.646-1.737,6.39c-0.249,0.867-0.417,1.601-0.532,2.114
        c-0.117,0.514-0.186,0.808-0.186,0.808L1128.739,892.338z"
            />
            <path
              fill="#FFFFFF"
              d="M1139.818,889.454c-2.404-0.627-3.816-3.114-3.152-5.554c0.656-2.442,3.139-3.843,5.543-3.127
        c2.359,0.701,3.759,3.185,3.126,5.546C1144.693,888.677,1142.225,890.081,1139.818,889.454z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1124.495,912.409c0,0,0.966-5.037,1.933-10.074c1.156-4.998,2.312-9.997,2.312-9.997l19.304,4.731
        c0,0-1.085,4.69-2.17,9.382c-0.907,4.729-1.812,9.459-1.812,9.459L1124.495,912.409z"
            />
            <path
              fill="#FFFFFF"
              d="M1135.373,908.83c-2.436-0.473-4.042-2.873-3.55-5.354c0.569-2.464,2.995-4.011,5.417-3.455
        c2.422,0.557,3.938,2.938,3.388,5.318C1140.152,907.739,1137.816,909.305,1135.373,908.83z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1121.535,932.701c0,0,0.145-1.273,0.359-3.185c0.178-1.916,0.588-4.446,0.97-6.982
        c0.384-2.535,0.769-5.07,1.057-6.972c0.13-0.953,0.316-1.734,0.41-2.287c0.104-0.552,0.165-0.866,0.165-0.866l19.564,3.502
        c0,0-0.058,0.296-0.156,0.812c-0.088,0.521-0.266,1.252-0.385,2.147c-0.271,1.786-0.631,4.168-0.99,6.55
        c-0.358,2.382-0.749,4.759-0.912,6.56c-0.202,1.797-0.336,2.992-0.336,2.992L1121.535,932.701z"
            />
            <path
              fill="#FFFFFF"
              d="M1132.1,928.441c-2.466-0.32-4.158-2.605-3.778-5.105c0.378-2.5,2.673-4.2,5.126-3.799
        c2.453,0.402,4.145,2.688,3.777,5.104C1136.857,927.061,1134.562,928.761,1132.1,928.441z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1119.783,953.13c0,0,0.012-0.32,0.033-0.881c0.041-0.559,0.1-1.357,0.17-2.316
        c0.143-1.917,0.332-4.474,0.521-7.029c0.105-1.277,0.168-2.559,0.303-3.752c0.133-1.194,0.258-2.31,0.365-3.266
        c0.215-1.91,0.358-3.185,0.358-3.185l19.745,2.271c0,0-0.135,1.197-0.338,2.992c-0.102,0.898-0.22,1.945-0.347,3.067
        s-0.184,2.327-0.282,3.526c-0.179,2.402-0.356,4.807-0.49,6.607c-0.067,0.9-0.125,1.651-0.163,2.178
        c-0.02,0.526-0.03,0.827-0.03,0.827L1119.783,953.13z"
            />
            <path
              fill="#FFFFFF"
              d="M1130.154,948.229c-2.479-0.166-4.338-2.345-4.15-4.865c0.096-1.258,0.676-2.354,1.557-3.097
        c0.902-0.741,2.078-1.135,3.314-1.014s2.3,0.73,3.021,1.607c0.742,0.88,1.168,2.03,1.076,3.253
        C1134.792,946.552,1132.634,948.395,1130.154,948.229z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1119.373,973.627c0,0-0.002-1.281-0.005-3.204c0.021-1.922-0.058-4.485,0.043-7.048
        c0.187-5.122,0.372-10.245,0.372-10.245l19.848,1.043c0,0-0.175,4.816-0.349,9.632c-0.099,2.408-0.02,4.818-0.039,6.626
        c0.003,1.808,0.004,3.013,0.004,3.013L1119.373,973.627z"
            />
            <path
              fill="#FFFFFF"
              d="M1129.379,968.094c-2.462-0.013-4.554-2.072-4.454-4.6c0.091-2.525,2.179-4.496,4.662-4.402
        c2.483,0.097,4.425,2.153,4.335,4.599C1133.824,966.133,1131.887,968.106,1129.379,968.094z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1120.199,994.11c0,0-0.098-1.278-0.246-3.195c-0.155-1.917-0.2-4.48-0.318-7.041
        c-0.093-2.562-0.218-5.122-0.257-7.044c-0.003-1.922-0.006-3.203-0.006-3.203l19.874-0.185c0,0,0.001,1.204,0.003,3.012
        c0.037,1.807,0.158,4.214,0.244,6.622c0.112,2.406,0.149,4.817,0.297,6.619c0.14,1.801,0.233,3.001,0.233,3.001L1120.199,994.11z"
            />
            <path
              fill="#FFFFFF"
              d="M1129.818,987.971c-2.48,0.142-4.573-1.792-4.672-4.317c-0.1-2.525,1.834-4.621,4.319-4.681
        c2.484-0.059,4.577,1.875,4.673,4.318C1134.234,985.735,1132.301,987.83,1129.818,987.971z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1122.25,1014.518c0,0-0.589-5.095-1.179-10.188c-0.337-2.543-0.456-5.107-0.625-7.022
        c-0.148-1.918-0.247-3.195-0.247-3.195l19.824-1.414c0,0,0.093,1.201,0.231,3.002c0.16,1.799,0.267,4.208,0.586,6.597
        c0.554,4.785,1.106,9.57,1.106,9.57L1122.25,1014.518z"
            />
            <path
              fill="#FFFFFF"
              d="M1131.549,1007.776c-2.468,0.296-4.703-1.5-4.99-4.013c-0.324-2.509,1.59-4.729,4.049-4.941
        c2.494-0.215,4.589,1.596,4.903,4.021C1135.79,1005.271,1134.018,1007.481,1131.549,1007.776z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1125.668,1034.74c0,0-0.242-1.26-0.605-3.147c-0.181-0.943-0.393-2.046-0.619-3.227
        c-0.112-0.591-0.229-1.2-0.348-1.82c-0.096-0.624-0.193-1.257-0.291-1.891c-0.777-5.069-1.554-10.139-1.554-10.139l19.697-2.651
        c0,0,0.73,4.762,1.461,9.521c0.091,0.597,0.182,1.19,0.271,1.776c0.112,0.582,0.224,1.155,0.329,1.71
        c0.214,1.108,0.412,2.143,0.583,3.03c0.342,1.772,0.568,2.956,0.568,2.956L1125.668,1034.74z"
            />
            <path
              fill="#FFFFFF"
              d="M1134.464,1027.442c-2.419,0.444-4.872-1.188-5.219-3.693c-0.384-2.499,1.298-4.823,3.756-5.19
        c2.458-0.366,4.751,1.295,5.122,3.711C1138.456,1024.692,1136.934,1026.988,1134.464,1027.442z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1130.302,1054.719c0,0-0.336-1.236-0.839-3.094c-0.506-1.855-1.034-4.366-1.628-6.86
        c-0.565-2.501-1.185-4.99-1.562-6.877c-0.362-1.889-0.605-3.146-0.605-3.146l19.493-3.88c0,0,0.228,1.183,0.567,2.957
        c0.353,1.771,0.938,4.107,1.468,6.457c0.559,2.343,1.05,4.702,1.526,6.443c0.475,1.742,0.791,2.904,0.791,2.904L1130.302,1054.719z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1138.605,1046.889c-2.412,0.603-4.828-0.906-5.398-3.369c-0.57-2.465,0.938-4.883,3.368-5.403
        c2.43-0.521,4.848,0.988,5.399,3.37C1142.525,1043.867,1141.018,1046.286,1138.605,1046.889z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1136.138,1074.387c0,0-1.534-4.895-3.067-9.79c-0.808-2.437-1.399-4.935-1.928-6.784
        c-0.504-1.854-0.841-3.094-0.841-3.094l19.21-5.096c0,0,0.316,1.162,0.79,2.904c0.497,1.735,1.046,4.084,1.81,6.369
        c1.438,4.596,2.877,9.189,2.877,9.189L1136.138,1074.387z"
            />
            <path
              fill="#FFFFFF"
              d="M1144.01,1066.021c-2.367,0.753-4.9-0.595-5.656-3.008c-0.789-2.402,0.682-4.945,3.056-5.612
        c2.411-0.679,4.802,0.71,5.567,3.031C1147.707,1062.767,1146.378,1065.268,1144.01,1066.021z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1143.301,1093.616c0,0-0.475-1.191-1.187-2.979c-0.687-1.798-1.722-4.146-2.54-6.581
        c-1.719-4.835-3.437-9.67-3.437-9.67l18.851-6.3c0,0,1.613,4.536,3.226,9.072c0.763,2.283,1.742,4.487,2.385,6.175
        c0.668,1.678,1.113,2.796,1.113,2.796L1143.301,1093.616z"
            />
            <path
              fill="#FFFFFF"
              d="M1150.564,1084.79c-2.295,0.894-5.006-0.251-5.818-2.647c-0.848-2.383,0.367-4.98,2.712-5.804
        s4.91,0.377,5.729,2.682C1153.971,1081.336,1152.902,1083.88,1150.564,1084.79z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1151.631,1112.362c0,0-0.561-1.153-1.402-2.884c-0.879-1.712-1.851-4.089-2.906-6.426
        c-1.03-2.349-2.09-4.686-2.833-6.459c-0.714-1.787-1.188-2.979-1.188-2.979l18.411-7.486c0,0,0.445,1.118,1.113,2.796
        c0.698,1.665,1.696,3.856,2.662,6.062c0.993,2.192,1.899,4.426,2.728,6.032c0.791,1.623,1.318,2.705,1.318,2.705L1151.631,1112.362
        z"
            />
            <path
              fill="#FFFFFF"
              d="M1158.295,1103.107c-2.254,1.047-4.911,0.021-5.933-2.292c-1.022-2.312,0.005-4.975,2.293-5.944
        s4.944,0.056,5.933,2.292S1160.549,1102.061,1158.295,1103.107z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1161.098,1130.561c0,0-0.67-1.096-1.556-2.803c-0.905-1.697-2.11-3.961-3.317-6.224
        c-0.595-1.137-1.224-2.255-1.75-3.335c-0.526-1.081-1.019-2.09-1.439-2.954c-0.842-1.729-1.403-2.883-1.403-2.883l17.901-8.636
        c0,0,0.527,1.082,1.316,2.707c0.396,0.812,0.855,1.76,1.35,2.773s1.088,2.063,1.646,3.13c1.131,2.126,2.265,4.251,3.113,5.845
        c0.83,1.604,1.46,2.633,1.46,2.633L1161.098,1130.561z"
            />
            <path
              fill="#FFFFFF"
              d="M1167.221,1120.874c-2.184,1.187-4.918,0.34-6.107-1.892c-0.592-1.114-0.686-2.353-0.34-3.45
        c0.365-1.109,1.149-2.07,2.26-2.63c1.109-0.56,2.334-0.613,3.412-0.258c1.099,0.347,2.074,1.092,2.647,2.174
        C1170.242,1116.976,1169.404,1119.688,1167.221,1120.874z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1171.716,1148.109c0,0-2.733-4.34-5.467-8.681c-2.577-4.435-5.153-8.869-5.153-8.869l17.321-9.745
        c0,0,2.419,4.164,4.838,8.329c2.567,4.074,5.136,8.149,5.136,8.149L1171.716,1148.109z"
            />
            <path
              fill="#FFFFFF"
              d="M1177.216,1138.059c-2.103,1.32-4.911,0.663-6.247-1.481c-1.271-2.188-0.559-4.974,1.59-6.224
        c2.147-1.25,4.885-0.55,6.111,1.564C1179.963,1133.993,1179.323,1136.735,1177.216,1138.059z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1183.436,1164.945c0,0-0.762-1.032-1.904-2.579c-0.571-0.773-1.237-1.677-1.952-2.645
        c-0.681-0.991-1.406-2.049-2.132-3.104c-1.447-2.117-2.895-4.235-3.979-5.823c-0.551-0.788-0.987-1.46-1.28-1.938
        c-0.299-0.475-0.472-0.745-0.472-0.745l16.675-10.815c0,0,0.162,0.255,0.441,0.7c0.273,0.451,0.684,1.081,1.203,1.821
        c1.02,1.489,2.378,3.479,3.737,5.467c0.681,0.992,1.362,1.984,2,2.917c0.671,0.907,1.297,1.754,1.834,2.479
        c1.073,1.452,1.788,2.42,1.788,2.42L1183.436,1164.945z"
            />
            <path
              fill="#FFFFFF"
              d="M1188.245,1154.601c-1.006,0.722-2.234,0.979-3.37,0.774c-1.128-0.208-2.186-0.862-2.898-1.903
        c-1.427-2.087-0.908-4.899,1.156-6.282c2.065-1.385,4.857-0.868,6.237,1.149c0.69,1.013,0.904,2.216,0.694,3.354
        C1189.863,1152.824,1189.259,1153.874,1188.245,1154.601z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1196.167,1181.028c0,0-0.835-0.975-2.087-2.435c-1.201-1.503-2.803-3.506-4.403-5.51
        c-1.592-2.011-3.222-3.992-4.335-5.562c-1.144-1.547-1.906-2.577-1.906-2.577l15.959-11.847c0,0,0.715,0.97,1.789,2.422
        c1.041,1.477,2.576,3.332,4.07,5.222c1.502,1.881,3.005,3.763,4.131,5.174c1.178,1.37,1.963,2.283,1.963,2.283L1196.167,1181.028z"
            />
            <path
              fill="#FFFFFF"
              d="M1200.333,1170.387c-1.922,1.576-4.757,1.251-6.333-0.727c-1.576-1.979-1.253-4.807,0.721-6.315
        c1.975-1.511,4.809-1.186,6.333,0.727C1202.577,1165.982,1202.254,1168.811,1200.333,1170.387z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1209.865,1196.297c0,0-3.483-3.765-6.967-7.529c-0.881-0.932-1.729-1.894-2.504-2.812
        c-0.783-0.912-1.514-1.764-2.141-2.493c-1.253-1.46-2.088-2.433-2.088-2.433l15.181-12.829c0,0,0.784,0.914,1.96,2.284
        c0.588,0.686,1.274,1.484,2.01,2.342c0.725,0.865,1.521,1.769,2.35,2.643c3.271,3.535,6.542,7.07,6.542,7.07L1209.865,1196.297z"
            />
            <path
              fill="#FFFFFF"
              d="M1213.396,1185.373c-1.818,1.694-4.685,1.562-6.398-0.296c-0.865-0.919-1.249-2.107-1.164-3.279
        c0.074-1.163,0.592-2.288,1.527-3.104c1.895-1.65,4.638-1.409,6.324,0.361C1215.344,1180.85,1215.214,1183.68,1213.396,1185.373z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1224.547,1210.622c0,0-0.936-0.876-2.34-2.19c-1.391-1.33-3.307-3.035-5.114-4.854
        c-3.614-3.64-7.228-7.279-7.228-7.279l14.344-13.759c0,0,3.395,3.418,6.788,6.835c1.694,1.711,3.5,3.309,4.803,4.559
        c1.318,1.234,2.197,2.059,2.197,2.059L1224.547,1210.622z"
            />
            <path
              fill="#FFFFFF"
              d="M1227.354,1199.529c-1.693,1.791-4.621,1.899-6.401,0.104c-1.782-1.794-1.793-4.664-0.024-6.41
        c1.768-1.747,4.598-1.757,6.32-0.021C1228.968,1194.939,1229.076,1197.71,1227.354,1199.529z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1240.089,1224.01c0,0-0.997-0.807-2.493-2.017c-1.517-1.185-3.451-2.867-5.381-4.558
        c-1.934-1.686-3.868-3.368-5.318-4.633c-1.41-1.309-2.35-2.182-2.35-2.182l13.45-14.633c0,0,0.881,0.818,2.204,2.05
        c1.363,1.187,3.179,2.766,4.996,4.347c1.811,1.588,3.625,3.172,5.051,4.279c1.404,1.135,2.341,1.893,2.341,1.893L1240.089,1224.01z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1242.157,1212.803c-1.601,1.92-4.431,2.114-6.339,0.457c-1.908-1.66-2.112-4.51-0.457-6.363
        c1.654-1.854,4.49-2.058,6.334-0.451C1243.541,1208.047,1243.739,1210.904,1242.157,1212.803z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1256.434,1236.412c0,0-1.064-0.716-2.623-1.845c-1.539-1.153-3.593-2.692-5.646-4.23
        c-1.026-0.77-2.052-1.539-3.015-2.261c-0.955-0.729-1.813-1.453-2.566-2.052c-1.496-1.209-2.494-2.016-2.494-2.016l12.499-15.453
        c0,0,0.937,0.757,2.34,1.894c0.707,0.562,1.511,1.243,2.408,1.927c0.903,0.677,1.866,1.397,2.83,2.12
        c1.926,1.443,3.852,2.888,5.297,3.971c1.461,1.061,2.462,1.729,2.462,1.729L1256.434,1236.412z"
            />
            <path
              fill="#FFFFFF"
              d="M1257.814,1225.055c-1.467,2.008-4.297,2.403-6.319,0.888c-2.021-1.521-2.427-4.323-0.876-6.3
        c1.52-1.935,4.357-2.344,6.311-0.875C1258.886,1220.233,1259.282,1223.049,1257.814,1225.055z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1273.532,1247.759c0,0-4.322-2.765-8.646-5.53c-2.148-1.4-4.229-2.904-5.818-3.987
        c-1.582-1.099-2.635-1.828-2.635-1.828l11.491-16.216c0,0,0.989,0.686,2.474,1.714c1.492,1.016,3.44,2.431,5.457,3.744
        c4.056,2.595,8.11,5.188,8.11,5.188L1273.532,1247.759z"
            />
            <path
              fill="#FFFFFF"
              d="M1274.208,1236.303c-1.336,2.096-4.146,2.69-6.276,1.326c-2.119-1.381-2.634-4.253-1.235-6.288
        c1.416-2.062,4.155-2.551,6.202-1.217C1274.958,1231.439,1275.544,1234.207,1274.208,1236.303z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1291.35,1257.939c0,0-1.129-0.609-2.822-1.523c-1.685-0.93-3.975-2.091-6.166-3.428
        c-2.211-1.303-4.42-2.604-6.079-3.58c-0.829-0.488-1.521-0.896-2.003-1.182c-0.477-0.298-0.747-0.469-0.747-0.469l10.435-16.915
        c0,0,0.256,0.16,0.701,0.439c0.454,0.268,1.102,0.647,1.88,1.105c1.556,0.916,3.63,2.138,5.704,3.358
        c2.053,1.259,4.205,2.343,5.785,3.216c1.588,0.857,2.646,1.43,2.646,1.43L1291.35,1257.939z"
            />
            <path
              fill="#FFFFFF"
              d="M1291.268,1246.513c-1.194,2.166-3.998,3.003-6.16,1.691c-2.181-1.283-2.914-4.054-1.642-6.188
        s4.013-2.858,6.119-1.619C1291.675,1241.668,1292.473,1244.325,1291.268,1246.513z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1309.766,1266.991c0,0-1.171-0.524-2.927-1.312c-1.771-0.759-4.041-1.958-6.353-3.072
        c-2.295-1.146-4.615-2.243-6.314-3.146c-1.693-0.913-2.822-1.522-2.822-1.522l9.336-17.546c0,0,1.059,0.572,2.646,1.432
        c1.594,0.847,3.771,1.874,5.925,2.948c2.168,1.045,4.296,2.176,5.957,2.884c1.647,0.735,2.746,1.228,2.746,1.228L1309.766,1266.991
        z"
            />
            <path
              fill="#FFFFFF"
              d="M1308.943,1255.612c-1.059,2.248-3.754,3.165-6.023,2.047s-3.188-3.817-2.053-6.028
        c1.134-2.211,3.832-3.129,6.023-2.048C1309.083,1250.664,1310.003,1253.363,1308.943,1255.612z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1328.717,1274.877c0-0.001-1.215-0.417-2.992-1.155c-1.785-0.72-4.166-1.68-6.546-2.64
        c-2.395-0.926-4.729-1.992-6.485-2.78c-1.756-0.786-2.928-1.311-2.928-1.311l8.194-18.107c0,0,1.099,0.492,2.746,1.229
        c1.646,0.738,3.834,1.742,6.081,2.606c2.232,0.9,4.463,1.801,6.137,2.476c1.666,0.695,2.805,1.084,2.805,1.084L1328.717,1274.877z"
            />
            <path
              fill="#FFFFFF"
              d="M1327.188,1263.514c-0.912,2.312-3.552,3.42-5.897,2.475c-2.356-0.92-3.422-3.612-2.429-5.898
        c0.988-2.271,3.596-3.303,5.874-2.416C1327.001,1258.586,1328.1,1261.202,1327.188,1263.514z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1348.159,1281.479c0,0-4.892-1.562-9.782-3.121c-4.83-1.74-9.66-3.479-9.66-3.479l7.012-18.598
        c0,0,4.527,1.631,9.055,3.263c4.583,1.461,9.167,2.923,9.167,2.923L1348.159,1281.479z"
            />
            <path
              fill="#FFFFFF"
              d="M1345.891,1270.251c-0.758,2.36-3.334,3.678-5.736,2.884c-2.381-0.855-3.627-3.447-2.786-5.785
        c0.841-2.339,3.387-3.562,5.686-2.733C1345.375,1265.381,1346.652,1267.879,1345.891,1270.251z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1367.984,1286.799c0,0-1.248-0.293-3.123-0.73c-1.883-0.403-4.356-1.088-6.83-1.774
        c-2.475-0.682-4.949-1.363-6.805-1.874c-0.933-0.241-1.688-0.513-2.227-0.675c-0.536-0.169-0.841-0.268-0.841-0.268l5.79-19.013
        c0,0,0.286,0.091,0.787,0.251c0.505,0.15,1.212,0.408,2.086,0.633c1.74,0.479,4.061,1.116,6.38,1.753
        c2.317,0.646,4.637,1.29,6.403,1.665c1.757,0.411,2.929,0.686,2.929,0.686L1367.984,1286.799z"
            />
            <path
              fill="#FFFFFF"
              d="M1364.98,1275.811c-0.608,2.423-3.074,3.825-5.515,3.157c-2.438-0.671-3.858-3.15-3.171-5.54
        c0.688-2.388,3.156-3.799,5.512-3.149C1364.165,1270.923,1365.582,1273.412,1364.98,1275.811z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1388.1,1290.882c0,0-0.317-0.05-0.873-0.135c-0.557-0.075-1.344-0.226-2.287-0.411
        c-1.891-0.366-4.409-0.854-6.928-1.341c-1.261-0.245-2.52-0.489-3.701-0.719c-1.17-0.273-2.265-0.528-3.2-0.747
        c-1.875-0.438-3.124-0.73-3.124-0.73l4.549-19.348c0,0,1.172,0.273,2.929,0.686c0.879,0.206,1.903,0.446,3.002,0.703
        c1.107,0.215,2.289,0.443,3.472,0.672c2.361,0.457,4.726,0.915,6.496,1.259c0.887,0.176,1.624,0.317,2.146,0.387
        c0.521,0.08,0.816,0.126,0.816,0.126L1388.1,1290.882z"
            />
            <path
              fill="#FFFFFF"
              d="M1384.408,1280.033c-0.448,2.443-2.825,4.036-5.309,3.556c-2.486-0.469-4.062-2.851-3.524-5.301
        c0.526-2.405,2.899-3.974,5.302-3.522C1383.275,1275.23,1384.857,1277.589,1384.408,1280.033z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1408.436,1293.63c0,0-5.097-0.594-10.192-1.188c-5.072-0.78-10.144-1.562-10.144-1.562l3.297-19.6
        c0,0,4.759,0.731,9.517,1.466c4.782,0.556,9.563,1.11,9.563,1.11L1408.436,1293.63z"
            />
            <path
              fill="#FFFFFF"
              d="M1404.059,1283.046c-0.291,2.462-2.565,4.248-5.074,3.933c-2.5-0.385-4.223-2.688-3.846-5.146
        c0.375-2.457,2.639-4.146,5.055-3.774C1402.617,1278.364,1404.352,1280.572,1404.059,1283.046z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1428.905,1295.062c0,0-1.282-0.05-3.205-0.122c-1.925-0.038-4.483-0.234-7.041-0.438
        c-2.558-0.195-5.116-0.392-7.034-0.538c-0.961-0.059-1.755-0.18-2.312-0.234c-0.559-0.063-0.877-0.101-0.877-0.101l2.041-19.771
        c0,0,0.299,0.035,0.821,0.095c0.523,0.053,1.27,0.168,2.171,0.222c1.799,0.138,4.199,0.319,6.601,0.502
        c2.399,0.192,4.799,0.38,6.604,0.412c1.806,0.067,3.007,0.113,3.007,0.113L1428.905,1295.062z"
            />
            <path
              fill="#FFFFFF"
              d="M1423.859,1284.85c-0.135,2.494-2.287,4.343-4.81,4.152c-2.521-0.192-4.39-2.355-4.171-4.831
        s2.371-4.333,4.809-4.146C1422.125,1280.208,1423.992,1282.381,1423.859,1284.85z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1449.264,1295.277c0,0-5.072,0.012-10.144,0.022c-1.269,0.002-2.536,0.004-3.726,0.006
        c-1.201-0.045-2.323-0.087-3.285-0.123c-1.924-0.071-3.204-0.121-3.204-0.121l0.774-19.858c0,0,1.202,0.045,3.006,0.113
        c0.901,0.034,1.955,0.074,3.082,0.118c1.143-0.003,2.36-0.006,3.578-0.009c4.873-0.012,9.747-0.022,9.747-0.022L1449.264,1295.277z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1443.737,1285.275c-0.002,2.484-2.041,4.505-4.556,4.51c-2.512,0.019-4.509-2.016-4.449-4.524
        c0.057-2.461,2.091-4.456,4.553-4.475C1441.746,1280.78,1443.738,1282.79,1443.737,1285.275z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1469.533,1294.697c0,0-5.064,0.222-10.129,0.441c-2.531,0.152-5.068,0.121-6.971,0.131
        c-1.901,0.005-3.171,0.008-3.171,0.008l-0.17-19.874c0,0,1.218-0.003,3.045-0.007c1.828-0.009,4.265,0.025,6.7-0.124
        c4.871-0.214,9.742-0.428,9.742-0.428L1469.533,1294.697z"
            />
            <path
              fill="#FFFFFF"
              d="M1463.617,1284.932c0.097,2.483-1.859,4.586-4.37,4.695c-2.511,0.143-4.584-1.891-4.628-4.377
        c-0.045-2.483,1.914-4.479,4.371-4.62C1461.45,1280.521,1463.521,1282.448,1463.617,1284.932z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1489.756,1293.334c0,0-5.05,0.425-10.099,0.85c-1.263,0.123-2.526,0.192-3.714,0.232
        c-1.188,0.053-2.295,0.102-3.245,0.143c-1.899,0.084-3.165,0.14-3.165,0.14l-0.952-19.853c0,0,1.218-0.053,3.045-0.133
        c0.913-0.039,1.979-0.086,3.121-0.136c1.142-0.037,2.358-0.104,3.573-0.225c4.86-0.409,9.722-0.819,9.722-0.819L1489.756,1293.334z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1483.471,1283.812c0.192,2.479-1.682,4.656-4.185,4.867c-0.625,0.053-1.229-0.021-1.784-0.206
        c-0.559-0.202-1.066-0.511-1.501-0.898c-0.869-0.777-1.438-1.878-1.513-3.118c-0.071-1.241,0.365-2.386,1.123-3.234
        c0.381-0.424,0.842-0.772,1.359-1.023c0.52-0.271,1.096-0.446,1.709-0.497C1481.134,1279.494,1483.277,1281.335,1483.471,1283.812z
        "
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1509.906,1291.203c0,0-5.027,0.624-10.055,1.248c-2.519,0.272-5.049,0.443-6.939,0.616
        c-1.895,0.159-3.156,0.267-3.156,0.267l-1.714-19.801c0,0,1.215-0.104,3.038-0.256c1.82-0.167,4.256-0.327,6.681-0.592
        c4.843-0.603,9.685-1.205,9.685-1.205L1509.906,1291.203z"
            />
            <path
              fill="#FFFFFF"
              d="M1503.268,1281.936c0.285,2.47-1.504,4.723-3.996,5.031c-2.496,0.271-4.723-1.605-4.957-4.066
        c-0.237-2.487,1.563-4.617,4.011-4.884C1500.768,1277.712,1502.98,1279.467,1503.268,1281.936z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1529.98,1288.319c0,0-5.011,0.815-10.021,1.632c-5.026,0.626-10.056,1.252-10.056,1.252l-2.461-19.724
        c0,0,4.843-0.601,9.685-1.2c4.806-0.784,9.61-1.568,9.61-1.568L1529.98,1288.319z"
            />
            <path
              fill="#FFFFFF"
              d="M1522.979,1279.317c0.381,2.452-1.319,4.781-3.804,5.175c-2.491,0.311-4.78-1.437-5.108-3.898
        c-0.329-2.464,1.384-4.706,3.826-5.01C1520.321,1275.199,1522.598,1276.858,1522.979,1279.317z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1549.938,1284.6c0,0-4.976,1.01-9.949,2.02c-2.483,0.527-5.004,0.854-6.877,1.188
        c-1.879,0.309-3.131,0.514-3.131,0.514l-3.24-19.608c0,0,1.201-0.197,3.004-0.492c1.797-0.319,4.216-0.629,6.598-1.138
        c4.771-0.97,9.543-1.938,9.543-1.938L1549.938,1284.6z"
            />
            <path
              fill="#FFFFFF"
              d="M1542.562,1275.877c0.483,2.438-1.123,4.82-3.588,5.319c-2.459,0.523-4.834-1.216-5.259-3.638
        c-0.434-2.475,1.202-4.695,3.607-5.21C1539.734,1271.859,1542.08,1273.439,1542.562,1275.877z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1569.727,1280.054c0,0-4.933,1.199-9.865,2.398c-1.234,0.294-2.465,0.61-3.626,0.866
        c-1.166,0.237-2.255,0.459-3.188,0.648c-1.866,0.379-3.108,0.632-3.108,0.632l-4.056-19.457c0,0,1.192-0.242,2.982-0.604
        c0.896-0.183,1.938-0.394,3.057-0.619c1.114-0.246,2.293-0.553,3.478-0.834c4.729-1.15,9.461-2.301,9.461-2.301L1569.727,1280.054z
        "
            />
            <path
              fill="#FFFFFF"
              d="M1561.988,1271.629c0.585,2.415-0.922,4.855-3.366,5.449c-1.22,0.296-2.437,0.073-3.41-0.527
        c-0.979-0.622-1.718-1.627-1.983-2.839c-0.269-1.216-0.017-2.407,0.598-3.373c0.609-0.985,1.581-1.74,2.777-2.031
        C1558.992,1267.727,1561.402,1269.213,1561.988,1271.629z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1589.312,1274.686c0,0-4.886,1.389-9.77,2.776c-2.446,0.678-4.873,1.433-6.732,1.841
        c-1.852,0.451-3.084,0.751-3.084,0.751l-4.867-19.271c0,0,1.184-0.287,2.957-0.719c1.785-0.391,4.109-1.115,6.455-1.767
        c4.683-1.33,9.364-2.661,9.364-2.661L1589.312,1274.686z"
            />
            <path
              fill="#FFFFFF"
              d="M1581.219,1266.574c0.687,2.389-0.719,4.883-3.138,5.569c-2.419,0.688-4.894-0.704-5.524-3.106
        c-0.633-2.404,0.771-4.897,3.137-5.57C1578.059,1262.795,1580.532,1264.186,1581.219,1266.574z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1608.656,1268.531c0,0-4.827,1.565-9.654,3.134c-2.413,0.782-4.827,1.563-6.638,2.151
        c-1.831,0.521-3.052,0.869-3.052,0.869l-5.677-19.049c0,0,1.171-0.332,2.927-0.831c1.737-0.562,4.055-1.314,6.369-2.066
        c4.635-1.503,9.268-3.005,9.268-3.005L1608.656,1268.531z"
            />
            <path
              fill="#FFFFFF"
              d="M1600.227,1260.738c0.785,2.357-0.517,4.898-2.907,5.675c-2.392,0.776-4.925-0.521-5.657-2.896
        s0.568-4.916,2.908-5.675C1596.909,1257.084,1599.441,1258.381,1600.227,1260.738z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1627.68,1261.542c0,0-1.176,0.467-2.939,1.168c-1.786,0.647-4.169,1.511-6.553,2.373
        c-4.766,1.724-9.53,3.448-9.53,3.448l-6.457-18.797c0,0,4.586-1.66,9.172-3.32c2.293-0.83,4.586-1.66,6.308-2.281
        c1.7-0.678,2.836-1.129,2.836-1.129L1627.68,1261.542z"
            />
            <path
              fill="#FFFFFF"
              d="M1618.986,1254.143c0.877,2.326-0.328,4.904-2.69,5.761c-2.362,0.855-4.948-0.353-5.776-2.694
        c-0.828-2.344,0.375-4.922,2.689-5.76S1618.109,1251.817,1618.986,1254.143z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1646.414,1253.893c0,0-1.16,0.501-2.9,1.252c-0.869,0.375-1.886,0.813-2.974,1.282
        c-1.103,0.438-2.278,0.906-3.454,1.374c-4.703,1.87-9.406,3.741-9.406,3.741l-7.166-18.538c0,0,4.538-1.805,9.076-3.61
        c1.135-0.449,2.27-0.9,3.332-1.323c1.053-0.454,2.035-0.879,2.877-1.241c1.684-0.728,2.807-1.212,2.807-1.212L1646.414,1253.893z"
            />
            <path
              fill="#FFFFFF"
              d="M1637.482,1246.84c0.967,2.311-0.142,4.932-2.478,5.854c-2.333,0.928-4.966-0.193-5.882-2.505
        c-0.915-2.311,0.198-4.922,2.487-5.832C1633.902,1243.454,1636.531,1244.562,1637.482,1246.84z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1664.859,1245.658c0,0-4.582,2.113-9.163,4.227c-4.642,2.004-9.282,4.008-9.282,4.008l-7.811-18.275
        c0,0,4.49-1.938,8.979-3.876c4.449-2.053,8.897-4.104,8.897-4.104L1664.859,1245.658z"
            />
            <path
              fill="#FFFFFF"
              d="M1655.701,1238.863c1.029,2.254,0.031,4.961-2.256,5.985c-2.305,0.994-4.978-0.046-5.971-2.322
        c-0.994-2.278,0.033-4.916,2.297-5.895C1652.021,1235.624,1654.666,1236.596,1655.701,1238.863z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1683.021,1236.93c0,0-4.521,2.215-9.041,4.432c-2.248,1.126-4.541,2.182-6.26,2.973
        c-1.717,0.796-2.861,1.325-2.861,1.325l-8.377-18.022c0,0,1.11-0.515,2.778-1.287c1.669-0.768,3.895-1.791,6.093-2.893
        c4.41-2.162,8.819-4.323,8.819-4.323L1683.021,1236.93z"
            />
            <path
              fill="#FFFFFF"
              d="M1673.677,1230.355c1.095,2.231,0.158,4.935-2.09,6.035c-2.239,1.116-4.944,0.151-6.011-2.102
        c-1.061-2.239-0.107-4.903,2.104-6.006C1669.898,1227.197,1672.584,1228.124,1673.677,1230.355z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1700.93,1227.806c0,0-4.468,2.294-8.936,4.589c-2.235,1.146-4.471,2.291-6.146,3.149
        c-1.695,0.831-2.826,1.386-2.826,1.386l-8.848-17.797c0,0,1.104-0.541,2.756-1.351c1.644-0.842,3.834-1.965,6.023-3.087
        c4.379-2.249,8.758-4.498,8.758-4.498L1700.93,1227.806z"
            />
            <path
              fill="#FFFFFF"
              d="M1691.443,1221.42c1.144,2.207,0.267,4.922-1.959,6.063c-2.225,1.141-4.935,0.269-6.053-1.951
        c-1.119-2.22-0.242-4.936,1.959-6.063C1687.592,1218.339,1690.302,1219.213,1691.443,1221.42z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1718.576,1218.343c0,0-1.09,0.604-2.725,1.511c-1.655,0.885-3.864,2.064-6.072,3.244
        c-4.426,2.354-8.85,4.708-8.85,4.708l-9.22-17.607c0,0,4.354-2.316,8.711-4.634c2.179-1.163,4.354-2.326,5.987-3.199
        c1.628-0.902,2.713-1.504,2.713-1.504L1718.576,1218.343z"
            />
            <path
              fill="#FFFFFF"
              d="M1709.04,1212.146c1.179,2.188,0.349,4.912-1.856,6.086c-2.205,1.173-4.935,0.342-6.096-1.855
        c-1.16-2.197-0.329-4.922,1.857-6.085C1705.131,1209.129,1707.859,1209.96,1709.04,1212.146z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1736.003,1208.678 1718.576,1218.343 1709.122,1200.86 1726.484,1191.23   "
            />
            <path
              fill="#FFFFFF"
              d="M1726.447,1202.525c1.188,2.183,0.387,4.931-1.79,6.139c-2.179,1.207-4.902,0.415-6.087-1.771
        c-1.185-2.185-0.383-4.934,1.791-6.139C1722.534,1199.549,1725.259,1200.343,1726.447,1202.525z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1753.432,1199.013 1736.003,1208.678 1726.484,1191.23 1743.846,1181.602  "
            />
            <path
              fill="#FFFFFF"
              d="M1743.842,1192.879c1.197,2.178,0.402,4.923-1.775,6.13c-2.178,1.208-4.908,0.419-6.102-1.762
        c-1.191-2.181-0.396-4.926,1.776-6.131C1739.913,1189.911,1742.646,1190.7,1743.842,1192.879z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1770.857,1189.348 1753.432,1199.013 1743.846,1181.602 1761.209,1171.973   "
            />
            <path
              fill="#FFFFFF"
              d="M1761.236,1183.23c1.205,2.175,0.416,4.916-1.762,6.122c-2.178,1.208-4.914,0.423-6.115-1.754
        c-1.2-2.176-0.412-4.917,1.762-6.122C1757.294,1180.271,1760.031,1181.058,1761.236,1183.23z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1788.234,1179.833 1770.857,1189.348 1761.209,1171.973 1778.725,1162.382   "
            />
            <path
              fill="#FFFFFF"
              d="M1778.667,1173.649c1.194,2.18,0.397,4.911-1.779,6.101s-4.919,0.393-6.124-1.78
        c-1.205-2.174-0.408-4.905,1.779-6.102C1774.73,1170.673,1777.473,1171.47,1778.667,1173.649z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1805.629,1170.404c0,0-1.095,0.565-2.736,1.418c-1.629,0.89-3.801,2.076-5.972,3.264
        c-4.343,2.374-8.687,4.747-8.687,4.747l-9.512-17.451c0,0,4.385-2.396,8.77-4.792c2.191-1.198,4.385-2.396,6.027-3.295
        c1.67-0.865,2.782-1.442,2.782-1.442L1805.629,1170.404z"
            />
            <path
              fill="#FFFFFF"
              d="M1796.122,1164.109c1.173,2.189,0.358,4.932-1.817,6.121c-2.178,1.189-4.902,0.384-6.087-1.802
        s-0.371-4.926,1.817-6.122C1792.223,1161.112,1794.948,1161.918,1796.122,1164.109z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="1823.146,1161.34 1805.629,1170.404 1796.303,1152.854 1814.116,1143.635  "
            />
            <path
              fill="#FFFFFF"
              d="M1813.763,1154.919c1.138,2.21,0.277,4.924-1.922,6.062c-2.198,1.139-4.921,0.277-6.078-1.922
        c-1.157-2.198-0.297-4.912,1.923-6.061C1809.904,1151.85,1812.625,1152.709,1813.763,1154.919z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1840.741,1152.474c0,0-1.106,0.537-2.767,1.344c-1.652,0.821-3.886,1.845-6.07,2.989
        c-4.379,2.268-8.758,4.533-8.758,4.533l-9.031-17.705c0,0,4.455-2.305,8.908-4.607c2.221-1.163,4.51-2.215,6.195-3.053
        c1.695-0.823,2.826-1.373,2.826-1.373L1840.741,1152.474z"
            />
            <path
              fill="#FFFFFF"
              d="M1831.464,1145.846c1.104,2.244,0.17,4.878-2.022,6.027c-2.199,1.138-4.892,0.263-6.012-1.956
        c-1.121-2.219-0.229-4.947,1.989-6.096C1827.632,1142.662,1830.374,1143.631,1831.464,1145.846z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1858.52,1144.064c0,0-4.463,2.056-8.929,4.109c-4.425,2.149-8.85,4.299-8.85,4.299l-8.695-17.872
        c0,0,4.521-2.194,9.042-4.391c4.569-2.104,9.139-4.208,9.139-4.208L1858.52,1144.064z"
            />
            <path
              fill="#FFFFFF"
              d="M1849.396,1137.238c1.05,2.254,0.078,4.916-2.166,5.952c-2.225,1.079-4.899,0.14-5.977-2.102
        s-0.124-4.94,2.126-6.034C1845.652,1134.007,1848.348,1134.986,1849.396,1137.238z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1876.471,1136.081c0,0-4.506,1.951-9.011,3.902c-2.255,0.97-4.471,2.038-6.149,2.797
        c-1.675,0.771-2.791,1.284-2.791,1.284l-8.293-18.062c0,0,1.144-0.525,2.856-1.313c1.72-0.776,3.985-1.868,6.298-2.864
        c4.621-2.001,9.241-4.003,9.241-4.003L1876.471,1136.081z"
            />
            <path
              fill="#FFFFFF"
              d="M1867.521,1129.043c0.994,2.277-0.037,4.92-2.305,5.901c-2.27,0.977-4.902,0.021-5.933-2.257
        c-1.021-2.252-0.025-4.977,2.274-5.967C1863.857,1125.727,1866.527,1126.766,1867.521,1129.043z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1894.6,1128.562c0,0-4.548,1.839-9.095,3.679c-2.282,0.896-4.528,1.887-6.219,2.62
        c-1.688,0.73-2.815,1.22-2.815,1.22l-7.849-18.26c0,0,1.155-0.5,2.888-1.251c1.733-0.751,4.036-1.767,6.383-2.688
        c4.674-1.891,9.346-3.781,9.346-3.781L1894.6,1128.562z"
            />
            <path
              fill="#FFFFFF"
              d="M1885.845,1121.301c0.936,2.303-0.163,4.92-2.452,5.846c-2.297,0.908-4.916-0.145-5.883-2.43
        c-0.97-2.293,0.105-4.962,2.436-5.884C1882.27,1117.893,1884.909,1118.998,1885.845,1121.301z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1912.92,1121.562c0,0-4.594,1.714-9.188,3.429c-2.299,0.85-4.596,1.709-6.291,2.422
        c-1.705,0.689-2.842,1.149-2.842,1.149l-7.363-18.462c0,0,1.168-0.472,2.922-1.181c1.742-0.73,4.104-1.616,6.469-2.49
        c4.725-1.764,9.447-3.526,9.447-3.526L1912.92,1121.562z"
            />
            <path
              fill="#FFFFFF"
              d="M1904.372,1114.062c0.871,2.326-0.298,4.913-2.611,5.776c-2.312,0.866-4.925-0.319-5.825-2.621
        c-0.912-2.327,0.262-4.903,2.607-5.784C1900.891,1110.559,1903.5,1111.734,1904.372,1114.062z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1931.424,1115.07c0,0-4.642,1.58-9.281,3.16c-1.16,0.396-2.32,0.791-3.408,1.16
        c-1.077,0.401-2.082,0.776-2.942,1.099c-1.724,0.644-2.871,1.071-2.871,1.071l-6.846-18.658c0,0,1.182-0.44,2.953-1.103
        c0.886-0.33,1.92-0.716,3.026-1.129c1.118-0.381,2.312-0.787,3.506-1.194c4.772-1.626,9.547-3.252,9.547-3.252L1931.424,1115.07z"
            />
            <path
              fill="#FFFFFF"
              d="M1923.088,1107.329c0.807,2.352-0.436,4.901-2.771,5.698c-2.34,0.789-4.911-0.449-5.746-2.771
        c-0.848-2.354,0.39-4.92,2.765-5.721C1919.707,1103.727,1922.283,1104.979,1923.088,1107.329z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1950.102,1109.104c0,0-4.685,1.444-9.369,2.891c-2.351,0.696-4.67,1.488-6.407,2.089
        c-1.74,0.593-2.9,0.988-2.9,0.988l-6.317-18.845c0,0,1.193-0.406,2.983-1.017c1.787-0.617,4.175-1.431,6.593-2.147
        c4.817-1.486,9.638-2.975,9.638-2.975L1950.102,1109.104z"
            />
            <path
              fill="#FFFFFF"
              d="M1941.987,1101.126c0.737,2.373-0.575,4.886-2.936,5.614c-1.18,0.365-2.403,0.212-3.43-0.319
        c-1.021-0.513-1.845-1.42-2.231-2.601c-0.386-1.183-0.257-2.414,0.268-3.452c0.529-1.021,1.459-1.831,2.654-2.201
        C1938.708,1097.428,1941.248,1098.753,1941.987,1101.126z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1968.939,1103.667c0,0-4.726,1.308-9.448,2.615c-2.371,0.623-4.703,1.382-6.462,1.918
        c-1.758,0.542-2.928,0.902-2.928,0.902l-5.783-19.016c0,0,1.205-0.371,3.013-0.929c1.809-0.551,4.208-1.329,6.647-1.973
        c4.858-1.346,9.721-2.691,9.721-2.691L1968.939,1103.667z"
            />
            <path
              fill="#FFFFFF"
              d="M1961.054,1095.458c0.671,2.394-0.714,4.867-3.093,5.525c-2.386,0.635-4.864-0.682-5.572-3.066
        c-0.707-2.38,0.653-4.937,3.076-5.58C1957.88,1091.668,1960.383,1093.064,1961.054,1095.458z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M1987.934,1098.787c0,0-4.765,1.16-9.529,2.321c-2.393,0.544-4.733,1.271-6.512,1.74
        c-1.771,0.49-2.953,0.817-2.953,0.817l-5.239-19.172c0,0,1.215-0.336,3.036-0.841c1.828-0.481,4.24-1.229,6.699-1.79
        c4.896-1.194,9.795-2.389,9.795-2.389L1987.934,1098.787z"
            />
            <path
              fill="#FFFFFF"
              d="M1980.275,1090.345c0.604,2.411-0.853,4.839-3.25,5.423c-2.407,0.557-4.838-0.793-5.481-3.213
        c-0.635-2.384,0.788-4.937,3.229-5.501C1977.209,1086.461,1979.672,1087.934,1980.275,1090.345z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2007.055,1094.396c0,0-1.201,0.252-3.002,0.631c-1.795,0.399-4.214,0.833-6.59,1.437
        c-4.765,1.161-9.529,2.323-9.529,2.323l-4.703-19.312c0,0,4.898-1.192,9.797-2.387c2.443-0.619,4.925-1.063,6.77-1.476
        c1.85-0.39,3.081-0.648,3.081-0.648L2007.055,1094.396z"
            />
            <path
              fill="#FFFFFF"
              d="M1999.624,1085.725c0.542,2.449-0.999,4.756-3.394,5.363c-2.397,0.585-4.808-0.902-5.379-3.32
        c-0.573-2.418,0.937-4.858,3.371-5.452C1996.652,1081.699,1999.091,1083.32,1999.624,1085.725z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2026.292,1090.502c0,0-1.208,0.216-3.021,0.539c-1.819,0.289-4.209,0.849-6.613,1.338
        c-4.803,1.009-9.604,2.02-9.604,2.02l-4.178-19.433c0,0,4.933-1.036,9.862-2.071c2.468-0.503,4.924-1.074,6.789-1.373
        c1.858-0.332,3.099-0.554,3.099-0.554L2026.292,1090.502z"
            />
            <path
              fill="#FFFFFF"
              d="M2019.091,1081.634c0.474,2.44-1.103,4.83-3.521,5.338c-2.418,0.51-4.787-1.052-5.295-3.484
        c-0.508-2.434,1.068-4.822,3.521-5.338S2018.618,1079.194,2019.091,1081.634z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2045.632,1087.088c0,0-1.218,0.164-3.026,0.504c-1.812,0.323-4.229,0.755-6.646,1.188
        c-4.834,0.86-9.667,1.724-9.667,1.724l-3.665-19.534c0,0,4.957-0.884,9.915-1.769c2.479-0.442,4.957-0.884,6.815-1.216
        c1.856-0.348,3.104-0.517,3.104-0.517L2045.632,1087.088z"
            />
            <path
              fill="#FFFFFF"
              d="M2038.671,1078.121c0.411,2.451-1.228,4.79-3.66,5.224c-2.433,0.436-4.764-1.196-5.208-3.642
        c-0.443-2.445,1.195-4.784,3.66-5.225C2035.929,1074.039,2038.26,1075.67,2038.671,1078.121z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="2065.068,1084.206 2045.632,1087.088 2042.462,1067.468 2062.377,1064.515   "
            />
            <path
              fill="#FFFFFF"
              d="M2058.328,1075.082c0.351,2.46-1.348,4.749-3.791,5.111c-2.445,0.361-4.736-1.335-5.119-3.79
        c-0.383-2.456,1.314-4.744,3.792-5.112C2055.688,1070.924,2057.979,1072.621,2058.328,1075.082z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2084.587,1081.843c0,0-4.883,0.574-9.765,1.148c-2.441,0.286-4.883,0.573-6.715,0.788
        c-0.916,0.097-1.673,0.229-2.205,0.303c-0.531,0.079-0.834,0.124-0.834,0.124l-2.691-19.691c0,0,0.312-0.046,0.855-0.127
        c0.545-0.075,1.32-0.211,2.259-0.311c1.873-0.22,4.37-0.516,6.868-0.809c4.994-0.588,9.989-1.175,9.989-1.175L2084.587,1081.843z"
            />
            <path
              fill="#FFFFFF"
              d="M2078.055,1072.527c0.293,2.468-1.461,4.701-3.916,4.99c-2.455,0.288-4.708-1.476-5.031-3.938
        c-0.321-2.465,1.432-4.699,3.916-4.991C2075.509,1068.295,2077.762,1070.059,2078.055,1072.527z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2104.154,1079.845c0,0-4.901,0.425-9.803,0.85c-4.884,0.574-9.766,1.148-9.766,1.148l-2.236-19.748
        c0,0,4.993-0.587,9.988-1.174c5.008-0.435,10.014-0.869,10.014-0.869L2104.154,1079.845z"
            />
            <path
              fill="#FFFFFF"
              d="M2097.824,1070.341c0.238,2.474-1.567,4.651-4.031,4.866c-2.455,0.289-4.662-1.48-4.929-3.951
        s1.532-4.711,4.018-5.003C2095.374,1066.036,2097.587,1067.867,2097.824,1070.341z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2123.766,1078.209c0,0-0.308,0.018-0.845,0.049c-0.537,0.035-1.308,0.062-2.226,0.152
        c-1.838,0.159-4.288,0.372-6.738,0.585c-4.9,0.425-9.803,0.85-9.803,0.85l-1.803-19.793c0,0,5.008-0.435,10.016-0.868
        c2.503-0.217,5.006-0.434,6.884-0.596c0.938-0.092,1.723-0.12,2.271-0.156c0.55-0.031,0.862-0.05,0.862-0.05L2123.766,1078.209z"
            />
            <path
              fill="#FFFFFF"
              d="M2117.643,1068.623c0.186,2.479-1.662,4.661-4.127,4.875c-2.465,0.213-4.635-1.621-4.848-4.098
        s1.635-4.658,4.127-4.874C2115.288,1064.311,2117.458,1066.145,2117.643,1068.623z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="2143.426,1077.068 2123.766,1078.209 2122.384,1058.382 2142.438,1057.218   "
            />
            <path
              fill="#FFFFFF"
              d="M2137.495,1067.381c0.135,2.481-1.758,4.609-4.229,4.753c-2.47,0.145-4.603-1.751-4.765-4.23
        c-0.161-2.479,1.731-4.607,4.229-4.753C2135.227,1063.005,2137.359,1064.899,2137.495,1067.381z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2163.114,1076.307c0,0-4.925,0.155-9.85,0.312c-2.463,0.077-4.926,0.14-6.768,0.271
        c-1.845,0.107-3.072,0.18-3.072,0.18l-0.988-19.852c0,0,1.254-0.071,3.134-0.182c1.881-0.135,4.389-0.199,6.896-0.278
        c5.016-0.158,10.031-0.315,10.031-0.315L2163.114,1076.307z"
            />
            <path
              fill="#FFFFFF"
              d="M2157.364,1066.469c0.088,2.483-1.847,4.561-4.321,4.639c-2.475,0.077-4.57-1.872-4.682-4.354
        c-0.112-2.482,1.822-4.56,4.32-4.639C2155.181,1062.035,2157.277,1063.984,2157.364,1066.469z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2182.805,1075.823c0,0-1.229,0.014-3.074,0.035c-1.844,0.015-4.304,0.041-6.768,0.138
        c-4.924,0.155-9.85,0.311-9.85,0.311l-0.615-19.864c0,0,5.016-0.157,10.031-0.315c2.507-0.099,5.02-0.125,6.903-0.141
        c1.886-0.021,3.143-0.035,3.143-0.035L2182.805,1075.823z"
            />
            <path
              fill="#FFFFFF"
              d="M2177.247,1065.87c0.042,2.477-1.93,4.52-4.403,4.612c-2.475,0.078-4.533-1.873-4.601-4.357
        s1.905-4.562,4.403-4.641C2175.145,1061.392,2177.206,1063.377,2177.247,1065.87z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2202.472,1075.799c0,0-4.915-0.025-9.83-0.05c-2.456-0.031-4.917,0.026-6.762,0.04
        c-1.845,0.021-3.075,0.034-3.075,0.034l-0.229-19.873c0,0,1.256-0.016,3.141-0.036c1.885-0.013,4.396-0.071,6.912-0.04
        c5.03,0.024,10.062,0.051,10.062,0.051L2202.472,1075.799z"
            />
            <path
              fill="#FFFFFF"
              d="M2197.139,1065.757c-0.014,2.485-2.027,4.489-4.501,4.478c-2.472-0.027-4.489-1.995-4.507-4.485
        c-0.016-2.479,1.998-4.542,4.501-4.515C2195.135,1061.246,2197.152,1063.271,2197.139,1065.757z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2222.105,1076.262c0,0-1.227-0.051-3.063-0.127c-1.841-0.042-4.296-0.098-6.75-0.152
        c-2.454-0.056-4.909-0.112-6.75-0.154c-1.843-0.018-3.07-0.028-3.07-0.028l0.218-19.874c0,0,1.259,0.012,3.146,0.029
        c1.887,0.043,4.404,0.101,6.921,0.157c2.518,0.058,5.034,0.114,6.922,0.157c1.889,0.078,3.147,0.131,3.147,0.131L2222.105,1076.262
        z"
            />
            <path
              fill="#FFFFFF"
              d="M2217.028,1066.071c-0.075,2.483-2.138,4.453-4.608,4.396c-2.47-0.057-4.438-2.115-4.396-4.602
        c0.041-2.484,2.104-4.453,4.606-4.396C2215.134,1061.527,2217.104,1063.588,2217.028,1066.071z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2241.693,1077.251c0,0-1.223-0.077-3.059-0.191c-1.835-0.1-4.275-0.3-6.729-0.388
        c-4.901-0.205-9.803-0.41-9.803-0.41l0.72-19.861c0,0,5.034,0.211,10.069,0.422c2.519,0.092,5.035,0.296,6.925,0.397
        c1.888,0.118,3.146,0.196,3.146,0.196L2241.693,1077.251z"
            />
            <path
              fill="#FFFFFF"
              d="M2236.902,1066.917c-0.145,2.496-2.253,4.336-4.721,4.246c-2.469-0.104-4.384-2.199-4.277-4.683
        c0.105-2.484,2.221-4.411,4.725-4.307C2235.133,1062.266,2237.044,1064.45,2236.902,1066.917z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2261.225,1078.764c0,0-4.879-0.41-9.758-0.821c-1.221-0.104-2.438-0.206-3.584-0.304
        c-1.146-0.071-2.216-0.14-3.133-0.196c-1.834-0.114-3.057-0.19-3.057-0.19l1.271-19.835c0,0,1.259,0.079,3.146,0.197
        c0.942,0.06,2.045,0.129,3.226,0.204c1.18,0.099,2.438,0.205,3.694,0.311c5.031,0.426,10.062,0.851,10.062,0.851L2261.225,1078.764
        z"
            />
            <path
              fill="#FFFFFF"
              d="M2256.743,1068.336c-0.216,2.478-2.384,4.315-4.843,4.107c-2.459-0.211-4.311-2.383-4.136-4.85
        c0.177-2.491,2.345-4.338,4.845-4.123C2255.107,1063.683,2256.959,1065.86,2256.743,1068.336z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2280.664,1080.949c0,0.001-4.84-0.68-9.711-1.155c-2.432-0.262-4.863-0.524-6.688-0.721
        c-0.911-0.1-1.673-0.182-2.203-0.237c-0.533-0.046-0.84-0.072-0.84-0.072l1.871-19.786c0,0,0.313,0.026,0.864,0.073
        c0.55,0.061,1.334,0.146,2.276,0.245c1.885,0.203,4.396,0.474,6.908,0.744c5.028,0.494,10.039,1.194,10.039,1.194
        L2280.664,1080.949z"
            />
            <path
              fill="#FFFFFF"
              d="M2276.535,1070.322c-0.295,2.468-2.521,4.254-4.975,3.99c-2.453-0.265-4.233-2.482-3.981-4.955
        c0.253-2.474,2.479-4.26,4.975-3.99C2275.05,1065.636,2276.831,1067.854,2276.535,1070.322z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2300,1083.851c0,0-4.823-0.809-9.646-1.616c-4.845-0.643-9.688-1.284-9.688-1.284l2.521-19.714
        c0,0,5.015,0.665,10.028,1.33c4.985,0.832,9.972,1.666,9.972,1.666L2300,1083.851z"
            />
            <path
              fill="#FFFFFF"
              d="M2296.236,1073.064c-0.379,2.457-2.657,4.112-5.09,3.711c-2.443-0.323-4.152-2.582-3.817-5.045
        c0.335-2.464,2.624-4.191,5.112-3.861C2294.918,1068.278,2296.615,1070.609,2296.236,1073.064z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2319.262,1087.237c0,0-1.193-0.255-2.986-0.638c-1.802-0.347-4.219-0.72-6.629-1.133
        c-4.822-0.809-9.646-1.616-9.646-1.616l3.185-19.618c0,0,4.984,0.836,9.971,1.672c2.49,0.428,4.988,0.816,6.85,1.173
        c1.855,0.396,3.092,0.658,3.092,0.658L2319.262,1087.237z"
            />
            <path
              fill="#FFFFFF"
              d="M2315.855,1076.354c-0.461,2.442-2.805,4.093-5.236,3.686s-4.064-2.725-3.647-5.174
        c0.417-2.45,2.763-4.102,5.236-3.686C2314.684,1071.596,2316.316,1073.912,2315.855,1076.354z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="2338.387,1091.314 2319.262,1087.237 2323.096,1067.735 2342.878,1071.955   "
            />
            <path
              fill="#FFFFFF"
              d="M2335.327,1080.425c-0.543,2.425-2.938,3.976-5.349,3.46c-2.41-0.514-3.961-2.904-3.463-5.339
        c0.5-2.436,2.894-3.983,5.349-3.461C2334.318,1075.607,2335.869,1077.999,2335.327,1080.425z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2357.327,1096.137c0,0-4.729-1.232-9.456-2.467c-2.364-0.618-4.729-1.235-6.501-1.698
        c-0.888-0.229-1.642-0.359-2.161-0.48c-0.523-0.111-0.822-0.175-0.822-0.175l4.491-19.36c0,0,0.31,0.064,0.851,0.182
        c0.539,0.124,1.317,0.261,2.235,0.498c1.836,0.479,4.284,1.116,6.731,1.754c4.895,1.277,9.79,2.555,9.79,2.555L2357.327,1096.137z"
            />
            <path
              fill="#FFFFFF"
              d="M2354.66,1085.091c-0.625,2.405-3.065,3.851-5.45,3.229c-2.385-0.622-3.847-3.086-3.267-5.504
        c0.58-2.416,3.021-3.86,5.45-3.229C2353.824,1080.222,2355.286,1082.686,2354.66,1085.091z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2376.098,1101.549c0,0-4.663-1.451-9.326-2.902c-2.338-0.707-4.722-1.257-6.488-1.738
        c-1.772-0.462-2.955-0.771-2.955-0.771l5.158-19.193c0,0,1.224,0.318,3.06,0.799c1.83,0.5,4.295,1.072,6.718,1.804
        c4.833,1.502,9.666,3.003,9.666,3.003L2376.098,1101.549z"
            />
            <path
              fill="#FFFFFF"
              d="M2373.839,1090.358c-0.709,2.382-3.19,3.72-5.544,2.986c-2.357-0.713-3.771-3.092-3.102-5.507
        c0.658-2.375,3.185-3.868,5.588-3.143C2373.18,1085.441,2374.549,1087.977,2373.839,1090.358z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2394.688,1107.546c0,0-1.148-0.415-2.871-1.038c-1.705-0.671-4.065-1.311-6.391-2.061
        c-4.664-1.449-9.328-2.898-9.328-2.898l5.831-19.001c0,0,4.833,1.503,9.666,3.007c2.408,0.776,4.854,1.444,6.622,2.138
        c1.785,0.645,2.978,1.073,2.978,1.073L2394.688,1107.546z"
            />
            <path
              fill="#FFFFFF"
              d="M2392.836,1096.265c-0.794,2.354-3.345,3.673-5.698,2.94c-2.353-0.731-3.653-3.245-2.903-5.615
        c0.748-2.37,3.3-3.687,5.697-2.939C2392.329,1091.396,2393.63,1093.909,2392.836,1096.265z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2413.046,1114.207c0,0-0.279-0.125-0.779-0.311c-0.503-0.183-1.22-0.44-2.081-0.752
        c-1.723-0.621-4.019-1.45-6.314-2.279c-4.592-1.66-9.185-3.319-9.185-3.319l6.507-18.78c0,0,4.762,1.722,9.522,3.44
        c2.38,0.86,4.761,1.722,6.546,2.366c0.893,0.322,1.637,0.593,2.157,0.78c0.519,0.192,0.809,0.321,0.809,0.321L2413.046,1114.207z"
            />
            <path
              fill="#FFFFFF"
              d="M2411.556,1102.993c-0.878,2.325-3.469,3.531-5.786,2.694c-2.315-0.838-3.52-3.414-2.686-5.757
        c0.833-2.341,3.424-3.547,5.785-2.692C2411.232,1098.092,2412.436,1100.668,2411.556,1102.993z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="2431.104,1121.641 2413.046,1114.207 2420.229,1095.675 2438.956,1103.383   "
            />
            <path
              fill="#FFFFFF"
              d="M2430.027,1110.369c-0.962,2.291-3.59,3.389-5.867,2.451c-2.277-0.938-3.381-3.57-2.463-5.88
        c0.918-2.311,3.545-3.407,5.867-2.451C2429.888,1105.445,2430.99,1108.077,2430.027,1110.369z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2448.875,1129.726c0,0-4.428-2.055-8.855-4.109c-2.217-1.021-4.422-2.068-6.094-2.814
        c-1.691-0.694-2.82-1.16-2.82-1.16l7.852-18.258c0,0,1.171,0.482,2.926,1.205c1.734,0.772,4.023,1.856,6.322,2.916
        c4.594,2.132,9.188,4.264,9.188,4.264L2448.875,1129.726z"
            />
            <path
              fill="#FFFFFF"
              d="M2448.229,1118.385c-1.045,2.255-3.703,3.242-5.938,2.205s-3.234-3.722-2.232-5.997
        c1.002-2.273,3.66-3.262,5.938-2.204C2448.275,1113.445,2449.275,1116.131,2448.229,1118.385z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2466.346,1138.43c0,0-4.331-2.25-8.662-4.5c-2.144-1.169-4.386-2.139-6.041-2.92
        c-1.66-0.771-2.768-1.284-2.768-1.284l8.516-17.958c0,0,1.148,0.533,2.871,1.333c1.717,0.812,4.041,1.821,6.269,3.031
        c4.495,2.333,8.989,4.667,8.989,4.667L2466.346,1138.43z"
            />
            <path
              fill="#FFFFFF"
              d="M2466.139,1127.037c-1.129,2.215-3.813,3.089-6,1.954c-2.169-1.17-3.123-3.78-2.035-6.023
        c1.08-2.229,3.827-3.229,6.041-2.036C2466.373,1122.089,2467.268,1124.822,2466.139,1127.037z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2483.503,1147.735c0,0-1.058-0.609-2.644-1.522c-0.793-0.456-1.72-0.988-2.711-1.56
        c-0.986-0.578-2.047-1.184-3.14-1.728c-4.332-2.249-8.664-4.497-8.664-4.497l9.175-17.631c0,0,4.493,2.334,8.987,4.67
        c1.134,0.565,2.234,1.192,3.259,1.792c1.029,0.591,1.989,1.145,2.812,1.617c1.646,0.946,2.744,1.578,2.744,1.578L2483.503,1147.735
        z"
            />
            <path
              fill="#FFFFFF"
              d="M2483.734,1136.312c-0.604,1.088-1.586,1.819-2.683,2.129c-1.091,0.3-2.305,0.192-3.407-0.359
        c-2.187-1.135-3.013-3.834-1.847-6.028s3.918-3.036,6.148-1.877c1.121,0.562,1.868,1.562,2.181,2.693
        C2484.445,1133.992,2484.337,1135.229,2483.734,1136.312z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2500.3,1157.678c0,0-1.03-0.651-2.577-1.631c-0.777-0.482-1.67-1.072-2.65-1.658
        c-0.992-0.569-2.05-1.179-3.107-1.785c-4.229-2.435-8.461-4.867-8.461-4.867l9.82-17.279c0,0,4.391,2.526,8.78,5.052
        c1.097,0.632,2.194,1.265,3.223,1.856c1.021,0.607,1.945,1.218,2.751,1.72c1.606,1.014,2.678,1.688,2.678,1.688L2500.3,1157.678z"
            />
            <path
              fill="#FFFFFF"
              d="M2500.967,1146.252c-1.274,2.102-4.055,2.836-6.188,1.605c-2.136-1.228-2.855-3.966-1.608-6.115
        c1.247-2.15,4.022-2.877,6.2-1.624C2501.546,1141.372,2502.27,1144.104,2500.967,1146.252z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2516.727,1168.227c0,0-1.004-0.693-2.51-1.734c-1.527-1.009-3.604-2.289-5.664-3.6
        c-4.127-2.606-8.253-5.215-8.253-5.215l10.454-16.903c0,0,4.281,2.704,8.562,5.409c2.136,1.36,4.291,2.691,5.875,3.737
        c1.562,1.079,2.604,1.799,2.604,1.799L2516.727,1168.227z"
            />
            <path
              fill="#FFFFFF"
              d="M2517.784,1156.879c-1.366,2.076-4.162,2.692-6.245,1.377c-2.082-1.314-2.695-4.087-1.37-6.188
        c1.325-2.103,4.121-2.72,6.245-1.377C2518.537,1152.032,2519.15,1154.803,2517.784,1156.879z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2532.76,1179.366c0,0-0.962-0.751-2.48-1.773c-1.506-1.04-3.514-2.428-5.522-3.814
        c-4.015-2.775-8.03-5.552-8.03-5.552l11.07-16.507c0,0,4.166,2.88,8.333,5.759c2.082,1.439,4.165,2.88,5.728,3.96
        c1.574,1.062,2.574,1.841,2.574,1.841L2532.76,1179.366z"
            />
            <path
              fill="#FFFFFF"
              d="M2534.191,1168.125c-1.441,2.024-4.254,2.53-6.279,1.13c-2.026-1.399-2.533-4.199-1.131-6.252
        c1.401-2.052,4.214-2.558,6.279-1.13C2535.128,1163.302,2535.634,1166.101,2534.191,1168.125z"
            />
          </g>
          <g>
            <polygon
              fill="#008F9E"
              points="2548.354,1191.119 2532.76,1179.366 2544.43,1163.278 2560.605,1175.471   "
            />
            <path
              fill="#FFFFFF"
              d="M2550.181,1179.958c-1.516,1.97-4.339,2.364-6.306,0.882s-2.364-4.306-0.889-6.305
        c1.477-1.999,4.3-2.396,6.306-0.882C2551.299,1175.165,2551.696,1177.988,2550.181,1179.958z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2563.498,1203.446c0,0-3.78-3.091-7.561-6.182c-1.89-1.546-3.777-3.092-5.195-4.251
        c-1.403-1.176-2.391-1.896-2.391-1.896l12.254-15.648c0,0,1.021,0.747,2.479,1.966c1.471,1.201,3.431,2.805,5.391,4.406
        c3.92,3.206,7.838,6.411,7.838,6.411L2563.498,1203.446z"
            />
            <path
              fill="#FFFFFF"
              d="M2565.742,1192.346c-1.586,1.913-4.418,2.199-6.325,0.642c-1.907-1.562-2.197-4.4-0.647-6.345
        c1.549-1.943,4.381-2.229,6.324-0.641C2567.038,1187.591,2567.328,1190.432,2565.742,1192.346z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2578.188,1216.316c0,0-3.66-3.232-7.32-6.465c-1.829-1.615-3.659-3.232-5.031-4.444
        c-0.678-0.616-1.273-1.093-1.688-1.431s-0.649-0.53-0.649-0.53l12.813-15.192c0,0,0.246,0.201,0.675,0.552s1.046,0.846,1.75,1.482
        c1.423,1.257,3.319,2.932,5.218,4.607c3.794,3.352,7.589,6.702,7.589,6.702L2578.188,1216.316z"
            />
            <path
              fill="#FFFFFF"
              d="M2580.838,1205.297c-1.655,1.854-4.493,2.036-6.34,0.405c-1.848-1.631-2.031-4.482-0.412-6.368
        c1.617-1.887,4.456-2.068,6.339-0.405S2582.492,1203.442,2580.838,1205.297z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2592.403,1229.708c0,0-3.536-3.368-7.071-6.735c-1.768-1.685-3.535-3.369-4.861-4.633
        c-1.37-1.214-2.283-2.022-2.283-2.022l13.355-14.719c0,0,0.947,0.839,2.367,2.1c1.375,1.309,3.208,3.055,5.041,4.8
        c3.665,3.491,7.33,6.983,7.33,6.983L2592.403,1229.708z"
            />
            <path
              fill="#FFFFFF"
              d="M2595.456,1218.785c-1.721,1.793-4.562,1.87-6.345,0.17c-1.784-1.699-1.863-4.558-0.178-6.384
        c1.685-1.826,4.524-1.903,6.345-0.171C2597.097,1214.133,2597.176,1216.991,2595.456,1218.785z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2606.133,1243.602c0,0-3.406-3.498-6.813-6.996c-1.713-1.74-3.388-3.521-4.704-4.792
        c-1.326-1.263-2.211-2.104-2.211-2.104l13.878-14.228c0,0,0.916,0.873,2.29,2.183c1.363,1.318,3.102,3.16,4.877,4.964
        c3.531,3.627,7.062,7.253,7.062,7.253L2606.133,1243.602z"
            />
            <path
              fill="#FFFFFF"
              d="M2609.581,1232.788c-1.784,1.73-4.623,1.703-6.342-0.062c-1.72-1.766-1.693-4.627,0.057-6.392
        s4.589-1.737,6.342,0.062C2611.39,1228.195,2611.364,1231.058,2609.581,1232.788z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2619.376,1257.963c0,0-3.28-3.619-6.562-7.238c-1.652-1.797-3.244-3.651-4.552-4.937
        c-1.278-1.312-2.131-2.188-2.131-2.188l14.378-13.722c0,0,0.883,0.906,2.207,2.267c1.354,1.333,3.004,3.253,4.718,5.114
        c3.398,3.75,6.798,7.5,6.798,7.5L2619.376,1257.963z"
            />
            <path
              fill="#FFFFFF"
              d="M2623.207,1247.277c-1.844,1.666-4.68,1.537-6.335-0.289c-1.654-1.825-1.526-4.686,0.286-6.387
        c1.812-1.699,4.647-1.57,6.334,0.289C2625.18,1242.752,2625.051,1245.611,2623.207,1247.277z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2632.096,1272.79c0,0-3.154-3.729-6.308-7.46c-1.589-1.854-3.126-3.754-4.362-5.105
        c-1.23-1.356-2.05-2.262-2.05-2.262l14.855-13.202c0,0,0.851,0.938,2.124,2.346c1.279,1.401,2.875,3.366,4.521,5.288
        c3.268,3.865,6.535,7.73,6.535,7.73L2632.096,1272.79z"
            />
            <path
              fill="#FFFFFF"
              d="M2636.3,1262.252c-1.901,1.6-4.732,1.371-6.325-0.511c-1.59-1.882-1.362-4.733,0.51-6.368
        c1.871-1.636,4.703-1.407,6.324,0.51C2638.43,1257.801,2638.201,1260.652,2636.3,1262.252z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2644.28,1288.061c0,0-3.023-3.837-6.048-7.673c-1.517-1.913-3.012-3.846-4.166-5.267
        c-1.184-1.398-1.972-2.331-1.972-2.331l15.316-12.665c0,0,0.815,0.966,2.041,2.416c1.196,1.472,2.748,3.472,4.319,5.455
        c3.133,3.976,6.266,7.951,6.266,7.951L2644.28,1288.061z"
            />
            <path
              fill="#FFFFFF"
              d="M2648.852,1277.682c-1.957,1.53-4.78,1.203-6.306-0.731c-1.524-1.937-1.198-4.775,0.73-6.345
        c1.928-1.566,4.751-1.239,6.306,0.732C2651.137,1273.311,2650.811,1276.15,2648.852,1277.682z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2655.915,1303.753c0,0-2.89-3.938-5.779-7.876c-1.444-1.97-2.888-3.938-3.972-5.416
        c-1.13-1.439-1.884-2.4-1.884-2.4l15.758-12.112c0,0,0.78,0.995,1.951,2.489c1.123,1.529,2.62,3.569,4.116,5.609
        c2.993,4.081,5.987,8.162,5.987,8.162L2655.915,1303.753z"
            />
            <path
              fill="#FFFFFF"
              d="M2660.848,1293.547c-2.011,1.461-4.822,1.034-6.279-0.953c-1.457-1.985-1.03-4.812,0.951-6.31
        c1.982-1.499,4.795-1.072,6.28,0.953C2663.285,1289.262,2662.859,1292.087,2660.848,1293.547z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2667.001,1319.835c0,0-2.756-4.031-5.513-8.062c-1.378-2.017-2.756-4.031-3.79-5.543
        c-0.507-0.764-0.976-1.367-1.287-1.801c-0.315-0.431-0.496-0.677-0.496-0.677l16.179-11.544c0,0,0.188,0.255,0.514,0.701
        c0.324,0.449,0.809,1.075,1.334,1.866c1.072,1.565,2.501,3.654,3.932,5.743c2.854,4.18,5.711,8.358,5.711,8.358L2667.001,1319.835z
        "
            />
            <path
              fill="#FFFFFF"
              d="M2672.287,1309.819c-2.062,1.388-4.861,0.863-6.252-1.171c-1.393-2.032-0.869-4.838,1.166-6.265
        s4.834-0.903,6.252,1.17C2674.872,1305.627,2674.35,1308.433,2672.287,1309.819z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2677.438,1336.337c0,0-0.605-1.06-1.571-2.614c-0.985-1.543-2.3-3.6-3.612-5.656
        c-2.626-4.115-5.252-8.23-5.252-8.23l16.582-10.956c0,0,2.727,4.269,5.45,8.535c1.36,2.135,2.724,4.271,3.743,5.87
        c1.004,1.612,1.634,2.712,1.634,2.712L2677.438,1336.337z"
            />
            <path
              fill="#FFFFFF"
              d="M2683.132,1326.495c-2.11,1.312-4.896,0.69-6.223-1.385c-1.325-2.077-0.709-4.856,1.376-6.209
        c2.085-1.354,4.871-0.732,6.223,1.386C2685.859,1322.404,2685.243,1325.184,2683.132,1326.495z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2687.257,1353.201c0,0-0.587-1.069-1.467-2.673c-0.849-1.62-2.14-3.693-3.373-5.798
        c-2.489-4.197-4.979-8.395-4.979-8.395l16.974-10.341c0,0,2.585,4.356,5.17,8.713c1.28,2.186,2.616,4.337,3.502,6.021
        c0.915,1.666,1.524,2.775,1.524,2.775L2687.257,1353.201z"
            />
            <path
              fill="#FFFFFF"
              d="M2693.364,1343.554c-2.158,1.231-4.929,0.515-6.186-1.604s-0.546-4.868,1.588-6.144
        c2.134-1.274,4.902-0.557,6.186,1.604C2696.234,1339.572,2695.523,1342.322,2693.364,1343.554z"
            />
          </g>
          <g>
            <path
              fill="#008F9E"
              d="M2699.38,1370.878c0,0-0.834-0.922-2.084-2.305c-0.654-0.722-1.416-1.564-2.233-2.468
        c-0.392-0.433-0.788-0.871-1.19-1.315c-0.225-0.194-0.336-0.524-0.504-0.794l-0.467-0.851c-0.359-0.654-0.726-1.319-1.091-1.984
        c-2.349-4.274-4.697-8.55-4.697-8.55l17.352-9.695c0,0,2.438,4.441,4.879,8.883c0.231,0.427,0.466,0.851,0.694,1.27l-0.047-0.085
        c0.747,0.825,1.444,1.596,2.042,2.257c1.251,1.383,2.085,2.305,2.085,2.305L2699.38,1370.878z"
            />
            <path
              fill="#FFFFFF"
              d="M2703.156,1360.123c-0.461,0.417-0.983,0.721-1.536,0.913c-0.28,0.092-0.55,0.176-0.854,0.194
        c-0.313,0.01-0.644-0.021-0.979-0.093c-0.326-0.082-0.718-0.154-1.015-0.357c-0.297-0.204-0.593-0.453-0.881-0.741
        c-0.315-0.34-0.504-0.496-0.7-0.729c-0.191-0.228-0.365-0.477-0.514-0.746c-1.186-2.158-0.38-4.874,1.801-6.067
        c2.182-1.193,4.93-0.375,6.139,1.828c0.15,0.274,0.271,0.558,0.359,0.846c0.086,0.28,0.172,0.621,0.111,0.748
        c-0.07,0.359-0.212,0.791-0.321,1.241c-0.066,0.229-0.149,0.47-0.248,0.718c-0.09,0.258-0.171,0.549-0.299,0.81
        C2703.972,1359.216,2703.617,1359.707,2703.156,1360.123z"
            />
          </g>
        </svg>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
/* global $ */
import tokenGenerator from '@/tokenGenerator.js'
import Footer from '@/components/Footer.vue'
export default {
  name: 'home',
  components: {
    Footer
  },
  data () {
    return {
      articles: [],
      videos: [],
      searchText: '',
      year: '',
      month: '',
      day: ''
    }
  },
  computed: {
    bannerVideo () {
      return this.videos[0]?.video || ''
    }
  },
  created () {
    const vm = this
    vm.year = new Date().getFullYear()
    switch (new Date().getMonth() + 1) {
      case 1:
        vm.month = 'Jan'
        break
      case 2:
        vm.month = 'Feb'
        break
      case 3:
        vm.month = 'Mar'
        break
      case 4:
        vm.month = 'Apr'
        break
      case 5:
        vm.month = 'May'
        break
      case 6:
        vm.month = 'June'
        break
      case 7:
        vm.month = 'July'
        break
      case 8:
        vm.month = 'Aug'
        break
      case 9:
        vm.month = 'Sep'
        break
      case 10:
        vm.month = 'Oct'
        break
      case 11:
        vm.month = 'Nov'
        break
      case 12:
        vm.month = 'Dec'
        break
    }
    vm.day = new Date().getDate()
    console.log(vm.year, vm.month, vm.day)
  },
  mounted () {
    $(window).on('load', function () {
      var headerArea = $('.header')
      var mainArea = headerArea.find('.navbar')
      var zero = 0
      var navbarSticky = $('.navbar-sticky')

      $(window).scroll(function () {
        var st = $(this).scrollTop()
        if (st > zero) {
          navbarSticky.addClass('navbar-scrollUp')
        } else {
          navbarSticky.removeClass('navbar-scrollUp')
        }
        zero = st

        if (mainArea.hasClass('navbar-sticky') && ($(this).scrollTop() <= 600 || $(this).width() <= 300)) {
          mainArea.removeClass('navbar-scrollUp')
          mainArea.removeClass('navbar-sticky').appendTo(headerArea)
          headerArea.css('height', 'auto')
        } else if (!mainArea.hasClass('navbar-sticky') && $(this).width() > 300 && $(this).scrollTop() > 600) {
          headerArea.css('height', headerArea.height())
          mainArea.addClass('navbar-scrollUp')
          mainArea.css({ opacity: '0' }).addClass('navbar-sticky')
          mainArea.appendTo($('body')).animate({ opacity: 1 })
        }
      })

      $(window).trigger('resize')
      $(window).trigger('scroll')
    })
    require('@/assets/js/main.js')
    Promise.all([this.getHotArticle(), this.getVideo()])
      .then(() => {})
  },
  methods: {
    getHotArticle () {
      return new Promise((resolve, reject) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

        vm.axios.post(api, {
          token: tokenGenerator(),
          type: ['1'],
          categoryId: ['11', '12', '13', '14', '15', '16', '17', '18'],
          pageSize: 5,
          page: 1
        })
          .then((res) => {
            vm.articles = res.data.content
            vm.articles.forEach((item, index) => {
              const i = index + 1
              if (i % 4 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 4 === 2) {
                return (item.themeClass = 'success')
              } else if (i % 4 === 3) {
                return (item.themeClass = 'danger')
              } else {
                return (item.themeClass = 'info')
              }
            })
            vm.$nextTick(() => {
              $('.team-slider').owlCarousel({
                loop: true,
                margin: 30,
                dots: false,
                nav: true,
                navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa fa-arrow-right"></i>'],
                responsive: {
                  0: {
                    items: 1
                  },
                  600: {
                    items: 3
                  },
                  1000: {
                    items: 4
                  }
                }
              })
              resolve()
            })
          })
      })
    },
    getVideo () {
      return new Promise((resolve, reject) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

        vm.axios.post(api, {
          token: tokenGenerator(),
          type: ['2', '3'],
          // type: ['1'],
          categoryId: ['11', '12', '13', '14', '15', '16', '17', '18'],
          pageSize: 5,
          page: 1
        })
          .then((res) => {
            vm.videos = res.data.content
            console.log(vm.videos)
            vm.$nextTick(() => {
              $('.vid-slider').owlCarousel({
                loop: true,
                margin: 30,
                dots: false,
                nav: true,
                navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa fa-arrow-right"></i>'],
                responsive: {
                  0: {
                    items: 3
                  },
                  600: {
                    items: 3
                  },
                  1000: {
                    items: 5
                  }
                }
              })
            })
            resolve()
          })
      })
    },
    checkGradeIsAvaliable (e, grade, link) {
      /**
       * 點擊任一連結時打 check api，如果未登入就導向登入頁
       * 如果已登入，確認是否可玩該區塊遊戲
       * 如果可以就 push 進去
       * 如果不行就維持在此頁
       */
      if (this.$route.fullPath.includes(link)) return null
      const memberToken = localStorage.getItem('memberToken')
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      if (!memberToken) {
        alert('尚未登入，請先登入之後才能開始遊戲~')
        vm.$router.push('/login')
        return null
      }
      vm.axios.post(api, {
        memberToken,
        token: tokenGenerator()
      }).then(res => {
        const userInfo = res.data.content
        if (userInfo.grade < grade) {
          alert('年級不符，請先玩其他年級的遊戲喔')
        } else {
          vm.$router.push(link)
        }
      })
    },
    search () {
      const vm = this

      if (vm.searchText === '') {
        return false
      }

      vm.$router.push(`/search?q=${vm.searchText}`)
    },
    handleMemberLink () {
      const memberToken = localStorage.getItem('memberToken')
      if (memberToken) {
        if (this.$route.fullPath !== '/member') {
          this.$router.push('/member')
        }
      } else {
        this.$router.push('/login')
      }
    },
    callCourseAnchor (query, goingPath, coveredByNav) {
      console.log({ query, goingPath, coveredByNav })
      // 處理非 /course 頁點擊連結時
      if (this.$route.path !== '/course') {
        this.$router.push(goingPath)
        return null
      }
      const paths = ['offline', 'online']
      // 當目前完整連結等於要去的完整連結
      if (this.$route.fullPath === goingPath) {
        // 處理相同頁面狀態下的捲動
        const target = document.querySelector(`#${this.$route.query.anchor}`)
        if (target) {
          const top = target.getBoundingClientRect().top
          const navbarHeight = coveredByNav ? document.querySelector('nav.navbar').getBoundingClientRect().height : 0
          window.scrollTo(0, top - navbarHeight)
        }
      // 當目前連結相同，但 ?anchor 的值不同時
      } else if (this.$route.fullPath === '/course' || paths.includes(query.anchor)) {
        // 這一行只有做改變錨點，下面的事情都一樣
        this.$router.push(goingPath).then(() => {
          const target = document.querySelector(`#${this.$route.query.anchor}`)
          if (target) {
            const top = target.getBoundingClientRect().top
            const navbarHeight = coveredByNav ? document.querySelector('nav.navbar').getBoundingClientRect().height : 0
            window.scrollTo(0, top - navbarHeight)
          }
        })
      }
    },
    callLearningSheetAnchor (query, goingPath) {
      console.log({ query, goingPath })
      // 處理非 /learning-sheet 頁點擊連結時
      if (this.$route.path !== '/learning-sheet') {
        this.$router.push(goingPath)
        return null
      }
      const paths = ['levelA', 'levelB', 'levelC', 'levelD', 'levelE']
      // 當目前完整連結等於要去的完整連結
      if (this.$route.fullPath === goingPath) {
        // 處理相同頁面狀態下的捲動
        const target = document.querySelector(`#${this.$route.query.anchor}`)
        if (target) {
          const top = target.getBoundingClientRect().top
          const navbarHeight = document.querySelector('nav.navbar').getBoundingClientRect().height
          window.scrollTo(0, top - navbarHeight)
        }
      // 當目前連結相同，但 ?anchor 的值不同時
      } else if (this.$route.fullPath === '/learning-sheet' || paths.includes(query.anchor)) {
        // 這一行只有做改變錨點，下面的事情都一樣
        this.$router.push(goingPath).then(() => {
          const target = document.querySelector(`#${this.$route.query.anchor}`)
          if (target) {
            const top = target.getBoundingClientRect().top
            const navbarHeight = document.querySelector('nav.navbar').getBoundingClientRect().height
            window.scrollTo(0, top - navbarHeight)
          }
        })
      }
    },
    openDropDown (id) {
      console.log({ id })
      $(id).dropdown('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/style.css";
@import "../assets/css/kidz.css";
@import "../assets/css/index.css";

.card-img-top {
  height: 225px;
  object-fit: cover;
}

</style>
