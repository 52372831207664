import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('@/views/Root.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: '/fqgame/fail',
        name: 'fqgame-fail',
        component: () => import('@/views/fqgame/FQGameFail.vue')
      },
      {
        path: '/fqgame/success',
        name: 'fqgame-success',
        component: () => import('@/views/fqgame/FQGameSuccess.vue')
      },
      {
        path: '/fqgame/connection-lost',
        name: 'fqgame-connection-lost',
        component: () => import('@/views/fqgame/FQGameConnectionLost.vue')
      },
      {
        path: '*',
        name: 'Base',
        component: () => import('../views/Base.vue'),
        children: [
          {
            path: '/search',
            name: 'search',
            component: () => import('../views/Search.vue')
          },
          {
            path: '/about',
            name: 'About',
            component: () => import('../views/About.vue')
          },
          {
            path: '/course',
            name: 'Course',
            component: () => import('../views/Course.vue')
          },
          {
            path: '/learning-sheet',
            component: () => import('../views/learning-sheet/Index.vue'),
            children: [
              {
                path: '',
                name: 'learning-sheet',
                component: () => import('../views/learning-sheet/LearningSheet.vue')
              },
              {
                path: 'download/:id',
                name: 'download',
                component: () => import('../views/learning-sheet/Download.vue')
              }
            ]
          },
          {
            path: '/faq',
            name: 'faq',
            component: () => import('@/views/faq/Index.vue'),
            children: [
              {
                path: '',
                component: () => import('../views/faq/Register.vue')
              },
              {
                path: 'register',
                name: 'register',
                component: () => import('../views/faq/Register.vue')
              },
              {
                path: 'game-rule',
                name: 'game-rule',
                component: () => import('../views/faq/GameRule.vue')
              },
              {
                path: 'point-exchange',
                name: 'point-exchange',
                component: () => import('../views/faq/PointExchange.vue')
              }
            ]
          },
          {
            path: '/forgot',
            name: 'forgot',
            component: () => import('../views/Forgot.vue')
          },
          {
            path: '/reset',
            name: 'reset',
            component: () => import('../views/Reset.vue')
          },
          {
            path: '/rank',
            name: 'rank',
            component: () => import('../views/Rank.vue')
          },
          {
            path: '/signup',
            name: 'signup',
            component: () => import('../views/SignUp.vue')
          },
          {
            path: '/login',
            name: 'login',
            component: () => import('../views/Login.vue')
          },
          {
            path: '/fqtest',
            name: 'fqtest',
            component: () => import('../views/Fqtest.vue')
          },
          {
            path: '/privacy',
            name: 'privacy',
            component: () => import('../views/Privacy.vue')
          },
          {
            path: '/activity',
            name: 'activity',
            component: () => import('@/views/activity/Index.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/activity/Activity.vue')
              },
              {
                path: 'camp',
                name: 'camp',
                component: () => import('@/views/activity/Camp.vue')
              },
              {
                path: 'course',
                name: 'course',
                component: () => import('@/views/activity/Course.vue')
              },
              {
                path: 'lecture',
                name: 'lecture',
                component: () => import('@/views/activity/Lecture.vue')
              },
              {
                path: 'welfare',
                name: 'welfare',
                component: () => import('@/views/activity/Welfare.vue')
              }
            ]
          },
          {
            path: '/record/:id',
            name: 'record',
            component: () => import('@/views/activity/Record.vue')
          },
          {
            path: '/article/:id',
            name: 'article',
            component: () => import('@/views/topic/Article.vue')
          },
          {
            path: '/video/:id',
            name: 'video',
            component: () => import('@/views/topic/Video.vue')
          },
          {
            path: '/topic',
            name: 'topic',
            component: () => import('@/views/topic/Index.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/topic/Topic.vue')
              },
              {
                path: 'management',
                name: 'management',
                component: () => import('@/views/topic/Management.vue')
              },
              {
                path: 'track-expenses',
                name: 'track-expenses',
                component: () => import('@/views/topic/TrackExpenses.vue')
              },
              {
                path: 'pocket-money',
                name: 'pocket-money',
                component: () => import('@/views/topic/PocketMoney.vue')
              },
              {
                path: 'currency',
                name: 'currency',
                component: () => import('@/views/topic/Currency.vue')
              },
              {
                path: 'investment',
                name: 'investment',
                component: () => import('@/views/topic/Investment.vue')
              },
              {
                path: 'balance-sheet',
                name: 'balance-sheet',
                component: () => import('@/views/topic/BalanceSheet.vue')
              },
              {
                path: 'board-game',
                name: 'board-game',
                component: () => import('@/views/topic/BoardGame.vue')
              },
              {
                path: 'column',
                name: 'column',
                component: () => import('@/views/topic/Column.vue')
              },
              {
                path: 'majashow',
                name: 'majashow',
                component: () => import('@/views/topic/Majashow.vue')
              }
            ]
          },
          {
            path: '/member',
            name: 'member',
            component: () => import('@/views/member/Index.vue'),
            children: [
              {
                path: '',
                component: () => import('@/views/member/Member.vue')
              },
              {
                path: 'myfqage',
                component: () => import('@/views/member/Myfqage.vue')
              },
              {
                path: 'mypoints',
                component: () => import('@/views/member/MyPoints.vue')
              },
              {
                path: 'mall',
                component: () => import('@/views/member/Mall.vue')
              },
              {
                path: 'exchange',
                component: () => import('@/views/member/Exchange.vue')
              }
            ]
          },
          {
            path: '/fqgame',
            component: () => import('@/views/fqgame/Index.vue'),
            children: [
              {
                path: '',
                name: 'fqgame',
                component: () => import('@/views/fqgame/FQGame.vue')
              },
              {
                path: ':grade',
                name: 'fqgame-board',
                component: () => import('@/views/fqgame/FQGameBoard.vue')
              }
            ]
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, saveTop) {
    if (saveTop) {
      return saveTop
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
