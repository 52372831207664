import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAnalytics from 'vue-analytics'
import router from './router'
import $ from 'jquery'

import './plugins/vue-social-sharing'

Vue.config.productionTip = false

window.$ = $
window.jQuery = $

Vue.use(VueAxios, axios)
Vue.use(VueAnalytics, {
  // 讀取 GA id
  id: process.env.VUE_APP_GA_ID,
  router,
  debug: {
    enabled: true,
    trace: true,
    sendHitTask: true
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
