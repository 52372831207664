const libs = ['/assets/plugins/bootstrap/js/bootstrap.bundle.min.js', '/assets/plugins/owl-carousel/owl.carousel.min.js', '/assets/plugins/fancybox/jquery.fancybox.min.js', '/assets/plugins/isotope/isotope.min.js', '/assets/plugins/syotimer/jquery.syotimer.min.js', '/assets/plugins/select2/js/select2.min.js', '/assets/plugins/no-ui-slider/nouislider.min.js', '/assets/plugins/lazyestload/lazyestload.js', '/assets/plugins/velocity/velocity.min.js', '/assets/plugins/images-loaded/js/imagesloaded.pkgd.min.js', '/assets/plugins/revolution/js/jquery.themepunch.tools.min.js', '/assets/plugins/revolution/js/jquery.themepunch.revolution.min.js', '/assets/plugins/wow/wow.min.js', '/assets/js/index.js', '/assets/js/run_transform.js', '/assets/js/run.js', '/assets/js/tween.min_1.js', '/assets/js/car.js']

addScript(libs, 0)

const kidz = ['/assets/js/kidz.js']
setTimeout(() => {
  addScript(kidz, 0)
}, 500)

function addScript (ary, i) {
  if (i === ary.length) return null
  const src = ary[i]
  const script = document.createElement('script')
  script.setAttribute('src', src)
  script.setAttribute('type', 'text/javascript')
  document.head.appendChild(script)
  i += 1
  script.onload = addScript(ary, i)
}
