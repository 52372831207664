import dayjs from 'dayjs'
const md5 = require('md5')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
/**
 * @returns {string} backend api fetch required api
 */
const tokenGenerator = function () {
  const YYYYMMDDHH = dayjs()
    .tz('Asia/Taipei')
    .format('YYYYMMDDHH')
  const minute =
    Math.floor(
      Number(
        dayjs()
          .tz('Asia/Taipei')
          .format('mm')
      ) / 5
    ) * 5
  const mm = minute > 9 ? minute : '0' + minute
  const result = `moneybar_${YYYYMMDDHH}${mm}`
  return md5(result)
}
export default tokenGenerator
